<template>
    <div>
        <div class="flex">

         <div>
        <p class="font-bold text-900 text-lg m-0">
                Free User card info
            </p>
            <p class="m-0 text-sm text-700 mb-3" >
                Configurations for the Free User card
            </p>
        </div>   
            <Button @click="updateEntity" class="ml-auto h-3rem" :icon="'pi pi-cloud-upload'" :label="'Save configuration'" :value="'test'"></Button>

        </div>
        <p class="p-0 mb-1">Language</p>
        <Dropdown  :options="languageOptions" optionLabel="value" optionValue="id" v-model="selectedLanguage" class="w-12rem"></Dropdown>

        <CardLabelList :title="'Free User Card'" 
        :type="'fullSection'"
        :bottomImage="imageUrl"
        :labels="labels" 
        :language="selectedLanguage" 
        :formFields="labelsFormField"
        :maxLabels="3"
        @onAddNew="addLabel"
        @onRemove="removeLabel" 
        @onChangeOrder="changeLabelOrder"   
        @onUpdate="onUpdateLabel"
        />

   

        <h2>Banner Image</h2>
        <div class="bannerSection mx-auto flex" style="width: 420px">
            <div  class="flex relative w-full justify-content-center flex-wrap overflow-hiden">
                    <div class="w-full  flex justify-content-center flex-wrap  " >
                        <img  id="freeusercard-img"   style="width:420px; object-fit: contain" v-if="imageUrl" :src="imageUrl"   />
                  
                    </div>
                    <div  class="flex w-full absolute bottom-0 p-2 gap-2">
                    <Button v-if="imageUrl" icon="pi pi-times" class="border-none bg-gray-500" @click="removeImage"  aria-label="Cancel"  />
                    <Button class="w-full h-14 bg-gray-200 border-none relative cursor-pointer flex" style="color:gray">
                        <input style="opacity:0" 
                        class="absolute w-full cursor-pointer"   
                        type="file" 
                        id="freeusercard-image"
                        @change="updateImageProperty($event, 'freeusercard-image')"
                        accept="image/svg+xml, image/png"
                        />
                        <p class="h-0 m-auto">{{imageUrl ? 'Upload new image...' : "Upload image..."}}</p>
                    </Button>
                    </div>
            </div>
        </div>
                
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import CardLabelList from '../components/CardLabelList.vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { uploadFile} from '@/services/fileUploadService'



export default {
    components: {
        CardLabelList
    },
   
	setup() {
     
     
     
        const store = useStore();
        const toast = useToast();

        const storeGetterGetEntity = "appContents/freeUserCardInfo"
        const storeGetterGetAppContentId = "appContents/appContentId"

        const storeActionUpdateEntity = 'appContents/updateFreeUserCardInfo'
       const loading = ref(false)
       const freeUserCardInfo = ref([{
            labels: [],
            imageUrl: "",
            languageCode: "default"
        }])

        const appContentId = ref()

        const languageOptions = [{id: "default", value:"Default"}, {id: "da", value:"Danish"}]
        const selectedLanguage = ref("default")        


        const labels = computed(()=>{
            return freeUserCardInfo.value?.find(x => x.languageCode == selectedLanguage.value)?.labels
        })

        const imageUrl = computed(()=>{
            return freeUserCardInfo.value?.find(x => x.languageCode == selectedLanguage.value)?.imageUrl
        })

   

        const labelsFormField = [
            
				{
					id: 'title',
					type: 'text-input',
					title: 'Title',
					help: '',
				},
                {
					id: 'subtitleTop',
					type: 'text-input',
					title: 'Subtitle Top',
					help: '',
				},
                {
					id: 'titleSize',
					type: 'dropdown',
					title: 'Title Size',
                    config: {
					options: ["Small", "Medium", "Large"]
			
				},
					help: '',
				},
                {
					id: 'subtitleBottom',
					type: 'text-input',
					title: 'Subtitle Bottom',
					help: '',
				}
      ]

      

        function addLabel(formContext){
            if(labels.value){
               labels.value?.push(formContext)
               return
            }
           freeUserCardInfo.value.push({
                labels: [formContext],
                imageUrl: "",
                languageCode: selectedLanguage.value
            })

      }


        function removeLabel(index){
            labels.value?.splice(index, 1)
      }

      function changeLabelOrder(lbl){
            var label = labels.value[lbl.oldIndex];
            labels.value?.splice(lbl.oldIndex, 1)
            labels.value?.splice(lbl.newIndex, 0, label);
      }


    

      function onUpdateLabel(label, index){
            labels.value[index] = label
      }
      



        function removeImage(){
                freeUserCardInfo.value.find(x => x.languageCode == selectedLanguage.value).imageUrl = ""
        }
        

        async function updateImageProperty(event, id){
            event.preventDefault();
      
            if(imageUrl.value == null){
                freeUserCardInfo.value.push({
                labels: [],
                imageUrl: "",
                languageCode: selectedLanguage.value
                })
            }
          
			const input = document.getElementById(id);
			const file = input.files[0]	
         
            freeUserCardInfo.value.find(x => x.languageCode == selectedLanguage.value).imageUrl =  URL.createObjectURL(file) 
            var img = new Image();
            var objectUrl = URL.createObjectURL(file);
            img.src = objectUrl;
          
            img.onload = async function () {              


                if(!fileSizeIsUnderMax(file)){
                     toast.add({ severity: 'error', summary: 'Error', detail: 'Image is too large', life: 3000 });
                    freeUserCardInfo.value.find(x => x.languageCode == selectedLanguage.value).imageUrl = ""
                    return;
                }


                var url = await handleBannerImageUpload(file)
        
                freeUserCardInfo.value.find(x => x.languageCode == selectedLanguage.value).imageUrl = url

             };


			
		}


    
        async function handleBannerImageUpload(image){
    
            var logoFile = await uploadFile(image, "freeuser-banner", "all", store)
            return logoFile.url
       }

        

          function fileSizeIsUnderMax(image){
            return image?.size < 5000000
        }

        function setOrderNumbers(){

            labels.value.map((x, i) =>{
                labels.value[i].orderNumber = i;
            } )

        }

        async function updateEntity(){
            loading.value = true;

            setOrderNumbers()

            var obj = {appContentId: appContentId.value, freeUserCardInfo: freeUserCardInfo.value}
            console.log(obj)
            await store.dispatch(storeActionUpdateEntity, obj)
            .then(()=> {
                toast.add({ severity: 'success', summary: 'Success', detail: 'Free User Card was updated', life: 3000 });

            })
            .catch((err)=>{
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update Free User Card' + ': ' + err, life: 3000 });
            })
            .finally(()=>loading.value = false)
        }
        
        
        

        onMounted(()=>{
            appContentId.value = store.getters[storeGetterGetAppContentId]
            freeUserCardInfo.value = store.getters[storeGetterGetEntity]
        })

        return {
        selectedLanguage,
        languageOptions,
        labelsFormField,
        imageUrl,
        labels,
        removeLabel,
        onUpdateLabel,
        changeLabelOrder,
        addLabel,
        updateImageProperty,
        removeImage,
        updateEntity
                }

    
}
}
</script>


<style scoped>
.bannerSection{
min-height: 22rem;

background-image: linear-gradient(to left top, #081654, #122886, #122886, #081654, #081654);
}
</style>