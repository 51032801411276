import * as apiGetAppGames from '@/api/appGames/getAppGames';
import * as apiUpdateAppGame from '@/api/appGames/updateAppGame';
import * as apiCreateAppGame from '@/api/appGames/createAppGame';
import * as apiDeleteAppGame from '@/api/appGames/deleteAppGame';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
        appGameListItems: [],
	}),
	mutations: {
		isLoading(state, loading) {
			state.loading = loading;
		},
        gotAppGames(state, appGames) {
			state.appGameListItems = appGames
		},
	},
	actions: {
        async getAppGameListItems({ commit }) {
			commit('isLoading', true);

            const result = await apiGetAppGames.get();
                console.log(result)
			if (result) {
				commit('gotAppGames', result)
			}
			commit('isLoading', false);
		},

        async ensureAppGameListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.appGameListItems.length === 0) {
                await dispatch('getAppGameListItems')
            }

            commit('isLoading', false)
        },


        async createAppGame({ commit }, newAppGame) {
            commit('isLoading', true)

            await apiCreateAppGame.post(newAppGame);

            commit('isLoading', false)
        },

        async updateAppGame({ commit }, appGame) {
            commit('isLoading', true)

            await apiUpdateAppGame.put(appGame.id, appGame);

            commit('isLoading', false)
        },

        async deleteAppGame({ commit }, appGameId) {
            commit('isLoading', true)

            await apiDeleteAppGame.del(appGameId);

            commit('isLoading', false)
        },

    },
    getters: {
        appGameListItems: (state) => state.appGameListItems
    }
}