import * as apiGetUserConsents from '@/api/userConsents/getUserConsents'
// import * as apiAddUserConsent from '@/api/userConsents/addUserConsent'
import * as apiDeleteUserConsent from '@/api/userConsents/deleteUserConsent'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        userConsentListItems: {},
        detailedUserConsents: {}
    }),
    mutations: {
        gotUserConsentListItems(state, { userId, userConsents }) {
            console.log(userConsents)
            state.userConsentListItems[userId] = userConsents
        },

        gotUserConsent(state, userConsent) {
            state.detailedUserConsents[userConsent.id] = userConsent
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        // async add({ commit }, { userId, memberCardId }) {
        //     commit('isLoading', true)

        //     const payload = { 
        //         memberCardId: memberCardId 
        //     }

        //     console.log('CREATING CARD', userId, payload)

        //     const result = await apiAddUserConsent.post(userId, payload)

        //     commit('isLoading', false)

        //     return result
        // },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeleteUserConsent.del(entityId)

            commit('isLoading', false)

            return result
        },

        async getUserConsentListItems({ commit }, { userId }) {
            commit('isLoading', true)

            console.log('Getting User Member Card List Items');
        
            const result = await apiGetUserConsents.get(userId);

            if (result) {
                commit('gotUserConsentListItems', 
                { 
                    userId: userId,
                    userConsents: result 
                })
            }
        
            commit('isLoading', false)
        },

        /*
        async getUserConsent({ commit }, { userId }) {
            commit('isLoading', true)

            console.log('Getting User:', userId);
        
            const result = await apiGetUser.get(userId)
            
            if (result) {
                commit('gotUser', result)
            }
        
            commit('isLoading', false)
        },*/

        async ensureUserConsentListItems({ commit, dispatch, getters }, { userId }) {
            commit('isLoading', true)

            if (!getters.userConsentListItems(userId)) {
                await dispatch('getUserConsentListItems', { userId: userId })
            }

            commit('isLoading', false)
        },

        async ensureUserConsent({ commit, dispatch, getters }, { userConsentId }) {
            commit('isLoading', true)

            if (!getters.userConsent(userConsentId)) {
                console.log('User Consent not cached. Getting from API.')
                await dispatch('getUserConsent', { userConsentId: userConsentId } )
            }

            commit('isLoading', false)
        }
    },
    getters: {
        userConsentListItems: (state) => (userId) => {
            return state.userConsentListItems[userId]
        },
        userConsent: (state) => (userConsentId) => {
            return state.detailedUserConsents[userConsentId]
        }
    }
};