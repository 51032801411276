import * as apiUpdateMembershipPerks from '@/api/membershipPerks/updateMembershipPerks';
import * as apiUpdateMembershipSignUpPerks from '@/api/membershipPerks/updateMembershipSignUpPerks';
import * as apiAssignSignUpPerkToMembersNow from '@/api/membershipPerks/assignSignUpPerkToMembersNow';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
	}),
	mutations: {
		isLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async update({ commit }, membership) {
			commit('isLoading', true);

			await apiUpdateMembershipPerks.put(membership.id, membership);

			commit('isLoading', false);
		},
		async updateSignUpPerks({ commit }, membership) {
			commit('isLoading', true);

			await apiUpdateMembershipSignUpPerks.put(membership.id, membership);

			commit('isLoading', false);
		},
		async assignSignUpPerksToMembersNow({ commit }, { membershipId, perkConfig }) {
			commit('isLoading', true);

			var res = await apiAssignSignUpPerkToMembersNow.post(membershipId, perkConfig);

			commit('isLoading', false);
			return res;
		},
	},
	getters: {},
};
