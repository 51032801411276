<template>
	<div class="min-h-screen max-h-screen max-w-screen w-full surface-ground ">
		<NavBar :breadcrumbs="breadcrumbs"></NavBar>
		<div class="h-full fixed " style="z-index:1; top:84px">
			<UserSidebar :user-id="userId"></UserSidebar>
		</div>
		<div class="flex pageview h-full " style="padding-top: 84px">
			<div class="w-full surface-section px-6 pt-0 " >
				<router-view class="" />
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue';
import UserSidebar from './UserSidebar';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
	inject: ['hasPermission'],
	name: 'UserLayout',
	components: {
		UserSidebar,
	},

	props: ['userId'],

	setup(props) {
		const store = useStore()
		const route = useRoute();

		const breadcrumbs = computed(() => {
			var user = store.getters['users/user'](props.userId)
			return [
				{ title: "Users", route: "users" },
				{ title: user ? user?.firstName + ' ' + user?.lastName : '...', route: "user-general" },
				{ title: route.meta?.breadcrumb ?? "..." }
			]
		})
		return { breadcrumbs, }
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.pageview {
	margin-left: 280px;
}

@media screen and (max-width: 991px) {
	.pageview {
		margin-left: 0px;
	}
}
</style>
