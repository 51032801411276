<template>

    <Sidebar v-model:visible="editOfferSidebarVisible" class="p-sidebar-md" position="right" :showCloseIcon="false">
    <div >

        <div class="flex flex-column mb-3">
            <h3 class="mb-1">Update special offer</h3>
        </div>     
 
        <Form :fields="formFields" :context="editedSpecialOffer" :actionLabel="'Update offer'" @onChange="onFormChange" @onAction="updateOffer" :actionIcon="'pi pi-plus-circle'" />

    </div>
</Sidebar>



     <PageHeader :title="'Subscription Plans'" 
       :description="'Here you can mange the Subscription Plans'" 
       :showEnabledChip="false" 
       :entityName="'Subscription Plans'" 
       />

    <div v-if="!isLoading" class="surface-section p-6 pt-0 pb-2">
   
        <div class="bg-gray-200 p-2 border-round">
            <div class="flex gap-2 align-items-center">
                <h3>Special Offer</h3>
                
                <Chip
                    v-tooltip="
                    `Special Offer is ${
                        chipEnabled ? 'enabled' : 'disabled'
                    } in the App`
                    "
                    :class="chipEnabled ? 'bg-green-400' : 'bg-orange-400'"
                    class="text-white px-3 "
                    style="height: 1.5rem"
                    :label="chipEnabled ? 'Enabled' : 'Disabled'"
                />

                <div class="flex gap-2 ml-auto align-items-center">
                    <Button
                    @click="showEdit"
                    :label="'Edit'"
                    icon="pi pi-plus-circle"
                    />

                    <Button
                    :class="
                        !chipEnabled
                        ? 'bg-green-400 border-green-400'
                        : 'bg-orange-400 border-orange-400'
                    "
                    :label="chipEnabled ? 'Disable' : 'Enable'"
                    :icon="`pi ${chipEnabled ? 'pi-stop' : 'pi-play'}`"
                    v-tooltip="
                        `${
                        !chipEnabled ? 'Enable' : 'Disable'
                        } special offer in the App`
                    "
                    @click="onEnable"
                    />
                
                
                </div>
 
            </div>

            <div>
       
                <div>
                    <div class="flex gap-6">
                        <div class="">
                            <h4 class="m-0">Price</h4>
                            <h5 class="m-0 ">{{specialOffer?.price}} DKK</h5>
                        </div>
                        <div v-if="specialOffer?.offerType == 'FixedEndDate'">
                            <h4 class="m-0">End date</h4>
                            <h5 class="m-0">{{formatDateSimple(specialOffer?.endDate) ?? 'Not defined'}}</h5>
                        </div>
                        <div v-else>
                            <h4 class="m-0">Number of days</h4>
                            <h5 class="m-0">{{specialOffer?.numberOfDays ?? 'Not defined'}}</h5>
                        </div>

                    </div>
                    <h4 class="mb-2">Title and terms</h4>
                     <Dropdown  :options="languageOptions" optionLabel="value" optionValue="id" v-model="selectedLanguage" class="w-12rem"></Dropdown>
                   
                    <div class="mt-2 card mx-auto p-2 text-center border-round flex flex-wrap" style="min-height: 3rem">

                         <div class="w-full">
                            <span  class="px-3 limited-label">LIMITED!!!</span>
                        </div>       
                        <div class="w-full flex offer align-items-center border-1 border-round w-22rem mx-auto">
                            <div style="width:80%" class="text-center text-white font-bold">
                                {{specialOfferLabel?.title}}
                            </div>
                            <div class="flex text-center gold-border border-left-1 h-full " style="width:20%">
                                <span class="mx-auto">{{specialOffer?.price}} <span style="font-size:6px">DKK</span></span>
                            </div>
                        </div>
                        <div class="w-full text-center">
                       <p class="w-22rem mx-auto text-white" style="font-size: 10px">{{specialOfferLabel?.terms}}</p>

                        </div>

                    </div>

                </div>
        

            </div>
        </div>
    
    </div>
        <Loading v-else class="mt-12rem" :title="'Loading Subscription plans'" :full="false" />

</template>

<script>
import { computed, onMounted, ref } from 'vue'
import PageHeader from '../../components/PageHeader.vue'
import { useStore } from 'vuex'
import useFormatDate from '../../utils/useFormatDate.js';
import { useToast } from 'primevue/usetoast';
import {specialOfferFormFieldsDate, specialOfferFormFieldsDays} from './formFields'

export default {

  components: {PageHeader},

  setup() {

    const store = useStore()
    const toast = useToast()
    
    const actionGetSpecialOffers = "paymentPlanOffers/getSpecialOffer";
    const actionUpsertSpecialOffer = "paymentPlanOffers/upsertSpecialOffer";
    const actionEnableSpecialOffer = "paymentPlanOffers/enableSpecialOffer";

    const getterSpecialOffer = "paymentPlanOffers/specialOffer";


    const specialOffer = ref()
    const specialOfferLabel = computed(() => specialOffer.value?.offerLabels?.find(x => x.languageCode == selectedLanguage.value))
    const editedSpecialOffer = ref(
        {
            language: "Default",
            title: "",
            terms: "",
            price: 49,
            offerType: "FixedNumberOfDays",
            numberOfDays: 60,
            endDate: new Date().toLocaleDateString()
        }
    )
    const formFields = ref(specialOfferFormFieldsDate)

    const editOfferSidebarVisible = ref(false)
    const isLoading = ref(false)
    
    const selectedLanguage = ref("default")
    const languageOptions = [{id: "default", value:"Default"}, {id: "da", value:"Danish"}]

    const chipEnabled = computed(() => specialOffer.value?.enabled)


    function onFormChange(val){
        if(val.offerType == "FixedEndDate")
           formFields.value = specialOfferFormFieldsDate
        if(val.offerType == "FixedNumberOfDays")
            formFields.value = specialOfferFormFieldsDays
      
    }

    function updateOffer(val){
        var offerLabels = specialOffer.value?.offerLabels?.map(x => {
            if(x.languageCode == selectedLanguage.value){
                x.title = val.title
                x.terms = val.terms
            }
            return x
        })

        if(val?.offerType == "FixedEndDate")
            val.numberOfDays = null
        else 
            val.endDate = null
    
    
        saveOffer({...val, offerLabels});
        editOfferSidebarVisible.value = false

    }

    async function saveOffer(offer){
        isLoading.value = true;
        await store.dispatch(actionUpsertSpecialOffer, offer)
        .then(()=>{
            specialOffer.value = {...offer}
            toast.add({ severity: 'success', summary: 'Success', detail: 'Special offer was updated', life: 3000 });
        })
         .catch((err)=>{
            toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update specail offer: ' + err, life: 3000 });
       })
       isLoading.value = false;

    }

    async function onEnable(){
        isLoading.value = true;
        var response = !chipEnabled.value ? 'enabled' : 'disabled'

        await store.dispatch(actionEnableSpecialOffer, !chipEnabled.value)
       .then(()=>{
            toast.add({ severity: 'success', summary: 'Success', detail: 'Special offer is now ' + response, life: 3000 });
       })
       .catch((err)=>{
            toast.add({ severity: 'error', summary: 'Error', detail: 'Could not ' + response +' Special offer: ' + err, life: 3000 });

       })
        getSpecialOffer()
    }

    function showEdit(){
        editedSpecialOffer.value =  {...specialOffer.value}
        var label = specialOffer.value?.offerLabels.find(x => x.languageCode == selectedLanguage.value)
        editedSpecialOffer.value.title = label?.title ?? ""
        editedSpecialOffer.value.terms = label?.terms ?? ""

        editOfferSidebarVisible.value = true
    }

    function formatDateSimple(date){
			return date != null ? useFormatDate().formatDateSimple(date) : "Not defined";
    }

    async function getSpecialOffer(){
        isLoading.value = true
        await store.dispatch(actionGetSpecialOffers)
        .catch((err)=>{
            toast.add({ severity: 'error', summary: 'Error', detail: 'Could not get Subscription plan offers: ' + err, life: 3000 });
        });
        specialOffer.value = store.getters[getterSpecialOffer]
        isLoading.value = false
    }   


    async function getRequiredData(){
        
        await getSpecialOffer();
        if(specialOffer.value == undefined){
            specialOffer.value = {
                offerLabels: [{languageCode: "da", title: "", terms: ""}, {languageCode: "default", title: "", terms: ""}],
                offerType: "FixedEndDate",
                price: 0,
                endDate: new Date(),
                numberOfDays: 0
            }
            await store.dispatch(actionUpsertSpecialOffer, specialOffer.value )
            
        }
       
    }

 

    onMounted(()=>{
        getRequiredData();
    })

    return {
        editOfferSidebarVisible,
        languageOptions,
        selectedLanguage,
        formFields,
        specialOffer,
        specialOfferLabel,

        editedSpecialOffer,
        chipEnabled,
        isLoading,

        showEdit,
        onFormChange,
        onEnable,
        updateOffer,
        formatDateSimple

    }
  }
}
</script>


<style lang="scss" scoped>

.card{
    width: 30rem;;
    background-image: linear-gradient(to right top, #081654, #081654, #122886, #122886, #122886);
}
.offer{
    border-color: rgb(255, 213, 0);
box-shadow: 0px 0px 4px 1px rgb(255, 213, 0);
}

.gold-border{
        border-color: rgb(255, 213, 0);
        color: rgb(255, 213, 0); ;
        font-weight: bold;
        align-items: center;
        font-size: 1.5rem;
}
.limited-label{
    font-size:7px; 
    background:  linear-gradient(to right, #ffd000, #f5ff86, #ffd000, #f5ff86, #ffd000);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0px 0px 4px 1px rgb(255, 213, 0);
    font-weight: bold;
    color: black;
    font-stretch: extra-expanded;


}
</style>

