import * as apiGetSSubscriptionEvents from '@/api/subscriptions/getUserSubscriptionEvents';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
		subscriptionEvents: [],
	}),
	mutations: {
		gotSubscriptionEvents(state, events) {
			state.subscriptionEvents = events;
		},

		isLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async getSubscriptionEvents({ commit }, { userId, stripeSubscriptionId, billWerkSubscriptionHandle }) {
			commit('isLoading', true);

			console.log('Getting Subscription events for:', userId, stripeSubscriptionId, billWerkSubscriptionHandle);

			const result = await apiGetSSubscriptionEvents.get(userId, stripeSubscriptionId, billWerkSubscriptionHandle);

			if (result) {
				commit('gotSubscriptionEvents', result);
			}

			commit('isLoading', false);
		},
	},
	getters: {
		subscriptionEvents: (state) => state.subscriptionEvents,
		isLoading: (state) => state.loading,
	},
};
