import * as apiGetPermissions from "@/api/idam/getPermissions";
import menuItems from "@/layouts/main/menuItems.js";
import { _ } from "core-js";
import getCountries from '@/utils/getCountries.js'
// import { get } from "lodash";

export default {
    namespaced: false,
    state: () => ({
        menuItems: menuItems,
        hasFetchedPermissions: false,
        permissions: null,
    }),
    mutations: {
        GOT_PERMISSIONS(state, permissions) {
            console.log("GOT_PERMISSIONS", permissions);
            state.permissions = permissions
            state.hasFetchedPermissions = true;
        },
    },
    actions: {
        async getPermissions({ commit, state }) {
            // Don't refetch permissions if we already have them. Logic should be moved out of RootLayout and into either App.vue or Main.js so this isn't necessary.
            if (state.permissions && state.permissions.globalPermissions.length > 0) return;

            const result = await apiGetPermissions.get();
            if (result) {
                commit("GOT_PERMISSIONS", result);
                console.log("[IDAM] Permissions received", result);
            }
        },
    },
    getters: {

        hasFetchedPermissions: state => state.hasFetchedPermissions,
        hasPermission: state => (permId) => {
            const countryCode = getCountries().getCountryCode(localStorage.activeUserAccount);
            if (!countryCode || !state.permissions) return false;
            var hasPermission = state.permissions.filter(p => p.entityInstance === countryCode && p.globalPermissions.includes(permId)).length > 0;
            // console.log("hasPermission", permId, hasPermission);
            return hasPermission;
        },
        hasRole: state => (roleId) => {
            const countryCode = getCountries().getCountryCode(localStorage.activeUserAccount);
            if (!countryCode || !state.permissions) return false;
            var hasRole = state.permissions.filter(p => p.entityInstance === countryCode && p.roleId == roleId).length > 0;
            // console.log("hasPermission", permId, hasPermission);
            return hasRole;
        },
        hasPermissionForEntity: state => (permId, forEntityId) => {
            if (!state.permissions) return false;
            return state.permissions.globalPermissions.includes(permId) || _.some(state.permissions.venuePermissions, v => v.venueId === forEntityId && v.permissions.includes(permId))
        },
        sidebarItems: (state, getters) => {
            return state.menuItems.filter((item) => item.permission === null || getters.hasPermission(item?.permission) || getters.hasRole(item?.role));
        },
        firstSidebarItem: (_, getters) => {
            const items = getters.sidebarItems[0]
            return (items)[0];
        },
        hasSidebarItems: (_, getters) => {
            return getters.sidebarItems.length > 0;
        }
    }
};
