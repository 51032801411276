import * as apiGetCatchOffers from '@/api/catchOffers/getCatchOffers';
import * as apiUpsertCatchOffer from '@/api/catchOffers/upsertCatchOffers';
import * as apiEnableCatchOffer from '@/api/catchOffers/enableCatchOffers';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
        catchOfferListItems: [],
	}),
	mutations: {
		isLoading(state, loading) {
			state.loading = loading;
		},
        gotCatchOffers(state, CatchOffers) {
			state.CatchOfferListItems = CatchOffers
		},
	},
	actions: {
        async getCatchOfferListItems({ commit }) {
			commit('isLoading', true);

            const result = await apiGetCatchOffers.get();
                console.log(result)
			if (result) {
				commit('gotCatchOffers', result)
			}
			commit('isLoading', false);
		},

        async ensureCatchOfferListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.CatchOfferListItems.length === 0) {
                await dispatch('getCatchOfferListItems')
            }

            commit('isLoading', false)
        },


        async upsertCatchOffer({ commit }, payload) {
            commit('isLoading', true)

            await apiUpsertCatchOffer.post(payload);

            commit('isLoading', false)
        },

        async enableCatchOffer({ commit }, payload) {
            commit('isLoading', true)

            await apiEnableCatchOffer.put(payload);

            commit('isLoading', false)
        }

    },
    getters: {
        catchOfferListItems: (state) => state.CatchOfferListItems
    }
}