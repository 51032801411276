<template>
	<div class="flex flex-column mb-2">
		<p v-if="configs == null || configs.length == 0">Please add a Benefit</p>

		<div style="padding-left: 12px">
			<div v-for="(benefitConfig, index) in configs" :key="index" class="field">
				<p style="font-size: 100%; font-weight: 600; margin-bottom: 12px">Benefit {{ index + 1 }}</p>
				
                <label :for="'config-' + index" style="font-size: 90%; margin-bottom: 2px">Subscription tier</label>
				<div class="p-inputgroup">
					<Dropdown v-model="benefitConfig.subscriptionTier" :options="subscriptionTiers" optionLabel="title" optionValue="id" placeholder="Select Subscription Tier" />
					<Button @click="deleteConfig(index)" icon="pi pi-times" class="p-button-warning" />
				</div>

				<label style="font-size: 90%; margin-bottom: 2px" class="mt-4">Title</label>
				<InputText v-model="benefitConfig.title" />

                <label style="font-size: 90%; margin-bottom: 2px" class="mt-4">Description</label>
				<Textarea v-model="benefitConfig.description"
                    :autoResize="true"
                    rows="5"
                    cols="30" />

                <!--
				<label style="font-size: 90%; margin-bottom: 2px" class="mt-2">Occurence</label>
				<div class="p-inputgroup">
					<InputNumber v-model="benefitConfig.occurence" style="max-width: 200px" />
					<Dropdown
						v-model="benefitConfig.occurenceType"
						:options="durationTypes"
						optionLabel="title"
						optionValue="id"
						placeholder="Select duration"
						style="margin-left: 8px; max-width: 200px"
					/>
				</div>

				<label style="font-size: 90%; margin-bottom: 2px" class="mt-4">Expires</label>
				<InputSwitch v-model="benefitConfig.expires" />

				<div v-if="benefitConfig.expires">
					<label style="font-size: 90%; margin-bottom: 2px" class="mt-2">Expires in</label>
					<div class="p-inputgroup">
						<InputNumber v-model="benefitConfig.expire" style="max-width: 200px" />
						<Dropdown
							v-model="benefitConfig.expireType"
							:options="durationTypes"
							optionLabel="title"
							optionValue="id"
							placeholder="Select duration"
							style="margin-left: 8px; max-width: 200px"
						/>
					</div>
				</div>-->
			</div>
			<Button @click="addConfig" label="Add Benefit" class="mt-2 p-button-secondary p-button-sm" />
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue';

//import { useStore } from 'vuex';
//import { useToast } from 'primevue/usetoast';

import Textarea from 'primevue/textarea';

export default {
    props: ["context", "field"],
    setup(props) {
        //const store = useStore();
        //const toast = useToast();
        const loading = ref(true);
        const subscriptionTiers = ref([
            {
                id: "NoMembership",
                title: "No membership",
            },
           
        ]);
       

        const configs = props.context.benefits != null ? ref(props.context.benefits) : ref(props.context);
  
        function addConfig() {
            console.log("addConfig", configs.value);
            configs.value.push({
                /*benefitId: {
                    id: null,
                },*/
                title: '',
                description: '',
                subscriptionTier: 'NoMembership'
            });
        }
        function deleteConfig(index) {
            configs.value.splice(index, 1);
        }
        async function loadRequiredData() {
            loading.value = true;
    

            loading.value = false;
        }
        onMounted(async () => {
            await loadRequiredData();
        });
        return {
            subscriptionTiers,
            configs,
            addConfig,
            deleteConfig,
        };
    },
    components: { Textarea }
};
</script>
