import apiClient from '@/services/axios'

export async function post() {
  var response = await apiClient.post('/roles/import')
  if (response) {
    return response.data
  }

  throw new Error('Unable to import Roles.')
}
