<template>
 

   <div class="min-h-screen max-h-screen max-w-screen w-full surface-ground " v-if="hasPermission('np.backoffice.venues.show')">
         <Loading v-if="loading" :title="'Loading Venue...'" :full="true" />

      <NavBar :breadcrumbs="breadcrumbs"></NavBar>
      <div class="h-full fixed" style="z-index:1; top:84px">
            <VenueSidebar :venueId="venueId"></VenueSidebar>
      </div>
      <div class="flex pageview " style="margin-top: 84px" >
        <div class="w-full h-screen surface-section px-6 pt-0">
            <router-view />
          </div>
      </div>
  </div>
 
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import VenueSidebar from './VenueSidebar';

export default {
  inject: ['hasPermission'],
  name: "VenueLayout",
  components: {
    VenueSidebar
  },
  props: ['venueId'],
  data: function () {
    return {
    }
  },

  methods: {
  },
  setup(props){
		const store = useStore()
		const route = useRoute();

		const breadcrumbs = computed(()=>{
			var venue =  store.getters['venues/venue'](props.venueId)
			return [
			{title: "Venues", route: "venues"}, 
			{title: venue ? venue?.name  : '...', route: "venue"},
			{title: route.meta?.breadcrumb ?? "..." }
			]
		})
		return {breadcrumbs }
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
