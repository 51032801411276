<template>
  <Sidebar
    v-if="hasPermission('np.backoffice.perks.addperk')"
    v-model:visible="createNewSidebarVisible"
    class="p-sidebar-lg"
    position="right"
    :showCloseIcon="false"
  >
    <Loading
      v-if="creating"
      :title="'Creating ' + entityName + '...'"
      :full="false"
    />
    <div v-else>
      <h3>Create {{ entityName }}</h3>
      <Form
        :fields="fields"
        :context="newEntity"
        @onAction="createEntity"
        :actionLabel="'Create'"
        :actionIcon="'pi pi-plus-circle'"
      />
    </div>
  </Sidebar>

  <Sidebar
    v-if="hasPermission('np.backoffice.perks.editdelete')"
    v-model:visible="updateSidebarVisible"
    class="p-sidebar-lg"
    position="right"
    :showCloseIcon="false"
  >
    <Loading
      v-if="updating"
      :title="'Updating ' + entityName + '...'"
      :full="false"
    />
    <div v-else>
      <h3>Edit {{ entityName }}</h3>
      <Form
        :fields="fields"
        :context="selectedEntity"
        @onAction="updateEntity"
        :actionLabel="'Update'"
        :actionIcon="'pi pi-cloud-upload'"
      />
    </div>
  </Sidebar>

  <div
    class="surface-section p-6 pt-0"
    v-if="hasPermission('np.backoffice.perks.show')"
  >
    <div class="flex">
      <div style="flex: 1">
        <div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
        <div class="text-700 mb-5 line-height-3 mb-5">
          {{ pageDescription }}.
        </div>
      </div>
      <div class="flex justify-content-end align-items-center">
        <Button
          v-if="hasPermission('np.backoffice.perks.addperk')"
          @click="createNewSidebarVisible = true"
          :label="'New ' + entityName"
          icon="pi pi-plus-circle"
        />
      </div>
    </div>

    <Loading
      v-if="loading"
      :full="false"
      :title="'Loading ' + pageTitle + '...'"
    />
    <div v-else class="card">
      <DataTable
        :value="entities"
        dataKey="id"
        @row-click="onRowClicked"
        class="p-datatable-lg"
        :rowHover="true"
        responsiveLayout="scroll"
      >
        <template #empty> No {{ pageTitle }} found. </template>
        <template #loading> Loading {{ pageTitle }}. Please wait. </template>
        <Column>
          <template #body="{ data }">
            <img
              v-if="data.pictureUrl"
              :src="data.pictureUrl"
              style="width: 60px; height: 60px; object-fit: contain"
            />
          </template>
        </Column>
        <Column field="perkName" header="Name"></Column>

        <Column header="Start date">
          <template #body="{ data }">
            {{ formatDate(data.autoPerkStartUtc) }}
          </template>
        </Column>

        <Column header="End date">
          <template #body="{ data }">
            {{ formatDate(data.autoPerkEndUtc) }}
          </template>
        </Column>

        <Column
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="{ data }">
            <Button
              v-if="hasPermission('np.backoffice.perks.editdelete')"
              @click="confirmDeleteEntity(data)"
              type="button"
              icon="pi pi-trash"
            ></Button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import { useStore } from "vuex";

import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

export default {
  inject: ["hasPermission"],
  components: {},
  setup() {
    const actionGetPerks = "perks/getPerkListItems";
    const getterGetPerks = "perks/perkListItems";
    const storeActionGetEntities = "onboardingperks/getPerkListItems";
    const storeGetterGetEntities = "onboardingperks/onboardingPerkListItems";
    const storeActionCreateEntity = "onboardingperks/create";
    const storeActionUpdateEntity = "onboardingperks/update";
    const storeActionDeleteEntity = "onboardingperks/delete";
    const entityName = "Onboarding Perk";
    const pageTitle = "Onboarding Perks";
    const pageDescription =
      "These perks are automatically assigned to users when they complete the onboarding flow and have successfully created a NightPay profile.";

    const fields = ref([]);

    const loading = ref(true);
    const creating = ref(false);
    const assigning = ref(false);
    const updating = ref(false);

    const useFormatDate = inject("useFormatDate");

    const store = useStore();
    const toast = useToast();
    const confirm = useConfirm();

    const entities = ref([]);
    const perks = ref([]);
    const selectedPerks = ref([]);
    const selectedCountry = ref({});

    const newEntity = ref({
      perkId: "",
      autoPerkStartUtc: null,
      autoPerkEndUtc:null,
      validFromUtc:null,
      validToUtc: null,
      visibleFromUtc: null,
      quantity: 1,
      expires: true,
    });
    const selectedEntity = ref({});

    const createNewSidebarVisible = ref(false);
    const updateSidebarVisible = ref(false);

    function onRowClicked(payload) {
      selectedEntity.value = JSON.parse(JSON.stringify(payload.data));

      updateSidebarVisible.value = true;
    }

    function confirmDeleteEntity(data) {
      confirm.require({
        message: "Are you sure you want to delete the " + entityName + "?",
        header: "Please confirm",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          loading.value = true;

          try {
            await store.dispatch(storeActionDeleteEntity, data.id);
            await reloadEntities();

            toast.add({
              severity: "success",
              summary: "Success",
              detail: entityName + " deleted",
              life: 3000,
            });
          } catch (err) {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: "Could not delete " + entityName + ": " + err,
              life: 3000,
            });
          }

          loading.value = false;
        },
        reject: () => {},
      });
    }

    async function createEntity(formContext) {
      creating.value = true;

      try {
        await store.dispatch(storeActionCreateEntity, formContext);

        toast.add({
          severity: "success",
          summary: "Success",
          detail: entityName + " created",
          life: 3000,
        });
        createNewSidebarVisible.value = false;

        loading.value = true;
        await reloadEntities();
      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Could not create " + entityName + ": " + err,
          life: 3000,
        });
      }

      loading.value = false;
      creating.value = false;
    }

    async function updateEntity(formContext) {
      updating.value = true;

      try {
        console.log("UPDATING ENTITY", formContext);
        await store.dispatch(storeActionUpdateEntity, formContext);

        toast.add({
          severity: "success",
          summary: "Success",
          detail: entityName + " updated",
          life: 3000,
        });
        updateSidebarVisible.value = false;

        loading.value = true;
        await reloadEntities();
      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Could not update " + entityName + ": " + err,
          life: 3000,
        });
      }

      loading.value = false;
      updating.value = false;
    }

    async function reloadEntities() {
      try {
        await store.dispatch(storeActionGetEntities);
        await store.dispatch(actionGetPerks);
        const loadedEntities = store.getters[storeGetterGetEntities];
        perks.value = store.getters[getterGetPerks];
        entities.value = loadedEntities;
      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Could not load " + pageTitle + ": " + err,
          life: 3000,
        });
      }
    }

    async function loadRequiredData() {
      loading.value = true;

      reloadEntities();

      fields.value = [
        {
          id: "autoPerkStartUtc",
          type: "time",
          title: "Onboarding start from",
        },
        {
          id: "autoPerkEndUtc",
          type: "time",
          title: "Onboarding end at",
        },
        {
          id: "validFromUtc",
          type: "time",
          title: "Perk Valid from",
        },
        {
          id: "validToUtc",
          type: "time",
          title: "Perk Valid to",
        },
        {
          id: "visibleFromUtc",
          type: "time",
          title: "Perk visible from",
        },
        {
          id: "quantity",
          type: "number-input",
          title: "Perk Quantity",
        },
        {
          id: "expires",
          type: "switch",
          title: "Perk expires",
        },


        {
          id: "perkId",
          type: "dropdown",
          title: "Perk",
          filter: "true",
          config: {
            options: perks,
            optionLabel: "title",
            optionValue: "id",
            placeholder: "Please select perk",
          },
          help: "Select a perk to assign to the user",
        },
      ];

      loading.value = false;
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      entityName,
      pageTitle,
      pageDescription,
      loading,
      creating,
      updating,
      assigning,

      fields,
      newEntity,
      selectedEntity,
      createEntity,
      updateEntity,
      confirmDeleteEntity,
      //   assignPerks,

      entities,
      selectedPerks,
      selectedCountry,

      createNewSidebarVisible,
      updateSidebarVisible,

      onRowClicked,

      formatDate: useFormatDate().formatDate,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.p-multiselect {
  width: 38rem;
}

.multiselect-custom .p-multiselect-label:not(.p-placeholder) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.multiselect-custom .perk-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.multiselect-custom .perk-item-value img.flag {
  width: 17px;
}

@media screen and (max-width: 640px) {
  .p-multiselect {
    width: 100%;
  }
}
</style>
