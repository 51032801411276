import apiClient from '@/services/axios';

export async function put(memberCardId, payload) {
	var perkConfigs = { perkConfigs: payload.perkConfigs };
	var response = await apiClient.put('MemberCardPerks?memberCardId=' + memberCardId, perkConfigs);
	if (response) {
		return response.data;
	}

	throw new Error('Unable to update MemberCard perk configs.');
}
