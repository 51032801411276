import apiClient from '@/services/axios'

export async function del(id) {
  var response = await apiClient.delete('/UserPerks/' + id)
  if (response) {
    return response.data
  }

  throw new Error('Unable to delete User Perk.')
}
