import apiClient from '@/services/axios'

export async function get() {
    var response = await apiClient.get(process.env.VUE_APP_LIFE_API_BASE_URL + `/Idam/User/Roles`)
    if (response) {
        return response.data
    }

    throw new Error('Unable to get IDAM permissions for user')
}
