import apiClient from '@/services/axios'

export async function get(userId) {
  var response = await apiClient.get('/Transactions/Subscription?userId='+userId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get transactions for subscription')
}
