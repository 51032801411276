import * as apiGetProductCategories from '@/api/productCategories/getProductCategories'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        productCategoryListItems: []
    }),
    mutations: {
        gotProductCategoryListItems(state, productCategories) {
            state.productCategoryListItems = productCategories
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getProductCategoryListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Product Category List Items');
        
            const result = await apiGetProductCategories.get();

            if (result) {
                commit('gotProductCategoryListItems', result)
            }
        
            commit('isLoading', false)
        },

        async ensureProductCategoryListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.productCategoryListItems.length === 0) {
                await dispatch('getProductCategoryListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        productCategoryListItems: (state) => state.productCategoryListItems,
        productCategory: (state) => (productCategoryId) => {
            return  _.find(state.productCategoryListItems, function(productCategory) { return productCategory.id === productCategoryId; });
        }
    }
};