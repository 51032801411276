<template>
    <div v-for="offer, i in offers" :key="offer" class="p-4 flex align-items-center gap-3 relative offer">
            <div class="right-0 label-edit offer-edit">
                <Button   class=" my-auto" icon="pi pi-pencil" label="" @click="editOffer(offer)"></Button>
                <Button class="text-xs mx-2 p-button-danger  my-auto " icon="pi pi-trash" label="" @click="removeOffer(i)"></Button>
            </div>
            <div class="flex-column h-full gap-2 top-0 justify-center offer-edit">
                <Button  class="text-xs p-button  text-gray-300  mt-auto " icon="pi pi-chevron-up " label="" @click="moveOffer(i, i - 1)"></Button>
                <Button class="text-xs p-button text-gray-300 mb-auto " icon="pi pi-chevron-down " label="" @click="moveOffer(i, i + 1)"></Button>
            </div>

        <div>
            <img :src="offer.iconUrl" class="w-5rem" />
        </div>
        <div>
            <span class="text-2xl text-white offer-title"> <p v-html="offer.title" > </p> </span>
            <p class="text-white offer-terms">{{offer.terms}}</p>
        </div>
    </div>
</template>

<script>

export default {

    props: ['offers'],
    emits: ['onEditOffer', 'onRemoveOffer', 'onMoveOffer'],
    setup(props, {emit}) {
        
        function editOffer(offer){
            emit("onEditOffer", offer)
        }

        function removeOffer(index){
            emit("onRemoveOffer", index)
        }

        function moveOffer(oldIndex, newIndex){
            console.log(oldIndex, newIndex)
            emit("onMoveOffer", {oldIndex, newIndex})
        }


        return {
            editOffer,
            removeOffer,
            moveOffer
        }

    },
}
</script>


<style scoped>
p b{
    font-weight: bold;
}

.offer-edit{
    display: none;
}

.offer:hover  .offer-edit{
  display:  flex;
  position: absolute;
}

.offer:hover {
      background-color: rgba(255, 255, 255, 0.45);

}

</style>