import apiClient from '@/services/axios'

export async function get(perkId) {
  var response = await apiClient.get('/perks/' + perkId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Perk.')
}
