<template>
  <div class="page">
    <div v-if="loading" class="loading-container">
      <div class="spinner-grow" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div style="text-align: center;">
        <h2>Welcome to {{activeUserAccount.title}}</h2>
      </div>
      <p class="mb-4">
        We're happy to have you on board. {{activeUserAccount.title}} is powered by NightPay.<br/>
        Please check out some of the things that you can do on the platform.
      </p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {
  },
  setup() {
    const loading = ref(true)

    const activeUserAccount = ref(undefined)

    const store = useStore()

    async function loadRequiredData() {
      loading.value = true

      activeUserAccount.value = store.getters['accounts/activeUserAccount']

      loading.value = false
    }

    onMounted(async () => {
      await loadRequiredData()
    })

    return {
      loading,

      activeUserAccount
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  display: flex;
}

.left {
  flex: 1;
}

.right {
  width: 350px;
}
</style>
