import * as apiCreateRole from '@/api/roles/createRole'
import * as apiGetRole from '@/api/roles/getRole'
import * as apiGetRoles from '@/api/roles/getRoles'
import * as apiUpdateRole from '@/api/roles/updateRole'
import * as apiDeleteRole from '@/api/roles/deleteRole'

import * as apiImportRoles from '@/api/roles/importRoles'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        roleListItems: [],
        detailedRoles: {},
        lastImportStats: {
            noChanges: 0,
            updated: 0,
            added: 0
        }
    }),
    mutations: {
        gotRoleListItems(state, roles) {
            state.roleListItems = roles
        },

        gotRole(state, role) {
            state.detailedRoles[role.id] = role
        },

        importedRoles(state, importStats) {
            state.lastImportStats = importStats
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async importIdamRoles({ commit }) {
            commit('isLoading', true)

            console.log('Importing Roles');
        
            const result = await apiImportRoles.post()
            
            if (result) {
                commit('importedRoles', result)
            }
        
            commit('isLoading', false)
        },

        async getRoleListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Role List Items');
        
            const result = await apiGetRoles.get();

            if (result) {
                commit('gotRoleListItems', result)
            }
        
            commit('isLoading', false)
        },

        async create({ commit }, payload) {
            commit('isLoading', true)

            console.log('Posting:', payload)

            const result = await apiCreateRole.post(payload)

            commit('isLoading', false)

            return result
        },

        async update({ commit }, role) {
            commit('isLoading', true)

            await apiUpdateRole.put(role.id, role)

            commit('isLoading', false)
        },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeleteRole.del(entityId)

            commit('isLoading', false)

            return result
        },

        async getRole({ commit }, { roleId }) {
            commit('isLoading', true)

            console.log('Getting Role:', roleId);
        
            const result = await apiGetRole.get(roleId)
            
            if (result) {
                commit('gotRole', result)
            }
        
            commit('isLoading', false)
        },

        async ensureRoleListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.roleListItems.length === 0) {
                await dispatch('getRoleListItems')
            }

            commit('isLoading', false)
        },

        async ensureRole({ commit, dispatch, getters }, { roleId }) {
            commit('isLoading', true)

            if (!getters.role(roleId)) {
                console.log('Role not cached. Getting from API.')
                await dispatch('getRoleListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        lastImportStats: (state) => state.lastImportStats,

        roleListItems: (state) => state.roleListItems,
        role: (state) => (roleId) => {
            return  _.find(state.roleListItems, function(role) { return role.id === roleId; });
        }
    }
};