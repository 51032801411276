import apiClient from '@/services/axios'

export async function put(userId, SubscriptionPlan) {
    
  var response = await apiClient.put('/Subscription/BillWerk/SubscriptionPlan?userId=' + userId, {SubscriptionPlan} )
  if (response) {
    return response.data
  }

  throw new Error('Unable to update subscription plan')
}
