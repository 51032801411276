import * as apiGetCampaigns from '@/api/campaigns/getCampaigns'
import * as apiImportCampaigns from '@/api/campaigns/importCampaigns'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        campaignListItems: [],
        detailedCampaigns: {},
        lastImportStats: {
            noChanges: 0,
            updated: 0,
            added: 0
        }
    }),
    mutations: {
        gotCampaignListItems(state, campaigns) {
            state.campaignListItems = campaigns
        },

        /*gotVenue(state, venue) {
            state.detailedVenues[venue.id] = venue
        },*/

        importedCampaigns(state, importStats) {
            state.lastImportStats = importStats
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async importOnlinePosCampaigns({ commit }) {
            commit('isLoading', true)

            console.log('Importing Campaigns');
        
            const result = await apiImportCampaigns.post()
            
            if (result) {
                commit('importedCampaigns', result)
            }
        
            commit('isLoading', false)
        },

        async getCampaignListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Campaign List Items');
        
            const result = await apiGetCampaigns.get();

            if (result) {
                commit('gotCampaignListItems', result)
            }
        
            commit('isLoading', false)
        },

        /*
        async getCampaign({ commit }, { campaignId }) {
            commit('isLoading', true)

            console.log('Getting Venue:', venueId);
        
            const result = await apiGetVenue.get(venueId)
            
            if (result) {
                commit('gotVenue', result)
            }
        
            commit('isLoading', false)
        },*/

        async ensureCampaignListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.campaignListItems.length === 0) {
                await dispatch('getCampaignListItems')
            }

            commit('isLoading', false)
        },

        /*
        async ensureVenue({ commit, dispatch, getters }, { venueId }) {
            commit('isLoading', true)

            if (!getters.venue(venueId)) {
                console.log('Venue not cached. Getting from API.')
                await dispatch('getVenue', { venueId: venueId } )
            }

            commit('isLoading', false)
        }*/
    },
    getters: {
        lastImportStats: (state) => state.lastImportStats,
        campaignListItems: (state) => state.campaignListItems
        /*venue: (state) => (venueId) => {
            return state.detailedVenues[venueId]
        }*/
    }
};