import * as apiGetVenue from '@/api/venues/getVenue'
import * as apiGetVenues from '@/api/venues/getVenues'
import * as apiCreateVenue from '@/api/venues/createVenue'
import * as apiImportIdmVenues from '@/api/venues/importIdmVenues'
import * as apiUpdateVenueGeneralInfo from '@/api/venues/updateVenueGeneralInfo'
import * as apiUpdateVenueNightPayInfo from '@/api/venues/updateVenueNightPayInfo'
import * as apiUpdateVenueImages from '@/api/venues/updateVenueImages'
import * as apiUpdateOnlinePosConfiguration from '@/api/venues/updateVenueOnlinePosConfigurations'
import * as apiDeleteVenue from '@/api/venues/deleteVenue'
import * as apiDeleteVenueImage from '@/api/venues/deleteVenueImage'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        venueListItems: [],
        detailedVenues: {},
        lastImportStats: {
            noChanges: 0,
            updated: 0,
            added: 0
        }
    }),
    mutations: {
        gotVenueListItems(state, venues) {
            state.venueListItems = venues
        },

        gotVenue(state, venue) {
            state.detailedVenues[venue.id] = venue
        },

        importedVenues(state, importStats) {
            state.lastImportStats = importStats
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async importIdmVenues({ commit }) {
            commit('isLoading', true)

            console.log('Importing Venues');
        
            const result = await apiImportIdmVenues.post()
            
            if (result) {
                commit('importedVenues', result)
            }
        
            commit('isLoading', false)
        },

        async create({ commit }, payload) {
            commit('isLoading', true)

            console.log('Posting:', payload)

            const result = await apiCreateVenue.post(payload)

            commit('isLoading', false)

            return result
        },

        async getVenueListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Venue List Items');
        
            const result = await apiGetVenues.get();

            if (result) {
                commit('gotVenueListItems', result)
            }
        
            commit('isLoading', false)
        },

        async getVenue({ commit }, { venueId }) {
            commit('isLoading', true)

            console.log('Getting Venue:', venueId);
        
            const result = await apiGetVenue.get(venueId)
            
            if (result) {
                commit('gotVenue', result)
            }
        
            commit('isLoading', false)
        },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeleteVenue.del(entityId)

            commit('isLoading', false)

            return result
        },

        async deleteVenueImage({ commit }, { venueId, url }) {
            commit('isLoading', true)

            console.log('deleteVenueImage', venueId, url )
            const result = await apiDeleteVenueImage.del( venueId, url );

            commit('isLoading', false)

            return result
        },

        async updateVenueGeneralInfo({ commit }, { venueId, generalInfo }) {
            commit('isLoading', true)

            await apiUpdateVenueGeneralInfo.put(venueId, generalInfo)
            
            commit('isLoading', false)
        },

        async updateVenueNightPayInfo({ commit }, { venueId, nightPayInfo }) {
            commit('isLoading', true)

            await apiUpdateVenueNightPayInfo.put(venueId, nightPayInfo)
            
            commit('isLoading', false)
        },

        async updateVenueImages({ commit }, { venueId, images }) {
            commit('isLoading', true)

            const payload = {
                images: images
            }

            await apiUpdateVenueImages.put(venueId, payload)
            
            commit('isLoading', false)
        },

        async updateVenueOnlinePosConfiguration({ commit }, {venueId, payload}) {
            commit('isLoading', true)
            console.log(payload)
            await apiUpdateOnlinePosConfiguration.put(venueId, payload)
            
            commit('isLoading', false)
        },

        /*
        async saveVenue({ commit }, { venue }) {
            commit('isLoading', true)

            console.log(venue)

            const payload = {
                title: workspace.title
            };

            await apiUpdateWorkspaceTemplate.put(workspace.id, payload)
            
            commit('isLoading', false)
        },*/

        async ensureVenueListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.venueListItems.length === 0) {
                await dispatch('getVenueListItems')
            }

            commit('isLoading', false)
        },

        async ensureVenue({ commit, dispatch, getters }, { venueId }) {
            commit('isLoading', true)

            if (!getters.venue(venueId)) {
                console.log('Venue not cached. Getting from API.')
                await dispatch('getVenue', { venueId: venueId } )
            }

            commit('isLoading', false)
        }
    },
    getters: {
        lastImportStats: (state) => state.lastImportStats,
        venueListItems: (state) => state.venueListItems,
        venue: (state) => (venueId) => {
            return state.detailedVenues[venueId]
        }
    }
};