<template>
	<Sidebar v-if="hasPermission('np.backoffice.membercards.add')" v-model:visible="createNewSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="creating" :title="'Creating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Create {{ entityName }}</h3>
			<Form :fields="fields" :context="newEntity" @onAction="createEntity" :actionLabel="'Create'" :actionIcon="'pi pi-plus-circle'" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.membercards.editdelete')" v-model:visible="updateSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit {{ entityName }}</h3>
			<Form :fields="fields" :context="selectedEntity" @onAction="updateEntity" :actionLabel="'Update'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.membercards.editdelete')" v-model:visible="assignCategoriesBarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit Categories for {{ selectedEntity.title }}</h3>
			<Form :fields="categoriesFields" :context="selectedCategories" @onAction="updateCategories" :actionLabel="'Update'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.membercards.editdelete')" v-model:visible="editBenefitsBarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit Benefits for {{ selectedEntity.title }}</h3>
			<Form :fields="benefitsFields" :context="selectedEntity" @onAction="updateBenefits" :actionLabel="'Update'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.membercards.editdelete')" v-model:visible="assignPerksBarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit Perks for {{ selectedEntity.title }}</h3>
			<Form :fields="perkFields" :context="selectedEntity" @onAction="updatePerkConfigs" :actionLabel="'Update'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.membercards.editdelete')" v-model:visible="assignQRUrl" class="p-sidebar-md" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else class="flex flex-column gap-2">
			<h3>QR Configuration for {{ entityName }}</h3>
			<Button class="w-full" type="button" :disabled="selectedEntity.qrUrl" label="Generate URL for QR" icon="pi pi-qrcode" :loading="updating" @click="generateUrl(selectedEntity)" ></Button>
			<div class="mt-6" v-if="selectedEntity.qrUrl" >
				<p class="font-medium">QR URL</p>
				<Button @click="copyToClipBoard(selectedEntity.qrUrl)" class="w-full bg-white text-center " style="color:black" icon="pi pi-copy" :label="selectedEntity.qrUrl"  ></Button>
			</div>

		</div>
	</Sidebar>

	<div class="surface-section p-6 pt-0 " v-if="hasPermission('np.backoffice.membercards.show')">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.membercards.add')" @click="createNewSidebarVisible = true" :label="'New ' + entityName" icon="pi pi-plus-circle" />
			</div>
		</div>

		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />

		<div v-else class="card overflow-x-auto xl:w-full ">

			<div class="mb-2 surface-ground border-1 border-round border-gray-300 p-2 " >
					<div class="flex flex-column ">
					<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2 w-8rem" @click="clearFilter1()" />
				
					<div class="flex w-full xl:flex-row flex-column ">
						
						<div class="flex w-full flex-column p-1 ">
							<h4 class="xl:w-fit w-full mt-1 mb-2 ml-0 text-gray-700 ">Filter venues</h4>
							<MultiSelect v-model="filters1.venueName.constraints[0].value" display="chip"
							filterPlaceholder="Search" :autoOptionFocus="false" class="xl:max-w-28rem max-w-full"
							:options="venues" optionLabel="name" optionValue="name" dataKey="id" filter :showToggleAll="false"  placeholder="Filter by venue" />
						</div>

						<div class="flex w-full flex-column p-1">
							<h4 class="w-full mt-1 mr-0 mb-2 text-gray-700">Search </h4>
							<InputText v-model="filters1['global'].value" placeholder="Keyword Search"  class="w-full" />
						</div>
						
					</div>

					</div>

			</div>
			
			<DataTable :value="entities" dataKey="id" @row-click="onRowClicked" class="p-datatable-lg"  :rowHover="true" responsiveLayout="scroll" v-model:filters="filters1">
				
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
				<Column header="Name" field="title"></Column>
				<Column field="onlinePosGroup" header="Online POS Group"></Column>
				<Column header="Venue" field="venueName"></Column>

				<Column header="Consent">
					<template #body="{ data }">
						<label v-if="data.crmDocumentId != null && data.crmDocumentId.id != null">Privacy</label>
						<label v-if="data.crmDocumentId != null && data.crmDocumentId.id != null && data.marketingDocumentId != null && data.marketingDocumentId.id != null"> / </label>
						<label v-if="data.marketingDocumentId != null && data.marketingDocumentId.id != null">Marketing</label>
						<!-- <Chip v-if="data.crmDocumentId != null && data.crmDocumentId.id != null" label="True" class="chip-enabled" />
						<Chip v-else label="False" class="chip-disabled" /> -->
					</template>
				</Column>

				<Column header="Visible">
					<template #body="{ data }">
						<Chip v-if="data.visible" label="Visible" class="chip-enabled" />
						<Chip v-else label="Not visible" class="chip-disabled" />
					</template>
				</Column>
				<Column header="Enabled">
					<template #body="{ data }">
						<Chip v-if="data.enabled" label="Enabled" class="chip-enabled" />
						<Chip v-else label="Disabled" class="chip-disabled" />
					</template>
				</Column>
				<!-- <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<Button v-if="hasPermission('np.backoffice.membercards.editdelete')" @click="assignCategories(data)" type="button" icon="fas fa-filter"></Button>
					</template>
				</Column> -->


			
				<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<div class="flex gap-2">
						<Button v-if="hasPermission('np.backoffice.membercards.editdelete')" @click="editBenefits(data)" type="button" icon="fa fa-tags"></Button>
						<Button v-if="hasPermission('np.backoffice.membercards.editdelete')" @click="assignPerks(data)" type="button" icon="fa fa-ticket"></Button>
						<Button v-if="hasPermission('np.backoffice.membercards.editdelete')" @click="showQRModal(data)" type="button" icon="pi pi-qrcode"></Button>
						<Button v-if="hasPermission('np.backoffice.membercards.editdelete')" @click="confirmDeleteEntity(data)" type="button" icon="pi pi-trash"></Button>
						</div>
					</template>
				</Column>
				
			</DataTable>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, inject, computed } from 'vue';
import { useStore } from 'vuex';

import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { uploadFile} from '@/services/fileUploadService'
import { FilterMatchMode, FilterOperator } from 'primevue/api';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'memberCards/getMemberCardListItems';
		const storeGetterGetEntities = 'memberCards/memberCardListItems';
		const storeActionCreateEntity = 'memberCards/create';
		const storeActionUpdateEntity = 'memberCards/update';
		const storeActionUpdateQrUrl = 'memberCards/updateQrUrl';
		const storeActionDeleteEntity = 'memberCards/delete';
		const storeActionUpdateBenefits = 'memberCardBenefits/update';
		const storeActionUpdatePerkConfigs = 'memberCardPerks/update';
		const storeActionUpdateCategories = 'memberCardCategories/update';
		const entityName = 'Member Card';
		const pageTitle = 'Member Cards';
		const pageDescription = 'Here you can manage your Member Cards';

		const fields = ref([]);
		const benefitsFields = ref([]);
		const perkFields = ref([]);
		const categoriesFields = ref([]);

		const loading = ref(true)
		const creating = ref(false)
		const updating = ref(false)

		const useFormatDate = inject('useFormatDate');

		const activeCountryCode = computed(() => {
			var activeAccountCountry = store.getters["activeAccountCountry"]
			var countryAccounts = store.getters["countryAccounts"]
			return countryAccounts?.find(x => x.id == activeAccountCountry)?.countryCode
		});

		// const userTerms = ref([]);
		const perks = ref([]);
		const venues = ref([]);
		const cities = ref([]);

		const filters1 = ref({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			title: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			onlinePosGroup: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			venueName: { operator: FilterOperator.AND, constraints: [{ value: [], matchMode: FilterMatchMode.IN }] },
			// email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			// 'representative': {value: null, matchMode: FilterMatchMode.IN},
			// 'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
			// 'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
			// 'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
			// 'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
			// 'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
		});

		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const entities = ref([]);

		const newEntity = ref({
			venueId: {
				id: null,
			},
			logoUrl: '',
			backgroundUrl: '',
			title: '',
			onlinePosGroup: '',
			onlinePosCampaignGroup: '',
			tagLine: '',
			backgroundSubtitle: '',
			sortKey: 0,
			discountPercentage: 0,
			showBenefits: false,
			isExpense: false,
			visible: false,
			enabled: false,
			crmDocumentId: {
				id: null,
			},
			marketingDocumentId: {
				id: null,
			},
			defaultExpirationMonths: 0
		});
		const selectedEntity = ref({});
		const selectedCategories = ref({});
		const createNewSidebarVisible = ref(false);
		const updateSidebarVisible = ref(false);
		const editBenefitsBarVisible = ref(false);
		const assignPerksBarVisible = ref(false);
		const assignCategoriesBarVisible = ref(false);
		const assignQRUrl = ref(false)


		const clearFilter1 = () => {
			initFilters1();
		};
		const initFilters1 = () => {
			filters1.value = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				venueName: { operator: FilterOperator.AND, constraints: [{ value: [], matchMode: FilterMatchMode.IN }] },
			};
		};

		function getVenueName(id) {
			var venue = venues.value.find((x) => x.id === id);

			if (venue) {
				return venue.name;
			}
			return '';
		}

		function editBenefits(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload));
			editBenefitsBarVisible.value = true;
		}

		function assignPerks(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload));
		
			selectedEntity.value.perkConfigs = selectedEntity.value.perkConfigs?.map(x => {
				return {...x, venueId: {id: x?.venueId?.id}}
			})  ?? []
			
			assignPerksBarVisible.value = true;
		}

		function assignCategories(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload));

			var citieslist = [];
			if (selectedEntity.value.memberCardCategories.cityConfigs.length > 0) {
				selectedEntity.value.memberCardCategories.cityConfigs.forEach((element) => {
					citieslist.push(element.city);
				});
			}
			selectedCategories.value.cities = citieslist;

			// var countriesList = [];
			// if (selectedEntity.value.memberCardCategories.countryConfigs.length > 0) {
			// 	selectedEntity.value.memberCardCategories.countryConfigs.forEach((element) => {
			// 		countriesList.push(element.country);
			// 	});
			// }
			// selectedCategories.value.countries = countriesList;

			// var venuesList = [];
			// if (selectedEntity.value.memberCardCategories.venueConfigs.length > 0) {
			// 	selectedEntity.value.memberCardCategories.venueConfigs.forEach((element) => {
			// 		venuesList.push(element.venueId.id);
			// 	});
			// }
			// selectedCategories.value.venueCardIds = venuesList;

			assignCategoriesBarVisible.value = true;
		}

		function showQRModal(payload){
				selectedEntity.value = JSON.parse(JSON.stringify(payload));
				assignQRUrl.value = true;
		}

		async function generateUrl(){
			updating.value = true
			await store.dispatch(storeActionUpdateQrUrl, {memberCardId: selectedEntity.value.id})
			.then((res)=>{
				toast.add({ severity: 'success', summary: 'Success', detail: 'QR-Url generated and added to member card', life: 3000 });
				selectedEntity.value.qrUrl = res.qrUrl
			})
			.catch((err)=>{
					toast.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
			})
			.finally(()=>{
				updating.value = false
			})

		}
		function copyToClipBoard(url){
				navigator.clipboard.writeText(url);
				toast.add({ severity: 'success', summary: 'Success', detail: 'QR-Url Copied to clipboard', life: 3000 });
		}



		function onRowClicked(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload.data));

			updateSidebarVisible.value = true;
		}

		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000 });
					} catch (err) {
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete ' + entityName + ': ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => {},
			});
		}

		async function createEntity(formContext) {

		creating.value = true;
	
			try {			

				var imageContainer = formContext.title + "-" + formContext.onlinePosGroup + "-" + activeCountryCode.value + "-images"
				if(formContext.logoUrl){
					var logoFile = await uploadFile(formContext.logoUrl, "membercards", imageContainer, store)
					formContext.logoUrl = logoFile.url
				}
				if(formContext.backgroundUrl){
					var bgFile = await uploadFile(formContext.backgroundUrl, "membercards", imageContainer, store)
					formContext.backgroundUrl = bgFile.url
				}

				await store.dispatch(storeActionCreateEntity, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' created', life: 3000 });
				createNewSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			creating.value = false;
		}

		async function updateBenefits(formContext) {
			updating.value = true;
			try {
				await store.dispatch(storeActionUpdateBenefits, formContext);
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: entityName + ' updated',
					life: 3000,
				});
				updateSidebarVisible.value = false;
				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update ' + entityName + ': ' + err,
					life: 3000,
				});
			}
			loading.value = false;
			updating.value = false;
		}

		async function updatePerkConfigs(formContext) {
			updating.value = true;
		
			var payload = formContext
			payload.perkConfigs = formContext.perkConfigs?.map(x => {
					return {...x, venueId:  x?.venueId?.id ? {id: x?.venueId?.id} : null}
			})
			
			try {
				
				await store.dispatch(storeActionUpdatePerkConfigs, payload);

				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: entityName + ' updated',
					life: 3000,
				});
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update ' + entityName + ': ' + err,
					life: 3000,
				});
			}

			loading.value = false;
			updating.value = false;
		}

		async function updateEntity(formContext) {
			updating.value = true;

			try {
				
				var imageContainer = formContext.title + "-" + formContext.onlinePosGroup + "-" + activeCountryCode.value + "-images"
				if(formContext.logoUrl && formContext.logoUrl.name ){
					var logoFile = await uploadFile(formContext.logoUrl, "membercards", imageContainer, store)
					formContext.logoUrl = logoFile.url
				}
				if(formContext.backgroundUrl && formContext.backgroundUrl.name ){
					var bgFile = await uploadFile(formContext.backgroundUrl, "membercards", imageContainer, store)
					formContext.backgroundUrl = bgFile.url
				}


				if(formContext.venueId != null && formContext.venueId.id == null)
					formContext.venueId = null

				await store.dispatch(storeActionUpdateEntity, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' updated', life: 3000 });
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			updating.value = false;
		}

		async function updateCategories(formContext) {
			updating.value = true;

			try {
				await store.dispatch(storeActionUpdateCategories, { memberCardId: selectedEntity.value.id, formContext: formContext });

				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: entityName + ' updated',
					life: 3000,
				});
				assignCategoriesBarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update ' + entityName + ': ' + err,
					life: 3000,
				});
			}

			loading.value = false;
			updating.value = false;
		}

		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities);
				const loadedEntities = store.getters[storeGetterGetEntities];
				entities.value = loadedEntities?.map(x => {
					x.venueName = getVenueName(x.venueId?.id)
					return x
				});

				// Reload benefits
				if (editBenefitsBarVisible.value) {
					var elementBenefits = entities.value.find((entity) => entity.id === selectedEntity.value.id);
					if (elementBenefits != null) {
						editBenefits(elementBenefits);
					}
				}

				// Reload perks
				if (assignPerksBarVisible.value) {
					var elementPerks = entities.value.find((entity) => entity.id === selectedEntity.value.id);
					if (elementPerks != null) {
						assignPerks(elementPerks);
					}
				}
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}

		async function loadPerks(){
			try {
				await store.dispatch('perks/ensurePerkListItems');
				const loadedPerks = store.getters['perks/perkListItems'];
				perks.value = loadedPerks;
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Perks: ' + err, life: 3000 });
			}
		}

		async function loadVenues(){
			try {
				await store.dispatch('venues/ensureVenueListItems');
				const loadedVenues = store.getters['venues/venueListItems'];
				venues.value = loadedVenues.sort((a, b) => a.name.localeCompare(b.name));
				cities.value = GetElementsFromList(loadedVenues, 'city');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Venues: ' + err, life: 3000 });
			}
		}

		async function loadRequiredData() {
			loading.value = true;

			await Promise.all([loadPerks(),loadVenues()])

			await reloadEntities();

			fields.value = [
				{
					id: 'title',
					type: 'text-input',
					title: 'Title',
					help: 'Name of the member card type',
				},
				{
					id: 'venueId',
					path: 'venueId.id',
					type: 'dropdown',
					title: 'Venue',
					filter: true,
					config: {
						options: venues,
						optionLabel: 'name',
						optionValue: 'id',
						placeholder: 'Select Venue',
					},
				},
				{
					id: 'logoUrl',
					type: 'imageUpload',
					title: 'Logo URL',
					container: "membercards"
				},
				{
					id: 'backgroundUrl',
					type: 'imageUpload',
					title: 'Background URL',
					container: "membercards"
				},
				{
					id: 'onlinePosGroup',
					type: 'text-input',
					title: 'Online POS group',
				},
				{
					id: 'onlinePosCampaignGroup',
					type: 'text-input',
					title: 'Online POS campaign group',
				},
				{
					id: 'tagLine',
					type: 'text-input',
					title: 'Tag line',
					help: 'Tag line of the member card type',
				},
				{
					id: 'backgroundSubtitle',
					type: 'text-input',
					title: 'Background subtitle',
				},
				{
					id: 'sortKey',
					type: 'number-input',
					title: 'Sort key',
				},
				{
					id: 'discountPercentage',
					type: 'number-input',
					title: 'Discount Percentage',
				},
				{
					id: 'crmDocumentId',
					path: 'crmDocumentId.id',
					type: 'text-input',
					title: 'Privacy Consent Document ID',
				},
				{
					id: 'marketingDocumentId',
					path: 'marketingDocumentId.id',
					type: 'text-input',
					title: 'Marketing Consent Document ID',
				},
				{
					id: 'defaultExpirationMonths',
					type: "number-input",
					title: 'Default expiration months',
					dependsOn: "expires",
					help: "Number of months after given to a user the card will expire. 0 = never"
				},
				{
					id: 'showBenefits',
					type: 'switch',
					title: 'Show benefits',
				},
				{
					id: 'isExpense',
					type: 'switch',
					title: 'Is expense',
				},
				{
					id: 'visible',
					type: 'switch',
					title: 'Visible',
				},
				{
					id: 'enabled',
					type: 'switch',
					title: 'Enabled',
				},
			];

			benefitsFields.value = [
				{
					id: 'benefits',
					type: 'BenefitConfigs',
					title: 'Benefits',
				},
			];

			perkFields.value = [
				{
					id: 'perkConfigs',
					type: 'RolePerkConfigs',
					title: 'Perk Configs',
				},
			];

			categoriesFields.value = [
				{
					id: 'cities',
					type: 'multi',
					title: 'Select Cities',
					help: 'Select cities for membercard',
					config: {
						options: cities,
						optionLabel: 'name',
						optionValue: 'name',
						placeholder: 'Select cities',
					},
				},
			];

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			entityName,
			pageTitle,
			pageDescription,
			loading,
			creating,
			updating,
			filters1,

			fields,
			benefitsFields,
			perkFields,
			categoriesFields,
			newEntity,
			selectedEntity,
			selectedCategories,
			createEntity,
			updateEntity,
			confirmDeleteEntity,
			updateBenefits,
			updatePerkConfigs,
			updateCategories,

			// userTerms,
			venues,

			entities,

			createNewSidebarVisible,
			updateSidebarVisible,
			editBenefitsBarVisible,
			assignPerksBarVisible,
			assignCategoriesBarVisible,
			assignQRUrl,
			clearFilter1,

			onRowClicked,
			editBenefits,
			assignPerks,
			assignCategories,
			showQRModal,
			copyToClipBoard,
			generateUrl,
			getVenueName,

			formatDate: useFormatDate().formatDate,
		};

		function GetElementsFromList(array, field) {
			const map = new Map();
			var result = [];

			for (const item of array) {
				if (!map.has(item[field])) {
					map.set(item[field], true);
					result.push({ name: item[field] });
				}
			}

			return result.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkbox-all-text {
    position: absolute;
    left: 42px;
	top:14px
}
</style>
