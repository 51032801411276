<template>
    <div class="flex flex-column mb-2">
        <p v-if="configs.length == 0">Please add a Member Card Config</p>

        <div style="padding-left: 12px;">
            <div v-for="(memberCardConfig, index) in configs" :key="index" class="field border-1 border-gray-300 p-2 border-round shadow-1">
                <label :for="'config-' + index" class="font-bold text-900" style="font-size:90%;margin-bottom: 2px;">Member Card {{index + 1}}</label>

                <div class="p-inputgroup">
                    <Dropdown  
                        v-model="memberCardConfig.id" 
                        :options="memberCards" 
                        optionLabel="title" 
                        optionValue="id" 
                        placeholder="Select Member Card" />
                    <Button @click="deleteConfig(index)" icon="pi pi-times" class="p-button-warning"/>
                </div>
            </div>
            <Button @click="addConfig" label="Add Member Card" class="mt-2 p-button-secondary p-button-sm" />
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'

import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'

export default {
    props: [
        'context',
        'field'
    ],
    setup(props) {
        const store = useStore()
        const toast = useToast()

        const loading = ref(true)
        const memberCards = ref([])
        
        const configs = ref(props.context.memberCardConfigs)

        function addConfig() {
            configs.value.push({ id: null })
        }

        function deleteConfig(index) {
            configs.value.splice(index, 1)
        }

        async function loadRequiredData() {
            loading.value = true

            // Load Member Cards
            try {
                await store.dispatch('memberCards/ensureMemberCardListItems');
            } catch (err) {
                toast.add({severity:'error', summary: 'Error', detail: 'Could not load Member Cards: ' + err, life: 3000})
            }
            const loadedMemberCards = store.getters['memberCards/memberCardListItems']
            memberCards.value = loadedMemberCards

            loading.value = false
        }

        onMounted(async () => {
            await loadRequiredData()
        })

        

        return {
            memberCards,

            configs,

            addConfig,
            deleteConfig
        }
    },
}
</script>