import * as apiGetUserNotifications from '@/api/userNotifications/getUserNotifications'
import * as apiDeleteUserNotification from '@/api/userNotifications/deleteUserNotification'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        userNotifications: {},
    }),
    mutations: {
        gotUserNotifications(state, { userId, userNotifications }) {
            state.userNotifications[userId] = userNotifications
            console.log(state.userNotifications)
        },


        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {


        async delete({ commit }, { notificationId }) {
            commit('isLoading', true)

             const result = await apiDeleteUserNotification.del(notificationId)

             commit('isLoading', false)

             return result
        },

        async getUserNotifications({ commit }, { userId }) {
            commit('isLoading', true)

            console.log('Getting User Notifications');
        
            const result = await apiGetUserNotifications.get(userId);

            if (result) {
                commit('gotUserNotifications', 
                { 
                    userId: userId,
                    userNotifications: result 
                })
            }
        
            commit('isLoading', false)
        },

       
    },
    getters: {
        userNotifications: (state) => (userId) => {
            return state.userNotifications[userId]
        },
    
    }
};