import apiClient from '@/services/axios'

export async function get(userIds) {
 
  var response = await apiClient.post('/users/by-ids', userIds)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Users.')
}
