import * as apiMemberCardBenefits from '@/api/memberCardBenefits/updateMemberCardBenefits';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
	}),
	mutations: {
		isLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async update({ commit }, membercard) {
			commit('isLoading', true);

			await apiMemberCardBenefits.put(membercard.id, membercard);

			commit('isLoading', false);
		},
	},
	getters: {},
};