<template>
	<Sidebar v-if="hasPermission('np.backoffice.roles.import')" v-model:visible="importSidebarVisible" position="right"
		:showCloseIcon="false">
		<h3>Import Roles</h3>
		<div class="flex flex-column">
			<div v-if="importing">
				<div class="spinner-grow" style="width: 3rem; height: 3rem" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>

			<div v-if="importing == false && importComplete == true">
				<Panel header="Import complete">
					<div class="font-semibold">No changes: {{ importStats.noChanges }}</div>
					<div class="mt-2 font-semibold">Updated: {{ importStats.updated }}</div>
					<div class="mt-2 font-semibold">Added: {{ importStats.added }}</div>
				</Panel>
			</div>

			<Button v-if="importComplete" class="flex-1 mt-3 p-button-success" @click="closeSidebar" label="Done"
				icon="pi pi-check-circle" />
			<Button v-else class="flex-1 mt-3" @click="importRoles" label="Import" icon="pi pi-refresh" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.roles.editdelete')" v-model:visible="updateSidebarVisible"
		class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit {{ entityName }}</h3>
			<Form :fields="fields" :context="selectedEntity" @onAction="updateEntity" :actionLabel="'Update'"
				:actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>


	<Sidebar v-if="hasPermission('np.backoffice.roles.editdelete')" v-model:visible="assignPerksBarVisible"
		class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit Perks</h3>
			<Form :fields="perkFields" :context="selectedEntity" @onAction="updatePerkConfigs" :actionLabel="'Update'"
				:actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<div class="surface-section p-6 pt-0" v-if="hasPermission('np.backoffice.roles.show')">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.roles.import')" @click="importSidebarVisible = true"
					label="Import Roles" icon="pi pi-refresh" />
			</div>
		</div>

		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
		<div v-else class="card">
			<DataTable :value="entities" dataKey="id" @row-click="onRowClicked" class="p-datatable-lg" :rowHover="true"
				responsiveLayout="scroll">
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
				<Column header="Title" field="title"></Column>
				<Column header="Role ID" field="roleId"></Column>
				<!-- <Column header="Perk">
					<template #body="{ data }">
						{{ formattedPerks(data.perkConfigs) }}
					</template>
				</Column> -->

				<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<Button v-if="hasPermission('np.backoffice.roles.editdelete')" @click="assignPerks(data)"
							type="button" icon="fa fa-ticket"></Button>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { ref, onMounted, inject } from 'vue';

import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import RolePerfConfigs from './components/RolePerfConfigs.vue';

export default {
	inject: ['hasPermission'],
	components: { RolePerfConfigs },
	setup() {
		const storeActionGetEntities = 'roles/getRoleListItems';
		const storeGetterGetEntities = 'roles/roleListItems';
		const storeActionUpdateEntity = 'roles/update';
		const storeActionDeleteEntity = 'roles/delete';
		const entityName = 'Role';
		const pageTitle = 'Roles';
		const pageDescription = 'Here you can manage your Roles';
		const canCreateNew = true;
		const canUpdate = true;

		const fields = ref([]);
		const perkFields = ref([]);

		const loading = ref(true);
		const creating = ref(false);
		const updating = ref(false);


		const importing = ref(false);
		const importComplete = ref(false);
		const importStats = ref({});

		const perks = ref([]);

		const useFormatDate = inject('useFormatDate');

		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const entities = ref([]);

		const importSidebarVisible = ref(false);

		const selectedEntity = ref({});

		const createNewSidebarVisible = ref(false);
		const updateSidebarVisible = ref(false);
		const assignPerksBarVisible = ref(false);

		function assignPerks(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload));
			assignPerksBarVisible.value = true;
		}

		function onRowClicked(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload.data));
			updateSidebarVisible.value = true;
		}

		function closeSidebar() {
			importing.value = false;
			importComplete.value = false;
			importSidebarVisible.value = false;
		}

		async function importRoles() {
			importing.value = true;

			try {
				await store.dispatch('roles/importIdamRoles');
				importStats.value = store.getters['roles/lastImportStats'];

				importComplete.value = true;

				toast.add({ severity: 'success', summary: 'Success', detail: 'Roles imported', life: 3000 });
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not import Roles: ' + err, life: 3000 });
			}

			const loadedEntities = store.getters['roles/roleListItems'];
			entities.value = loadedEntities;

			importing.value = false;
		}

		function formattedPerks(perkConfigs) {
			if (perkConfigs == null || perkConfigs.length == 0) return '';

			const perkList = [];
			perkConfigs.forEach((perkConfig) => {
				if (perkConfig.perkId.id != null) {
					const perk = store.getters['perks/perk'](perkConfig.perkId.id);
					if (perk != null) {
						perkList.push(
							perkConfig.quantity + ' x ' + perk.title + ' each ' + perkConfig.occurence + ' ' + formattedDuration(perkConfig.occurence, perkConfig.occurenceType).toLowerCase()
						);
					}
				}
			});

			return perkList.join(', ');
		}

		function formattedDuration(quantity, duration) {
			if (quantity == 1) return duration;

			return duration + 's';
		}

		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000 });
					} catch (err) {
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete ' + entityName + ': ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => { },
			});
		}

		async function updateEntity(formContext) {
			updating.value = true;

			try {
				await store.dispatch(storeActionUpdateEntity, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' updated', life: 3000 });
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			updating.value = false;
		}

		async function updatePerkConfigs(formContext) {
			updating.value = true;
			try {
				await store.dispatch(storeActionUpdateEntity, formContext);

				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: entityName + ' updated',
					life: 3000,
				});
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update ' + entityName + ': ' + err,
					life: 3000,
				});
			}

			loading.value = false;
			updating.value = false;
		}

		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities);
				const loadedEntities = store.getters[storeGetterGetEntities];
				entities.value = loadedEntities;

				// reload perks
				if (assignPerksBarVisible.value) {
					var element = entities.value.find((entity) => entity.id === selectedEntity.value.id);
					if (element != null) {
						assignPerks(element);
					}
				}
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}

		async function loadRequiredData() {
			loading.value = true;

			// Load Perks
			try {
				await store.dispatch('perks/ensurePerkListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Perks: ' + err, life: 3000 });
			}
			const loadedPerks = store.getters['perks/perkListItems'];
			perks.value = loadedPerks;

			reloadEntities();

			fields.value = [
				{
					id: 'title',
					type: 'text-input',
					title: 'Title',
					config: {
						disabled: true,
					},
				},
				{
					id: 'roleId',
					type: 'text-input',
					title: 'Role ID',
					config: {
						disabled: true,
					},
				},
				// {
				// 	id: 'perkConfigs',
				// 	type: 'RolePerkConfigs',
				// 	title: 'Perk Configs',
				// },
			];

			perkFields.value = [
				{
					id: 'perkConfigs',
					type: 'RolePerkConfigs',
					title: 'Perk Configs',
				},
			];

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			entityName,
			pageTitle,
			pageDescription,
			canCreateNew,
			canUpdate,
			loading,
			creating,
			updating,

			formattedPerks,

			fields,
			selectedEntity,
			updateEntity,
			confirmDeleteEntity,

			perks,


			importSidebarVisible,
			importing,
			importComplete,
			importStats,
			importRoles,
			closeSidebar,

			entities,

			createNewSidebarVisible,
			updateSidebarVisible,
			assignPerksBarVisible,

			onRowClicked,
			assignPerks,

			updatePerkConfigs,

			formatDateTime: useFormatDate().formatDateTime,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
