<template>

	<Sidebar v-model:visible="showNotificationDetailDialog" class="p-sidebar-md" position="right" :showCloseIcon="false">
		
		<div>
                <h3 class="flex">User notification  <span class="text-sm my-auto ml-1 text-gray-500"> - {{userNotificationDetail.venueName ?? 'OneSignal'}}</span></h3>

                <div class="w-full flex">
                    <img  class="p-0 mx-auto mt-0 mb-4" :src="userNotificationDetail.venueLogoUrl ?? npLogo" width="200"  />
                </div>

            <div class="flex flex-wrap h-full ">
            
                <div style="min-height: 9rem" class="w-full h-full  bg-gray-100 border-round p-2">

                    <div class="text-lg mb-1 font-bold" style="word-wrap: break-word;">
                        {{userNotificationDetail.title}}
                    </div>
                    <div class="mb-4" style="word-wrap: break-word;">
                        {{userNotificationDetail.description}}
                    </div>

                    <div v-if="userNotificationDetail.image" class="border-round  overflow-hidden mx-auto w-8">
                        <img :src="userNotificationDetail.image" class="w-full" />
                    </div>

                
                </div>
                <div class="w-full">
                    <small class="w-full text-gray-400">Sent from {{userNotificationDetail.venueName ?? "OneSignal"}} - {{formatDateTime(userNotificationDetail.timestamp)}}</small>
                </div>

                <span style="height:0.6px" class="mt-4 bg-gray-400 w-full"></span>

                <div  class="w-full h-full flex justify-content-center flex-row gap-6 mt-6">
                    <span class="text-center ">
                        <h3 class="p-0 m-0 mb-1 " :style="{color: getStatusTitle() =='Deleted' ? 'red': getStatusTitle() =='Read'? 'LimeGreen' : 'lightBlue'}">{{getStatusTitle()}}</h3>
                        <p class="p-0 m-0">Status</p>
                    </span>
                    <span class="text-center ">
                        <h2 class="p-0 m-0">{{userNotificationDetail.openCount}}</h2>
                        <p class="p-0 m-0">Open count</p>
                    </span>
                    <span class="text-center">
                        <h2 class="p-0 m-0">{{userNotificationDetail.readCount}}</h2>
                        <p class="p-0 m-0">Read count</p>
                    </span>
                        
                </div>

                 <Button v-if="!userNotificationDetail.deleted" @click="deleteNotification(userNotificationDetail.id)" type="button" class="mt-6 p-button-danger text-center text-sm w-full" >
                     <i class="pi pi-trash mr-2" />   <p class="m-0 p-0 text-center w-full">Delete notification</p> 
                 </Button>

            </div>


		</div>
	</Sidebar>


    <div  class="surface-section">
            <div class="flex">
                <div style="flex: 1">
                    <div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
                    <div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
                </div>

            </div>
            <Loading v-if="loading" :title="'Loading Notifications'" :full="false" />
            <div v-else>
                <DataTable @row-click="onNotificationSelected" :value="userNotifications" dataKey="id" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll" >
                
                        <template #empty> No {{ pageTitle }} found. </template>
                        <template #loading> Loading {{ pageTitle }}. Please wait. </template>
                        <Column field="title" header="">
                          <template #body="{ data }">
                         <img :src="data.venueLogoUrl ?? npLogo" class="w-4rem" />
                        </template>
                        </Column>
                        <Column field="title" header="Title"></Column>
                        <Column field="venueName" header="Venue">
                          <template #body="{ data }">
                         <p>{{data.venueName ?? '-'}}</p>
                        </template>
                        </Column>
                        <Column field="timestamp" header="Sent">
                        <template #body="{ data }">
                            <span>{{formatDateTime(data.timestamp)}}</span>
                        </template>
                        </Column>
                        <Column  header="Status">
                             <template #body="{ data }">
                                <Chip class="px-3 p-2 font-bold text-xs text-white" style="background: red" v-if="data.deleted">DELETED</Chip> 
                                <Chip class="px-3 p-2 font-bold text-xs  text-white" style="background: LimeGreen" v-else-if="data.isRead">READ</Chip> 
                                <Chip class="px-3 p-2 font-bold text-xs bg-primary"  v-else-if="data.isOpened">OPENED</Chip> 
                                <Chip class="px-3 p-2 font-bold text-white text-xs" v-else>SENT</Chip> 
                            </template>
                        </Column>

                </DataTable>
            </div>
    </div>


</template>


<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import useFormatDate from '@/utils/useFormatDate'
import npLogo from '../../../assets/nightpay_logo_RGB.jpg'

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeGetterGetEntities = 'userNotifications/userNotifications';
		const storeActionGetEntities = 'userNotifications/getUserNotifications';
		const storeActionDeleteEntity = 'userNotifications/delete';

		const pageTitle = 'Notifications';
		const pageDescription = 'Here you can manage users notifications';
	

        const store = useStore();
        const route = useRoute()
        const toast = useToast();


        const userId = route.params.userId;

        const user = ref()
        const loading = ref(true);
        const showNotificationDetailDialog = ref(false)
        const userNotifications = ref([])
        const userNotificationDetail = ref()




        function formatDateTime(date){
            return useFormatDate().formatDateTime(date) ?? "-"
        }

        function getStatusTitle(){
            var notification = userNotificationDetail.value
            if(notification.deleted) return "Deleted";
            if(notification.isRead) return "Read";
            if(notification.isOpened) return "Opened"
            return "Sent"
        }

        function onNotificationSelected(notification){
            userNotificationDetail.value = notification.data
            showNotificationDetailDialog.value = true;

        }

        async function deleteNotification(notificationId){
            showNotificationDetailDialog.value = false;
            loading.value = true;
            await store.dispatch(storeActionDeleteEntity, {notificationId})
            .then(()=>{
                toast.add({ severity: 'success', summary: 'Success', detail: 'Notification was deleted', life: 3000 });

            })
            .catch((err)=>{
                toast.add({ severity: 'error', summary: 'Error', detail: 'Could delete notification' + ': ' + err, life: 3000 });

            })

            loadRequiredData();
        }



        async function loadRequiredData(){
            loading.value = true;


            //Ensure user
			await store.dispatch('users/ensureUser', { userId: userId });
			const loadedUser = store.getters['users/user'](userId);
            user.value = loadedUser;

            // Load notifications
            await store.dispatch(storeActionGetEntities, {userId})
            .then(()=> {
                const loadedNotifications = store.getters[storeGetterGetEntities](userId)
                userNotifications.value = loadedNotifications
            })
            .catch((err)=>{
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not get notifications' + ': ' + err, life: 3000 });
            })
            .finally(()=> {
                loading.value = false
            })
		
        }

        onMounted(()=>{
            loadRequiredData()

        })

        return {
            pageTitle,
            pageDescription,
            loading, 
            showNotificationDetailDialog,

            userNotifications,
            userNotificationDetail,
            getStatusTitle,

            formatDateTime,
            npLogo,

            onNotificationSelected,
            deleteNotification
        }
    }
}
</script>
