<template>
  <div v-if="full == null || full == true" class="fullscreen-modal plain">
    <div class="fullscreen-modal-container center-on-screen">
      <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <h1 class="logo">{{title ?? env.VUE_APP_TITLE_LOWER}}<span>.</span></h1>
    </div>
  </div>
  <div v-else class="flex align-items-center justify-content-center">
    <div class="flex flex-column align-items-center">
      <div class="spinner-grow" style="width: 2rem; height: 2rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <h3 class="logo">{{title ?? env.VUE_APP_TITLE_LOWER}}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  components: {},
  props: [
    'title',
    'full'
  ],
  setup() {
    return {
      env: process.env
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fullscreen-modal h1 {
  margin-bottom: 20px;
  font-weight: 700; 
}
.fullscreen-modal h1.logo {
  color: #2A2371; 
}
.fullscreen-modal h1.logo > span {
  color: #F8B449; 
}

.fullscreen-modal {
  display: flex;
  background: rgb(98,200,141);
  background: linear-gradient(90deg, rgba(98,200,141,1) 0%, rgba(99,200,109,1) 100%); 
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.fullscreen-modal.plain {
  background: #fff
}

.fullscreen-modal-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.center-on-screen {
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }
</style>
