import * as apiGetUserMemberCards from '@/api/userMemberCards/getUserMemberCards'
import * as apiAddUserMemberCard from '@/api/userMemberCards/addUserMemberCard'
import * as apiDeleteUserMemberCard from '@/api/userMemberCards/deleteUserMemberCard'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        userMemberCardListItems: {},
        detailedUserMemberCards: {}
    }),
    mutations: {
        gotUserMemberCardListItems(state, { userId, userMemberCards }) {
            console.log(userMemberCards)
            state.userMemberCardListItems[userId] = userMemberCards
        },

        gotUserMemberCard(state, userMemberCard) {
            state.detailedUserMemberCards[userMemberCard.id] = userMemberCard
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async add({ commit }, { userId, memberCardId, expiresAt }) {
            commit('isLoading', true)

            const payload = { 
                memberCardId: memberCardId ,
                expiresAt: expiresAt == "" ? "0001-01-01" : expiresAt
            }

            console.log('CREATING CARD', userId, payload)

            const result = await apiAddUserMemberCard.post(userId, payload)

            commit('isLoading', false)

            return result
        },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeleteUserMemberCard.del(entityId)

            commit('isLoading', false)

            return result
        },

        async getUserMemberCardListItems({ commit }, { userId }) {
            commit('isLoading', true)

            console.log('Getting User Member Card List Items');
        
            const result = await apiGetUserMemberCards.get(userId);

            if (result) {
                commit('gotUserMemberCardListItems', 
                { 
                    userId: userId,
                    userMemberCards: result 
                })
            }
        
            commit('isLoading', false)
        },

        /*
        async getUserMemberCard({ commit }, { userId }) {
            commit('isLoading', true)

            console.log('Getting User:', userId);
        
            const result = await apiGetUser.get(userId)
            
            if (result) {
                commit('gotUser', result)
            }
        
            commit('isLoading', false)
        },*/

        async ensureUserMemberCardListItems({ commit, dispatch, getters }, { userId }) {
            commit('isLoading', true)

            if (!getters.userMemberCardListItems(userId)) {
                await dispatch('getUserMemberCardListItems', { userId: userId })
            }

            commit('isLoading', false)
        },

        async ensureUserMemberCard({ commit, dispatch, getters }, { userMemberCardId }) {
            commit('isLoading', true)

            if (!getters.userMemberCard(userMemberCardId)) {
                console.log('User Member Card not cached. Getting from API.')
                await dispatch('getUserMemberCard', { userMemberCardId: userMemberCardId } )
            }

            commit('isLoading', false)
        }
    },
    getters: {
        userMemberCardListItems: (state) => (userId) => {
            return state.userMemberCardListItems[userId]
        },
        userMemberCard: (state) => (userMemberCardId) => {
            return state.detailedUserMemberCards[userMemberCardId]
        }
    }
};