import { createWebHistory, createRouter } from 'vue-router'

import { registerGuard } from "./guard";

import RootLayout from '@/pages/RootLayout'

import Welcome from '@/pages/welcome/Welcome.vue'

import Dashboard from '@/pages/dashboard/Dashboard.vue'

import Users from '@/pages/users/Users.vue'
import UserLayout from '@/pages/users/UserLayout.vue';
import GeneralUser from '@/pages/users/user/GeneralUser.vue'
import MemberCardsUser from '@/pages/users/user/MemberCardsUser.vue'
import ReceiptsUser from "@/pages/users/user/ReceiptsUser.vue"
import MembershipUser from '@/pages/users/user/MembershipUser.vue'
import BillWerkUser from '@/pages/users/user/BillWerk.vue'
import PerksUser from '@/pages/users/user/PerksUser.vue'
import PermissionsUser from '@/pages/users/user/PermissionsUser.vue'
import EventLogUser from '@/pages/users/user/EventLogUser.vue'
import DangerZoneUser from '@/pages/users/user/DangerZoneUser.vue'
// import CountryCodeUser from '@/pages/users/user/CountryCodeUser.vue'
import ConsentsUser from '@/pages/users/user/ConsentsUser.vue'
import NotificationsUser from '@/pages/users/user/NotificationsUser.vue'

import Venues from '@/pages/venues/Venues.vue'
import VenueLayout from '@/pages/venues/VenueLayout.vue';
import GeneralVenue from '@/pages/venues/venue/GeneralVenue.vue'
import NightPayVenue from '@/pages/venues/venue/NightPayVenue.vue'
import PhotosVenue from '@/pages/venues/venue/PhotosVenue.vue'
import OnlinePosConfigurationVenue from '@/pages/venues/venue/OnlinePosConfigurationVenue.vue'

// import UserTerms from '@/pages/user-terms/UserTerms.vue'
import Perks from '@/pages/perks/Perks.vue'
import OnboardingPerks from '@/pages/onboardingperks/OnboardingPerks.vue'
import MemberCards from '@/pages/member-cards/MemberCards.vue'
import Memberships from '@/pages/memberships/Memberships.vue'
import AppNotifications from '@/pages/appNotifications/AppNotifications.vue'
import AppContent from '@/pages/appContent/AppContent.vue'
import AppGames from '@/pages/appGames/AppGames.vue'
import CatchOffers from '@/pages/catchOffers/CatchOffers.vue'
import SubscriptionPlans from '@/pages/subscriptionPlans/SubscriptionPlans.vue'

import Campaigns from '@/pages/campaigns/Campaigns.vue'
import Partnerships from '@/pages/partnerships/Partnerships.vue'
import PromoCodes from '@/pages/promoCodes/PromoCodes.vue'
import Roles from '@/pages/roles/Roles.vue'

// import Orders from '@/pages/orders/Orders.vue'

import Developers from '@/pages/developers/Developers.vue'

import Test from '@/pages/test/Test.vue'

import Settings from '@/pages/settings/Settings.vue'
import SettingsTeam from '@/pages/settings/team/Team.vue'

import Invite from '@/pages/invites/Invite.vue'

import NoAccount from '@/pages/common/NoAccount.vue'
import NoPermissions from '@/pages/common/NoPermissions.vue'
import Failed from '@/pages/common/Failed.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: RootLayout,
    meta: {
      requiresAuth: true
    },
    redirect: () => {
      return '/dashboard'
    },

    children: [
      {
        path: 'welcome',
        name: 'welcome',
        component: Welcome,
        //beforeEnter: routeGuard
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          breadcrumb: "Home"
        }
        //beforeEnter: routeGuard
      },

      {
        path: 'developers',
        name: 'developers',
        component: Developers,
        meta: {
          breadcrumb: "Developers"
        }
        //beforeEnter: routeGuard
      },

      {
        path: 'settings',
        name: 'settings',
        component: Settings,
        meta: {
          breadcrumb: "Settings"
        }
        //beforeEnter: routeGuard
      },
      {
        path: 'settings/team',
        name: 'settings-team',
        component: SettingsTeam,
        meta: {
          breadcrumb: "Settings-team"
        }
        //beforeEnter: routeGuard
      },

      {
        path: 'users',
        name: 'users',
        component: Users,
        meta: {
          breadcrumb: "Users"
        }
        //beforeEnter: routeGuard
      },

      {
        path: 'venues',
        name: 'venues',
        component: Venues,
        meta: {
          breadcrumb: "Venues"
        }
        //beforeEnter: routeGuard
      },

      // {
      //   path: 'orders',
      //   name: 'orders',
      //   component: Orders,
      //   //beforeEnter: routeGuard
      // },

      // {
      //   path: 'user-terms',
      //   name: 'user-terms',
      //   component: UserTerms,
      //   //beforeEnter: routeGuard
      // },

      {
        path: 'perks',
        name: 'perks',
        component: Perks,
        meta: {
          breadcrumb: "Perks"
        }
        //beforeEnter: routeGuard
      },

      {
        path: 'onboardingperks',
        name: 'onboardingperks',
        component: OnboardingPerks,
        meta: {
          breadcrumb: "Onboarding Perks"
        }
        //beforeEnter: routeGuard
      },

      {
        path: 'member-cards',
        name: 'member-cards',
        component: MemberCards,
        meta: {
          breadcrumb: "Member Cards"
        }
        //beforeEnter: routeGuard
      },

      {
        path: 'memberships',
        name: 'Memberships',
        component: Memberships,
        meta: {
          breadcrumb: "Memberships"
        }

        //beforeEnter: routeGuard
      },

      {
        path: 'subscription-plans',
        name: 'SubscriptionPlans',
        component: SubscriptionPlans,
        meta: {
          breadcrumb: "Subscription Plans"
        }

        //beforeEnter: routeGuard
      },

      {
        path: 'catchoffers',
        name: 'CatchOffers',
        component: CatchOffers,
        meta: {
          breadcrumb: "Catch Offers"
        }

        //beforeEnter: routeGuard
      },
      {
        path: 'app-content',
        name: 'App Content',
        component: AppContent,
        meta: {
          breadcrumb: "App Content"
        }
        
        //beforeEnter: routeGuard
      },

      {
        path: 'notifications',
        name: 'App Notifications',
        component: AppNotifications,
        meta: {
          breadcrumb: "App Notifications"
        }

        //beforeEnter: routeGuard
      },
      {
        path: 'games',
        name: 'App Games',
        component: AppGames,
        meta: {
          breadcrumb: "App Games"
        }
        
        //beforeEnter: routeGuard
      },
      {
        path: 'campaigns',
        name: 'campaigns',
        component: Campaigns,
        meta: {
          breadcrumb: "Campaigns"
        }

        //beforeEnter: routeGuard
      },

      {
        path: 'partnerships',
        name: 'partnerships',
        component: Partnerships,
        meta: {
          breadcrumb: "Partnerships"
        }
        //beforeEnter: routeGuard
      },

      {
        path: 'promo-codes',
        name: 'promo-codes',
        component: PromoCodes,
        meta: {
          breadcrumb: "Promo Codes"
        }
        //beforeEnter: routeGuard
      },

      {
        path: 'test',
        name: 'test',
        component: Test,
        //beforeEnter: routeGuard
      },

      {
        path: 'roles',
        name: 'roles',
        component: Roles,
        meta: {
          breadcrumb: "Roles"
        }
        //beforeEnter: routeGuard
      }
    ]
  },

  {
    path: '/invites/:invitationId',
    name: 'invite',
    component: Invite,
    //beforeEnter: routeGuard
  },
  {
    path: '/invites',
    redirect: to => {
      console.log(to)

      const { query } = to
      return '/invites/' + query.invitationId
    }
  },

  {
    path: '/no-account',
    name: 'no-account',
    component: NoAccount
  },
  {
    path: '/no-permissions',
    name: 'no-permissions',
    component: NoPermissions
  },
  {
    path: '/failed',
    name: 'failed',
    component: Failed,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/user/:userId',
    name: 'user',
    component: UserLayout,
    props: route => ({ userId: route.params.userId }),
    //beforeEnter: routeGuard,

    children: [
      {
        path: '',
        name: 'edit-user',
        redirect: to => {
          const { params } = to
          return '/user/' + params.userId + '/general'
        }
      },
      {
        path: 'general',
        component: GeneralUser,
        name: 'user-general',
        meta: {
          breadcrumb: "General"
        }
      },
      {
        path: 'consents',
        component: ConsentsUser,
        meta: {
          breadcrumb: "Consents"
        }
      },
      // {
      //   path: 'country',
      //   component: CountryCodeUser,
      //   meta: {
      //     breadcrumb: "Country"
      //   }
      // },
      {
        path: 'member-cards',
        component: MemberCardsUser,
        meta: {
          breadcrumb: "Member Cards"
        }
      },
      {
        path: 'receipts',
        component: ReceiptsUser,
        meta: {
          breadcrumb: "Receipts"
        }
      },
      {
        path: 'billwerk',
        component: BillWerkUser,
        meta: {
          breadcrumb: "BillWerk"
        }
      },
      {
        path: 'membership',
        component: MembershipUser,
        meta: {
          breadcrumb: "Membership"
        }
      },
      {
        path: 'notifications',
        component: NotificationsUser,
        meta: {
          breadcrumb: "Notifications"
        }
      },
      {
        path: 'perks',
        component: PerksUser,
        meta: {
          breadcrumb: "Perks"
        }
      },
      {
        path: 'permissions',
        component: PermissionsUser,
        meta: {
          breadcrumb: "Permissions"
        }
      },
      {
        path: 'event-log',
        component: EventLogUser,
        meta: {
          breadcrumb: "Event log"
        }
      },
      {
        path: 'danger-zone',
        component: DangerZoneUser,
        meta: {
          breadcrumb: "Danger Zone"
        }
      }
    ]
  },

  {
    path: '/venue/:venueId',
    name: 'venue',
    component: VenueLayout,
    props: route => ({ venueId: route.params.venueId }),
    redirect: to => {
      return '/venue/' + to.params.venueId + '/general'
    },

    children: [
      /*{
        path: '',
        name: 'edit-venue',
        redirect: to => {
          const { params } = to
          return '/venue/' + params.venueId + '/general'
        }
      },*/
      {
        path: 'general',
        component: GeneralVenue,
        meta: {
          breadcrumb: "General"
        },
        props: route => ({ venueId: route.params.venueId })
      },
      {
        path: 'nightpay',
        component: NightPayVenue,
        meta: {
          breadcrumb: "NightPay"
        },
        props: route => ({ venueId: route.params.venueId })
      },
      {
        path: 'photos',
        component: PhotosVenue,
        meta: {
          breadcrumb: "Photos"
        },
        props: route => ({ venueId: route.params.venueId })
      },
      {
        path: 'onlinepos',
        component: OnlinePosConfigurationVenue,
        meta: {
          breadcrumb: "OnlinePos"
        },
        props: route => ({ venueId: route.params.venueId })
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

registerGuard(router);

export default router;