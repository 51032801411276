<template>
  <div id="app-sidebar-2" class="
      surface-section
      hidden
      lg:block
      flex-shrink-0
      z-1
      border-right-1
      surface-border
      sidebar
      select-none
      bg-blue-400
      fixed
      left-0
      overflow-y-auto
      h-full
      " style="width: 280px;">
    <div class="flex flex-column" style="margin-top: 84px">
      <div class="flex align-items-center px-5 flex-shrink-0" style="height: 60px">
        <h1 v-if="account" style="line-height: 20px">
          <router-link :to="'/account/' + account.accountId" class="logo" style="font-size: 18px">{{ account.title
          }}<span>.</span></router-link>
        </h1>
      </div>
      <div class="overflow-y-auto">
        <ul class="list-none p-3 pt-0 m-0">
          <li v-for="option in config.productOptions" :key="option.title">
            <router-link :to="option.slug" style="text-decoration: none" class="
                flex
                align-items-center
                cursor-pointer
                p-3
                border-round
                text-700
                hover:surface-100
                transition-duration-150 transition-colors
              ">
              <i class="fa-fw mr-2" :class="option.icon"></i>
              <span class="font-medium">{{ option.title }}</span>
              <span v-if="option.label" class="
                  inline-flex
                  align-items-center
                  justify-content-center
                  ml-auto
                  bg-blue-500
                  text-0
                  border-circle
                " style="min-width: 1.5rem; height: 1.5rem">
                {{ option.label }}
              </span>
            </router-link>
          </li>
        </ul>

        <ul class="list-none p-3 pt-0 m-0">
          <li>
            <div class="
                p-3
                flex
                align-items-center
                justify-content-between
                text-600
                cursor-pointer
              " v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                enterActiveClass: 'slidedown',
                leaveToClass: 'hidden',
                leaveActiveClass: 'slideup',
              }">
              <span class="font-medium">ONLINEPOS</span>
              <i class="pi pi-chevron-down"></i>
            </div>
            <ul class="list-none p-0 m-0 overflow-hidden">
              <li v-for="option in config.posOptions" :key="option.title">
                <router-link :to="option.slug" style="text-decoration: none" class="
                    flex
                    align-items-center
                    cursor-pointer
                    p-3
                    border-round
                    text-700
                    hover:surface-100
                    transition-duration-150 transition-colors
                  ">
                  <i class="fa-fw mr-2" :class="option.icon"></i>
                  <span class="font-medium">{{ option.title }}</span>
                  <span v-if="option.label" class="
                      inline-flex
                      align-items-center
                      justify-content-center
                      ml-auto
                      bg-blue-500
                      text-0
                      border-circle
                    " style="min-width: 1.5rem; height: 1.5rem">
                    {{ option.label }}
                  </span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>

        <ul class="list-none p-3 pt-0 m-0">
          <li>
            <div class="
                p-3
                flex
                align-items-center
                justify-content-between
                text-600
                cursor-pointer
              " v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                enterActiveClass: 'slidedown',
                leaveToClass: 'hidden',
                leaveActiveClass: 'slideup',
              }">
              <span class="font-medium">TEST</span>
              <i class="pi pi-chevron-down"></i>
            </div>
            <ul class="list-none p-0 m-0 overflow-hidden">
              <li v-for="option in config.testOptions" :key="option.title">
                <router-link :to="option.slug" style="text-decoration: none" class="
                    flex
                    align-items-center
                    cursor-pointer
                    p-3
                    border-round
                    text-700
                    hover:surface-100
                    transition-duration-150 transition-colors
                  ">
                  <i class="fa-fw mr-2" :class="option.icon"></i>
                  <span class="font-medium">{{ option.title }}</span>
                  <span v-if="option.label" class="
                      inline-flex
                      align-items-center
                      justify-content-center
                      ml-auto
                      bg-blue-500
                      text-0
                      border-circle
                    " style="min-width: 1.5rem; height: 1.5rem">
                    {{ option.label }}
                  </span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>

        <ul class="list-none p-3 pt-0 m-0">
          <li>
            <div class="
                p-3
                flex
                align-items-center
                justify-content-between
                text-600
                cursor-pointer
              " v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                enterActiveClass: 'slidedown',
                leaveToClass: 'hidden',
                leaveActiveClass: 'slideup',
              }">
              <span class="font-medium">ACCOUNT</span>
              <i class="pi pi-chevron-down"></i>
            </div>
            <ul class="list-none p-0 m-0 overflow-hidden">
              <li v-for="option in config.accountOptions" :key="option.title">
                <router-link :to="option.slug" style="text-decoration: none" class="
                    flex
                    align-items-center
                    cursor-pointer
                    p-3
                    border-round
                    text-700
                    hover:surface-100
                    transition-duration-150 transition-colors
                  ">
                  <i class="fa-fw mr-2" :class="option.icon"></i>
                  <span class="font-medium">{{ option.title }}</span>
                  <span v-if="option.label" class="
                      inline-flex
                      align-items-center
                      justify-content-center
                      ml-auto
                      bg-blue-500
                      text-0
                      border-circle
                    " style="min-width: 1.5rem; height: 1.5rem">
                    {{ option.label }}
                  </span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "RootSidebar",
  props: [],
  setup() {
    const loading = ref(false);
    const account = ref();

    const store = useStore();

    async function loadRequiredData() {
      loading.value = true;
      account.value = store.getters["activeUserAccount"];
      loading.value = false;
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      loading,

      account,

      config: computed(() => {
        const productOptions = store.getters["sidebarItems"].filter(
          (item) => item.group === "main"
        );
        const posOptions = store.getters["sidebarItems"].filter(
          (item) => item.group === "onlinepos"
        );
        const testOptions = store.getters["sidebarItems"].filter(
          (item) => item.group === "test"
        );
        const accountOptions = store.getters["sidebarItems"].filter(
          (item) => item.group === "account"
        );

        return {
          productOptions,
          posOptions,
          testOptions,
          accountOptions,
        };
      }),
    };
  },
};
</script>

<style scoped>
.router-link-exact-active .font-medium,
.router-link-exact-active i.pi {
  font-weight: 700 !important;
}

h1 {
  margin-bottom: 20px;
  font-weight: 700;
}

h1 .logo {
  color: #2a2371;
  text-decoration: none;
}

h1 .logo>span {
  color: #f8b449;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>
