import * as apiGetUserTerms from '@/api/userTerms/getUserTerms'
import * as apiCreateUserTerms from '@/api/userTerms/createUserTerms'
import * as apiUpdateUserTerms from '@/api/userTerms/updateUserTerms'
import * as apiUpdateUserTermsVersions from '@/api/userTerms/updateUserTermsVersions'
import * as apiDeleteUserTerms from '@/api/userTerms/deleteUserTerms'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        userTermsListItems: []
    }),
    mutations: {
        gotUserTermsListItems(state, userTerms) {
            state.userTermsListItems = userTerms
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async create({ commit }, payload) {
            commit('isLoading', true)

            console.log('Posting:', payload)

            const result = await apiCreateUserTerms.post(payload)

            commit('isLoading', false)

            return result
        },

        async getUserTermsListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting User Terms List Items');
        
            const result = await apiGetUserTerms.get();

            if (result) {
                commit('gotUserTermsListItems', result)
            }
        
            commit('isLoading', false)
        },

        async update({ commit }, userTerms) {
            commit('isLoading', true)

            await apiUpdateUserTerms.put(userTerms.id, userTerms)

            commit('isLoading', false)
        },

        async updateVersions({ commit }, userTerms) {
            commit('isLoading', true)

            await apiUpdateUserTermsVersions.put(userTerms.id, userTerms.versions)

            commit('isLoading', false)
        },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeleteUserTerms.del(entityId)

            commit('isLoading', false)

            return result
        },

        async ensureUserTermsListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.userTermsListItems.length === 0) {
                await dispatch('getUserTermsListItems')
            }

            commit('isLoading', false)
        },

        async ensureUserTerms({ commit, dispatch, getters }, { userTermsId }) {
            commit('isLoading', true)

            if (!getters.userTerms(userTermsId)) {
                console.log('User Terms not cached. Getting from API.')
                await dispatch('getUserTermsListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        userTermsListItems: (state) => state.userTermsListItems,
        userTerms: (state) => (userTermsId) => {
            return  _.find(state.userTermsListItems, function(userTerms) { return userTerms.id === userTermsId; });
        }
    }
};