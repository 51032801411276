<template>
  <div class="page">
    <h2><span class="fa fa-code"></span> Developers</h2>
    <p class="mb-4">
      Here you can add webhooks and generate API keys for integration in 3rd party applications.
    </p>
    <div>
      <div style="text-align: center;">
        <img src="@/assets/icons/empty-box.png" alt="" style="opacity: 0.3; width: 178px; height: 178px;">
        <p class="text-2xl font-bold mt-2" style="color: #b3cbe6;">You are not listed as a Developer.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {},
  data: function () {
    return {
    }
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  display: flex;
}

.left {
  flex: 1;
}

.right {
  width: 350px;
}
</style>
