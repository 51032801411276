import apiClient from '@/services/axios';

export async function post(payload) {
	console.log('payload', payload);

	var response = await apiClient.post('/Users/GetUsersFromFile/', payload);

	if (response) {
		return response.data;
	}

	throw new Error('Unable to get users from file.');
}
