import * as apiGetPools from '@/api/pools/getPools'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        poolListItems: []
    }),
    mutations: {
        gotPoolListItems(state, pools) {
            state.poolListItems = pools
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getPoolListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Pool List Items');
        
            const result = await apiGetPools.get();

            if (result) {
                commit('gotPoolListItems', result)
            }
        
            commit('isLoading', false)
        },

        async ensurePoolListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.poolListItems.length === 0) {
                await dispatch('getPoolListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        poolListItems: (state) => state.poolListItems
    }
};