import apiClient from '@/services/axios'

export async function post(payload) {
  console.log('Creating Promo Code:', payload)
  
  var response = await apiClient.post('/promoCodes/', payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to create Promo Code.')
}