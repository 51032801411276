<template>
	<Sidebar v-if="hasPermission('np.backoffice.partnerships.add')" v-model:visible="createNewSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="creating" :title="'Creating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Create {{ entityName }}</h3>
			<Form :fields="fields" :context="newEntity" @onAction="createEntity" :actionLabel="'Create'" :actionIcon="'pi pi-plus-circle'" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.partnerships.editdelete')" v-model:visible="updateSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit {{ entityName }}</h3>
			<Form :fields="fields" :context="selectedEntity" @onAction="updateEntity" :actionLabel="'Update'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<div class="surface-section p-6 pt-0" v-if="hasPermission('np.backoffice.partnerships.show')">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.partnerships.add')" @click="createNewSidebarVisible = true" :label="'New ' + entityName" icon="pi pi-plus-circle" />
			</div>
		</div>

		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
		<div v-else class="card">
			<DataTable :value="entities" dataKey="id" @row-click="onRowClicked" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll">
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
				<Column header="Pool">
					<template #body="{ data }">
						{{ getPool(data.poolId) }}
					</template>
				</Column>
				<Column header="Member Card">
					<template #body="{ data }">
						{{ getMemberCard(data.memberCardId) }}
					</template>
				</Column>
				<Column header="Created">
					<template #body="{ data }">
						{{ formatDate(data.created) }}
					</template>
				</Column>
				<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<Button v-if="hasPermission('np.backoffice.partnerships.editdelete')" @click="confirmDeleteEntity(data)" type="button" icon="pi pi-trash"></Button>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import { useStore } from 'vuex';

import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'partnerships/getPartnershipListItems';
		const storeGetterGetEntities = 'partnerships/partnershipListItems';
		const storeActionCreateEntity = 'partnerships/create';
		const storeActionUpdateEntity = 'partnerships/update';
		const storeActionDeleteEntity = 'partnerships/delete';
		const entityName = 'Partnership';
		const pageTitle = 'Partnerships';
		const pageDescription = 'Here you can manage your Partnerships';

		const fields = ref([]);

		const loading = ref(true);
		const creating = ref(false);
		const updating = ref(false);


		const memberCards = ref([]);
		const pools = ref([]);

		const useFormatDate = inject('useFormatDate');

		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const entities = ref([]);

		const newEntity = ref({
			poolId: {
				id: null,
			},
			memberCardId: {
				id: null,
			},
		});
		const selectedEntity = ref({});

		const createNewSidebarVisible = ref(false);
		const updateSidebarVisible = ref(false);

		function onRowClicked(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload.data));
			updateSidebarVisible.value = true;
		}

		function getPool(poolId) {
			if (poolId == null) {
				return 'Not defined';
			}

			const foundPool = pools.value.find((pool) => pool.id === poolId.id);
			if (foundPool) {
				return foundPool.title;
			}

			return 'Not defined';
		}

		function getMemberCard(memberCardId) {
			if (memberCardId == null) {
				return 'Not defined';
			}

			const foundMemberCard = memberCards.value.find((memberCard) => memberCard.id === memberCardId.id);
			if (foundMemberCard) {
				return foundMemberCard.title;
			}

			return 'Not defined';
		}

		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000 });
					} catch (err) {
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete ' + entityName + ': ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => {},
			});
		}

		async function createEntity(formContext) {
			creating.value = true;

			try {
				await store.dispatch(storeActionCreateEntity, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' created', life: 3000 });
				createNewSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			creating.value = false;
		}

		async function updateEntity(formContext) {
			updating.value = true;

			try {
				await store.dispatch(storeActionUpdateEntity, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' updated', life: 3000 });
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			updating.value = false;
		}

		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities);
				const loadedEntities = store.getters[storeGetterGetEntities];
				entities.value = loadedEntities;
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}

		async function loadRequiredData() {
			loading.value = true;

			// Load Member Cards
			try {
				await store.dispatch('memberCards/ensureMemberCardListItems');
				const loadedMemberCards = store.getters['memberCards/memberCardListItems'];
				memberCards.value = loadedMemberCards;
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Member Cards: ' + err, life: 3000 });
			}

			// Load Pools
			try {
				await store.dispatch('pools/ensurePoolListItems');
				const loadedPools = store.getters['pools/poolListItems'];
				pools.value = loadedPools;
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Pools: ' + err, life: 3000 });
			}

			reloadEntities();

			console.log('Pools:', pools.value);
			console.log('memberCards:', memberCards.value);

			fields.value = [
				{
					id: 'poolId',
					path: 'poolId.id',
					type: 'dropdown',
					title: 'Pools',
					config: {
						options: pools,
						optionLabel: 'title',
						optionValue: 'id',
						placeholder: 'Select Pool',
					},
				},
				{
					id: 'memberCardId',
					path: 'memberCardId.id',
					type: 'dropdown',
					title: 'Member Cards',
					config: {
						options: memberCards,
						optionLabel: 'title',
						optionValue: 'id',
						placeholder: 'Select Member Card',
					},
				},
			];

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			entityName,
			pageTitle,
			pageDescription,
			loading,
			creating,
			updating,

			getPool,
			getMemberCard,

			fields,
			newEntity,
			selectedEntity,
			createEntity,
			updateEntity,
			confirmDeleteEntity,

			//userTerms,

			entities,

			createNewSidebarVisible,
			updateSidebarVisible,

			onRowClicked,

			formatDate: useFormatDate().formatDate,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
