<template>
	<Sidebar v-if="hasPermission('np.backoffice.perks.addperk')" v-model:visible="createNewSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="creating" :title="'Creating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Create {{ entityName }}</h3>
			<Form :fields="fields" :context="newEntity" @onAction="createEntity" :actionLabel="'Create'" :actionIcon="'pi pi-plus-circle'" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.perks.bulkassign')" v-model:visible="BulkAssignSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="assigning" :title="'Bulk assign ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Bulk assign perks</h3>
		</div>

		<div class="mb-5">
			<p class="text-lg font-semibold">Upload CSV from Google Analytics</p>
			<FileUpload
				name="demo[]"
				mode="basic"
				:customUpload="true"
				@uploader="uploadFile($event)"
				@upload="onUpload"
				:multiple="false"
				accept=".csv"
				:auto="true"
				chooseLabel="Browse"
				:maxFileSize="1000000"
			/>
		</div>

		<div v-if="usersFound">
			<div>
				<p class="text-lg font-semibold">Users found in file</p>
			</div>
			<div class="mb-5">
				<DataTable :value="usersInFile" dataKey="id" class="p-datatable-lg" :rowHover="true" responsiveLayout="paging">
					<template #empty> No {{ pageTitle }} found. </template>
					<template #loading> Loading {{ pageTitle }}. Please wait. </template>
					<Column header="Name">
						<template #body="{ data }"> {{ data.firstName }} {{ data.lastName }} </template>
					</Column>
					<Column field="email" header="Email"></Column>
				</DataTable>
			</div>

			<div>
				<p class="text-lg font-semibold">Select country</p>
			</div>
			<div class="mb-5">
				<Dropdown v-model="selectedCountry" :options="countries" optionLabel="label" placeholder="Select country"></Dropdown>
				<!-- <Dropdown v-model="selectedPropertyCountry" :options="countries" optionLabel="label" placeholder="Select country" @change="propertiesCountryChanged($event)"></Dropdown> -->
			</div>

			<div>
				<p class="text-lg font-semibold">Select perks</p>
			</div>
			<div class="mb-5">
				<MultiSelect v-model="selectedPerks" :options="entities" optionLabel="name" placeholder="Select Perks" :filter="true" class="multiselect-custom">
					<template #value="slotProps">
						<div class="perk-item perk-item-value" v-for="option of slotProps.value" :key="option.code">
							<div>{{ option.name }}</div>
						</div>
						<template v-if="!slotProps.value || slotProps.value.length === 0"> Select Perks </template>
					</template>
					<template #option="slotProps">
						<div class="perk-item">
							<div>{{ slotProps.option.name }}</div>
						</div>
					</template>
				</MultiSelect>
			</div>

			<div>
				<p class="text-lg font-semibold">Perks configuration</p>
			</div>
			<Form @onAction="assignPerks" :fields="perkDetailsFields" :context="perkDetailsContext" :actionLabel="'Assign perks'" :icon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.perks.editdelete')" v-model:visible="updateSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit {{ entityName }}</h3>
			<Form :fields="fields" :context="selectedEntity" @onAction="updateEntity" :actionLabel="'Update'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<div class="surface-section p-6 pt-0" v-if="hasPermission('np.backoffice.perks.show')">

		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center mr-2">
				<Button v-if="hasPermission('np.backoffice.perks.bulkassign')" @click="BulkAssignSidebarVisible = true" label="Bulk assign perks" icon="fa fa-users" />
				<!-- <Button v-if="canBulkAssign" @click="ShowBulkAssignSidebar" label="Bulk assign perks" icon="fa fa-users" /> -->
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.perks.addperk')" @click="createNewSidebarVisible = true" :label="'New ' + entityName" icon="pi pi-plus-circle" />
			</div>
		</div>

		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
		<div v-else class="card">
			<DataTable :value="entities" dataKey="id" @row-click="onRowClicked" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll">
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
				<Column>
					<template #body="{ data }">
						<img v-if="data.pictureUrl" :src="data.pictureUrl" style="width: 60px; height: 60px; object-fit: contain" />
					</template>
				</Column>
				<Column field="name" header="Name"></Column>
				
				<Column header="Created">
					<template #body="{ data }">
						{{ formatDate(data.created) }}
					</template>
				</Column>
				<Column header="Enabled">
					<template #body="{ data }">
						<Chip v-if="data.enabled" label="Enabled" class="chip-enabled" />
						<Chip v-else label="Disabled" class="chip-disabled" />
					</template>
				</Column>
				<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<Button v-if="hasPermission('np.backoffice.perks.editdelete')" @click="confirmDeleteEntity(data)" type="button" icon="pi pi-trash"></Button>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, inject, computed } from 'vue';
import { useStore } from 'vuex';

import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { uploadFile as fileUploadService} from '@/services/fileUploadService'

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'perks/getPerkListItems';
		const storeGetterGetEntities = 'perks/perkListItems';
		const storeActionCreateEntity = 'perks/create';
		const storeActionUpdateEntity = 'perks/update';
		const storeActionDeleteEntity = 'perks/delete';
		const storeActionGetUsersFromFile = 'users/getUsersFromFile';
		const storeActionAssignPerks = 'userPerks/bulkAssign';
		const entityName = 'Perk';
		const pageTitle = 'Perks';
		const pageDescription = 'Here you can manage your Perks';

		const fields = ref([]);

		const loading = ref(true);
		const creating = ref(false);
		const assigning = ref(false);
		const updating = ref(false);

		const useFormatDate = inject('useFormatDate');

		const activeCountryCode = computed(() => {
			var activeAccountCountry = store.getters["activeAccountCountry"]
			var countryAccounts = store.getters["countryAccounts"]
			return countryAccounts?.find(x => x.id == activeAccountCountry)?.countryCode
		});

		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const entities = ref([]);
		const selectedPerks = ref([]);
		const selectedCountry = ref({});
		const venues = ref([])

		const newEntity = ref({
			title: '',
			name: '',
			price: {
				amount: 0,
				currencyCode: 'DKK',
			},
			description: '',
			pictureUrl: '',
			iconUrl: '',
			memberCardId: { id: null },
			posCampaignId: '',
			perkType: { id: null },
			assignable: false,
			purchasable: false,
			isExpense: false,
			enabled: false,
			productCategories: {
				id: null,
			},
		});
		const selectedEntity = ref({});

		const perkDetailsContext = ref({
			quantity: 1,
			perkType: 'claimable',
			validFromUtc: null,
			validToUtc: null,
			visibleFromUtc: null,
		});

		const perkType = ref([
			{ title: 'NonRedeemable', id: 'NonRedeemable' },
			{ title: 'Redeemable', id: 'Redeemable' },
			{ title: 'UnspecifiedRedeemable', id: 'UnspecifiedRedeemable' },
			{ title: 'RedeemableNoPos', id: 'RedeemableNoPos' }
		]);

		const currencies = ref([
			{ value: 'DKK', label: 'DKK' },
			{ value: 'EUR', label: 'EUR' },
			{ value: 'NOK', label: 'NOK' },
		]);

		const countries = ref([
			{ value: 'DK', label: 'Denmark' },
			{ value: 'NO', label: 'Norway' },
			{ value: 'FI', label: 'Finland' },
		]);

		const perkDetailsFields = ref([
			{
				id: 'quantity',
				type: 'number-input',
				title: 'Quantity',
			},
			{
				id: 'perkType',
				type: 'dropdown',
				title: 'Claim Type',
				config: {
					options: perkType,
					optionLabel: 'title',
					optionValue: 'id',
					placeholder: 'Please select Claim Type',
				},
			},
			{
				id: 'validFromUtc',
				type: 'time',
				title: 'Valid from',
			},
			{
				id: 'validToUtc',
				type: 'time',
				title: 'Valid to',
			},
			{
				id: 'visibleFromUtc',
				type: 'time',
				title: 'Visible from',
			},
			{
				id: 'venueId',
				type: 'dropdown',
				title: 'Venue',
				filter: 'true',
				config: {
					options: [],
					optionLabel: "name",
					optionValue: "id",
					placeholder: "All"
				},
				help: "Allow perks to be used only on a specific venue or all venues"
			},
			{
				id: 'isFromNightPay',
				type: 'switch',
				title: 'Is from NightPay',
			},
			
		]);

		const memberCards = ref([]);
		const productCategories = ref([]);
		const onlinePosCampaigns = ref([]);
		const usersFound = ref(false);
		const usersInFile = ref(false);

		const createNewSidebarVisible = ref(false);
		const updateSidebarVisible = ref(false);
		const BulkAssignSidebarVisible = ref(false);

		function onRowClicked(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload.data));

			updateSidebarVisible.value = true;
		}

		async function onUpload() {
			console.log('onUpload');
		}

		async function uploadFile(event) {
			usersFound.value = true;

			var formData = new FormData();
			formData.append('file', event.files[0]);

			try {
				await store.dispatch(storeActionGetUsersFromFile, formData);
				const loadedUsers = store.getters['users/userListItems'];
				usersInFile.value = loadedUsers;

				toast.add({ severity: 'success', summary: 'Success', detail: loadedUsers.length + ' users found in file', life: 3000 });
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not get users in file: ' + err, life: 3000 });
			}
		}


		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000 });
					} catch (err) {
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete ' + entityName + ': ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => {},
			});
		}

		async function assignPerks(formContext) {
			assigning.value = true;

			try {
				formContext.perkIds = selectedPerks.value.map((x) => x.id);
				formContext.userIds = usersInFile.value.map((x) => x.id);
				formContext.countryCode = selectedCountry.value.value;

				await store.dispatch(storeActionAssignPerks, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: 'Perks added to users', life: 3000 });
				BulkAssignSidebarVisible.value = false;
				usersFound.value = null;
				usersInFile.value = null;
				selectedPerks.value = null;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not add perks to users: ' + err, life: 3000 });
			}

			loading.value = false;
			assigning.value = false;
		}

		async function createEntity(formContext) {
			creating.value = true;

			try {
				
				var imageContainer = formContext.name + "-" + activeCountryCode.value + "-images"
				if(formContext.pictureUrl && formContext.pictureUrl.name ){
					var pictureFile = await fileUploadService(formContext.pictureUrl, "perks", imageContainer, store)
					formContext.pictureUrl = pictureFile.url
				}
				if(formContext.iconUrl && formContext.iconUrl.name ){
					var iconFile = await fileUploadService(formContext.iconUrl, "perks", imageContainer, store)
					formContext.iconUrl = iconFile.url
				}

				await store.dispatch(storeActionCreateEntity, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' created', life: 3000 });
				createNewSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			creating.value = false;
		}

		async function updateEntity(formContext) {
			updating.value = true;

			try {

				var imageContainer = formContext.name + "-" + activeCountryCode.value + "-images"
				if(formContext.pictureUrl && formContext.pictureUrl.name ){
					var pictureFile = await fileUploadService(formContext.pictureUrl, "perks", imageContainer, store)
					formContext.pictureUrl = pictureFile.url
				}
				if(formContext.iconUrl && formContext.iconUrl.name ){
					var iconFile = await fileUploadService(formContext.iconUrl, "perks", imageContainer, store)
					formContext.iconUrl = iconFile.url
				}

				await store.dispatch(storeActionUpdateEntity, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' updated', life: 3000 });
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			updating.value = false;
		}

		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities);
				const loadedEntities = store.getters[storeGetterGetEntities];
				entities.value = loadedEntities;
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}

		async function loadRequiredData() {
			loading.value = true;

			// Load Product Categories
			try {
				await store.dispatch('productCategories/ensureProductCategoryListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Product Categories: ' + err, life: 3000 });
			}
			const loadedProductCategories = store.getters['productCategories/productCategoryListItems'];
			productCategories.value = loadedProductCategories;

			// Load Member Cards
			try {
				await store.dispatch('memberCards/ensureMemberCardListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Member Cards: ' + err, life: 3000 });
			}
			const loadedMemberCards = store.getters['memberCards/memberCardListItems'];
			memberCards.value = loadedMemberCards;

			// Load OnlinePOS Campaigns
			try {
				await store.dispatch('campaigns/ensureCampaignListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Campaigns: ' + err, life: 3000 });
			}
			const loadedCampaigns = store.getters['campaigns/campaignListItems'];
			onlinePosCampaigns.value = loadedCampaigns;

			
			reloadEntities();

			fields.value = [
				{
					id: 'title',
					type: 'text-input',
					title: 'Title',
					help: 'Name of the perk as shown in the app.',
				},
				{
					id: 'name',
					type: 'text-input',
					title: 'Name',
					help: 'Name only shown to admins. Can be name of campaign initiative.',
				},
				{
					id: 'priceAmount',
					path: 'price.amount',
					type: 'number-input-decimal',
					title: 'Price',
				},
				{
					id: 'priceCurrencyCode',
					path: 'price.currencyCode',
					type: 'dropdown',
					title: 'Currency',
					config: {
						options: [
							{ value: 'DKK', label: 'DKK' },
							{ value: 'NOR', label: 'NOR' },
							{ value: 'EUR', label: 'EUR' },
						],
						optionLabel: 'label',
						optionValue: 'value',
						placeholder: 'Select Currency',
					},
				},
				{
					id: 'description',
					type: 'text-area',
					title: 'Description',
				},
				{
					id: 'pictureUrl',
					type: 'imageUpload',
					title: 'Picture',
					help: 'Picture that will be shown on tickets. Dimensions of 215 x 115 will be fully visible. Try to keep below 300kb in size.',
				},
				{
					id: 'iconUrl',
					type: 'imageUpload',
					title: 'Icon',
					help: 'Icon that will be shown on benefits on a member card.',
				},
				{
					id: 'productCategoryIds',
					type: 'multi',
					title: 'Assign Product Categories',
					config: {
						options: productCategories,
						optionLabel: 'title',
						optionValue: 'id',
						placeholder: 'Select Product Category',
					},
					help: 'Select product categories that are relevant to the perk (Only unspecified redeemable perks)',
				},
				{
					id: 'posCampaignId',
					type: 'dropdown',
					title: 'Associate with OnlinePOS Campaign',
					config: {
						options: onlinePosCampaigns,
						optionLabel: 'campaignName',
						optionValue: 'posCampaignId',
						placeholder: 'Select OnlinePOS Campaign',
					},
				},
				{
					id: 'perkType',
					type: 'dropdown',
					title: 'Claim Type',
					config: {
						options: perkType,
						optionLabel: 'title',
						optionValue: 'id',
						placeholder: 'Please select Claim Type',
					},
				},
				{
					id: 'assignable',
					type: 'switch',
					title: 'Assignable',
				},
				{
					id: 'purchasable',
					type: 'switch',
					title: 'Purchasable',
				},
				{
					id: 'isExpense',
					type: 'switch',
					title: 'Is expense',
				},
				{
					id: 'enabled',
					type: 'switch',
					title: 'Enabled',
				},
			];
	
			loadVenues();

			loading.value = false;
		}
		
		async function loadVenues(){
			await store.dispatch('venues/ensureVenueListItems');
			const loadedVenues = store.getters['venues/venueListItems'];
			venues.value = loadedVenues;

			var options = [{id: null, name: "All"}, ...venues.value]

			var venueFieldIndex = perkDetailsFields.value.findIndex(x => x.id == "venueId");
			perkDetailsFields.value[venueFieldIndex].config["options"] = options
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			entityName,
			pageTitle,
			pageDescription,
			usersFound,
			loading,
			creating,
			updating,
			assigning,

			fields,
			newEntity,
			selectedEntity,
			perkDetailsFields,
			perkDetailsContext,
			createEntity,
			updateEntity,
			confirmDeleteEntity,
			uploadFile,
			onUpload,
			assignPerks,

			memberCards,
			productCategories,
			onlinePosCampaigns,
			currencies,
			usersInFile,
			perkType,
			countries,

			entities,
			selectedPerks,
			selectedCountry,

			createNewSidebarVisible,
			updateSidebarVisible,
			BulkAssignSidebarVisible,

			onRowClicked,

			formatDate: useFormatDate().formatDate,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.p-multiselect {
	width: 38rem;
}

.multiselect-custom .p-multiselect-label:not(.p-placeholder) {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.multiselect-custom .perk-item-value {
	padding: 0.25rem 0.5rem;
	border-radius: 3px;
	display: inline-flex;
	margin-right: 0.5rem;
	background-color: var(--primary-color);
	color: var(--primary-color-text);
}

.multiselect-custom .perk-item-value img.flag {
	width: 17px;
}

@media screen and (max-width: 640px) {
	.p-multiselect {
		width: 100%;
	}
}
</style>
