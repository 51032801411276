<template>
    <Sidebar v-model:visible="showNotificationDetailDialog" class="p-sidebar-md" position="right" :showCloseIcon="false">
    
    <div>
            <h3 class="flex">App notification  <span class="text-sm my-auto ml-1 text-gray-500"> - {{notificationDetail.venueName ?? 'OneSignal'}}</span></h3>

            <div class="w-full flex">
                <img  class="p-0 mx-auto mt-0 mb-4" :src="notificationDetail.venueLogoUrl ?? npLogo" width="200"  />
            </div>

        <div class="flex flex-wrap h-full ">
        
            <div style="min-height: 9rem" class="w-full h-full  bg-gray-100 border-round p-2">

                <div class="text-lg mb-1 font-bold" style="word-wrap: break-word">
                    {{notificationDetail.title}}
                </div>
                <div  class="mb-4" style="word-wrap: break-word;">
                 {{notificationDetail.description}}
                </div>

                <div v-if="notificationDetail.image" class="border-round overflow-hidden mx-auto w-8">
                    <img :src="notificationDetail.image" class="w-full" />
                </div>

            
            </div>
            <div class="w-full">
                <small class="w-full text-gray-400">Sent from {{notificationDetail?.venueName ?? "OneSignal"}} - {{formatDateTime(notificationDetail.timeStamp)}}</small>
            </div>

            <span style="height:0.6px" class="mt-4 bg-gray-400 w-full"></span>

            <div  class="w-full h-full flex justify-content-center flex-row gap-6 mt-6">
                <span class="text-center ">
                    <h3 class="p-0 m-0 mb-1 " :style="{color: notificationDetail.deleted == true ? 'red' : 'LimeGreen'}">{{notificationDetail.deleted == true ? 'Deleted':'Sent'}}</h3>
                    <p class="p-0 m-0">Status</p>
                </span>
                <span class="text-center ">
                    <h2 class="p-0 m-0">{{notificationDetail.openCount}}</h2>
                    <p class="p-0 m-0">Open count</p>
                </span>
                <span class="text-center">
                    <h2 class="p-0 m-0">{{notificationDetail.readCount}}</h2>
                    <p class="p-0 m-0">Read count</p>
                </span>
                    
            </div>

                <Button v-if="!notificationDetail.deleted" @click="deleteNotification(notificationDetail.id)" type="button" class="mt-6 p-button-danger text-center text-sm w-full" >
                    <i class="pi pi-trash mr-2" />   <p class="m-0 p-0 text-center w-full">Delete notification from all users inbox</p> 
                </Button>

        </div>


    </div>
</Sidebar>



  <div class="surface-section p-6 pt-0">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
        </div>

        <Loading v-if="loading" :title="'Loading Notifications'" :full="false" />
        <div v-else>
            <DataTable @row-click="onNotificationSelected" :value="notifications" dataKey="id" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll" >
            
                    <template #empty> No {{ pageTitle }} found. </template>
                    <template #loading> Loading {{ pageTitle }}. Please wait. </template>
                    <Column field="title" header="">
                        <template #body="{ data }">
                        <img :src="data.venueLogoUrl ?? npLogo" class="w-4rem" />
                    </template>
                    </Column>
                    <Column field="title" header="Title"></Column>
                    <Column field="venueName" header="Venue">
                        <template #body="{ data }">
                        <p>{{data.venueName ?? '-'}}</p>
                    </template>
                    </Column>
                    <Column field="timeStamp" header="Sent timestamp">
                    <template #body="{ data }">
                        <span>{{formatDateTime(data.created)}}</span>
                    </template>
                    </Column>
                    <Column  header="Opened by">
                        <template #body="{ data }">
                            <p>{{data.openCount}}</p>
                        </template>
                    </Column>
                    <Column  header="Status">
                            <template #body="{ data }">
                            <Chip class="px-3 p-2 font-bold text-xs text-white" style="background: red" v-if="data.deleted">DELETED</Chip> 
                            <Chip class="px-3 p-2 font-bold text-white text-xs"  style="background: LimeGreen" v-else>SENT</Chip> 
                        </template>
                    </Column>

            </DataTable>
        </div>

  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import useFormatDate from '../../utils/useFormatDate';
import npLogo from '@/assets/nightpay_logo_RGB.jpg'


export default {
	components: {},
	setup() {
		const storeActionGetEntities = 'notifications/getNotificationListItems';
		const storeGetterGetEntities = 'notifications/notificationListItems';
		// // const storeActionCreateEntity = 'memberships/create'
		// const storeActionUpdateEntity = 'memberships/update';
		const storeActionDeleteEntity = 'notifications/delete';
		// const storeActionUpdatePerkConfigs = 'membershipPerks/update';
		const entityName = 'Notification';
		const pageTitle = 'App notifications';
		const pageDescription = 'Here you can manage sent app notifications';
        
        const store = useStore();
        const toast = useToast();

        const notifications = ref([])
        const notificationDetail = ref()

        const loading = ref(false)

        const showNotificationDetailDialog = ref(false)


        function onNotificationSelected(notification){
            notificationDetail.value = notification.data
            showNotificationDetailDialog.value = true;

        }

        function formatDateTime(date){
            return useFormatDate().formatDateTime(date) ?? "-"
        }

        async function deleteNotification(notificationId){

            await store.dispatch(storeActionDeleteEntity, {notificationId})
            .then(()=>{
                toast.add({ severity: 'success', summary: 'Success', detail: 'Notification was deleted', life: 3000 });
                showNotificationDetailDialog.value = false
            })
            .catch((err)=>{
                console.log("HEP")
                toast.add({ severity: 'error', summary: 'Error', detail: 'Could delete notification' + ': ' + err, life: 3000 });
            })
         
            loadRequiredData();
        }

        async function loadRequiredData(){

            // Load notifications
            loading.value = true;
            await store.dispatch(storeActionGetEntities)
            .then(()=> {
                const loadedNotifications = store.getters[storeGetterGetEntities]
                notifications.value = loadedNotifications
                console.log(notifications.value)
            })
            .catch((err)=>{
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not get notifications' + ': ' + err, life: 3000 });
            })
            .finally(()=>loading.value = false)
           
		
        }

        onMounted(()=>{
            loadRequiredData()

        })

		return {
			entityName,
			pageTitle,
			pageDescription,

            notifications,
            notificationDetail,
            loading,
            showNotificationDetailDialog,

            npLogo,

            formatDateTime,

            onNotificationSelected,
            deleteNotification
		};
	},
};
</script>