import apiClient from '@/services/axios';

export async function post(userId, payload) {
	// console.log(payload);
	var response = await apiClient.post('/UserPerks/' + userId, payload);
	if (response) {
		return response.data;
	}

	throw new Error('Unable to add User Perk.');
}
