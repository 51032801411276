<template>
    <div v-if="editor" >
        <div class="text-200 p-0 flex gap-1"> 
            <input
              type="color"
              @input="editor.chain().focus().setColor($event.target.value).run()"
              :value="editor.getAttributes('textStyle').color"
            >

            <button v-if="showHeaders" class="editorButton font-bold" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) && !disabled  }">
            H1
            </button>
            <button  v-if="showHeaders" class="editorButton font-bold" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) && !disabled  }">
            H2
            </button>
            <button  v-if="showHeaders" class="editorButton font-bold" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) && !disabled }">
            H3
            </button>
            <button  class="editorButton" @click="editor.chain().focus().setHorizontalRule().run()">
                <i class="fa fa-minus"></i>
            </button>
            <button class="editorButton" @click="editor.chain().focus().toggleBulletList().run()" :class="{'is-active' : editor.isActive('bulletList') && !disabled}">
                <i class="fa fa-list"></i>
            </button>
            <button class="editorButton bold" @click="editor.chain().focus().toggleBold().run()" :class="{'is-active' : editor.isActive('bold') && !disabled}">
                <i class="fa fa-bold" />
            </button>
             <button class="editorButton" @click="editor.chain().focus().toggleItalic().run()" :class="{'is-active' : editor.isActive('italic') && !disabled}" >
                <i class="fa fa-italic" />
            </button>
            <button class="editorButton" @click="editor.chain().focus().toggleUnderline().run()" :class="{'is-active' : editor.isActive('underline') && !disabled}" >
                <i class="fa fa-underline" />
            </button>
             <Button v-if="editButton" class="btn btn-xs text-xs ml-auto mb-1 flex gap-2" @click="setDisabled" style="font-size: 1rem" >
                <i style="font-size: 0.8rem" class="pi pi-pencil"> </i>
                Edit
             </Button>

        </div>
        <div @click="editor.chain().focus()" class="p-2 border-300 border-2 border-round editor" :class="{'bg-gray-300' : disabled}" style="min-height:200px;">
            <editor-content :disabled="true"  :editor="editor" />
        </div>
 
    </div>
</template>

<script>
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import { onMounted, ref } from 'vue'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'


export default {
  components: {
    EditorContent,
  },
  props: {
        modelValue:{
            default: ''
        },
        editButton:{
            default:false
        },
         showHeaders:{
            default:false
        },
        blackTextColor:{
            default: false
        }
    },
    emits: ["update:modelValue"],

  setup(props, {emit}) {

    const disabled = ref(props.editButton ? true : false)

    function setDisabled(){
        disabled.value = !disabled.value
        editor.value.setEditable(!disabled.value)
        if(editor.value.isEditable)
            editor.value.chain().focus();
    }

    const editor = useEditor({
      content: props.modelValue,
      extensions: [
        StarterKit,
        TextStyle,
        Underline,
        Color
      ],
      editable: props.editButton ? false : true,
       onUpdate: () => {
        emit('update:modelValue', editor.value.getHTML())
      },
    })
  onMounted(()=>{
    if(!props.showHeaders && !props.blackTextColor)
      editor.value.chain().focus().setColor('#ffffff').run()
  })
    
    return { editor, setDisabled, disabled }
  },
  watch: {
    modelValue(value) {
        
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)
    },
  },
}
</script>
<style scoped>
.editorButton{
    border: none;
    background: none;
}
.editorButton:hover{
    background: lightblue;
}
.is-active{
    background: lightblue;
    border-radius: 2px;
}

</style>
<style >

.ProseMirror:focus {
    outline: none;
  }

</style>