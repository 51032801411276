import apiClient from '@/services/axios'

export async function put(venueId, payload) {
  var response = await apiClient.put('/venues/' + venueId + '/images', payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update Venue Images.')
}
