import * as apiGetFiles from '@/api/content/getFiles'
import * as apiRequestUpload from '@/api/content/requestUpload'
import * as apiAddFile from '@/api/content/addFile'
import * as apiDeleteFile from '@/api/content/deleteFile'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        files: []
    }),
    mutations: {
        gotFiles(state, files) {
            state.files = files
        },

        gotWorkflow(state, workflow) {
            state.detailedWorkflows[workflow.id] = workflow
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getFiles({ commit }) {
            commit('isLoading', true)

            console.log('Getting File List Items');
        
            const result = await apiGetFiles.get();

            if (result) {
                commit('gotFiles', result)
            }
        
            commit('isLoading', false)
        },

        async requestUpload({ commit }, { name }) {
            commit('isLoading', true)

            const payload = {
                container: 'content',
                blob: 'content',
                name: name,
            };

            const result = await apiRequestUpload.post(payload)

            commit('isLoading', false)

            return result
        },

        async add({ commit }, { name, mimeType, size, url }) {
            commit('isLoading', true)

            const payload = {
                name: name,
                mimeType: mimeType,
                size: size,
                url: url
            };

            const result = await apiAddFile.post(payload)

            commit('isLoading', false)

            return result
        },

        async delete({ commit }, { contentFileId }) {
            commit('isLoading', true)

            const payload = {
                contentFileId: contentFileId
            };

            const result = await apiDeleteFile.del(payload)

            commit('isLoading', false)

            return result
        },

        async refresh({ commit, dispatch }) {
            commit('isLoading', true)

            await dispatch('getFiles')

            commit('isLoading', false)
        },

        async ensureFiles({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.files.length === 0) {
                await dispatch('getFiles')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        files: (state) => state.files/*,
        workflow: (state) => (workflowId) => {
            return state.detailedWorkflows[workflowId]
        }*/
    }
};