<template>

        <div>
            <div class="surface-section p-6 pt-0 pb-0">
                <div class="flex">
                    <div style="flex: 1">
                        <div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
                        <div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
                    </div>
                    <div class="flex justify-content-end align-items-center">
                        <Button v-if="activeTab < 6" :disabled="!appContentUpdated" @click="createOrUpdateEntity" :label="'Save ' + entityName" icon="pi pi-cloud-upload" />
                    </div>
                </div>
              
            </div>

            <Loading v-if="loading" class="mt-22" :title="'Loading App Content Configurations'" :full="false" />

            <div v-else class="w-full">

            <TabView v-model:activeIndex="activeTab" >
                <TabPanel header="Limitations">
                    <div class="px-6 w-full">
                        <p class="font-bold text-900 text-lg m-0" for="limitationInfo">
                            Limitations info
                        </p>
                        <p class="m-0 text-sm line-height-2 text-700 mb-3" for="limitationInfo">
                            Information about the memberships limitations
                        </p>
                        <HtmlEditor id="limitationInfo" name="limitationInfo"  :showHeaders="true" @update:modelValue="appContentUpdated = true" v-model="editedAppContent.limitationsInfoText" :editButton="true" /> 
                    </div>
                </TabPanel>
                <TabPanel header="Membership">
                    <div class="px-6 w-full">
                        <p class="font-bold text-900 text-lg m-0">
                            Membership info
                        </p>
                        <p class="m-0 text-sm text-700 mb-3" >
                            Summary of the membership
                        </p>
                        <HtmlEditor id="membershipInfo" name="membershipInfo"  :showHeaders="true"  @update:modelValue="appContentUpdated = true" v-model="editedAppContent.membershipInfoText" :editButton="true" /> 
                    </div>
                </TabPanel>
                 <TabPanel header="Payment plan">
                    <div class="px-6 w-full">
                        <p class="font-bold text-900 text-lg m-0">
                            Payment plan info
                        </p>
                        <p class="m-0 text-sm text-700 mb-3" >
                            Information about the payment plan
                        </p>
                        <HtmlEditor id="paymentInfo" name="paymentInfo"  @update:modelValue="appContentUpdated = true" v-model="editedAppContent.paymentPlanInfoTextInfoText" :editButton="true" /> 
                    </div>
                </TabPanel>
                <TabPanel header="Pause membership">
                  
                    <div class="px-6 w-full">
                        <p class="font-bold text-900 text-lg m-0">
                            Pause membership info
                        </p>
                        <p class="m-0 text-sm text-700 mb-3" >
                            Information about the pausing of a membership
                        </p>
                        <HtmlEditor id="pauseMembershipInfo" name="pauseMembershipInfo"  :showHeaders="true"  @update:modelValue="appContentUpdated = true" v-model="editedAppContent.pauseMembershipInfoText" :editButton="true" /> 
                    </div>

                </TabPanel>
                <TabPanel header="Cancel membership">
                    <div class="px-6 w-full">
                        <p class="font-bold text-900 text-lg m-0">
                            Cancel membership info
                        </p>
                        <p class="m-0 text-sm text-700 mb-3" >
                            Information about the canceling of a membership
                        </p>
                        <HtmlEditor id="cancelMembershipInfo" name="cancelMembershipInfo"  :showHeaders="true"  @update:modelValue="appContentUpdated = true" v-model="editedAppContent.cancelMembershipInfoText" :editButton="true" /> 
                    </div>
                </TabPanel>

                 <TabPanel header="Reactivate membership">
                    <div class="px-6 w-full">
                        <p class="font-bold text-900 text-lg m-0">
                            Reactivate membership info
                        </p>
                        <p class="m-0 text-sm text-700 mb-3" >
                            Information about the reactivation of a paused membership
                        </p>
                        <HtmlEditor id="reactiveMembershipInfo" name="reactiveMembershipInfo"  :showHeaders="true" @update:modelValue="appContentUpdated = true" v-model="editedAppContent.reactivateMembershipInfoText" :editButton="true" /> 
                    </div>
                </TabPanel>

                   <TabPanel header="Signup Card">
                    <div class="px-6 w-full">
          

                        <SignupCardInfo />

                    </div>
                </TabPanel>

                 <TabPanel header="Free User Card">
                    <div class="px-6 w-full">
          

                        <free-user-card-info />

                    </div>
                </TabPanel>

                   <TabPanel header="Active User Card">
                    <div class="px-6 w-full">
          

                        <active-user-card-info />

                    </div>
                </TabPanel>
            </TabView>
                
              

              


               

            </div>
        
        </div>
</template>
<script>
import { onMounted, ref } from 'vue';
import HtmlEditor from '../../components/HtmlEditor.vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import SignupCardInfo from './views/SignupCardInfo.vue';
import FreeUserCardInfo from './views/FreeUserCardInfo.vue';
import ActiveUserCardInfo from './views/ActiveUserCardInfo.vue';


export default {
    components: {
        HtmlEditor,
        SignupCardInfo,
        FreeUserCardInfo,
        ActiveUserCardInfo
    },

   
	setup() {
        const storeActionGetEntity = 'appContents/getAppContent'
        const storeActionUpdateEntity = 'appContents/update'
        const storeActionCreateEntity = 'appContents/create'

        const storeGetterGetEntity = "appContents/appContent"

		const entityName = 'App Content';
		const pageTitle = 'App Content';
		const pageDescription = 'Here you can manage the App contents';

        const store = useStore();
        const toast = useToast();

        const loading = ref(false)
        const activeTab = ref(0)

        const appContent = ref()

        const editedAppContent = ref({
            limitationInfoText: "",
            membershipInfoText: "",
            paymentPlanInfoTextInfoText: "",
            pauseMembershipInfoText: "",
            cancelMembershipInfoText: "",
            reactivateMembershipInfoText: "",
            signupCardInfo: {
                topSection: [],
                bottomSection: [],
                imageUrl: null

            }
        })

        const appContentUpdated= ref(false)


        function createOrUpdateEntity(){
          if(appContent.value == null){
                createEntity() 
          } else {
                updateEntity();
          }
        }


        async function updateEntity(){
            loading.value = true;
            await store.dispatch(storeActionUpdateEntity, editedAppContent.value)
            .then(()=> {
                toast.add({ severity: 'success', summary: 'Success', detail: 'App contents was updated', life: 3000 });
                appContentUpdated.value = false

            })
            .catch((err)=>{
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not get app contents' + ': ' + err, life: 3000 });
            })
            .finally(()=>loading.value = false)
        }
        
        async function createEntity(){
            loading.value = true;
            await store.dispatch(storeActionCreateEntity, editedAppContent.value)
            .then(()=> {
                toast.add({ severity: 'success', summary: 'Success', detail: 'App content configurations was created', life: 3000 });
                appContentUpdated.value = false
            })
            .catch((err)=>{
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create app contents' + ': ' + err, life: 3000 });
            })
            .finally(()=>loading.value = false)
        }


        async function loadRequiredData(){

            loading.value = true;
            await store.dispatch(storeActionGetEntity)
            .then(()=> {
                const loadedAppContent = store.getters[storeGetterGetEntity]
                if(loadedAppContent == null){
                    return;
                }
                appContent.value = loadedAppContent
                editedAppContent.value = loadedAppContent;
            })
            .catch((err)=>{
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not get app contents' + ': ' + err, life: 3000 });
            })
            .finally(()=>loading.value = false)
           
        }
        

        onMounted(()=>{
            loadRequiredData();
        })
        
        return {
			entityName,
			pageTitle,
			pageDescription,

            editedAppContent,
            appContentUpdated,

            loading,
            activeTab,

            createOrUpdateEntity,
            }   
    }

    
}
</script>
