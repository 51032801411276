import apiClient from '@/services/axios'

export async function get(promoCodeId) {
  var response = await apiClient.get('/promoCodes/' + promoCodeId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Promo Code.')
}
