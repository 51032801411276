import apiClient from '@/services/axios';

export async function put(membershipId, payload) {
	var perkConfigs = { perkConfigs: payload.signUpPerkConfigs };
	var response = await apiClient.put('MembershipPerks/sign-up?membershipId=' + membershipId, perkConfigs);
	if (response) {
		return response.data;
	}

	throw new Error('Unable to update Membership sign up perk configs.');
}
