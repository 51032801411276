<template>
    <div v-if="catchOffers">
       <PageHeader :title="'Catch Offers'" 
       :description="'Here you can mange the Catch offers'" 
       :showEnabledChip="true" 
       :entityName="'Catch Offers'" 
       :chipEnabled="catchOffers.enabled" 
       @onEnable="toggleCatchOffers"
       />


    <TabView v-model:activeIndex="activeTab" >
        <TabPanel header="First-Time Unsubscribe">
           <OfferConfigurations  @onUpdate="upsertCatchOffer" :catchOffers="firstTimeOffers"   :title="'First-Time Unsubscribe Offer'" :description="'Configurations for the catch offer that is invoked for first-time unsubscribers'" />
        </TabPanel>
        
        <TabPanel header="Second-Time No Fees Paid">
             <OfferConfigurations @onUpdate="upsertCatchOffer" :catchOffers="secondTimeOffersNoPaidFees" :title="'Second-Time Unsubscribe Offer'" :description="'Configurations for the catch offer that is invoked for second-time unsubscribers that has not paid any additional fees since first offer'" />
        </TabPanel>
               
        <TabPanel header="Second-Time Fees Paid">
             <OfferConfigurations @onUpdate="upsertCatchOffer" :catchOffers="secondTimeOffersPaidFees" :title="'Second-Time Unsubscribe Offer'" :description="'Configurations for the catch offer that is invoked for second-time unsubscribers that has paid one or more fees since first offer'" />
        </TabPanel>
    </TabView>

    </div>
    <Loading v-else class="mt-12rem" :title="'Loading Catch Offers Configurations'" :full="false" />

</template>

<script>
import { computed, onMounted, ref } from 'vue'
import PageHeader from '../../components/PageHeader.vue'
import OfferConfigurations from "./components/OfferConfigurations.vue"
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'

export default {


  components: {PageHeader, OfferConfigurations},

  setup() {


    const store = useStore();
    const toast = useToast()
    const actionGetCatchOffers = "catchOffers/getCatchOfferListItems";
    const actionUpsertCatchOffers = "catchOffers/upsertCatchOffer";
    const actionEnableCatchOffers = "catchOffers/enableCatchOffer";

    const getterGetCatchOffers = "catchOffers/catchOfferListItems";

    const catchOffers = ref()
    const activeTab = ref(0)

    const firstTimeOffers = computed(() => catchOffers.value?.firstTimeOffers?.map(x => {
        return {...x, perkId: x.perkId?.id}
    }))
    const secondTimeOffersNoPaidFees = computed(() => catchOffers.value?.secondTimeOffersNoPaidFees?.map(x => {
        return {...x, perkId: x.perkId?.id}
    }))

     const secondTimeOffersPaidFees = computed(() => catchOffers.value?.secondTimeOffersPaidFees?.map(x => {
        return {...x, perkId: x.perkId?.id}
    }))
       
    async function toggleCatchOffers(){

        var response = catchOffers.value.enabled ? 'disabled' : 'enabled'
        await store.dispatch(actionEnableCatchOffers, !catchOffers.value.enabled)
         .then(()=> {
            catchOffers.value.enabled = !catchOffers.value.enabled
            toast.add({ severity: 'success', summary: 'Success', detail: 'Catch offers ' + response, life: 3000 });
        })
        .catch((err)=> {
			toast.add({ severity: 'error', summary: 'Error', detail: 'Could not ' + response  +' Catch offers: ' + err, life: 3000 });
        })
        
    }

    async function getCatchOffers(){
       await store.dispatch(actionGetCatchOffers);
       catchOffers.value = store.getters[getterGetCatchOffers]

       if(catchOffers.value == undefined)
         await store.dispatch(actionUpsertCatchOffers, {type: "FirstTimeOffer", offer: []})

    }

    async function upsertCatchOffer(offer){

        await store.dispatch(actionUpsertCatchOffers, {type: activeTab.value, offer} )
        .then(()=> {
            toast.add({ severity: 'success', summary: 'Success', detail: 'Catch offers updated', life: 3000 });
        })
        .catch((err)=> {
			toast.add({ severity: 'error', summary: 'Error', detail: 'Could update Catch Offer: ' + err, life: 3000 });
        })
    }

    onMounted(()=>{
        getCatchOffers();
    })
    

    return {
        catchOffers,
        activeTab,
        firstTimeOffers,
        secondTimeOffersNoPaidFees,
        secondTimeOffersPaidFees,

        toggleCatchOffers,
        upsertCatchOffer
    }
  }
}

</script>
