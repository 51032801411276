<template>
      <NavBar :breadcrumbs="breadcrumbs"></NavBar>
      <RootSidebar></RootSidebar>
      <div class="min-w-screen max-w-screen min-h-screen">
          <div class="surface-section pageview overflow-auto pb-6" style="margin-top: 84px; ">
              <router-view />
          </div>
      </div>
</template>

<script>
import { computed } from 'vue';
import RootSidebar from "./RootSidebar";
import { useRoute } from 'vue-router';

export default {
  name: "RootLayout",
  components: {
    RootSidebar,
  },
  props: [],
  setup() {

    const route = useRoute()
    const breadcrumbs = computed(()=>{
			return [
			{title: route.meta?.breadcrumb ?? "..." }
			]
		})
      
   
    return {
        breadcrumbs
      // account,
    };
  },
  mounted: function () {
    //console.log('Root Layout: mounted')
  },
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .pageview {
        margin-left:280px;
      }
   @media screen and (max-width: 991px) {
      .pageview {
        margin-left: 0px;
      }
   }

/* Hide scrollbar for Chrome, Safari and Opera */
.pp::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pp {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

  
</style>
