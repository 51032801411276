<template>
    <div>
        <div class="flex">

         <div>
        <p class="font-bold text-900 text-lg m-0">
                Signup card info
            </p>
            <p class="m-0 text-sm text-700 mb-3" >
                Configurations for the Signup card
            </p>
        </div>   
            <Button @click="updateEntity" class="ml-auto h-3rem" :icon="'pi pi-cloud-upload'" :label="'Save configuration'" :value="'test'"></Button>

        </div>
        <p class="p-0 mb-1">Language</p>
        <Dropdown  :options="languageOptions" optionLabel="value" optionValue="id" v-model="selectedLanguage" class="w-12rem"></Dropdown>

        <CardLabelList :title="'Top Section'" 
        :type="'topSection'"
        :labels="topSection" 
        :language="selectedLanguage" 
        :formFields="topSectionFormField"
        :maxLabels="3"
        @onAddNew="addLabelToTopSection"
        @onRemove="removeLabelFromTopSection" 
        @onChangeOrder="changeLabelOrderFromTopSection"   
        @onUpdate="onUpdateTopSectionLabel"
        />

        <CardLabelList :title="'Bottom Section'" 
         :type="'bottomSection'"
         :labels="bottomSection" 
         :language="selectedLanguage" 
         :formFields="bottomSectionFormField"
         @onAddNew="addLabelToBottomSection"
         @onRemove="removeLabelFromBottomSection"
         @onChangeOrder="changeLabelOrderFromBottomSection"
         @onUpdate="onUpdateBottomSectionLabel"
         />


        <h2>Banner Image</h2>
        <div class="bannerSection mx-auto flex" style="width: 420px">
            <div  class="flex relative w-full justify-content-center flex-wrap overflow-hiden">
                    <div class="w-full  flex justify-content-center flex-wrap  " >
                        <img  id="signupcard-img"   style="width:420px; object-fit: contain" v-if="imageUrl" :src="imageUrl"   />
                  
                    </div>
                    <div  class="flex w-full absolute bottom-0 p-2 gap-2">
                    <Button v-if="imageUrl" icon="pi pi-times" class="border-none bg-gray-500" @click="removeImage"  aria-label="Cancel"  />
                    <Button class="w-full h-14 bg-gray-200 border-none relative cursor-pointer flex" style="color:gray">
                        <input style="opacity:0" 
                        class="absolute w-full cursor-pointer"   
                        type="file" 
                        id="signupcard-image"
                        @change="updateImageProperty($event, 'signupcard-image')"
                        accept="image/svg+xml, image/png"
                        />
                        <p class="h-0 m-auto">{{imageUrl ? 'Upload new image...' : "Upload image..."}}</p>
                    </Button>
                    </div>
            </div>
        </div>
                
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import CardLabelList from '../components/CardLabelList.vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { uploadFile} from '@/services/fileUploadService'



export default {
    components: {
        CardLabelList
    },
    props: ["configuration"],
   
	setup() {
     
     
     
        const store = useStore();
        const toast = useToast();

        const storeGetterGetEntity = "appContents/signupCardInfo"
        const storeGetterGetAppContentId = "appContents/appContentId"

        const storeActionUpdateEntity = 'appContents/updateSignupCardInfo'
       const loading = ref(false)
       const signupCardInfo = ref([{
            topSection: [],
            bottomSection: [],
            imageUrl: "",
            languageCode: "default"
        }])

        const appContentId = ref()

        const languageOptions = [{id: "default", value:"Default"}, {id: "da", value:"Danish"}]
        const selectedLanguage = ref("default")        


        const topSection = computed(()=>{
            return signupCardInfo.value?.find(x => x.languageCode == selectedLanguage.value)?.topSection
        })

         const bottomSection = computed(()=>{
            return signupCardInfo.value?.find(x => x.languageCode == selectedLanguage.value)?.bottomSection
        })

        const imageUrl = computed(()=>{
            return signupCardInfo.value?.find(x => x.languageCode == selectedLanguage.value)?.imageUrl
        })

   

        const topSectionFormField = [
            
				{
					id: 'title',
					type: 'text-input',
					title: 'Title',
					help: '',
				},
                {
					id: 'subtitleBottom',
					type: 'text-input',
					title: 'Subtitle Bottom',
					help: '',
				}
      ]

       const bottomSectionFormField = [
            
				{
					id: 'title',
					type: 'text-input',
					title: 'Title',
					help: '',
				},
                {
					id: 'iconUrl',
					type: 'imageUpload',
					title: 'Icon',
					help: '',
                    accept: "image/svg+xml"
				},
                {
					id: 'subtitleBottom',
					type: 'text-input',
					title: 'Subtitle Bottom',
					help: '',
				}
      ]

        function addLabelToTopSection(formContext){
            if(topSection.value){
               topSection.value?.push(formContext)
               return
            }
           signupCardInfo.value.push({
             topSection: [formContext],
            bottomSection: [],
            imageUrl: "",
            languageCode: selectedLanguage.value
        
           })

      }

       async function addLabelToBottomSection(formContext){
            
            if(formContext?.iconUrl){
                var url = await handleIconUpload(formContext?.iconUrl)
                formContext.iconUrl = url
            }

           if(bottomSection.value){
               bottomSection.value?.push(formContext)
               return
            }
           signupCardInfo.value.push({
             topSection: [],
            bottomSection: [formContext],
            imageUrl: "",
            languageCode: selectedLanguage.value
        
           })

      }

        function removeLabelFromTopSection(index){
            topSection.value?.splice(index, 1)
      }

        function removeLabelFromBottomSection(index){
            bottomSection.value?.splice(index, 1)
      }

      function changeLabelOrderFromTopSection(lbl){
            var label = topSection.value[lbl.oldIndex];
            topSection.value?.splice(lbl.oldIndex, 1)
            topSection.value?.splice(lbl.newIndex, 0, label);
      }


      function changeLabelOrderFromBottomSection(lbl){
            var label = bottomSection.value[lbl.oldIndex];
            bottomSection.value?.splice(lbl.oldIndex, 1)
            bottomSection.value?.splice(lbl.newIndex, 0, label);
      }

      function onUpdateTopSectionLabel(label, index){
            topSection.value[index] = label
      }
      
      async function onUpdateBottomSectionLabel(label, index){
            var currLabel =  bottomSection.value[index] 

            if(currLabel?.iconUrl != label?.iconUrl && label.iconUrl){
              var url =  await handleIconUpload(label.iconUrl)
              label.iconUrl = url
            }

            bottomSection.value[index] = label
      }



        function removeImage(){
                signupCardInfo.value.find(x => x.languageCode == selectedLanguage.value).imageUrl = ""
        }
        

        async function updateImageProperty(event, id){
            event.preventDefault();
      
            if(imageUrl.value == null){
                signupCardInfo.value.push({
                topSection: [],
                bottomSection: [],
                imageUrl: "",
                languageCode: selectedLanguage.value
                })
            }
          
			const input = document.getElementById(id);
			const file = input.files[0]		
         
            signupCardInfo.value.find(x => x.languageCode == selectedLanguage.value).imageUrl =  URL.createObjectURL(file) 
            var img = new Image();
            var objectUrl = URL.createObjectURL(file);
            img.src = objectUrl;
          
            img.onload = async function () {              


                if(!fileSizeIsUnderMax(file)){
                     toast.add({ severity: 'error', summary: 'Error', detail: 'Image is too large', life: 3000 });
                    signupCardInfo.value.find(x => x.languageCode == selectedLanguage.value).imageUrl = ""
                    return;
                }


                var url = await handleBannerImageUpload(file)
        
                signupCardInfo.value.find(x => x.languageCode == selectedLanguage.value).imageUrl = url

             };


			
		}


        async function handleIconUpload(image){
    
            var logoFile = await uploadFile(image, "card-icons", "all", store)
            return logoFile.url
       }
        async function handleBannerImageUpload(image){
    
            var logoFile = await uploadFile(image, "signup-banner", "all", store)
            return logoFile.url
       }

      

          function fileSizeIsUnderMax(image){
            console.log(image.size)
            return image?.size < 5000000
        }


        function setOrderNumbers(){

            topSection.value.map((x, i) =>{
                topSection.value[i].orderNumber = i;
            } )

            bottomSection.value.map((x, i) =>{
                bottomSection.value[i].orderNumber = i;
            } )

        }


        async function updateEntity(){
            loading.value = true;
        
            setOrderNumbers()
            var obj = {appContentId: appContentId.value, signupCardInfo: signupCardInfo.value}
            await store.dispatch(storeActionUpdateEntity, obj)
            .then(()=> {
                toast.add({ severity: 'success', summary: 'Success', detail: 'Signup Card was updated', life: 3000 });

            })
            .catch((err)=>{
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update Signup Card' + ': ' + err, life: 3000 });
            })
            .finally(()=>loading.value = false)
        }
        
        
        

        onMounted(()=>{
            appContentId.value = store.getters[storeGetterGetAppContentId]
            signupCardInfo.value = store.getters[storeGetterGetEntity]
        })

        return {
        signupCardInfo,
        selectedLanguage,
        languageOptions,
        topSection,
        topSectionFormField,
        bottomSection,
        bottomSectionFormField,
        imageUrl,
        updateImageProperty,
        removeImage,
        onUpdateTopSectionLabel,
        onUpdateBottomSectionLabel,
        addLabelToTopSection,
        addLabelToBottomSection,
        removeLabelFromTopSection,
        removeLabelFromBottomSection,
        changeLabelOrderFromTopSection,
        changeLabelOrderFromBottomSection,
        updateEntity
                }

    
}
}
</script>


<style scoped>
.bannerSection{
min-height: 22rem;

background-image: linear-gradient(to left top, #081654, #122886, #122886, #081654, #081654);
}
</style>