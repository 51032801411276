import * as apiCreateOnboardingPerk from '@/api/onboardingperks/createOnboardingPerk'
import * as apiGetOnboardingPerks from '@/api/onboardingperks/getOnboardingPerks'
import * as apiGetOnboardingPerk from '@/api/onboardingperks/getOnboardingPerk'
import * as apiUpdateOnboardingPerk from '@/api/onboardingperks/updateOnboardingPerk'
import * as apiDeleteOnboardingPerk from '@/api/onboardingperks/deleteOnboardingPerk'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        perkListItems: [],
        detailedPerks: {},
        subscriptionPerks: [],
    }),
    mutations: {
        gotPerkListItems(state, perks) {
            state.perkListItems = perks
        },

        gotPerk(state, perk) {
            state.detailedPerks[perk.id] = perk
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getPerkListItems({ commit }) {
            commit('isLoading', true)
            console.log('Getting Perk List Items');

            const result = await apiGetOnboardingPerks.get();

            if (result) {
                commit('gotPerkListItems', result)
            }

            commit('isLoading', false)
        },




        async create({ commit }, payload) {
            commit('isLoading', true)

            console.log('Posting:', payload)

            const result = await apiCreateOnboardingPerk.post(payload)

            commit('isLoading', false)

            return result
        },

        async update({ commit }, perk) {
            commit('isLoading', true)

            await apiUpdateOnboardingPerk.put(perk.id, perk)

            commit('isLoading', false)
        },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeleteOnboardingPerk.del(entityId)

            commit('isLoading', false)

            return result
        },

        async getPerk({ commit }, { perkId }) {
            commit('isLoading', true)

            console.log('Getting Perk:', perkId);

            const result = await apiGetOnboardingPerk.get(perkId)

            if (result) {
                commit('gotPerk', result)
            }

            commit('isLoading', false)
        },

        async ensurePerkListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.onboardingPerkListItems.length === 0) {
                await dispatch('getPerkListItems')
            }

            commit('isLoading', false)
        },

        async ensurePerk({ commit, dispatch, getters }, { perkId }) {
            commit('isLoading', true)

            if (!getters.onboardingPerk(perkId)) {
                console.log('Perk not cached. Getting from API.')
                await dispatch('getPerkListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {

        onboardingPerkListItems: (state) => state.perkListItems,
        onboardingPerk: (state) => (perkId) => {
            return _.find(state.perkListItems, function (perk) { return perk.id === perkId; });
        }
    }
};