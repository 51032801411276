import * as apiGetUserPerks from '@/api/userPerks/getUserPerks';
import * as apiAddUserPerk from '@/api/userPerks/addUserPerk';
import * as apiDeleteUserPerk from '@/api/userPerks/deleteUserPerk';
import * as apiBulkAssign from '@/api/userPerks/bulkAssign';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
		userPerkListItems: {},
		detailedUserPerks: {},
	}),
	mutations: {
		gotUserPerkListItems(state, { userId, userPerks }) {
			state.userPerkListItems[userId] = userPerks;
		},

		gotUserPerk(state, userPerk) {
			state.detailedUserPerks[userPerk.id] = userPerk;
		},

		isLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async add({ commit }, { userId, payload }) {
			commit('isLoading', true);

			const result = await apiAddUserPerk.post(userId, payload);

			commit('isLoading', false);

			return result;
		},

		async delete({ commit }, entityId) {
			commit('isLoading', true);

			const result = await apiDeleteUserPerk.del(entityId);

			commit('isLoading', false);

			return result;
		},

		async getUserPerkListItems({ commit }, { userId, from, to }) {
			commit('isLoading', true);

			const result = await apiGetUserPerks.get(userId, from, to);

			if (result) {
				commit('gotUserPerkListItems', {
					userId: userId,
					userPerks: result,
				});
			}

			commit('isLoading', false);
		},

		async bulkAssign({ commit }, payload) {
			commit('isLoading', true);

			const result = await apiBulkAssign.post(payload);

			commit('isLoading', false);

			return result;
		},

		async ensureUserPerkListItems({ commit, dispatch, getters }, { userId }) {
			commit('isLoading', true);

			if (!getters.userPerkListItems(userId)) {
				await dispatch('getUserPerkListItems', { userId: userId });
			}

			commit('isLoading', false);
		},

		async ensureUserPerk({ commit, dispatch, getters }, { userPerkId }) {
			commit('isLoading', true);

			if (!getters.userPerk(userPerkId)) {
				console.log('UserPerks not cached. Getting from API.');
				await dispatch('getUserPerk', { userPerkId: userPerkId });
			}

			commit('isLoading', false);
		},
	},
	getters: {
		userPerkListItems: (state) => (userId) => {
			return state.userPerkListItems[userId];
		},
		userPerk: (state) => (userPerkId) => {
			return state.detailedUserPerks[userPerkId];
		},
	},
};
