const specialOfferFormFieldsDays = [
  
    {
        id: 'title',
        type: 'text-input',
        title: 'Title',
        help: '',
    },
    
     {
        id: 'terms',
        type: 'text-input',
        title: 'Terms',
        help: 'Terms for the offer',
    },

        {
        id: 'price',
        type: 'number-input',
        title: 'Price',
        help: 'The offer price in DKK'
        },
      {
            id: 'offerType',
            type: 'dropdown',
            title: 'Offer type',
            config: {
            options: ["FixedEndDate", "FixedNumberOfDays"]
    
        },
            help: '',
    },
    {
        id: 'numberOfDays',
        type: 'number-input',
        title: 'Number of days',
        help: 'Number of days before plan ends',
    }

  ]

  const specialOfferFormFieldsDate = [
  
    {
        id: 'title',
        type: 'text-input',
        title: 'Title',
        help: '',
    },
    
     {
        id: 'terms',
        type: 'text-input',
        title: 'Terms',
        help: 'Terms for the offer',
    },

        {
        id: 'price',
        type: 'number-input',
        title: 'Price',
        help: 'The offer price in DKK'
        },
      {
            id: 'offerType',
            type: 'dropdown',
            title: 'Offer type',
            config: {
            options: ["FixedEndDate", "FixedNumberOfDays"]
    
        },
            help: '',
    },
    {   
        id: 'endDate',
        type: 'calendar',
        title: 'End Date',
        help: 'Date for when the plan ends',
    }

  ]



  export {specialOfferFormFieldsDate, specialOfferFormFieldsDays}