import apiClient from '@/services/axios'

export async function put(userTermsId, versions) {
  console.log('Saving:', versions)
  var response = await apiClient.put('/userTerms/' + userTermsId + '/versions', versions)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update User Terms Versions.')
}
