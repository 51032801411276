<template>
	<div class="surface-section pt-0">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.venues.editdelete')" @click="updateEntity" label="Save Photos" icon="pi pi-cloud-upload" />
			</div>
		</div>

		<Loading v-if="loading" :title="'Loading Photos'" :full="false" />
		<div v-else>
			<!-- <div v-if="venue.imageUrls.length === 0 && venue.images.length === 0" style="text-align: center">
				<img src="@/assets/icons/empty-box.png" alt="" style="opacity: 0.3; width: 178px; height: 178px" />
				<h3 style="color: #b3cbe6">There are no photos.</h3>
			</div>

			<div v-else> -->
			<div>
				<p class="font-semibold">NightPay Photos</p>
				<div v-if="venue.images.length > 0" style="display: flex; flex-wrap: wrap">
					<div class="card mr-3 mb-3" style="width: 18rem" v-for="(file, index) in venue.images" :key="index">
						<img class="card-img-top" :src="file.url" />

						<div class="card-body">
							<!--<h5 @click="copyUrlToClipboard(file)" class="card-title copy-to-clipboard" data-toggle="tooltip" data-placement="top" title="Copy URL to clipboard">Image {{index+1}}</h5>-->
							<p
								class="card-text copy-to-clipboard"
								style="margin-bottom: 0"
								@click="copyUrlToClipboard(file.url)"
								data-toggle="tooltip"
								data-placement="top"
								title="Copy URL to clipboard"
							>
								<span class="font-medium">Image {{ index + 1 }}</span>
							</p>

							<div style="display: flex; justify-content: flex-end">
								<button @click="deleteFile(file)" class="btn btn-warning round">
									<i class="fas fa-trash" style="font-size: 0.9rem"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div style="flex: 1"  v-if="hasPermission('np.backoffice.venues.editdelete')">
					<file-pond name="test" ref="pond" label-idle="Drop files here..." v-bind:allow-multiple="true" accepted-file-types="image/jpeg, image/png" />
				</div>
				<!-- </div> -->

				<hr v-if="venue.imageUrls.length > 0" />

				<div v-if="venue.imageUrls.length > 0">
					<p class="font-semibold">Imported Photos from IDM</p>
					<div style="display: flex; flex-wrap: wrap">
						<div class="card mr-3 mb-3" style="width: 18rem" v-for="(file, index) in venue.imageUrls" :key="index">
							<img class="card-img-top" :src="file" />

							<div class="card-body">
								<!--<h5 @click="copyUrlToClipboard(file)" class="card-title copy-to-clipboard" data-toggle="tooltip" data-placement="top" title="Copy URL to clipboard">Image {{index+1}}</h5>-->
								<p
									class="card-text copy-to-clipboard"
									style="margin-bottom: 0"
									@click="copyUrlToClipboard(file)"
									data-toggle="tooltip"
									data-placement="top"
									title="Copy URL to clipboard"
								>
									<span class="font-medium">Image {{ index + 1 }}</span>
								</p>

								<div style="display: flex; justify-content: flex-end">
									<!--<button @click="deleteFile(file)" class="btn btn-warning round">
                    <i class="fas fa-trash" style="font-size:0.9rem;"></i>
                  </button>
                  -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue';

import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';

import { copyText } from 'vue3-clipboard';

const { BlobServiceClient } = require('@azure/storage-blob');

const uploadFile = async (file, store) => {
	try {
		const sasUri = await store.dispatch('content/requestUpload', { name: file.name });
		const decodedSasUri = sasUri;

		const blobServiceClient = new BlobServiceClient(decodedSasUri);

		// Create a unique name for the container
		const containerName = 'content';

		// Get a reference to a container
		const containerClient = blobServiceClient.getContainerClient(containerName);

		const blockBlobClient = containerClient.getBlockBlobClient(file.name);
		await blockBlobClient.uploadData(file);

		var url = new URL(blockBlobClient.url);

		return {
			name: file.name,
			mimeType: file.type,
			size: file.size,
			url: url.origin + url.pathname,
		};
	} catch (error) {
		console.log(error.message);
		return null;
	}
};

// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import FilePond plugins
// Please note that you need to install these plugins separately

//import FilePondPluginImagePreview from "filepond-plugin-image-preview";
//import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import image preview plugin styles
//import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
//import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
//import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Create component
const FilePond = vueFilePond();
//FilePondPluginFileValidateType,
//FilePondPluginImagePreview

export default {
	inject: ['hasPermission'],
	components: {
		FilePond,
	},
	props: {},
	setup() {
		const storeActionDeleteVenueImage = 'venues/deleteVenueImage';
		//const entityName = 'Photos'
		const pageTitle = 'Photos';
		const pageDescription = 'Venue photos from NightPay and synced photos from IDM.';

		const loading = ref(true);
		const showUpdatingDialog = ref(false);

		const store = useStore();
		const toast = useToast();
		const route = useRoute();
		const confirm = useConfirm();

		const venue = ref();
		const venueId = route.params.venueId;

		setOptions({
			server: {
				process: async (fieldName, file, metadata, load, error, progress, abort) => {
					const result = await uploadFile(file, store);

					if (result) {
						//load(result);

						//uploadedImages.value.push(result)
						venue.value.images.push(result);

						await updateVenueImages();
					} else {
						error('oh no');
					}

					// Should expose an abort method so the request can be cancelled
					return {
						abort: () => {
							// This function is entered if the user has tapped the cancel button
							//request.abort();

							// Let FilePond know the request has been cancelled
							abort();
						},
					};
				},
			},
		});

		function copyUrlToClipboard(file) {
			copyText(file, undefined, (error) => {
				if (error) {
					toast.add({ severity: 'error', summary: 'Success', detail: 'Could not copy url', life: 3000 });
				} else {
					toast.add({ severity: 'success', summary: 'Success', detail: 'Copied url', life: 3000 });
				}
			});
		}

		async function updateVenueImages() {
			showUpdatingDialog.value = true;

			await store.dispatch('venues/updateVenueImages', { venueId: venueId, images: venue.value.images });

			showUpdatingDialog.value = false;
		}

		async function loadRequiredData() {
			loading.value = true;

			await store.dispatch('venues/getVenue', { venueId: venueId });

			const loadedVenue = store.getters['venues/venue'](venueId);
			venue.value = JSON.parse(JSON.stringify(loadedVenue));

			loading.value = false;
		}

		// async function deleteFile(file) {
		// console.log('deleteFile', file);
		// }

		function deleteFile(file) {
			console.log('deleteFile', file);
			console.log('deleteFile', venueId);
			confirm.require({
				message: 'Are you sure you want to delete the image?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteVenueImage, { venueId: venueId, url: file.url });
						await loadRequiredData();

						toast.add({ severity: 'success', summary: 'Success', detail: 'Image deleted', life: 3000 });
					} catch (err) {
						console.log(err);
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete Image: ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => {},
			});
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			pageTitle,
			pageDescription,
			loading,

			venue,

			showUpdatingDialog,

			copyUrlToClipboard,
			updateVenueImages,
			deleteFile,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.copy-to-clipboard:hover {
	cursor: pointer;
	color: cornflowerblue;
}

.card-img-top {
	width: 100%;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}
</style>
