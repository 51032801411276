<template>
	<div class="surface-section ">

		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
		</div>

		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
		<div v-else class="card">
			<DataTable :value="userConsents" dataKey="id" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll">
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
				<Column field="title" header="Title"></Column>
				<Column field="consentArea" header="Consent Area"></Column>
				<Column field="consentType" header="Consent Type"></Column>
				<Column field="countryCode" header="CountryCode"></Column>
				<Column header="Consent Given">
					<template #body="{ data }">
						<Chip v-if="data.consentGiven" label="True" class="chip-enabled" />
						<Chip v-else label="False" class="chip-disabled" />
					</template>
				</Column>
				<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<Button v-if="hasPermission('np.backoffice.users.editdelete')" @click="confirmDeleteEntity(data)" type="button" icon="pi pi-trash"></Button>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'userConsents/getUserConsentListItems';
		const storeGetterGetEntities = 'userConsents/userConsentListItems';
		const storeActionDeleteEntity = 'userConsents/delete';
		const entityName = 'Consents';
		const pageTitle = 'Consents';
		const pageDescription = "Here you can see the User's Consents";
		
		const loading = ref(true);


		const useFormatDate = inject('useFormatDate');

		const route = useRoute();
		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const entities = ref([]);

		const userId = route.params.userId;
		const user = ref();

		const userConsents = ref([]);

		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities, { userId: userId });
				const loadedUserConsents = store.getters[storeGetterGetEntities](userId);
				userConsents.value = JSON.parse(JSON.stringify(loadedUserConsents));

				console.log('CONSENTS:', entities.value);
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}

		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000 });
					} catch (err) {
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete ' + entityName + ': ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => {},
			});
		}

		async function loadRequiredData() {
			loading.value = true;

			// Load User
			await store.dispatch('users/ensureUser', { userId: userId });
			const loadedUser = store.getters['users/user'](userId);
			user.value = JSON.parse(JSON.stringify(loadedUser));
			// entities.value = user.value.consents;


			// Load User's Consents
			await store.dispatch('userConsents/ensureUserConsentListItems', { userId: userId });
			const loadedUserConsents = store.getters['userConsents/userConsentListItems'](userId);
			userConsents.value = JSON.parse(JSON.stringify(loadedUserConsents));

			reloadEntities();

			loading.value = false;

		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			entityName,
			pageTitle,
			pageDescription,
			loading,

			entities,
			userConsents,

			confirmDeleteEntity,
			reloadEntities,

			formatDate: useFormatDate().formatDate,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
