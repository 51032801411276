import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { apiConfig } from '@/adb2c/apiConfig'

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    tenant: 'rekomgroupb2c',
    clientId: process.env.VUE_APP_AD_B2C_CLIENT_ID,
    redirectUri: process.env.VUE_APP_AD_B2C_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: process.env.VUE_APP_AD_B2C_LOGOUT_URL, // Must be registered as a SPA redirectURI on your app registration
    authority: process.env.VUE_APP_AD_B2C_AUTHORITY + process.env.VUE_APP_AD_B2C_POLICY_SIGNIN,
    knownAuthorities: [process.env.VUE_APP_AD_B2C_AUTHORITY + process.env.VUE_APP_AD_B2C_POLICY_SIGNIN],
    validateAuthority: false
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {	
                  return;	
              }
              switch (level) {	
                  case LogLevel.Error:	
                      console.error(message);	
                      return;	
                  case LogLevel.Info:	
                      //console.info(message);	
                      return;	
                  case LogLevel.Verbose:	
                      //console.debug(message);	
                      return;	
                  case LogLevel.Warning:	
                      console.warn(message);	
                      return;	
                  default:
                      return;
              }
          },
          logLevel: LogLevel.Verbose
      }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: apiConfig.b2cScopes,
};

// Add here the endpoints for MS Graph API services you would like to use.
/*
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};*/