import * as apiCreatePartnership from '@/api/partnerships/createPartnership'
import * as apiGetPartnership from '@/api/partnerships/getPartnership'
import * as apiGetPartnerships from '@/api/partnerships/getPartnerships'
import * as apiUpdatePartnership from '@/api/partnerships/updatePartnership'
import * as apiDeletePartnership from '@/api/partnerships/deletePartnership'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        partnershipListItems: [],
        detailedPartnerships: {}
    }),
    mutations: {
        gotPartnershipListItems(state, partnerships) {
            state.partnershipListItems = partnerships
        },

        gotPartnership(state, partnership) {
            state.detailedPartnerships[partnership.id] = partnership
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getPartnershipListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Partnership List Items');
        
            const result = await apiGetPartnerships.get();

            if (result) {
                commit('gotPartnershipListItems', result)
            }
        
            commit('isLoading', false)
        },

        async create({ commit }, payload) {
            commit('isLoading', true)

            console.log('Posting:', payload)

            const result = await apiCreatePartnership.post(payload)

            commit('isLoading', false)

            return result
        },

        async update({ commit }, partnership) {
            commit('isLoading', true)

            console.log('Updating partnership:', partnership)

            await apiUpdatePartnership.put(partnership.id, partnership)

            commit('isLoading', false)
        },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeletePartnership.del(entityId)

            commit('isLoading', false)

            return result
        },

        async getPartnership({ commit }, { partnershipId }) {
            commit('isLoading', true)

            console.log('Getting Partnership:', partnershipId);
        
            const result = await apiGetPartnership.get(partnershipId)
            
            if (result) {
                commit('gotPartnership', result)
            }
        
            commit('isLoading', false)
        },

        async ensurePartnershipListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.partnershipListItems.length === 0) {
                await dispatch('getPartnershipListItems')
            }

            commit('isLoading', false)
        },

        async ensurePartnership({ commit, dispatch, getters }, { partnershipId }) {
            commit('isLoading', true)

            if (!getters.partnership(partnershipId)) {
                console.log('Partnership not cached. Getting from API.')
                await dispatch('getPartnershipListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        partnershipListItems: (state) => state.partnershipListItems,
        partnership: (state) => (partnershipId) => {
            return  _.find(state.partnershipListItems, function(partnership) { return partnership.id === partnershipId; });
        }
    }
};