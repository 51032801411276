<template>
    <div>
        <div class="flex">

         <div>
        <p class="font-bold text-900 text-lg m-0">
                Active User Card info
            </p>
            <p class="m-0 text-sm text-700 mb-3" >
                Configurations for the Active User Card
            </p>
        </div>   
            <Button @click="updateEntity" class="ml-auto h-3rem" :icon="'pi pi-cloud-upload'" :label="'Save configuration'" :value="'test'"></Button>

        </div>
        <div class="flex gap-2">
            <div>
                <p class="p-0 mb-1">Language</p>
                <Dropdown  :options="languageOptions" optionLabel="value" optionValue="id" v-model="selectedLanguage" class="w-12rem"></Dropdown>
            </div>
             <div>
                <p class="p-0 mb-1">Subscription Tier</p>
                <Dropdown  :options="subscriptionTierOptions" optionLabel="subscriptionTier" optionValue="subscriptionTier" v-model="selectedTier" class="w-12rem"></Dropdown>
            </div>

        </div>
        <CardLabelList :title="'Active User Card'" 
        :type="'fullSection'"
        :labels="labels" 
        :language="selectedLanguage" 
        :formFields="formFields"
        :maxLabels="3"
        @onAddNew="addLabel"
        @onRemove="removeLabel" 
        @onChangeOrder="changeLabelOrder"   
        @onUpdate="onUpdateLabel"
        @onFormChange="onFormChange"
        />

   
                
    </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import CardLabelList from '../components/CardLabelList.vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { uploadFile} from '@/services/fileUploadService'



export default {
    components: {
        CardLabelList
    },
   
	setup() {
     
     
     
        const store = useStore();
        const toast = useToast();

        const storeGetterGetEntity = "appContents/activeUserCardInfo"
        const storeGetterGetAppContentId = "appContents/appContentId"

       const storeActionUpdateEntity = 'appContents/updateActiveUserCardInfo'
       const loading = ref(false)
       const activeUserCardInfo = ref([{
            labels: [],
            imageUrl: "",
            languageCode: "default",
            subscriptionTier: "Standard"
        }])

        const appContentId = ref()

        const languageOptions = [{id: "default", value:"Default"}, {id: "da", value:"Danish"}]
        const selectedLanguage = ref("default")
        
        const subscriptionTierOptions = ref([])
        const selectedTier = ref("Standard")


        const labels = computed(()=>{
            return activeUserCardInfo.value?.find(x => x.languageCode == selectedLanguage.value && x.subscriptionTier == selectedTier.value)?.labels
        })

        const imageUrl = computed(()=>{
            return activeUserCardInfo.value?.find(x => x.languageCode == selectedLanguage.value  && x.subscriptionTier == selectedTier.value)?.imageUrl
        })

   

        const labelsFormField = [
               
				{
					id: 'title',
					type: 'text-input',
					title: 'Title',
					help: '',
				},
                {
					id: 'subtitleTop',
					type: 'text-input',
					title: 'Subtitle Top',
					help: '',
				},
              
                 {
					id: 'subtitleBottom',
					type: 'text-input',
					title: 'Subtitle Bottom',
					help: '',
				},
                 {
					id: 'titleSize',
					type: 'dropdown',
					title: 'Title Size',
                    config: {
					options: ["Small", "Medium", "Large"]
			
				},
					help: '',
				},
                  {
					id: 'recurringDrinkAsTitle',
					type: 'switch',
					title: 'Recurring Drink As Title',
					help: 'Special label for showing amount of drinks a tier is promised',
				},
      ]

       const specialLabelsFormField = [
               
				{
					id: 'title',
					type: 'number-input',
					title: 'Amount',
					help: '',
				},
                {
					id: 'subtitleTop',
					type: 'text-input',
					title: 'Title',
					help: '',
				},
              
                 {
					id: 'subtitleBottom',
					type: 'text-input',
					title: 'Subtitle Bottom',
					help: '',
				},


                 {
					id: 'iconUrl',
					type: 'imageUpload',
					title: 'Icon',
					help: '',
                    accept: "image/svg+xml"
				},
                {
					id: 'recurringDrinkAsTitle',
					type: 'switch',
					title: 'Recurring Drink As Title',
					help: 'Special label for showing amount of drinks a tier is promised',
				},
      ]

      const formFields = ref(labelsFormField)


        function onFormChange(context){
            if(context.recurringDrinkAsTitle){
                formFields.value = specialLabelsFormField;
            } else {
                formFields.value = labelsFormField;
            }
        }
      

        async function addLabel(formContext){

            if(formContext?.iconUrl){
                var url = await handleIconUpload(formContext?.iconUrl)
                formContext.iconUrl = url
            }

            if(formContext?.recurringDrinkAsTitle){
                formContext.title = formContext.title + ""
            }

            if(labels.value){
               labels.value?.push(formContext)
               return
            }
           activeUserCardInfo.value.push({
                labels: [formContext],
                imageUrl: "",
                languageCode: selectedLanguage.value,
                subscriptionTier: selectedTier.value
            })

            formFields.value = labelsFormField;

      }


        function removeLabel(index){
            labels.value?.splice(index, 1)
      }

        function changeLabelOrder(lbl){
                var label = labels.value[lbl.oldIndex];
                labels.value?.splice(lbl.oldIndex, 1)
                labels.value?.splice(lbl.newIndex, 0, label);
        }


        async function handleIconUpload(image){
    
            var logoFile = await uploadFile(image, "card-icons", "all", store)
            return logoFile.url
       }

        async function onUpdateLabel(label, index){
              var currLabel =  labels.value[index] 

            if(currLabel?.iconUrl != label?.iconUrl && label.iconUrl){
              var url =  await handleIconUpload(label.iconUrl)
              label.iconUrl = url
            }

             if(label?.recurringDrinkAsTitle){
                label.title = label.title + ""
            }
            labels.value[index] = label
            formFields.value = labelsFormField;

        }
        
        

        function setOrderNumbers(){

            labels.value.map((x, i) =>{
                labels.value[i].orderNumber = i;
            } )

        }


        async function updateEntity(){
            loading.value = true;
             setOrderNumbers()

            var obj = {appContentId: appContentId.value, activeUserCardInfo: activeUserCardInfo.value}
            await store.dispatch(storeActionUpdateEntity, obj)
            .then(()=> {
                toast.add({ severity: 'success', summary: 'Success', detail: 'Active User Card was updated', life: 3000 });

            })
            .catch((err)=>{
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update Active User Card' + ': ' + err, life: 3000 });
            })
            .finally(()=>loading.value = false)
        }
        
        
        

        onMounted(async ()=>{
            await store.dispatch('memberships/ensureMembershipListItems');

			subscriptionTierOptions.value = store.getters['memberships/membershipListItems'];
            appContentId.value = store.getters[storeGetterGetAppContentId]
            activeUserCardInfo.value = store.getters[storeGetterGetEntity]
        })

        return {
        selectedLanguage,
        languageOptions,
        labelsFormField,
        imageUrl,
        labels,
        selectedTier,
        subscriptionTierOptions,
        formFields,
        onFormChange,
        removeLabel,
        onUpdateLabel,
        changeLabelOrder,
        addLabel,
        updateEntity
                }

    
}
}
</script>


<style scoped>
.bannerSection{
min-height: 22rem;

background-image: linear-gradient(to left top, #081654, #122886, #122886, #081654, #081654);
}
</style>