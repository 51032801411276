

function  getPerkOfferFormFields(perks){
    return [
    {
            id: 'offerType',
            type: 'dropdown',
            title: 'Offer type',
            config: {
            options: ["Perk", "FreeMembershipMonth", "DiscountOnMembership"]
    
        },
            help: '',
    },

    {
        id: 'title',
        type: 'html-input',
        title: 'Title',
        inputClass: 'htmleditor',
        help: '',
    },
    {
        id: 'terms',
        type: 'text-input',
        title: 'Terms',
        help: 'Terms for the offer',
    },
    {
        id: 'perkId',
        type: 'dropdown',
        title: 'Perk',
        config: {
            options: [...perks],
            optionLabel: 'name',
            optionValue: 'id',
            placeholder: 'Select perk',
        },
        help: '',
    },
    {
        id: 'amount',
        type: 'number-input',
        title: 'Amount',
        help: 'Amount of perks',
    },
    {
        id: 'iconUrl',
        type: 'imageUpload',
        title: 'Icon',
        help: '',
        accept: "image/svg+xml"
    },
  ]
}

  var freeMembershipOfferFormFields = [
    {
            id: 'offerType',
            type: 'dropdown',
            title: 'Offer type',
            config: {
            options: ["Perk", "FreeMembershipMonth", "DiscountOnMembership"]
    
        },
            help: '',
    },

    {
        id: 'title',
        type: 'html-input',
        title: 'Title',
        inputClass: 'htmleditor',
        help: '',
    },
    {
        id: 'terms',
        type: 'text-input',
        config:{
            disabled: true,
        },
        title: 'Terms',
        help: 'Terms for the offer',
    },
    {
        id: 'amount',
        type: 'number-input',
        title: 'Number of free months',
        help: '',
    },
    {
        id: 'iconUrl',
        type: 'imageUpload',
        title: 'Icon',
        help: '',
        accept: "image/svg+xml, image/png"
    },
  ]

  var discountOnMembershipOfferFormFields = [
    {
            id: 'offerType',
            type: 'dropdown',
            title: 'Offer type',
            config: {
            options: ["Perk", "FreeMembershipMonth", "DiscountOnMembership"]
    
        },
            help: '',
    },

  
    {
        id: 'title',
        type: 'html-input',
        title: 'Title',
        inputClass: 'htmleditor',
        help: '',
    },
      {
        id: 'terms',
        type: 'text-input',
        title: 'Terms',
        help: 'Terms for the offer',
    },
    {
        id: 'amount',
        type: 'number-input',
        title: 'Amount',
        help: 'Percent off next membership payment',
    },
    {
        id: 'iconUrl',
        type: 'imageUpload',
        title: 'Icon',
        help: '',
        accept: "image/svg+xml"
    },
  ]

  export {getPerkOfferFormFields, freeMembershipOfferFormFields, discountOnMembershipOfferFormFields}