import apiClient from '@/services/axios';

export async function post(membershipId, payload) {
	

	if (payload.venueId && !payload.venueId.id) {
		payload.venueId = { id: null };
	}
	
	var payloadFixed = { perkConfig: payload };
	var response = await apiClient.post('MembershipPerks/sign-up?membershipId=' + membershipId, payloadFixed);
	if (response) {
		return response.data;
	}

	throw new Error('Unable to update assign sign up perks to members now.');
}
