import apiClient from '@/services/axios'

export async function put(userTermsId, payload) {
  var response = await apiClient.put('/userTerms/' + userTermsId, payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update User Terms.')
}
