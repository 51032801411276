import * as apiCreatePromoCode from '@/api/promoCodes/createPromoCode'
import * as apiGetPromoCode from '@/api/promoCodes/getPromoCode'
import * as apiGetPromoCodes from '@/api/promoCodes/getPromoCodes'
import * as apiUpdatePromoCode from '@/api/promoCodes/updatePromoCode'
import * as apiDeletePromoCode from '@/api/promoCodes/deletePromoCode'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        promoCodeListItems: [],
        detailedPromoCodes: {}
    }),
    mutations: {
        gotPromoCodeListItems(state, promoCodes) {
            state.promoCodeListItems = promoCodes
        },

        gotPromoCode(state, promoCode) {
            state.detailedPromoCodes[promoCode.id] = promoCode
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getPromoCodeListItems({ commit }, filter) {
            commit('isLoading', true)

            console.log('Getting PromoCode List Items');
            const result = await apiGetPromoCodes.get(filter.showExpired, filter.type);

            if (result) {
                commit('gotPromoCodeListItems', result)
            }
        
            commit('isLoading', false)
        },

        async create({ commit }, payload) {
            commit('isLoading', true)

            console.log('Posting:', payload)

            const result = await apiCreatePromoCode.post(payload)

            commit('isLoading', false)

            return result
        },

        async update({ commit }, promoCode) {
            commit('isLoading', true)

            await apiUpdatePromoCode.put(promoCode.id, promoCode)

            commit('isLoading', false)
        },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeletePromoCode.del(entityId)

            commit('isLoading', false)

            return result
        },

        async getPromoCode({ commit }, { promoCodeId }) {
            commit('isLoading', true)

            console.log('Getting PromoCode:', promoCodeId);
        
            const result = await apiGetPromoCode.get(promoCodeId)
            
            if (result) {
                commit('gotPromoCode', result)
            }
        
            commit('isLoading', false)
        },

        async ensurePromoCodeListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.promoCodeListItems.length === 0) {
                await dispatch('getPromoCodeListItems')
            }

            commit('isLoading', false)
        },

        async ensurePromoCode({ commit, dispatch, getters }, { promoCodeId }) {
            commit('isLoading', true)

            if (!getters.promoCode(promoCodeId)) {
                console.log('PromoCode not cached. Getting from API.')
                await dispatch('getPromoCodeListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        promoCodeListItems: (state) => state.promoCodeListItems,
        promoCode: (state) => (promoCodeId) => {
            return  _.find(state.promoCodeListItems, function(promoCode) { return promoCode.id === promoCodeId; });
        }
    }
};