import * as apiMemberCardCategories from '@/api/memberCardCategories/updateMemberCardCategories';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
	}),
	mutations: {
		isLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async update({ commit }, payload) {
			commit('isLoading', true);

			await apiMemberCardCategories.put(payload.memberCardId, payload.formContext);

			commit('isLoading', false);
		},
	},
	getters: {},
};
