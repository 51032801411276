import apiClient from '@/services/axios'

export async function put(userId) {
  console.log('GcreateNewOnlinePosCard:', userId)
  var response = await apiClient.put('/users/' + userId + '/createNewOnlinePosCard')
  if (response) {
    return response.data
  }

  throw new Error('Unable to create new onlinepos card.')
}
