import * as apiCreateMemberCard from '@/api/memberCards/createMemberCard'
import * as apiGetMemberCard from '@/api/memberCards/getMemberCard'
import * as apiGetMemberCards from '@/api/memberCards/getMemberCards'
import * as apiUpdateMemberCard from '@/api/memberCards/updateMemberCard'
import * as apiDeleteMemberCard from '@/api/memberCards/deleteMemberCard'
import * as apiUpdateMemberCardQrUrl from '@/api/memberCards/updateMemberCardQrUrl'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        memberCardListItems: [],
        detailedMemberCards: {}
    }),
    mutations: {
        gotMemberCardListItems(state, memberCards) {
            state.memberCardListItems = memberCards
        },

        gotMemberCard(state, memberCard) {
            state.detailedMemberCards[memberCard.id] = memberCard
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async create({ commit }, payload) {
            commit('isLoading', true)

            const result = await apiCreateMemberCard.post(payload)

            commit('isLoading', false)

            return result
        },

        async getMemberCardListItems({ commit }) {
            commit('isLoading', true)

            const result = await apiGetMemberCards.get();

            if (result) {
                commit('gotMemberCardListItems', result)
            }
        
            commit('isLoading', false)
        },

        async update({ commit }, memberCard) {
            commit('isLoading', true)

            await apiUpdateMemberCard.put(memberCard.id, memberCard)

            commit('isLoading', false)
        },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeleteMemberCard.del(entityId)

            commit('isLoading', false)

            return result
        },

        async getMemberCard({ commit }, { memberCardId }) {
            commit('isLoading', true)

            const result = await apiGetMemberCard.get(memberCardId)
            
            if (result) {
                commit('gotMemberCard', result)
            }
        
            commit('isLoading', false)
        },

        async ensureMemberCardListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.memberCardListItems.length === 0) {
                await dispatch('getMemberCardListItems')
            }

            commit('isLoading', false)
        },

        async ensureMemberCard({ commit, dispatch, getters }, { memberCardId }) {
            commit('isLoading', true)

            if (!getters.memberCard(memberCardId)) {
                console.log('Member Card not cached. Getting from API.')
                await dispatch('getMemberCardListItems')
            }

            commit('isLoading', false)
        },

        async updateQrUrl({ commit }, {memberCardId}) {
            commit('isLoading', true)

            return await apiUpdateMemberCardQrUrl.put(memberCardId)
            .finally(()=>{
                commit('isLoading', false)
            })


        },
    },
    getters: {
        memberCardListItems: (state) => state.memberCardListItems,
        memberCard: (state) => (memberCardId) => {
            return  _.find(state.memberCardListItems, function(memberCard) { return memberCard.id === memberCardId; });
        }
    }
};