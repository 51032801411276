<template>
    <div class="w-14rem h-min flex flex-wrap flex-column gap-2 p-2 shadow-3 overflow-hidden hover:shadow-6 cursor-pointer relative" @click="openStripe(receipt)">
        <div v-if="receipt.refunded" class="absolute text-center w-5rem  bg-orange-400 right-0 top-0 text-white -mr-3 mt-3" style="transform: rotate(45deg); font-size: 12px">
                Refunded
        </div>
     
        <div class="flex h-min w-full">
            <img class="w-3rem h-3rem m-0 p-0  mx-auto shadow-2 border-circle bg-contain " :src="receipt.cachedVenue?.venueLogoUrl ?? fallBackLogo" />
       </div>
       <div v-if="receipt.paymentType != 'SubscriptionPayment'"> 
            <h4 class="text-center m-0 p-0 w-full">{{receipt.cachedVenue?.name}}</h4>
            <p class="text-center m-0 p-0 w-full text-xs">{{receipt.cachedVenue?.street}}</p>
            <p class="text-center m-0 p-0 w-full text-xs">{{receipt.cachedVenue?.postalCode}} {{receipt.cachedVenue?.city}}</p>
       </div>
       <div v-else>
            <h4 class="text-center m-0 p-0 w-full" >NightPay Membership</h4>
       </div>
        <span style="height:1px" class="w-full bg-gray-400"></span>

        <div class="w-full flex flex-wrap gap-1 text-xs py-1">
            <div class="flex flex-wrap justify-content-between w-full">
                <div>Receipt total</div>
                <div >{{receipt.originalAmount}} {{receipt.currencyCode}}</div>
            </div>
            
            <div class="flex flex-wrap justify-content-between w-full font-bold">
                <div >You saved</div>
                <div >{{receipt.discount}} {{receipt.currencyCode}}</div>
            </div>
            <div style="font-size: .85rem" class="flex flex-wrap justify-content-between mt-2 w-full font-bold">
                <div >Total with discount</div>
                <div >{{receipt.amountPaid}} {{receipt.currencyCode}}</div>
            </div>
            <div v-if="receipt.refunded" style="font-size: .85rem" class="flex flex-wrap justify-content-between  w-full ">
                <div >Refunded amount</div>
                <div >{{receipt.amountRefunded}} {{receipt.currencyCode}}</div>
            </div>
            <div style="opacity: 0.65"  class="flex flex-wrap justify-content-between mt-2 w-full ">
                <div >VAT amount</div>
                <div >{{receipt.vatAmount}} {{receipt.currencyCode}}</div>
            </div>
        </div>

        <span style="height:1px" class="w-full bg-gray-400"></span>

        <div class="text-xs flex flex-wrap gap-1">
            <div class="font-bold">{{receipt.title}}</div>
            <div >{{receipt.description}}</div>
            <div style="font-size: .7rem; opacity: 0.65" > {{receipt.paymentMethodFormatted}} </div>

        </div>

        <span style="height:1px" class="w-full bg-gray-400"></span>
        
        <div class="text-xs text-center flex-1 h-full align-content-center">
            <div class="font-bold">{{receipt.receiptInfo.organization}}</div>
            <div >{{receipt.receiptInfo.address.street}}</div>
            <div >{{receipt.receiptInfo.address.city}}</div>
            <div >{{receipt.receiptInfo.companyNumber}}</div>
        </div>

        <span style="height:1px" class="w-full bg-gray-400"></span>

        <p class="m-0 p-1 text-center text-xs w-full">{{formatDateTime(receipt.created)}}</p>

    </div>
</template>


<script>
import { useToast } from 'primevue/usetoast';
import useFormatDate from '../../utils/useFormatDate';
import fallBackLogo from '../../assets/nightPay/fallback-venue-logo.png';


export default {
	inject: ['hasPermission'],
	components: {},
    props: ["receipt"],
	setup() {
		
        const toast = useToast()
        
        function formatDateTime(date){
            return useFormatDate().formatDateTime(date) ?? "-"
        }

        function openStripe(receipt){

            

            if(receipt.paymentType == "SubscriptionPayment")
            {
                if(receipt.stripeInvoiceId == null){
                      toast.add({ severity: 'error', summary: 'Could not open link', detail: "Receipt does not have a link to stripe", life: 3000, });
                    return
                }   

                 window.open("https://dashboard.stripe.com/invoices/" + receipt.stripeInvoiceId, "_blank")
                 return
            }
            if(receipt.paymentType == "Purchase" || receipt.paymentType == "MemberCard" )
            {
                   if(receipt.stripePaymentIntentId == null){
                      toast.add({ severity: 'error', summary: 'Could not open link', detail: "Receipt does not have a link to stripe", life: 3000, });
                        return
                   }
                    
                 window.open("https://dashboard.stripe.com/paymnet/" + receipt.stripePaymentIntentId, "_blank")
                 return
            }
            
            toast.add({ severity: 'error', summary: 'Could not open link', detail: "Receipt does not have a link to stripe", life: 3000, });


        }

        return {
            fallBackLogo,
            openStripe,
            formatDateTime
        }
    }
}
</script>

