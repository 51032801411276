import * as apiCreateMembership from '@/api/memberships/createMembership'
import * as apiGetMembership from '@/api/memberships/getMembership'
import * as apiGetMemberships from '@/api/memberships/getMemberships'
import * as apiUpdateMembership from '@/api/memberships/updateMembership'
import * as apiDeleteMembership from '@/api/memberships/deleteMembership'

import _ from "lodash";

export default {
	namespaced: true,
	state: () => ({
		loading: false,
		membershipListItems: [],
		detailedMemberships: {}
	}),
	mutations: {
		gotMembershipListItems(state, membership) {
			state.membershipListItems = membership
		},

		gotMembership(state, membership) {
			state.detailedMemberships[membership.id] = membership
		},

		isLoading(state, loading) {
			state.loading = loading
		}
	},
	actions: {
		async create({ commit }, payload) {
			commit('isLoading', true)

			const result = await apiCreateMembership.post(payload)

			commit('isLoading', false)

			return result
		},

		async getMembershipListItems({ commit }) {
			commit('isLoading', true)

			const result = await apiGetMemberships.get();

			if (result) {
				commit('gotMembershipListItems', result)
			}

			commit('isLoading', false)
		},

		async update({ commit }, membership) {
			commit('isLoading', true)

			await apiUpdateMembership.put(membership.id, membership)

			commit('isLoading', false)
		},

		async delete({ commit }, entityId) {
			commit('isLoading', true)

			const result = await apiDeleteMembership.del(entityId)

			commit('isLoading', false)

			return result
		},

		async getMembership({ commit }, { membershipId }) {
			commit('isLoading', true)

			const result = await apiGetMembership.get(membershipId)

			if (result) {
				commit('gotMembership', result)
			}

			commit('isLoading', false)
		},

		async ensureMembershipListItems({ commit, dispatch, getters }) {
			commit('isLoading', true)

			if (getters.membershipListItems.length === 0) {
				await dispatch('getMembershipListItems')
			}

			commit('isLoading', false)
		},

		async ensureMembership({ commit, dispatch, getters }, { membershipId }) {
			commit('isLoading', true)

			if (!getters.membership(membershipId)) {
				console.log('Membership not cached. Getting from API.')
				await dispatch('getMembershipListItems')
			}

			commit('isLoading', false)
		}
	},
	getters: {
		membershipListItems: (state) => state.membershipListItems,
		membership: (state) => (membershipId) => {
			return _.find(state.membershipListItems, function (membership) { return membership.id === membershipId; });
		}
	}
};