import apiClient from '@/services/axios'

export async function get(payload) {
  console.log('payload', payload);
  var response = await apiClient.post('/users/paging/', payload)
  console.log("getUsersPaging", response);
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Users.')
}
