import * as apiGetNotifications from '@/api/notifications/getNotifications'
import * as apiDeleteNotification from '@/api/notifications/deleteNotification'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        notificationListItems: []
    }),
    mutations: {
        gotNotificationListItems(state, notifications) {
            state.notificationListItems = notifications
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getNotificationListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Notification List Items');
        
            const result = await apiGetNotifications.get();

            if (result) {
                commit('gotNotificationListItems', result)
            }
        
            commit('isLoading', false)
        },
        async delete({ commit }, { notificationId }) {
            commit('isLoading', true)

             const result = await apiDeleteNotification.del(notificationId)

             commit('isLoading', false)

             return result
        },

        async ensureNotificationsListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.poolListItems.length === 0) {
                await dispatch('getNotificationListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        notificationListItems: (state) => state.notificationListItems,
        loading: (state) => state.loading
    }
};