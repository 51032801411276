import * as apiGetSubscriptionStatistics from '@/api/statistics/getSubscriptionStatistics'


export default {
    namespaced: true,
    state: () => ({
        loading: false,
        subscriptionStatistics: null
    }),
    mutations: {
        gotSubscriptionStatistics(state, stats) {
            state.subscriptionStatistics = stats
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {


        async getSubscriptionStatistics({ commit }) {
            commit('isLoading', true)

            console.log('Getting statistics about subscriptions');
        
            const result = await apiGetSubscriptionStatistics.get();

            if (result) {
                commit('gotSubscriptionStatistics', result)
            }
        
            commit('isLoading', false)
        },

        async ensureSubscriptionStatistics({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (!getters.subscriptionStatistics) {
                console.log('Subscription statistics not cached. Getting from API.')
                await dispatch('getSubscriptionStatistics')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        subscriptionStatistics: (state) =>  {
            return state.subscriptionStatistics
        }
    }
};