<template>
	<Sidebar v-if="hasPermission('np.backoffice.venues.addvenue')" v-model:visible="createNewSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="creating" :title="'Creating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Create {{ entityName }}</h3>
			<Form :fields="fields" :context="newEntity" @onAction="createEntity" :actionLabel="'Create'" :actionIcon="'pi pi-plus-circle'" />
		</div>
	</Sidebar>
	<Sidebar v-model:visible="importSidebarVisible" position="right" :showCloseIcon="false">
		<Loading v-if="importing" :title="'Importing ' + pageTitle + '...'" :full="false" />
		<div v-else>
			<h3>Import Campaigns</h3>
			<div class="flex flex-column">
				<div v-if="importing == false && importComplete == true">
					<Panel header="Import complete">
						<div class="font-semibold">No changes: {{ importStats.noChanges }}</div>
						<div class="mt-2 font-semibold">Updated: {{ importStats.updated }}</div>
						<div class="mt-2 font-semibold">Added: {{ importStats.added }}</div>
					</Panel>
				</div>

				<Button v-if="importComplete" class="flex-1 mt-3 p-button-success" @click="closeSidebar" label="Done" icon="pi pi-check-circle" />
				<Button v-else class="flex-1 mt-3" @click="importVenues" label="Import" icon="pi pi-cloud-download" />
			</div>
		</div>
	</Sidebar>
	<div class="surface-section p-6 pt-0" v-if="hasPermission('np.backoffice.venues.show')">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.venues.addvenue')" @click="createNewSidebarVisible = true" :label="'New ' + entityName" icon="pi pi-plus-circle" />
				<Button
					v-if="hasPermission('np.backoffice.venues.importvenues')"
					@click="importSidebarVisible = true"
					label="Import IDM Venues"
					icon="pi pi-cloud-download"
					class="ml-2 p-button-secondary"
				/>
			</div>
		</div>

		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
		
		<div v-else >
			<Toolbar class="mb-2">
				<template #start>
				<div class="flex flex-wrap w-full">
					<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2 w-8rem " @click="clearFilters()" />
					<div class="w-full">
						<h4 class="w-full mb-2 text-gray-700">Search</h4>
						<InputText v-model="filters['global'].value" placeholder="Keyword Search"  class="w-full" />
					</div>
				</div>
				</template>
			</Toolbar>
			<div class="card">
				<DataTable :value="entities" dataKey="id" @row-click="onRowClicked" class="p-datatable-lg" v-model:filters="filters" :rowHover="true" responsiveLayout="scroll">
					<template #empty> No {{ pageTitle }} found. </template>
					<template #loading> Loading {{ pageTitle }}. Please wait. </template>
					<Column field="name" header="Name"></Column>
					<Column header="Address">
						<template #body="{ data }"> {{ data.street }}, {{ data.postalCode }} {{ data.city }}, {{ data.country }} </template>
					</Column>
					<Column field="rebateCutOffModel" header="Rebate Model">
					<template #body="{ data }">
							<p>{{rebateCutOffModelToString(data.rebateCutOffModel)}}</p>
						</template>
					</Column>
					<Column header="Active">
						<template #body="{ data }">
							<Chip v-if="data.active" label="Active" class="chip-enabled" />
							<Chip v-else label="Inactive" class="chip-disabled" />
						</template>
					</Column>
					<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="{ data }">
							<Button v-if="hasPermission('np.backoffice.venues.editdelete')" @click="confirmDeleteEntity(data)" type="button" icon="pi pi-trash"></Button>
						</template>
					</Column>
				</DataTable>
			</div>

		</div>
	</div>
</template>

<script>
import { ref, onMounted, inject, computed } from 'vue';

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { uploadFile} from '@/services/fileUploadService'
import { FilterMatchMode } from 'primevue/api';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'venues/getVenueListItems';
		const storeGetterGetEntities = 'venues/venueListItems';
		const storeActionCreateEntity = 'venues/create';
		const storeActionDeleteEntity = 'venues/delete';
		const entityName = 'Venue';
		const pageTitle = 'Venues';
		const pageDescription = 'Here you can manage your Venues';
		// const canCreateNew = hasPermission('np.backoffice.venues.shows');
		const canUpdate = false;

		const loading = ref(false);
		const creating = ref(false);
	
		const importing = ref(false);
		const importComplete = ref(false);
		const importStats = ref({});

		const useFormatDate = inject('useFormatDate');

		const router = useRouter();
		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const activeCountryCode = computed(() => {
			var activeAccountCountry = store.getters["activeAccountCountry"]
			var countryAccounts = store.getters["countryAccounts"]
			return countryAccounts?.find(x => x.id == activeAccountCountry)?.countryCode
		});

		const entities = ref([]);

		const newEntity = ref({
			name: '',
			description: '',
			online: false,
			address: '',
		});

		const filters = ref({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS }
		})

		function clearFilters(){
			filters.value.global.value = null
		}

		const fields = ref([
			{
				id: 'name',
				type: 'text-input',
				title: 'Name',
				help: 'Name of the venue as shown in the app.',
			},
			{
				id: 'description',
				type: 'text-area',
				title: 'Description',
			},
			{
				id: 'venueLogoUrl',
				type: 'text-input',
				title: 'Venue Logo Url',
				config: {
					readonly: 'true',
				},
			},
			{
				id: 'nightPayLogoUrl',
				type: 'imageUpload',
				title: 'NightPay Logo',
			},
			{
				id: 'isOnline',
				type: 'switch',
				title: 'Online',
			},
			{
				id: 'address',
				type: 'GoogleMapsAddress',
				title: 'Address',
			},
		]);

		const importSidebarVisible = ref(false);
		function closeSidebar() {
			importing.value = false;
			importComplete.value = false;
			importSidebarVisible.value = false;
		}

		const createNewSidebarVisible = ref(false);

		function onRowClicked(payload) {
			router.push({ name: 'venue', params: { venueId: payload.data.id } });
		}

		function rebateCutOffModelToString(model){
			if (model == "alwaysReduced")
			{
				return "OnlyAllNight"
			}
			return model
		}

		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000 });
					} catch (err) {
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete ' + entityName + ': ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => {},
			});
		}

		async function importVenues() {
			importing.value = true;

			try {
				await store.dispatch('venues/importIdmVenues');
				importStats.value = store.getters['venues/lastImportStats'];

				importComplete.value = true;

				toast.add({ severity: 'success', summary: 'Success', detail: 'Venues imported', life: 3000 });
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not import Venues: ' + err, life: 3000 });
			}

			const loadedEntities = store.getters['venues/venueListItems'];
			entities.value = loadedEntities;

			importing.value = false;
		}

		async function createEntity(formContext) {
			creating.value = true;

			try {

				var imageContainer = formContext.name + "-" + activeCountryCode.value + "-images"
		
				if(formContext.nightPayLogoUrl && formContext.nightPayLogoUrl.name ){
					var logoFile = await uploadFile(formContext.nightPayLogoUrl, "venues", imageContainer, store)
					formContext.nightPayLogoUrl = logoFile.url
				}

				await store.dispatch(storeActionCreateEntity, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' created', life: 3000 });
				createNewSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			creating.value = false;
		}

		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities);
				const loadedEntities = store.getters[storeGetterGetEntities];
				entities.value = loadedEntities;
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}

		async function loadRequiredData() {
			loading.value = true;

			await reloadEntities();

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			pageTitle,
			pageDescription,
			// canCreateNew,
			canUpdate,
			loading,
			creating,

			importing,
			importComplete,
			importStats,

			importVenues,
			createEntity,

			entities,
			newEntity,
			fields,
			filters,
			confirmDeleteEntity,

			rebateCutOffModelToString,

			importSidebarVisible,
			closeSidebar,
			createNewSidebarVisible,

			onRowClicked,
			clearFilters,

			entityName,

			formatDate: useFormatDate().formatDate,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
