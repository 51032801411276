<template>
	<div class="surface-section p-6 pt-0" v-if="hasPermission('np.backoffice.campaigns.show')">
		<Breadcrumbs :items="breadcrumbs" />
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
			</div>
		</div>

		<div class=" flex justify-content-left">
			<!-- <div>
				<h4 class="mb-2">Select Venue</h4>
				<div class="card flex justify-content-left">
					<Dropdown v-model="selectedVenue" :options="venues" filter optionLabel="name"
						placeholder="Select a Venue" class="w-full md:w-14rem"></Dropdown>
				</div>
			</div> -->
			<div class="ml-2">
				<h4 class="mb-2">Select Time</h4>
				<div class="">
					<Calendar id="calendar-timeonly" v-model="selectedTime" timeOnly />
&nbsp;
					<Button type="submit" label="Update" />
				</div>
			<!-- </div>
			<div class="ml-2"> -->
					
				<div class="card flex justify-content-left justify-content-bottom">
				</div>
			</div>
		</div>
		<br />
		


		<DataView :value="entities" :layout="layout">
			<template #header>
				<div class="flex justify-content-end">
					<DataViewLayoutOptions v-model="layout" />
				</div>
			</template>

			<template #list="slotProps">
				<div class="col-12">
					<div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">

						<img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
							v-bind:style="{ backgroundImage: 'url(' + slotProps.data.backgroundUrl + ')' }"
							:src="slotProps.data.logoUrl" :alt="slotProps.data.title" v-if="slotProps.data.logoUrl != ''" />
						<div
							class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
							<div class="flex flex-column align-items-center sm:align-items-start gap-3">
								<div class="text-2xl font-bold text-900">{{ slotProps.data.title }}</div>
								<!-- <Rating :modelValue="slotProps.data.rating" readonly :cancel="false"></Rating> -->
								<div class="flex align-items-center gap-3">
									<span class="flex align-items-center gap-2">
										<i class="pi pi-tag"></i>
										<!-- <span class="font-semibold">{{ slotProps.data.category }}</span> -->
									</span>
									<!-- <Tag :value="slotProps.data.inventoryStatus" :severity="getSeverity(slotProps.data)">
									</Tag> -->
								</div>
							</div>
							<div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
								<!-- <span class="text-2xl font-semibold">${{ slotProps.data.price }}</span> -->
								<!-- <Button icon="pi pi-shopping-cart" rounded
									:disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'"></Button> -->
							</div>
						</div>
					</div>
				</div>
			</template>

			<template #grid="slotProps">
				<div class="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
					<div class="p-4 border-1 surface-border surface-card border-round">
						<div class="flex flex-wrap align-items-center justify-content-between gap-2">
							<div class="flex align-items-center gap-2">
								<i class="pi pi-tag"></i>
								<!-- <span class="font-semibold">{{ slotProps.data.category }}</span> -->
							</div>
							<!-- <Tag :value="slotProps.data.inventoryStatus" :severity="getSeverity(slotProps.data)"></Tag> -->
						</div>
						<div class="flex flex-column align-items-center gap-3 py-5">
							<img class="w-9 shadow-2 border-round"
								:src="`${slotProps.data.logoUrl != null ? slotProps.data.logoUrl : ''}`"
								:alt="slotProps.data.name" />
							<div class="text-2xl font-bold">{{ slotProps.data.title }}</div>
							<Rating value="{product.rating}" readonly :cancel="false"></Rating>
						</div>
						<div class="flex align-items-center justify-content-between">
							<span class="text-2xl font-semibold">${{ slotProps.data.price }}</span>
							<Button icon="pi pi-shopping-cart" rounded
								:disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'"></Button>
						</div>
					</div>
				</div>
			</template>
		</DataView>
	</div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
//import { useRouter } from 'vue-router'
import { useStore } from 'vuex';

import { useToast } from 'primevue/usetoast';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'memberCards/getMemberCardListItems';
		const storeGetterGetEntities = 'memberCards/memberCardListItems';
		const pageTitle = 'Membercard test';
		const pageDescription = 'Here you can test the membercards and their discounts.';
		const loading = ref(true);
		const breadcrumbs = ref([
			{
				title: pageTitle,
			},
		]);

		const importing = ref(false);
		const importComplete = ref(false);


		const useFormatDate = inject('useFormatDate');

		//const router = useRouter()
		const store = useStore();
		const toast = useToast();

		const selectedTime = ref(new Date());
		const selectedVenue = ref(null);

		const entities = ref([]);
		const venues = ref([]);

		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities);
				const loadedEntities = store.getters[storeGetterGetEntities];
				entities.value = loadedEntities;
				console.log(entities.value);
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}

		async function loadRequiredData() {
			loading.value = true;


			try {
				await store.dispatch('venues/ensureVenueListItems');
				const loadedVenues = store.getters['venues/venueListItems'];
				venues.value = loadedVenues.sort((a, b) => a.name.localeCompare(b.name));
				console.log('venues', venues.value);
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Venues: ' + err, life: 3000 });
			}

			reloadEntities();

			loading.value = false;
		}

		function getVenueName(id) {
			var venue = venues.value.find((x) => x.id === id);

			if (venue) {
				return venue.name;
			}
			return '';
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			loading,
			breadcrumbs,
			pageTitle,
			pageDescription,

			getVenueName,


			importing,
			importComplete,
			// importCampaigns,

			selectedVenue,
			selectedTime,
			entities,
			venues,

			formatDate: useFormatDate().formatDate,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
