import * as apiGetUserPayments from '@/api/userPayments/getUserPayments'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        userPayments: {},
    }),
    mutations: {
        gotUserPayments(state, { userId, userPayments }) {
            state.userPayments[userId] = userPayments
            console.log("GOT USER PAYMENTS:", state.userPayments)
        },


        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {


     

        async getUserPayments({ commit }, { userId }) {
            commit('isLoading', true)

            console.log('Getting User Payments');
        
            const result = await apiGetUserPayments.get(userId);
            console.log(result)
            if (result) {
                commit('gotUserPayments', 
                { 
                    userId: userId,
                    userPayments: result 
                })
            }
        
            commit('isLoading', false)
        },

       
    },
    getters: {
        userPayments: (state) => (userId) => {
            return state.userPayments[userId]
        },
    
    }
};