import apiClient from '@/services/axios'

export async function get(userId, stripeSubscriptionId, billWerkSubscriptionHandle) {
  var response = await apiClient.get('/Subscription/Events', { params: { userId, stripeSubscriptionId, billWerkSubscriptionHandle } })
  if (response) {
    return response.data
  }

  throw new Error('Unable to get subscription events')
}
