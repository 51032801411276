import apiClient from '@/services/axios';

export async function put(memberCardId, payload) {
	var response = await apiClient.put('MemberCardCategories?memberCardId=' + memberCardId, payload);
	if (response) {
		return response.data;
	}

	throw new Error('Unable to update MemberCard categories.');
}
