import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { ref, watch } from "vue";
import { useMsal } from "./useMsal";

//import { b2cPolicies } from '@/adb2c/policies'

/*
export type MsalAuthenticationResult = {
    acquireToken: Function;
    result: Ref<AuthenticationResult|null>;
    error: Ref<AuthError|null>;
    inProgress: Ref<boolean>;
}
*/

export function useMsalAuthentication(interactionType, request) {
    const { instance, inProgress } = useMsal();

    const localInProgress = ref(false);
    const result = ref(null);
    const error = ref(null);

    const acquireToken = async (requestOverride) => {
        console.log('acquireToken')

        if (!localInProgress.value) {
            localInProgress.value = true;
            const tokenRequest = requestOverride || request;

            //alert('inProgress: ' + inProgress.value)
            //alert('tokenRequest: ' + JSON.stringify(tokenRequest))

            if (inProgress.value === InteractionStatus.Startup || inProgress.value === InteractionStatus.HandleRedirect) {
                try {
                    const response = await instance.handleRedirectPromise()
                    if (response) {
                        result.value = response;
                        error.value = null;
                        return result;
                    }
                } catch (e) {
                    /*console.log('useMsal ERROR:', e)
                    if (e.errorMessage.includes("AADB2C90118")) {
                        console.log('Reset password')

                        await instance.loginRedirect(b2cPolicies.authorities.resetPassword).catch((e) => {
                            error.value = e;
                            result.value = null;
                        });
                    }*/

                    result.value = null;
                    error.value = e;
                    return result;
                }
            }

            try {
                const response = await instance.acquireTokenSilent(tokenRequest);
                result.value = response;
                error.value = null;
            } catch(e) {
                if (inProgress.value !== InteractionStatus.None) {
                    //alert(e.errorMessage)
                    return result;
                }

                if (interactionType === InteractionType.Popup) {
                    instance.loginPopup(tokenRequest).then((response) => {
                        result.value = response;
                        error.value = null;
                    }).catch((e) => {
                        error.value = e;
                        result.value = null;
                    });
                } else if (interactionType === InteractionType.Redirect) {
                    await instance.loginRedirect(tokenRequest).catch((e) => {
                        error.value = e;
                        result.value = null;
                    });
                }
            }

            localInProgress.value = false;
        }

        return result
    }

    const stopWatcher = watch(inProgress, () => {
        if (!result.value && !error.value) {
            acquireToken();
        } else {
            stopWatcher();
        }
    });

    acquireToken();
    
    return {
        acquireToken,
        result,
        error,
        inProgress: localInProgress
    }
}