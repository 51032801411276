class TokenService {
    getLocalRefreshToken() {
        return localStorage.getItem('rekom.nightpay.refreshToken')
    }
    updateLocalRefreshToken(token) {
        localStorage.setItem('rekom.nightpay.refreshToken', token)
    }
    clearLocalRefreshToken() {
        localStorage.removeItem('rekom.nightpay.refreshToken')
    }

    getLocalAccessToken() {
        return localStorage.getItem('rekom.nightpay.accessToken')
    }
    updateLocalAccessToken(token) {
        localStorage.setItem('rekom.nightpay.accessToken', token)
    }
    clearLocalAccessToken() {
        localStorage.removeItem('rekom.nightpay.accessToken')
    }

    clearTokens() {
        this.clearLocalRefreshToken()
        this.clearLocalAccessToken()
    }
  
    /*
    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }
  
    setUser(user) {
        console.log(JSON.stringify(user));
        localStorage.setItem("user", JSON.stringify(user));
    }
  
    removeUser() {
        localStorage.removeItem("user");
    }*/
  }
  
  export default new TokenService();