import * as apiGetUserAccounts from '@/api/accounts/getUserAccounts'
import * as apiUpdateLastLogin from '@/api/accounts/updateLastLogin'
import * as apiGetCountryAccounts from '@/api/accounts/getCountryAccounts'
import * as apiUpdateAppGamesEnabled from '@/api/accounts/updateAppGamesEnabled'
import * as apiUpdateFreemiumConfiguration from '@/api/accounts/updateFreemiumConfig'

export default {
    namespaced: false,
    state: () => ({
        loading: false,
        userAccounts: [],
        countryAccounts: [],
        activeUserAccount: null,
        activeCountryAccount: null,
    }),
    mutations: {
        gotUserAccounts(state, accounts) {
            console.log('gotUserAccounts:', accounts)
            state.userAccounts = accounts
            if (!localStorage.activeUserAccount && accounts.length > 0) {
                localStorage.activeUserAccount = accounts[0].accountId
            }
        },

        gotCountryAccounts(state, accounts) {
            console.log('gotCountryAccounts:', accounts)
            const accountsWithAccess = accounts.filter(account => state.userAccounts.some(userAccount => userAccount.accountId === account.id));
            state.countryAccounts = accountsWithAccess;
            if (!localStorage.activeCountryAccount && accountsWithAccess.length > 0) {
                localStorage.activeCountryAccount = accountsWithAccess[0].id;
            }
            state.activeCountryAccount = localStorage.activeCountryAccount;
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getUserAccounts({ commit }) {
            commit('isLoading', true)
            console.log('Getting User Accounts');
            const result = await apiGetUserAccounts.get();
            if (result) {
                console.log('Got user accounts:', result)
                commit('gotUserAccounts', result)
            }
            commit('isLoading', false)
        },

        async updateLastLogin({ commit }) {
            commit('isLoading', true)
            console.log('Update last login');
            const result = await apiUpdateLastLogin.get();
            if (result) {
                console.log('Last login updated', result)
                commit('updateLastLogin', result)
            }
            commit('isLoading', false)
        },

        async updateAppGamesMenuEnabled({ commit }, enabled) {
            commit('isLoading', true)
   
            await apiUpdateAppGamesEnabled.put(enabled);
          
            commit('isLoading', false)
        },
        async updateFreemiumConfig({ commit }, enabled) {
            commit('isLoading', true)
   
            await apiUpdateFreemiumConfiguration.put(enabled);
          
            commit('isLoading', false)
        },


        async getCountryAccounts({ commit }) {
            commit('isLoading', true)
            console.log('Getting country Accounts');
            const result = await apiGetCountryAccounts.get();
            if (result) {
                console.log('Got country accounts:', result)
                commit('gotCountryAccounts', result)
            }
            commit('isLoading', false)
        },

        async ensureUserAccounts({ commit, dispatch, getters }) {
            commit('isLoading', true)
            if (getters.userAccounts.length === 0) {
                console.log('ensureUserAccounts')
                await dispatch('getUserAccounts')
            }
            commit('isLoading', false)
        },

        async setActiveUserAccount({ commit, dispatch }, { accountId }) {
            commit('isLoading', true)
            localStorage.activeUserAccount = accountId
            console.log('setActiveUserAccount')
            await dispatch('getUserAccounts')
            commit('isLoading', false)
        },

        setAccountCountry({ state }, { accountId }) {
            localStorage.setItem("activeCountryAccount", accountId)
            state.activeCountryAccount = accountId
            // TODO: Reload
        }
    },
    getters: {
        userAccounts: (state) => state.userAccounts,
        hasAccount: (state) => {
            let activeAccount = state.userAccounts.find(userAccount => userAccount.accountId === localStorage.activeUserAccount)
            if (state.userAccounts.find(a => a.accountId === activeAccount.accountId)) {
                return true;
            }
            return false;
        },
        activeUserAccount: (state) => {
            let activeAccount = state.userAccounts.find(userAccount => userAccount.accountId === localStorage.activeUserAccount)
            if (!activeAccount) {
                console.log('ACCOUNT: Could not find active account.')

                if (state.userAccounts.length === 0) {
                    console.log('ACCOUNT: No accounts assigned.')
                    localStorage.activeUserAccount = null;
                    return null;
                }

                console.log('ACCOUNT: Assigning active account:', state.userAccounts[0].accountId)
                localStorage.activeUserAccount = state.userAccounts[0].accountId
                activeAccount = state.userAccounts[0];
            }
            return activeAccount
        },
        activeAccountCountry: (state) => {
            return state.activeCountryAccount
        },
        activeCountryCode: (state) => {
            return state.countryAccounts?.find(x => x.id == state.activeAccountCountry).countryCode
        },

        countryAccounts: (state) => {
            return state.countryAccounts;
        }
    }
};