<template>
  <Dialog v-model:visible="showUpdatingDialog" :showHeader="false" :closeOnEscape="false" :closable="false" :draggable="false" :style="{width: '50vw'}" :modal="true">
      <Loading :title="'Updating ' + entityName" :full="false" class="mt-5" />
  </Dialog>
  <div class="surface-section pt-0">
    <div class="flex">
      <div style="flex: 1;">
        <div class="font-bold text-900 text-2xl mt-5 mb-2">{{pageTitle}}</div>
        <div class="text-700 mb-5 line-height-3 mb-5">{{pageDescription}}.</div>
      </div>
      <div class="flex justify-content-end align-items-center">
        <Button v-if="hasPermission('np.backoffice.venues.editdelete')" @click="updateEntity" label="Save Venue" icon="pi pi-cloud-upload" />
      </div>
    </div>

    <Loading v-if="loading" :title="'Loading NightPay'" :full="false" />
    <div v-else class="card">
      <Form :fields="fields"  :context="venue" @onChange="entityUpdated" :hasAction="false" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { useRoute } from 'vue-router'

export default {
  inject: ['hasPermission'],
  components: {
  },
  setup() {
    const storeActionUpdateEntity = 'venues/updateVenueNightPayInfo'
    const entityName = 'NightPay Info'
    const pageTitle = 'NightPay'
    const pageDescription = 'NightPay specific Venue information.'

    const loading = ref(true)
    const updating = ref(false)

    const store = useStore()
    const toast = useToast()
    const route = useRoute()

    const venue = ref()
    const venueId = route.params.venueId

    const showUpdatingDialog = ref(false)

    const rebateModel = ref([
			{ title: 'Unknown Rebate Model', id: 'Unknown' },
			{ title: 'Only All Night', id: 'AlwaysReduced' },
			{ title: 'Reduced discount after 20:00', id: 'After20' },
			{ title: 'Reduced discount after 22:00', id: 'After22' },
			{ title: 'Reduced discount after 24:00', id: 'After24' },
      { title: 'Custom Rebate Model', id: 'Custom' }

		]);

    const memberCards = ref([])

    const fields = ref([
        {
          id: 'active',
          type: 'switch',
          title: 'Active',
          horizontal: true
        },
        {
          id: 'isOnline',
          type: 'switch',
          title: 'Online service',
          horizontal: true
        },
        {
					id: 'rebateCutOffModel',
					type: 'dropdown',
					title: 'Rebate model',
          horizontal: false,
 
          config: {
						options: rebateModel,
						optionLabel: 'title',
            optionValue: 'id',
            placeholder: 'Please select Venue Rebate Model',
					},
				},
        {
          id: 'benefitTagline',
          type: 'text-input',
          title: 'Benefit tagline',
          help: '% discount.'
        },
        {
          id: 'benefitDescription',
          type: 'text-input',
          title: 'Benefit description',
          help: 'Description of deal aka \'what you get\'.'
        },
        {
          id: 'standardMemberCardId',
          type: 'dropdown',
          title: 'Standard member card',
          help: 'The venues standard member card',
          horizontal: false,
                   filter: true,
          config: {
						options: memberCards,
						optionLabel: 'title',
            optionValue: 'id',
            placeholder: 'Please select Member card',
					},
        },
        {
          id: 'externalLinkTitle',
          type: 'text-input',
          title: 'External Link title',
          help: 'Display text for button (if deal requires this).'
        },
        {
          id: 'externalLinkUrl',
          type: 'text-input',
          title: 'External Link URL',
          help: 'Destination link (if deal requires this).'
        },
        {
          id: 'tagsVenue',
          type: 'switch',
          title: 'Venue',
          horizontal: true
        },
        {
          id: 'tagsLimitedVenue',
          type: 'switch',
          title: 'Limited Venue',
          horizontal: true
        },
        {
          id: 'tagsDining',
          type: 'switch',
          title: 'Dining',
          horizontal: true
        },
        {
          id: 'tagsFashion',
          type: 'switch',
          title: 'Fashion',
          horizontal: true
        },
        {
          id: 'tagsHealth',
          type: 'switch',
          title: 'Health',
          horizontal: true
        },
        {
          id: 'tagsOther',
          type: 'switch',
          title: 'Other',
          horizontal: true
        }
    ])


    function entityUpdated(formContext) {
      venue.value = formContext

      var index = fields.value.findIndex(x => x.id == "customRebateCutOffHour")

      if(formContext.rebateCutOffModel == "Custom" && index <= 0){

          formContext.customRebateCutOffHour = 22
          venue.value = formContext

          index = fields.value.findIndex(x => x.id == "rebateCutOffModel")
          fields.value.splice(index + 1, 0,  {
          id: 'customRebateCutOffHour',
          type: 'number-input',
          title: 'Custom Rebate cut-off hour',
          max: 24,
          min: 1,
          suffix: ":00",
          help: 'The hour of which the membership discount will be cut off after',
          horizontal: false
        })

      } else if( formContext.rebateCutOffModel != "Custom") {
        if(index >= 0)
        {
           fields.value.splice(index, 1)
           formContext.customRebateCutOffHour = null
           venue.value = formContext
        }
       
      }

    }

    async function updateEntity() {
      showUpdatingDialog.value = true


      try {
        await store.dispatch(storeActionUpdateEntity, { venueId: venueId, nightPayInfo: venue.value })

        toast.add({severity: 'success', summary: 'Success', detail: entityName + ' updated', life: 3000});

        loading.value = true
      } catch (err) {
        toast.add({severity:'error', summary: 'Error', detail: 'Could not update ' + entityName + ': ' + err, life: 3000});
      }

      loading.value = false
      showUpdatingDialog.value = false
    }

    async function loadRequiredData() {
      loading.value = true

      await store.dispatch('venues/ensureVenue', { venueId: venueId });

      const loadedVenue = store.getters['venues/venue'](venueId)
      console.log(loadedVenue);
      venue.value = JSON.parse(JSON.stringify(loadedVenue))
      venue.value.standardMemberCardId = venue.value.standardMemberCardId?.id

      await store.dispatch('memberCards/ensureMemberCardListItems');

      const loadedMemberCards = store.getters['memberCards/memberCardListItems']

      memberCards.value = loadedMemberCards;

    if(venue.value.rebateCutOffModel == "Custom"){
          var index = fields.value.findIndex(x => x.id == "rebateCutOffModel")
          fields.value.splice(index + 1, 0,  {
          id: 'customRebateCutOffHour',
          type: 'number-input',
          title: 'Custom Rebate cut-off hour',
          max: 24,
          min: 1,
          suffix: ":00",
          help: 'The hour of which the membership discount will be cut off after',
          horizontal: false
        })
      }

      loading.value = false
    }

    onMounted(async () => {
      await loadRequiredData()
    })

    return {
      pageTitle,
      pageDescription,
      loading,
      updating,
      
      entityUpdated,
      updateEntity,

      venue,

      fields,

      entityName,

      showUpdatingDialog
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vue-map-container {
  height: 400px;
}
</style>