<template>
	<div v-if="hasPermission('np.backoffice.team.show')">
		<overlay class="invite-users-popup" animate="zoom-in" :opened="inviteUsersPopupOpened" :closeable="true"
			:visible="inviteUsersPopupVisible" @closed="closeInvitePopup()">
			<div v-if="hasPermission('np.backoffice.administration.manage')">
				<h4>Invite new users</h4>
				<p style="font-size: 14px">Enter the email addresses of the users you'd like to invite, and choose the role
					they should have.</p>

				<hr />

				<smart-tagz editable inputPlaceholder="jane@example.com, etc." :on-changed="onSelectedEmailsChanged"
					:allowPaste="{ delimiter: ',' }" :allowDuplicates="false" :theme="{
						primary: '#17a2b8',
						background: '#f4f7f8',
						tagTextColor: '#fff',
					}" />

				<hr />

				<div v-for="role in roles" :key="role.value" class="form-check">
					<div class="col">
						<input class="form-check-input" @change="onChangeRole(role.value)" name="role" :value="role.value"
							:checked="selectedRole == role.value" type="radio" />
						<label class="form-check-label" :for="role.value">
							{{ role.title }}
						</label>
					</div>
					<div class="col description">
						<span>
							{{ role.description }}
						</span>
					</div>
				</div>

				<button @click="closeInvitePopup" type="button" class="btn btn-outline-primary">Cancel</button>
				<button @click="sendInvites" type="button" class="ml-2 btn btn-primary">
					<div v-if="loading">
						<div class="spinner-grow" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div v-else>Invite</div>
				</button>
			</div>
		</overlay>

		<overlay class="remove-user-popup" animate="zoom-in" :opened="removeUserPopupOpened" :closeable="true"
			:visible="removeUserPopupVisible" @closed="closeRemoveUserPopup()">
			<div>
				<h5>Remove user from team</h5>
				<p style="font-size: 14px">
					This invitation for <strong>{{ selectedTeamMember.email }}</strong> will be deleted, and they will no
					longer be able to sign up for NightPay to share this account.
				</p>

				<button @click="closeRemoveUserPopup" type="button" class="btn btn-outline-primary">Cancel</button>
				<button @click="removeUser" type="button" class="ml-2 btn btn-danger">
					<div v-if="loading">
						<div class="spinner-grow" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div v-else>Remove user</div>
				</button>
			</div>
		</overlay>

		<div class="surface-section p-6 pt-0">
			<div class="flex">
				<div style="flex: 1">
					<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
					<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
				</div>
				<div class="flex justify-content-end align-items-center">
					<Button v-if="canCreateNew" @click="openInvitePopup()" label="New member" icon="pi pi-plus-circle" />
					<Button v-if="canCreateNew" @click="createNewSidebarVisible = true" label="Export"
						icon="pi pi-plus-circle" class="ml-2 p-button-secondary" />
				</div>
			</div>

			<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
			<div v-else class="card">
				<DataTable :value="teamMembers" dataKey="id" @row-click="onRowClicked" class="p-datatable-lg"
					:rowHover="true" responsiveLayout="scroll">
					<template #empty> No {{ pageTitle }} found. </template>
					<template #loading> Loading {{ pageTitle }}. Please wait. </template>
					<Column field="email" header="Team member"></Column>
					<Column field="role" header="Role"></Column>
					<Column field="global" header="Global"></Column>
					<Column header="Last Login">
						<template #body="{ data }">

							<!-- <Chip v-if="!data.userId" class="chip-active">Invitation sent</Chip>
							<Chip v-else-if="data.lastLogin" label="Active" class="chip-enabled">{{ data.lastLogin }}</Chip> -->

							<span v-if="!data.userId"><i>Invitation sent</i></span>
							<span v-if="data.lastLogin">{{ formatDate(data.lastLogin) }}</span>
						</template>
					</Column>
					<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="{ data }">
							<!-- <Button @click="assignPerks(data)" type="button" icon="fa fa-trash"></Button> -->
							<Button v-if="hasPermission('np.backoffice.administration.manage')"
								@click="openRemoveUserPopup(data)" type="button" icon="fa fa-trash"></Button>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>


	</div>
</template>

<script>
import { ref, computed, onMounted, inject } from 'vue';
import { useMsal } from '@/plugins/useMsal';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { SmartTagz } from 'smart-tagz';
import 'smart-tagz/dist/smart-tagz.css';
import Overlay from '@/components/Overlay';

export default {
	inject: ['hasPermission'],
	components: {
		SmartTagz,
		Overlay,
	},
	setup() {
		const loading = ref(true);
		const teamMembers = ref([]);
		const pageTitle = 'Team';
		const pageDescription = 'Add or remove members on your team';

		const useFormatDate = inject('useFormatDate');

		const { accounts } = useMsal();
		const user = computed(() => {
			if (accounts.value.length > 0) {
				const decoded = accounts.value[0].idTokenClaims;

				return {
					name: decoded.name,
					firstName: decoded.given_name,
					lastName: decoded.family_name,
					role: decoded.extension_UserRole,
					type: decoded.extension_UserType,
					picture: 'https://rekomlifestorageprod.blob.core.windows.net/user-uploads/avatars/' + decoded.oid + '.jpg',
				};
			}

			return {};
		});

		const removeUserPopupOpened = ref(false);
		const removeUserPopupVisible = ref(false);
		const selectedTeamMember = ref(null);

		const inviteUsersPopupOpened = ref(false);
		const inviteUsersPopupVisible = ref(false);

		const canCreateNew = ref(true);

		const selectedRole = ref('administrator');
		const selectedEmails = ref([]);

		const roles = ref([
			{
				value: 'administrator',
				title: 'Administrator',
				description: 'Best for business owners and company administrators',
			},
			{
				value: 'developer',
				title: 'Developer',
				description: 'Best for developers or people primarily using the NightPay API',
			},
			{
				value: 'analyst',
				title: 'Analyst',
				description: "Best for people who need full access to NightPay data, but don't need to update business settings",
			},
			{
				value: 'supportspecialist',
				title: 'Support Specialist',
				description: 'Best for employees who regularly handle support questions and issues',
			},
			{
				value: 'viewonly',
				title: 'View only',
				description: "Best for people who need to view NightPay data, but don't need to make any updates",
			},
		]);

		const store = useStore();

		const toast = useToast();

		async function loadRequiredData() {
			loading.value = true;

			try {
				await store.dispatch('teamMembers/getTeamMemberListItems');
			} catch (err) {
				toast.error('Could not load Team Members: ' + err);
			}

			const loadedTeamMembers = store.getters['teamMembers/teamMemberListItems'];
			teamMembers.value = loadedTeamMembers;

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			pageTitle,
			pageDescription,

			loading,
			canCreateNew,

			teamMembers,

			user,

			selectedTeamMember,
			removeUserPopupOpened,
			removeUserPopupVisible,

			inviteUsersPopupOpened,
			inviteUsersPopupVisible,
			selectedRole,
			roles,

			selectedEmails,

			toast,
			formatDate: useFormatDate().formatDateTime,
		};
	},
	methods: {
		openRemoveUserPopup(teamMember) {
			this.selectedTeamMember = teamMember;

			this.removeUserPopupOpened = true;
			this.removeUserPopupVisible = true;
		},
		closeRemoveUserPopup() {
			console.log('closeRemoveUserPopup');
			this.removeUserPopupOpened = false;
			this.removeUserPopupVisible = false;
		},

		async removeUser() {
			await this.$store.dispatch('teamMembers/removeTeamMember', {
				id: this.selectedTeamMember.teamMemberId,
			});

			this.closeRemoveUserPopup();
		},

		openInvitePopup() {
			this.inviteUsersPopupOpened = true;
			this.inviteUsersPopupVisible = true;
		},
		closeInvitePopup() {
			console.log('closeInvitePopup');
			this.inviteUsersPopupOpened = false;
			this.inviteUsersPopupVisible = false;
		},

		onChangeRole(role) {
			this.selectedRole = role;
		},

		onSelectedEmailsChanged(tags) {
			this.selectedEmails = tags;
		},

		async sendInvites() {
			try {
				await this.$store.dispatch('teamMembers/inviteTeamMembers', {
					emails: this.selectedEmails,
					role: this.selectedRole,
				});
			} catch (err) {
				this.toast.error('Could not send invites: ' + err);
			}

			this.closeInvitePopup();
			this.$router.go();
		},
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-check {
	display: flex;
}

.col {
	flex: 1;
}

.col.description {
	flex: 3;
}

.form-check-label {
	font-weight: 500;
	color: #000;
}

.form-check label,
.form-check span {
	font-size: 13px;
}
</style>

<style>
.tag-name[data-v-bb7ceecc] {
	font-size: 0.8rem;
}

.tag-container[data-v-bb7ceecc] {
	filter: none;
}

table.links {
	width: 100%;
}

table.links td {
	vertical-align: top;
}

table.links a {
	display: block;
}

.invite-users-popup .v-overlay-main {
	max-width: 700px;
}

.remove-user-popup .v-overlay-main {
	max-width: 700px;
}
</style>
