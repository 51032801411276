import apiClient from '@/services/axios'

export async function del(id) {
  var response = await apiClient.delete('/promoCodes/' + id)
  if (response) {
    return response.data
  }

  throw new Error('Unable to delete Promo Code.')
}
