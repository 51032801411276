import * as apiGetAmbassadorGroups from '@/api/ambassadorGroups/getAmbassadorGroups'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        ambassadorGroupListItems: []
    }),
    mutations: {
        gotAmbassadorGroupListItems(state, ambassadorGroups) {
            state.ambassadorGroupListItems = ambassadorGroups
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getAmbassadorGroupListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting AmbassadorGroup List Items');
        
            const result = await apiGetAmbassadorGroups.get();

            if (result) {
                commit('gotAmbassadorGroupListItems', result)
            }
        
            commit('isLoading', false)
        },

        async ensureAmbassadorGroupListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.ambassadorGroupListItems.length === 0) {
                await dispatch('getAmbassadorGroupListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        ambassadorGroupListItems: (state) => state.ambassadorGroupListItems,
        ambassadorGroup: (state) => (ambassadorGroupId) => {
            return  _.find(state.ambassadorGroupListItems, function(ambassadorGroup) { return ambassadorGroup.id === ambassadorGroupId; });
        }
    }
};