import apiClient from '@/services/axios'

export async function put(appGameId, payload) {
  var response = await apiClient.put('/appGame/' + appGameId, payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update App Game.')
}
