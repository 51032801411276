<template>
	<div class="surface-section p-6 pt-0" v-if="hasPermission('np.backoffice.users.show')">

		<div class="flex ">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<!-- <div class="flex justify-content-end align-items-center"> -->
			<!-- <span class="p-input-icon-left">
					<i class="pi pi-search" />
					<InputText type="text" v-model="value1" placeholder="Search" />
				</span> -->
			<!-- <Button  @click="createNewSidebarVisible = true" label="Bulk assign perks" icon="fa fa-users" /> -->
			<!-- </div> -->
		</div>
	
		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
		<div v-show="!loading" class="card ">
			<DataTable :value="entities" dataKey="id" lazy paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
				:rows="25" :rowsPerPageOptions="[25, 50, 100]" :totalRecords="totalRecords" 
				@page="onPage($event)"  @row-click="onRowClicked"
				class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll" v-model:filters="filters1"
				filterDisplay="menu" :globalFilterFields="['firstName', 'lastName', 'email']">
				<template #header >
					<div class="flex gap-3">
						<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined"
							@click="clearFilters()" />
						<span class="p-input-icon-left ml-auto">
							<i class="pi  pi-id-card" />
							<InputText v-model="filterCard['global'].value" @keydown.enter="filterCallbackCard()" v-tooltip.top="{ value: `Min. 4 characters`, escape: true }"
							placeholder="UserCard Search" />
						</span>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" @keydown.enter="filterCallback()" v-tooltip.top="{ value: `Min. 4 characters`, escape: true }"
							placeholder="Keyword Search" />
						</span>
					</div>
				</template>
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
				<Column header="Name">
					<template #body="{ data }"> {{ data.firstName }} {{ data.lastName }} </template>
				</Column>
				<Column field="email" header="Email"></Column>
				<Column header="Joined">
					<template #body="{ data }" >
						{{ formatDate(data.created) }}
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'users/getUsersPaging';
		const storeGetterGetEntities = 'users/usersPagingObject';
		const entityName = 'User';
		const pageTitle = 'Users';
		const pageDescription = 'Here you can manage your Users';

		const loading = ref(true);
	
		const totalRecords = ref(0);
		const pagingRequest = ref({
			pageSize: 25,
			filter: null,
			page: 0,
			totalCount: null,
			tokens: {}
		})

		const pagingResponse = ref({
			hasMore: false,
			results: null,
			tokens: null,
			totalCount: null,
			totalPages: null
		})

		const filters1 = ref({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			firstName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			lastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
		});

		const filterCard = ref({
			global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		});

		const useFormatDate = inject('useFormatDate');

		const store = useStore();
		const router = useRouter();
		const toast = useToast();

		const entities = ref([]);

		const clearFilters = () => {
			
			const hasFilters = filters1.value.global?.value?.length > 0 || filterCard.value.global?.value?.length > 0
			
			initFilters();

			if(hasFilters){
				filterCallback();
				filterCallbackCard();
			}
			

		};
		const initFilters = () => {
			filters1.value = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				firstName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				lastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			};
			filterCard.value = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			}
		};

		const onPage = (event) => {
			var val = event;
			console.log("onPage", val)
			pagingRequest.value.page = val.page;
			if (pagingRequest.value.pageSize != val.rows) {
				pagingRequest.value.page = 1;
				pagingRequest.value.pageSize = val.rows;
				pagingRequest.value.totalCount = null;
				pagingRequest.value.tokens = null;
			}

			// lazyParams.value = event;
			loadRequiredData();
		};



		function onRowClicked(payload) {
			router.push({ name: 'edit-user', params: { userId: payload.data.id } });
		}

		async function filterCallback() {
			// console.log("onFilter - pagingRequest", filters1.value.global.value);
			// console.log("onFilter", filters1.value)
			if(filters1.value.global.value?.length <= 3 && filters1.value.global.value?.length != 0 ) return;
			pagingRequest.value.filter = filters1.value.global.value;
			pagingRequest.value.totalCount = null;
			pagingRequest.value.tokens = null;
		
			loadRequiredData();
		}

		async function filterCallbackCard(){
			
			if(filterCard.value.global.value?.length <= 3 && filterCard.value.global.value?.length != 0 ) return;
			pagingRequest.value.cardFilter = filterCard.value.global.value;
			pagingRequest.value.totalCount = null;
			pagingRequest.value.tokens = null;

			loadRequiredData();

		}

		async function reloadEntities() {
			try {
				// console.log("reloadEntities - pagingRequest", pagingRequest.value);
				await store.dispatch(storeActionGetEntities, pagingRequest.value);
				pagingResponse.value = store.getters[storeGetterGetEntities];

				// console.log("reloadEntities - pagingResponse", pagingResponse.value);
				totalRecords.value = pagingResponse.value.totalCount;
				pagingRequest.value.totalCount = pagingResponse.value.totalCount;
				pagingRequest.value.tokens = pagingResponse.value.tokens;


				// const loadedEntities = pagingObject.results
				entities.value = pagingResponse.value.results;
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}

		async function loadRequiredData() {
			loading.value = true;

			await reloadEntities();

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		function getProperty(source, field) {
			return this[source][field];
		}

		function updateProperty(source, field, payload) {
			this[source][field] = payload;
		}

		return {
			getProperty,
			updateProperty,
			clearFilters,
			onPage,

			entityName,
			pageTitle,
			pageDescription,
			loading,
			filters1,
			filterCard,

			entities,
			totalRecords,

			onRowClicked,
			filterCallback,
			filterCallbackCard,

			formatDate: useFormatDate().formatDateTimeFull,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
