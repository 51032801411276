<template>

<Sidebar v-model:visible="createNewSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
    <div >

        <div class="flex flex-column mb-3">
                 <h3 class="mb-1">Create new offer</h3>
        </div>           
        <Form :fields="offerFormFields" :context="newEntity" :actionLabel="'Add to '" @onChange="onFormChange" @onAction="addOffer" :actionIcon="'pi pi-plus-circle'" />
    </div>
</Sidebar>


<Sidebar v-model:visible="editOfferSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
    <div >

        <div class="flex flex-column mb-3">
                 <h3 class="mb-1">Update offer</h3>
                 
        </div>           
        <Form :fields="offerFormFields" :context="editEntity" :actionLabel="'Update offer'" @onChange="onFormChange" @onAction="updateOffer" :actionIcon="'pi pi-plus-circle'" />
    </div>
</Sidebar>


<div>
     <div class="px-6 w-full flex flex-wrap ">
                <div>
                    <p class="font-bold text-900 text-lg m-0">
                       {{title}}
                    </p>
                    <p class="m-0 text-sm text-700 mb-3" >
                       {{description}}
                    </p>     
                </div>
                <div class="ml-auto align-items-center w-full md:w-fit md:flex">
                 <Button
                class="h-min bg-green-400 border-green-400"
                :label="'Save' "
                icon="pi pi-cloud-upload"
                @click="onSave"
                />  
                <Button
                class="ml-2 h-min "
                :label="'Add offer' "
                icon="pi pi-plus-circle"
                @click="showAddNewSideBar"
                />     
                </div>
        </div>
              
        <div class="w-full flex mt-6">
            <div class="card mx-auto ">
               <OfferList :offers="offers" @onEditOffer="showEditSideBar" @onRemoveOffer="removeOffer" @onMoveOffer="updateOrderIndex" />
            </div>
        </div>
</div>


</template>


<script>
import { onMounted, ref } from 'vue'
import OfferList from "./OfferList.vue"
import {discountOnMembershipOfferFormFields, freeMembershipOfferFormFields, getPerkOfferFormFields} from "../formValues"
import { useStore } from 'vuex';
import { uploadFile} from '@/services/fileUploadService'
import { useToast } from 'primevue/usetoast';

export default {
    components:{
        OfferList
    },
    props: ['catchOffers', 'title', 'description'],
    emits: ['onUpdate'],
    setup(props, {emit}) {

        const store = useStore();
        const toast = useToast()
        const actionGetPerks = "perks/getPerkListItems";
        const getterGetPerks = "perks/perkListItems";

        const createNewSidebarVisible = ref(false)
        const editOfferSidebarVisible = ref(false)

        const perks = ref([])
        const offerFormFields = ref([])
        const offers = ref(props.catchOffers ?? [])

        const newEntity = ref({
            title: "",
            terms: "",
            amount: 1,
            iconUrl: undefined,
            offerType: "Perk",
            perkId: undefined,
            orderIndex: 0,
            newEntity: true
        })

        const editEntity = ref({
            title: "",
            terms: "",
            amount: 1,
            iconUrl: undefined,
            offerType: "Perk",
            perkId: undefined,
            orderIndex: 0,
            newEntity: false

        })





        function showAddNewSideBar(){
            createNewSidebarVisible.value = true
        }

        function showEditSideBar(offer){
            onFormChange(offer)
            editEntity.value = {...offer}
            editOfferSidebarVisible.value = true
        }

        function updateOrderIndex(indexes){
    

            if(offers.value.length == 1 || indexes.oldIndex == 0 && indexes.newIndex <= indexes.oldIndex || indexes.newIndex == offers.value.length)
                return;

            var offer = offers.value.at(indexes.oldIndex)
            offer.orderIndex = indexes.newIndex;
            offers.value.splice(indexes.oldIndex, 1)
            offers.value.splice(indexes.newIndex, 0, offer)
        }

        function removeOffer(index){
            offers.value.splice(index, 1);
        }

        function onFormChange(value){
            

            if(value.offerType == "FreeMembershipMonth"){
                offerFormFields.value = freeMembershipOfferFormFields;
                editEntity.value.perkId = undefined;
                newEntity.value.perkId = undefined;
                editEntity.value.terms = "(Gældende fra {{next_invoice_date}})"
                newEntity.value.terms = "(Gældende fra {{next_invoice_date}})"
            }

            if(!value.newEntity && editEntity.value.offerType == value.offerType)
                return
            
            if(value.offerType == "DiscountOnMembership"){
                offerFormFields.value = discountOnMembershipOfferFormFields;
                editEntity.value.perkId = undefined
                newEntity.value.perkId = undefined;
                editEntity.value.terms = ""
                newEntity.value.terms = ""
            }
            if(value.offerType == "Perk"){
                offerFormFields.value = getPerkOfferFormFields(perks.value);
                editEntity.value.terms = ""
                newEntity.value.terms = ""
            }

               editEntity.value.offerType = value.offerType
               newEntity.value.offerType = value.offerType
        }

        async function addOffer(offer){

           var valid = validateFormInputs(offer)
           if(!valid)
            return;

            var url = await handleIconUpload(offer.iconUrl)
            offer.iconUrl = url;
            offer.orderIndex = offers.value?.length
            offers.value.push(offer)
            createNewSidebarVisible.value = false
        }

        async function updateOffer(updatedOffer){
           var valid = validateFormInputs(updatedOffer, true)
           if(!valid)
            return;
           
           var offer = offers.value.at(updatedOffer.orderIndex)

            var url = offer.iconUrl
            if(updatedOffer.iconUrl != url){
               url = await handleIconUpload(updatedOffer.iconUrl)
            }
            updatedOffer.iconUrl = url;

            offers.value.splice(updatedOffer.orderIndex, 1, updatedOffer)
            editOfferSidebarVisible.value = false
        }

  
        async function handleIconUpload(image){
            var logoFile = await uploadFile(image, "card-icons", "all", store)
            return logoFile.url
       }

       async function getPerks(){
             await store.dispatch(actionGetPerks)
             perks.value = store.getters[getterGetPerks];
            offerFormFields.value = getPerkOfferFormFields(perks.value)

       }
        function validateFormInputs(offer, isUpdate = false){


          if(offer.title == '' || offer.amount <= 0 || offer.iconUrl == null || offer.iconUrl == ''){
                toast.add({ severity: 'error', summary: 'Error', detail: 'Please fill out all values in form', life: 3000 });
                return false;
          }

          if(offer.offerType == "Perk" && (offer.perkId == null || offer.perkId == '') ){
             toast.add({ severity: 'error', summary: 'Error', detail: 'Please fill out all values in form', life: 3000 });
             return false
          }

          var freeMembershipMonthOffer = offers.value?.find(x => x.offerType == "FreeMembershipMonth")
          var discountOnMembershipOffer = offers.value?.find(x => x.offerType == "DiscountOnMembership")

          if(!isUpdate && (offer.offerType != 'Perk' && freeMembershipMonthOffer != null || offer.offerType != 'Perk' && discountOnMembershipOffer != null) ){
                toast.add({ severity: 'error', summary: 'Error', detail: 'Not possible to add 2 membership offers', life: 3000 });
                return false
          }

          return true;
        }

        function onSave(){
            emit("onUpdate", offers.value)
        }


        onMounted(()=>{
             getPerks();
        })




        return {
            newEntity,
            editEntity,
            offerFormFields,
            createNewSidebarVisible,
            editOfferSidebarVisible,
            perks,
            offers,

            showAddNewSideBar,
            showEditSideBar,
            onFormChange,
            updateOrderIndex,
            removeOffer,
            addOffer,
            updateOffer,
            onSave
        }
    }
}
</script>


<style scoped>

.card{
min-height: 28rem;
border-radius: 4px;
width:440px;
background-image: linear-gradient(to right top, #081654, #081654, #122886, #122886, #122886);
}
.htmleditor {
    background: #081654;
}
</style>