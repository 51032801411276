import apiClient from '@/services/axios'

export async function put(memberCardId) { 
  var response = await apiClient.put('/MemberCards/' + memberCardId + "/qr" )
  if (response) {
    return response.data
  }

  throw new Error('Unable to update Member Card qr url.')
}
