<template>
	<div>
		<div class="page">
			<div v-if="showIntro" class="grid grid-nogutter surface-0 p-6 pt-0 text-800 overflow-auto">
				<div class="w-full flex border-bottom-1 surface-border">
					<div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center">
						<section>
							<span class="block text-6xl font-bold mb-1" style="color: #000; line-height: normal">Welcome to NightPay</span>
							<div class="text-6xl text-primary font-bold mb-3" style="color: #5e81ac">Administration</div>
							<p class="mt-0 mb-5 text-700 line-height-3" style="color: #2e3440 !important; line-height: 1.5rem !important; font-weight: 500">
								This portal provides you with all the features needed to operate NightPay.
							</p>

							<Button label="See intro" @click="selectCaseTypeDialogVisible = true" class="mr-2" icon="pi pi-play"></Button>
							<Button label="Read more" class="p-button-outlined" icon="pi pi-check"></Button>
						</section>
					</div>
					<div class="col-12 md:col-6 overflow-hidden">
						<img src="@/assets/images/hero.jpg" alt="Image" class="md:ml-auto block md:h-full " style="clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%)" />
					</div>
				</div>

				<div class="font-bold text-900 text-2xl mt-6">Latest activities</div>

				<div class="col-12 surface-card shadow-2 border-round p-4 mt-4">
					<ul class="list-none p-0 m-0">
						<li class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
							<div class="flex align-items-start mr-0 lg:mr-5">
								<img src="https://www.primefaces.org/primeblocks-vue/images/blocks/avatars/circle-big/avatar-f-1.png" class="mr-3 w-3rem h-3rem" />
								<div>
									<span class="text-900 font-medium block mb-2">Jane Cooper</span>
									<div class="text-700 mb-2">responded to an issue.</div>
									<a class="text-blue-500 cursor-pointer">
										<i class="pi pi-github text-sm mr-2"></i>
										<span>Issue #1185</span>
									</a>
								</div>
							</div>
							<span class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">14 mins ago</span>
						</li>
						<li class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
							<div class="flex align-items-start mr-0 lg:mr-5">
								<img src="https://www.primefaces.org/primeblocks-vue/images/blocks/avatars/circle-big/avatar-m-1.png" class="mr-3 w-3rem h-3rem" />
								<div>
									<span class="text-900 font-medium block mb-2">Robert Fox</span>
									<div class="text-700">changed team size from <span class="text-900">5</span> to <span class="text-900">6</span>.</div>
								</div>
							</div>
							<span class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">20 mins ago</span>
						</li>
						<li class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
							<div class="flex align-items-start mr-0 lg:mr-5">
								<img src="https://www.primefaces.org/primeblocks-vue/images/blocks/avatars/circle-big/avatar-f-2.png" class="mr-3 w-3rem h-3rem" />
								<div>
									<span class="text-900 font-medium block mb-2">Kristin Watson Cooper</span>
									<div class="text-700 mb-2">created a Q4 presentation to an issue.</div>
									<a class="text-blue-500 cursor-pointer">
										<i class="pi pi-file-pdf text-sm mr-2"></i>
										<span>q4_presentation.pdf</span>
									</a>
								</div>
							</div>
							<span class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">38 mins ago</span>
						</li>
						<li class="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
							<div class="flex align-items-start mr-0 lg:mr-5">
								<img src="https://www.primefaces.org/primeblocks-vue/images/blocks/avatars/circle-big/avatar-f-3.png" class="mr-3 w-3rem h-3rem" />
								<div>
									<span class="text-900 font-medium block mb-2">Annette Black</span>
									<div class="text-700">added <span class="text-900">Nico Greenberg</span> to <span class="text-blue-500">Watchlist Tier-1</span>.</div>
								</div>
							</div>
							<span class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">1 day ago</span>
						</li>
						<li class="py-3 flex md:align-items-start md:justify-content-between flex-column md:flex-row">
							<div class="flex align-items-start mr-0 lg:mr-5">
								<img src="https://www.primefaces.org/primeblocks-vue/images/blocks/avatars/circle-big/avatar-m-2.png" class="mr-3 w-3rem h-3rem" />
								<div>
									<span class="text-900 font-medium block mb-2">Floyd Miles</span>
									<div class="text-700">has refunded a blue t-shirt for <span v-if="hasPermission('np.backoffice.dashboard.show')" class="text-blue-500">79$</span>.</div>
								</div>
							</div>
							<span class="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">4 days ago</span>
						</li>
					</ul>
				</div>
			</div>

			<!--
      <div v-else>
        <div class="row">
          <div class="col">
            <div class="card hero">
              <div class="card-body extra-padding" style="display: flex;flex-direction: row;justify-content: space-between;">
                <div>
                  <h3 v-if="$auth.isAuthenticated" class="welcome-message">Velkommen, {{ $auth.user.value.given_name }}</h3>
                  <h1 class="header-title">Der er nyt i sagen.</h1>
                </div>

                <div style="display: flex;flex-direction: row;align-items: center;">
                  <a href="#" class="btn btn-text text-white"><i class="far fa-comment-dots"></i> Giv feedback</a>
                  <a href="#" class="btn btn-primary"><i class="fas fa-bolt"></i> Hurtig søg</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="section-header mt-5">Ting du skal tage stilling til</h2>
        <div class="row">
          <div class="col-8">
            <div class="primary-action-cards-container">
              <div class="card">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Cras justo odio</li>
                  <li class="list-group-item">Dapibus ac facilisis in</li>
                  <li class="list-group-item">Vestibulum at eros</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="secondary-action-cards-container">
              <div class="card">
                <div class="card-body">
                  <i class="fas fa-calendar-alt"></i> <span style="margin-left:10px">Kommende aktiviteter</span>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <i class="fas fa-envelope"></i> <span style="margin-left:10px">Indbakke (3)</span>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <i class="fas fa-star"></i> <span style="margin-left:10px">Favoritter</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="section-header mt-5">Personer tilknyttet sagen (3)</h2>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body connection">
                <div class="card with-border">
                  <div class="card-body connection-link">
                    <div class="horizontal">
                      <h1><span class="badge badge-info">D</span></h1>
                      <div class="badge-label-container">
                        <h3 class="badge-label">Mikkel Hempel</h3>
                        <p class="badge-label">Bobestyrer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body connection">
                <div class="card with-border">
                  <div class="card-body connection-link">
                    <div class="horizontal">
                      <h1><span class="badge badge-success">P</span></h1>
                      <div class="badge-label-container">
                        <h3 class="badge-label">Peter Roel Jørgensen</h3>
                        <p class="badge-label">Pårørende</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card" style="padding-bottom:25px;">
              <div class="card-body connection">
                <div class="card with-border">
                  <div class="card-body connection-link">
                    <div class="horizontal">
                      <h1><span class="badge badge-dark">H</span></h1>
                      <div class="badge-label-container">
                        <h3 class="badge-label">Henrik Bechmann</h3>
                        <p class="badge-label">Bankrådgiver</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
/*
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useToast } from "vue-toastification";
*/

export default {
	inject: ['hasPermission'],
	name: 'Dashboard',
	components: {},
	props: {},
	setup(/*props*/) {
		const loading = ref(true);

		/*
    const store = useStore()
    const route = useRoute()
    const toast = useToast()
    */

		async function loadRequiredData() {
			loading.value = true;

			loading.value = false;
		}

		loadRequiredData();

		return {
			loading,

			showIntro: true,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.text-primary {
	color: #5e81ac !important;
}

.card.hero {
	background: rgb(4, 88, 204);
	background: linear-gradient(90deg, rgba(4, 88, 204, 1) 0%, rgba(4, 100, 234, 1) 100%);
	border-radius: 10px;
}
.card.hero h3 {
	color: white;
}
.card.hero h1 {
	color: white;
}

.workspace-link:hover {
	cursor: pointer;
	background-color: ghostwhite;
}

.card {
	border: none;
}

.welcome-message {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 0.75em;
}

.header-title {
	font-size: 32px;
	font-weight: 500;
	margin-top: 0px;
	margin-bottom: 0px;
}

.card-body.extra-padding {
	padding: 2.25rem;
}

.primary-action-cards-container {
	display: flex;
	height: 280px;

	border: 2px solid #edeff3;
	border-radius: 5px;
}

.primary-action-cards-container .card {
	width: 100%;
}

.secondary-action-cards-container {
	display: flex;
	flex-direction: column;
	height: 280px;
	justify-content: space-between;
}

.secondary-action-cards-container .card {
	background-color: #677383;
	color: white;
}

.secondary-action-cards-container .card .card-body {
	padding: 1.75rem;
}

.card.with-border {
	border: 1px solid #edeff3;
}

.horizontal {
	display: flex;
	justify-content: left;
	align-items: center;
}

.badge-label {
	font-size: 14px;
	font-weight: 500;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
}

.badge-label-container {
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
}

.badge-label {
	margin: 0;
}

h3.badge-label {
	font-size: 18px;
	font-weight: 500;
}

.connection.card-body {
	padding-top: 5px;
	padding-bottom: 5px;
}
</style>
