<template>
 <div class="surface-section pt-0">
    <div class="flex" >
        <div class="flex-1">
            <div class="font-bold text-900 w-full text-2xl mt-5 mb-2">{{pageTitle}}</div>
            <div class="text-700 mb-5 line-height-3 mb-5">{{pageDescription}}.</div>
        </div>
      <div class="flex justify-content-end align-items-center">
        <Button v-if="hasRole('np-dev')" @click="updateEntity" label="Save Configurations" icon="pi pi-cloud-upload" />
      </div>
    </div>
    
    <Loading v-if="loading" :title="'Loading Configurations'" :full="false" />
    <div v-else class="card">
      <Form :fields="fields" :context="onlinePosConfiguration" @onChange="onFormChange" :hasAction="false" />
    </div>


 </div>    
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';

export default {
  inject: ['hasRole'],


    setup() {
    const entityName = "Venue OnlinePos Configuration";
    const pageTitle = "OnlinePos Configurations";
    const pageDescription = "Here you can mange onlinePos configurations for the venue"

    var store = useStore()
    var route = useRoute()
    var toast = useToast()
    const venueId = route.params.venueId


    var loading = ref(false)
    var venue = ref()
    var onlinePosConfiguration = ref({
        token: "",
        firmaId: ""
    })

    const fields = ref(
        [{
          id: 'token',
          type: 'text-input',
          title: 'Token',
          horizontal: false
        },
        {
          id: 'firmaId',
          type: 'text-input',
          title: 'FirmaId',
          horizontal: false
        }]
        )

    function onFormChange(context){
        onlinePosConfiguration.value = context
    }

    async function updateEntity(){
        loading.value = true

        var payload = onlinePosConfiguration.value

        await store.dispatch("venues/updateVenueOnlinePosConfiguration", {venueId, payload})
        .then(()=>{
            toast.add({severity: 'success', summary: 'Success', detail: entityName + ' updated', life: 3000});
            store.dispatch("venues/getVenue", {venueId})

        })
        .catch(err =>{
            toast.add({severity:'error', summary: 'Error', detail: 'Could not update ' + entityName + ': ' + err, life: 3000});

        })
        .finally(() => {
         loading.value = false

        })
    
    }

     async function loadRequiredData() {
      loading.value = true

      await store.dispatch('venues/ensureVenue', { venueId: venueId });

      const loadedVenue = store.getters['venues/venue'](venueId)
      venue.value = loadedVenue
      onlinePosConfiguration.value.token = venue.value.onlinePosConfiguration?.token
      onlinePosConfiguration.value.firmaId = venue.value.onlinePosConfiguration?.firmaId

      loading.value = false
    }

    onMounted(async () => {
      await loadRequiredData()
    })

    return {
        pageTitle,
        pageDescription,
        loading,

        fields,
        onlinePosConfiguration,

        updateEntity,
        onFormChange,

    }
    },
}
</script>
