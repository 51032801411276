<template>
  <ConfirmDialog></ConfirmDialog>
  <Toast />

  <AsyncRouterView v-if="!loading" />
  <Loading v-else title="Loading NightPay" />
</template>

<script>
import { defineAsyncComponent, provide, ref, onErrorCaptured } from 'vue'
import mitt from 'mitt'
import useFormatDate from '@/utils/useFormatDate'
import { loginRequest } from "@/authConfig";
import { useMsalAuthentication } from "@/plugins/useMsalAuthentication"
import { InteractionType } from "@azure/msal-browser"
import setupInterceptors from '@/services/axios/setupInterceptors'
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';

export default {
  name: 'App',
  components: {
    AsyncRouterView: defineAsyncComponent({
      loader: () => import('@/AsyncRouterView.vue')
    })
  },
  setup() {
    onErrorCaptured(e => {
      console.log('ERROR:', e)
      return true
    })

    // BOOTSTRAPPING ///////
    const emitter = mitt()
    provide('emitter', emitter);
    provide('useFormatDate', useFormatDate)

    const { acquireToken } = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    //const tokenResult = acquireToken()
    //console.log('tokenResult:', tokenResult)

    setupInterceptors(acquireToken)

    // Moved from rootLayout to fix reload from users
    const loading = ref(true);

    // const account = ref();
    const store = useStore();
    const toast = useToast();
    const router = useRouter();

    async function loadRequiredData() {
      loading.value = true;

      await store.dispatch("getUserAccounts")
        .catch((err) => {
          console.error("Error while loading user accounts: ", err);
          toast.error(err.toString());
        });

      await store.dispatch("getCountryAccounts")
        .catch((err) => {
          console.error("Error while loading country accounts: ", err);
          toast.error(err.toString());
        });

      const userAccounts = store.getters["userAccounts"];
      const activeUserAccount = store.getters["activeUserAccount"];

      if (activeUserAccount == null) {
        if (router.currentRoute.value.name == "invite") {
          const activeInvitationId = store.getters['invitations/activeInvitationId']
          if (activeInvitationId != null && router.currentRoute.value.name !== "invite") {
            router.push({ name: "invite", params: { invitationId: activeInvitationId } })
          }
        } else {
          if (userAccounts.length === 0) {
            router.push({ name: "no-account" });
          } else {
            await store.dispatch("setActiveUserAccount", { accountId: userAccounts[0].accountId }).catch((err) => {
              console.error("Error while setting active user account: ", err);
              toast.error(err.toString());
            });
          }

          await getPermissions();
        }
      } else {
        await getPermissions();
      }
      loading.value = false;
    }


    async function getPermissions() {
      await store.dispatch("getPermissions").catch((err) => {
        console.error("Error while loading IDAM permissions: ", err);
        toast.error(err.toString());
      });

      await store.dispatch('ensureUserAccounts').catch((err) => {
        console.error("Error while loading user accounts: ", err);
        toast.error(err.toString());
      });

      const hasAccount = store.getters["hasAccount"];
      if (!hasAccount) {
        console.log('NO ACCOUNT');
        router.push({ name: "no-account" });
      } else {
        // Has access = has access to one or more root sidebar items.
        const hasAccess = store.getters["hasSidebarItems"];
        console.log('hasAccess:', hasAccess);
        if (!hasAccess) {
          router.push({ name: "no-permissions" });
        }
        else {
          await store.dispatch("updateLastLogin").catch((err) => {
            console.error("Error while updating last login: ", err);
            toast.error(err.toString());
          });
        }
      }
    }

    loadRequiredData();

    return {
      loading
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  background-color: #FFFFFF !important;
  overflow-x: hidden;
}

.main-router {
  min-height: 100vh;
}

.field {
  display: flex;
  flex-direction: column;
}




.p-chip.chip-enabled {
  background: #28a745;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 14px;
}


.p-chip.chip-waiting {
  background: #eec73a;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 14px;
}

.p-chip.chip-disabled {
  background: #ffc107;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 14px;
}

.p-chip.chip-redeemed {
  background: #1867d7;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 14px;
}

.p-chip.chip-expired {
  background: #a2a2a2;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 14px;
}

.p-chip.chip-grey {
  background: #d4d4d4;
  color: #161616;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 14px;
}

.pac-container,
.pac-logo {
  z-index: 10000;
}

</style>

<style scoped>
.loading-container {
  padding-top: 80px;
}
</style>

<style lang="scss">
@import './assets/styles/app/layout.scss';
</style>