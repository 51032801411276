<template>
	<Dialog v-model:visible="showUpdatingDialog" :showHeader="false" :closeOnEscape="false" :closable="false"
		:draggable="false" :style="{ width: '50vw' }" :modal="true">
		<Loading :title="'Updating ' + entityName" :full="false" class="mt-5" />
	</Dialog>
	<Dialog v-model:visible="showCancelMembershipDialog" :draggable="false" modal header="Cancel Membership">
		<div class="text-center">
			<p class="m-0 p-0">You are about to cancel the membership for {{ user?.firstName + ' ' + user?.lastName }}.</p>
			<p class="mb-5 p-0 mt-0">Choose option to confirm</p>
		</div>

		<div class="flex justify-content-evenly">
			<Button v-if="hasPermission('np.backoffice.users.editdelete')" :disabled="loading"
				class="w-fit danger p-button-danger p-2" @click="cancelMembership(false)" :label="'Cancel immediately'" />
			<Button v-if="hasPermission('np.backoffice.users.editdelete')" :disabled="loading"
				class="w-fit danger p-button-danger p-2" @click="cancelMembership(true)" :label="'Cancel at period end'" />
		</div>
	</Dialog>
	<Dialog v-model:visible="showPauseMembershipDialog" modal :draggable="false" header="Pause Membership">
		<div class="text-center">
			<p class="m-0 p-0 w-full">You are about to pause the membership for {{ user?.firstName + ' ' + user?.lastName
			}}.</p>
			<div class="mt-4 flex flex-wrap gap-3 justify-content-center w-full">
				<div class="flex align-items-center">
					<RadioButton v-model="numberOfPausedMonths" inputId="1month" name="1month" value="1" />
					<label for="1month" class="ml-2">1 month</label>
				</div>
				<div class="flex align-items-center">
					<RadioButton v-model="numberOfPausedMonths" inputId="2month" name="2months" value="2" />
					<label for="2month" class="ml-2">2 months</label>
				</div>
			</div>
			<div class="mt-4 flex justify-content-center w-full">
				<Button v-if="hasPermission('np.backoffice.users.editdelete')" :disabled="loading"
					class="w-fit bg-orange-600 border-orange-600 p-2" @click="pauseMembership(false)"
					:label="'Pause subscription'" />
			</div>
		</div>
	</Dialog>
	<Dialog v-model:visible="showProlongPauseDialog" :draggable="false" modal header="Prolong membership pause">
		<div class="text-center">
			<p class="m-0 p-0 w-full">You are about to prolong the membership pause for {{ user?.firstName + ' ' +
				user?.lastName }}.</p>
			<div class="mt-4 flex flex-wrap gap-3 justify-content-center w-full">
				<div class="flex align-items-center">
					<RadioButton v-model="numberOfPausedMonths" inputId="1month" name="1month" value="1" />
					<label for="1month" class="ml-2">1 month</label>
				</div>
				<div class="flex align-items-center">
					<RadioButton v-model="numberOfPausedMonths" inputId="2month" name="2months" value="2" />
					<label for="2month" class="ml-2">2 months</label>
				</div>
			</div>
			<div class="mt-4 flex justify-content-center w-full">
				<Button v-if="hasPermission('np.backoffice.users.editdelete')" :disabled="loading"
					class="w-fit bg-orange-600 border-orange-600 p-2" @click="pauseMembership(true)"
					:label="'Pause subscription'" />
			</div>
		</div>
	</Dialog>
	<Dialog v-model:visible="showResumeMembershipDialog" :draggable="false" modal header="Resume Membership">
		<div class="text-center">
			<p class="m-0 p-0">You are about to resume the membership for {{ user?.firstName + ' ' + user?.lastName }}.</p>
			<p class="mb-5 p-0 mt-0">Confirm to execute action</p>
		</div>
		<div class="flex justify-content-evenly">
			<Button v-if="hasPermission('np.backoffice.users.editdelete')" :disabled="loading" class="w-fit p-2"
				@click="resumeMembership()" :label="'Resume immediately'" />
		</div>
	</Dialog>
	<Dialog v-model:visible="showAddFreeDaysDialog" :draggable="false" modal header="Add free days">
		<div class="text-center">
			<p class="m-0 p-0">You are about to add free days to the membership for {{ user?.firstName + ' ' +
				user?.lastName }}.</p>
			<p class="mb-5 p-0 mt-0">Choose amount of free days and confirm</p>
		</div>

		<div class="flex flex-column align-items-center justify-content-center gap-4">
			<span class="p-float-label">
				<InputNumber v-model="freeDays" id="number-input" inputId="minmax" :min="0" :max="100" />
				<label for="number-input">Number of free days</label>
			</span>
			<Button v-if="hasPermission('np.backoffice.users.editdelete')" :disabled="!freeDays || loading" class="w-4 p-2"
				@click="addFreeDays" :label="'Confirm'" />
		</div>
	</Dialog>

	<Dialog v-model:visible="showChangeSubscriptionPlan" :draggable="false" modal header="Change subscription plan">
		<div class="text-center">
			<p class="m-0 p-0">You are about change the subscription plan from</p>
			<h3>
				<b> {{ user.subscriptionPlan == 'Monthly' ? 'Monthly' : '6 Month plan' }} </b> <span
					class="font-normal">to</span> <b> {{ possibleUpdatePlan }} </b>
			</h3>
		</div>

		<div class="flex flex-column align-items-center justify-content-center gap-4">
			<Button v-if="hasPermission('np.backoffice.users.editdelete')" :disabled="loading" class="w-6 p-2"
				@click="updateSubscriptionPlan" :label="'Confirm change'" />
		</div>
	</Dialog>

	<Dialog v-model:visible="showChangeMembershipDialog" :draggable="false" modal header="Change subscription tier">
		<div class="text-center flex flex-wrap gap-4">
			<p class="m-0 p-0 w-full">You are about change the membership tier</p>
			<Dropdown :options="membershipOptions" optionLabel="subscriptionTier" optionValue="subscriptionTier"
				class="w-full" v-model="updatedMembership" />

			<div class="flex flex-column align-items-center justify-content-center w-full gap-4">
				<Button v-if="hasPermission('np.backoffice.users.editdelete')" :disabled="loading" class="w-6 p-2"
					@click="updateEntity" :label="'Confirm change'" />
			</div>
		</div>
	</Dialog>

	<div class="surface-section">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex gap-2 justify-content-end align-items-center">
				<Button @click="goToStripe" label="Stripe" icon="pi pi-link" class="p-button-secondary" />
			</div>
		</div>

		<Loading v-if="loading" :title="'Loading Permissions'" :full="false" />

		<div v-else class="flex flex-wrap gap-4">
			<div class="flex flex-wrap gap-4 w-full">
				<Card class="p-2 flex-grow-1">
					<template #header>
						<div class="flex">
							<h5 class="m-0">Current membership</h5>
							<i class="ml-auto fa fa-edit text-blue-400 cursor-pointer"
								@click="showChangeMembershipDialog = true" />
						</div>
					</template>
					<template #content>
						<div class="flex flex-wrap justify-content-center">
							<h3 class="p-0 m-0 w-full text-center">{{ user.subscriptionTier }}</h3>
							<p v-if="user.isStaff" class="p-0 m-0 mt-1">(Is staff)</p>
							<p v-if="subscriptionIsPaused" class="p-0 m-0 mt-1">(Paused)</p>
						</div>
					</template>
				</Card>

				<Card class="p-2 flex-grow-1">
					<template #header>
						<div class="flex">
							<h5 class="m-0">Membership before 40/10</h5>
						</div>
					</template>
					<template #content>
						<div class="flex flex-wrap justify-content-center">
							<h3 class="p-0 m-0 w-full text-center">{{ user.subscriptionTierBefore4010 ?? 'None' }}</h3>
						</div>
					</template>
				</Card>
				<Card class="p-2 flex-grow-1">
					<template #header>
						<div class="flex">
							<h5 class="m-0">Stripe subscription status</h5>
						</div>
					</template>
					<template #content>
						<div class="flex flex-wrap justify-content-center">
							<h3 class="p-0 m-0 w-full text-lg text-center" :class="colorOfSubscriptionStatus">{{
								subscriptionStatus }}</h3>
							<!-- <p v-if="subscriptionWillBePaused" class="p-0 m-0 mt-1 text-orange-500">(Pauses on {{formatDate(user.subscriptionPausedOn)}})</p>
								<p v-if="subscriptionIsPaused" class="p-0 m-0 mt-1 text-orange-500">(Resumes on {{formatDate(user.subscriptionReactivationDate)}})</p> -->
							<p v-if="subscriptionWillBeCancelled" class="p-0 m-0 mt-1 text-orange-500">(Cancels on {{
								formatDate(user.subscriptionCancelledOn) }})</p>
						</div>
					</template>
				</Card>
			</div>

			<div class="flex flex-wrap gap-4 w-full">
				<Card class="p-2 flex-grow-1">
					<template #header>
						<div class="flex">
							<h5 class="m-0">Subscription Plan</h5>
							<!-- <i class="ml-auto fa fa-edit text-blue-400 cursor-pointer" @click="showChangeSubscriptionPlan = true" 
								v-if="user.subscriptionPlan != 'None' && !membershipIsNotStandard" /> -->
						</div>
					</template>
					<template #content>
						<div class="flex flex-wrap justify-content-center">
							<h3 class="p-0 m-0 w-full text-center">{{ user.subscriptionPlan == 'Monthly' ? 'Monthly' :
								'None' }}</h3>
						</div>
					</template>
				</Card>
				<Card class="p-2 flex-grow-1">
					<template #header>
						<div class="flex">
							<h5 class="m-0">Next memberfee payment</h5>
						</div>
					</template>
					<template #content>
						<div class="flex flex-wrap justify-content-center">
							<h3 class="p-0 m-0 w-full text-center">{{ nextInvoiceDate }}</h3>
						</div>
					</template>
				</Card>
				<Card class="p-2 flex-grow-1">
					<template #header>
						<div class="flex">
							<h5 class="m-0">Continual memberfee payments</h5>
						</div>
					</template>
					<template #content>
						<div class="flex flex-wrap justify-content-center">
							<h3 class="p-0 m-0 w-full text-center">{{ continualPayments }}</h3>
						</div>
					</template>
				</Card>
			</div>

			<Card class="p-2 flex-auto min-w-max">
				<template #header>
					<h5 class="m-0">Membership fees</h5>
				</template>
				<template #content>
					<div class="flex flex-column gap-2 max-h-12rem overflow-y-auto">
						<div v-for="t in invoices" :key="t"
							class="p-2 border-1 flex align-items-center justify-content-between border-round border-gray-300">
							<div class="w-2">
								{{ t.amount }} <span class="text-gray-500">{{ t.currencyCode }}</span>
							</div>
							<div class="w-5 flex justify-content-center">{{ formatDateTime(t.created) }}</div>
							<Tag class="w-3" :style="{ background: getColorOfMembershipFeeTag(t) }"><small>{{
								getTextOfMembershipFeeTag(t) }}</small></Tag>
						</div>
					</div>
				</template>
			</Card>
			<Card class="p-2 w-full">
				<template #header>
					<h5 class="m-0">Membership history</h5>
				</template>
				<template #content>
					<div class="flex flex-column gap-2 max-h-16rem overflow-y-auto">
						<div
							class="p-2 pl-5 h-3rem overflow-hidden flex-auto flex align-items-center justify-content-between border-round border-gray-300">
							<small class="w-1 text-center font-bold">Date</small>
							<small class="w-2 text-center font-bold">Provider</small>
							<small class="w-3 text-center font-bold">Event</small>
							<small class="w-3 text-center font-bold">Membership</small>
						</div>
						<template v-for="(t, i) in subscriptionEvents" :key="t">
							<div class="flex align-items-center gap-2">
								<span style="width: 1.2rem; height: 1.2rem" class="relative border-circle flex border-3 z-3"
									:class="t.obtained ? 'border-primary' : 'border-gray-300'">
									<span v-if="i != subscriptionEvents?.length - 1"
										style="width: 0.14rem; height: 2.4rem; margin-top: 3px"
										class="bg-gray-300 absolute top-100 z-0 right-50"></span>
								</span>
								<div :class="{ 'opacity-50': !t.obtained }"
									class="p-2 border-1 h-3rem overflow-hidden flex-auto flex align-items-center justify-content-between border-round border-gray-300">
									<small class="w-1">{{ t.date }} </small>
									<small class="w-2 text-center">{{ t.provider }} </small>
									<small class="w-3 text-center">{{ getSubscriptionEventTitle(t.status, t?.val) }}</small>
									<div class="w-3 flex justify-content-center"
										:class="getColorForSubscriptionTier(t.tier)">{{ t.tier }}</div>
								</div>
							</div>
						</template>
					</div>
				</template>
			</Card>

			<div class="field grid gap-4 w-full mt-4">
				<div v-if="hasOngoingSubscription" class="flex flex-wrap gap-2">
					<Button
						v-if="hasPermission('np.backoffice.users.editdelete') && !user?.subscriptionCancelled && !user?.isStaff && user?.subscriptionProvider == 'Stripe'"
						class="w-fit danger p-button-danger py-2" @click="showCancelMembershipDialog = true"
						:label="'Cancel ' + entityName" icon="text-sm pi pi-times" />
					<!-- <Button v-if="hasPermission('np.backoffice.users.editdelete') &&  !user?.subscriptionCancelled && !subscriptionIsPaused && !subscriptionWillBePaused && !user?.isStaff"  class="w-fit bg-orange-600 border-orange-600 py-2" @click="showPauseMembershipDialog = true" :label="'Pause ' + entityName" icon="text-sm pi pi-pause" /> -->
					<!-- <Button v-if="hasPermission('np.backoffice.users.editdelete') &&  !user?.subscriptionCancelled && subscriptionIsPaused && subscriptionPauseAllowedToProlong  && !user?.isStaff"  class="w-fit bg-orange-600 border-orange-600 py-2" @click="showProlongPauseDialog = true" :label="'Prolong pause'" icon="text-sm pi pi-replay" /> -->
					<!-- <Button v-if="hasPermission('np.backoffice.users.editdelete') &&  !user?.subscriptionCancelled && (subscriptionIsPaused || subscriptionWillBePaused ) && !user?.isStaff"  class="w-fit py-2" @click="showResumeMembershipDialog = true" :label="'Resume ' + entityName" icon="text-sm pi pi-play" /> -->
					<Button
						v-if="hasPermission('np.backoffice.users.editdelete') && !user?.subscriptionCancelled && !user?.isStaff && user?.subscriptionProvider == 'Stripe'"
						class="w-fit danger py-2" @click="showAddFreeDaysDialog = true" :label="'Add free days'"
						icon="font-light text-sm pi pi-percentage" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';

import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import {
	getSubscriptionEventTitle,
	getColorOfMembershipFeeTag,
	getTextOfMembershipFeeTag,
	getStripeSubscriptionStatus,
	hasNoMembership,
	getColorForSubscriptionTier,
} from '../../../utils/membershipHelper.js';
import useFormatDate from '../../../utils/useFormatDate.js';
import { startCase } from 'lodash';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionUpdateEntity = 'users/updateUserMembership';
		const storeActionUpdateSubscriptionPlan = 'subscriptions/updateSubscriptionPlan';
		const storeActionDeleteUserMembership = 'users/deleteUserMembership';
		const storeActionPauseUserMembership = 'users/pauseUserMembership';
		const storeActionResumeUserMembership = 'users/resumeUserMembership';

		const storeActionUpdateFreeDays = 'users/updateUserMembershipFreeDays';
		const storeActionGetSubscriptionInfo = 'subscriptions/ensureSubscription';
		const storeActionGetSubscriptionEvents = 'userSubscriptionEvents/getSubscriptionEvents';

		const entityName = 'Membership';
		const pageTitle = 'Membership';
		const pageDescription = 'User Membership information.';

		const loading = ref(true);
		const updating = ref(false);

		const store = useStore();
		const toast = useToast();
		const route = useRoute();

		const user = ref();
		const memberships = ref([]);
		const membershipOptions = computed(() => {
			var tiers = memberships.value;
			tiers.push({ subscriptionTier: 'NoMembership' });
			return tiers;
		});
		const updatedMembership = ref();

		const possibleUpdatePlan = computed(() => (user.value?.subscriptionPlan == 'Monthly' ? '6 Month plan' : 'Monthly plan'));

		const stripeSubscriptionId = computed(() => user.value?.stripeSubscriptionId ?? '');
		// const billWerkSubscriptionHandle = computed(() => user.value?.billWerkSubscriptionHandle ?? '');
		const subscription = computed(() => store.getters['subscriptions/subscription'](stripeSubscriptionId.value));
		const invoices = computed(() => store.getters['subscriptions/subscriptionInvoices'](stripeSubscriptionId.value));
		const subscriptionEvents = computed(() =>
			store.getters['userSubscriptionEvents/subscriptionEvents']?.map((event) => {
				return {
					status: event.eventType,
					date: useFormatDate().formatDateTime(new Date(event.eventDate)),
					obtained: new Date(event.eventDate) <= new Date(),
					provider: event.subscriptionProvider,
					tier: event.subscriptionTier,
					val: event.eventValue,
				};
			})
		);

		const membershipIsNotStandard = computed(() => user.value.subscriptionTier != 'Standard');
		const subscriptionStatus = computed(() =>
			subscription.value?.status
				? getStripeSubscriptionStatus(
					startCase(subscription.value?.status),
					subscription.value.trialStart,
					subscription.value.trialEnd,
					user.value?.startOfSubscription,
					subscriptionEvents.value,
					subscriptionIsPaused.value
				)
				: 'None'
		);
		const nextInvoiceDate = computed(() => {
			if (subscriptionWillBePaused.value || subscriptionIsPaused.value) return useFormatDate().formatDateTime(user?.value.subscriptionReactivationDate);

			return subscription.value?.nextInvoiceDate ? useFormatDate().formatDateTime(subscription.value?.nextInvoiceDate) : '-';
		});

		const continualPayments = computed(() => {
			var count = 0;
			invoices.value?.forEach((i) => {
				if (!i?.paid) return;
				count++;
			});
			return count;
		});
		const userId = route.params.userId;

		const showCancelMembershipDialog = ref(false);
		const showPauseMembershipDialog = ref(false);
		const showProlongPauseDialog = ref(false);

		const showResumeMembershipDialog = ref(false);
		const showAddFreeDaysDialog = ref(false);

		const showChangeSubscriptionPlan = ref(false);

		const showChangeMembershipDialog = ref(false);
		const freeDays = ref();
		const numberOfPausedMonths = ref('1');

		const subscriptionIsPaused = computed(() => new Date(user.value.subscriptionPausedOn) < new Date() && new Date(user.value.subscriptionPausedOn).getFullYear() != 1);
		const subscriptionWillBePaused = computed(() => new Date(user.value.subscriptionPausedOn).getFullYear() != 1 && new Date(user.value.subscriptionPausedOn) > new Date());
		const subscriptionPauseAllowedToProlong = computed(() => {
			var date = new Date();
			date.setDate(date.getDate() - 5);
			date.setHours(0, 0, 0);
			return new Date(user.value.subscriptionReactivationDate).getFullYear() != 1 && new Date(user.value.subscriptionReactivationDate) >= date;
		});
		const subscriptionWillBeCancelled = computed(() => new Date(user.value.subscriptionCancelledOn).getFullYear() != 1 && new Date(user.value.subscriptionCancelledOn) > new Date());

		const hasOngoingSubscription = computed(() => subscription.value);
		const showUpdatingDialog = ref(false);

		function formatDateTime(date) {
			return useFormatDate().formatDateTime(date);
		}

		function formatDate(date) {
			return useFormatDate().formatDateSimple(date);
		}

		function entityUpdated(formContext) {
			user.value = formContext;
		}

		async function updateEntity() {
			loading.value = true;

			try {
				await store.dispatch(storeActionUpdateEntity, { userId: userId, subscriptionTier: updatedMembership.value });

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' updated', life: 3000 });

				await reloadUser();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update ' + entityName + ': ' + err.messagae, life: 3000 });
			}

			showChangeMembershipDialog.value = false;
			loading.value = false;
		}

		const colorOfSubscriptionStatus = computed(() => {
			if (subscriptionStatus.value == 'Paused') return 'text-orange-300';
			if (!subscription.value?.status) return '';
			switch (subscription.value.status) {
				case 'active':
					return 'text-green-400';
				case 'canceled':
					return 'text-orange-500';
				case 'past_due' || 'unpaid':
					return 'text-orange-600';
				default:
					return 'text-primary';
			}
		});
		async function cancelMembership(cancelAtPeriodEnd) {
			loading.value = true;
			try {
				await store.dispatch(storeActionDeleteUserMembership, { userId, cancelAtPeriodEnd });
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: cancelAtPeriodEnd ? 'Membership will be cancelled at period end' : 'Membership was successfully cancelled.',
					life: 3000,
				});
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Could not cancel Membership ',
					detail: err,
					life: 3000,
				});
				showCancelMembershipDialog.value = false;
				return;
			}
			showCancelMembershipDialog.value = false;

			reloadUser();
		}

		async function pauseMembership(prolong) {
			loading.value = true;
			try {
				await store.dispatch(storeActionPauseUserMembership, { userId: userId, nMonths: numberOfPausedMonths.value, prolong });
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Membership was successfully paused.',
					life: 3000,
				});
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Could not pause Membership ',
					detail: err,
					life: 3000,
				});
				showPauseMembershipDialog.value = false;
				return;
			}
			showPauseMembershipDialog.value = false;
			showProlongPauseDialog.value = false;

			reloadUser();
		}

		async function resumeMembership() {
			loading.value = true;
			try {
				await store.dispatch(storeActionResumeUserMembership, userId);
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Membership was successfully resumed.',
					life: 3000,
				});
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Could not resume Membership ',
					detail: err,
					life: 3000,
				});
				showResumeMembershipDialog.value = false;
				return;
			}
			showResumeMembershipDialog.value = false;

			reloadUser();
		}

		async function addFreeDays() {
			loading.value = true;
			try {
				await store.dispatch(storeActionUpdateFreeDays, { userId, trialDays: freeDays.value });
				const amount = freeDays.value;
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: amount + ' free days was added to membership!',
					life: 3000,
				});
				getStripeSubscriptionInfo();
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Could not add free days to Membership',
					detail: err,
					life: 3000,
				});
			}
			freeDays.value = undefined;
			showAddFreeDaysDialog.value = false;
			loading.value = false;
		}

		async function reloadUser() {
			await store.dispatch('users/getUser', { userId: userId });
			const loadedUser = store.getters['users/user'](userId);
			user.value = JSON.parse(JSON.stringify(loadedUser));
			updatedMembership.value = user.value.subscriptionTier;
			getStripeSubscriptionInfo();
			loading.value = false;
		}

		async function getStripeSubscriptionInfo() {
			const stripeSubscriptionId = user.value.stripeSubscriptionId;
			const userId = user.value.id;
			const billWerkSubscriptionHandle = null;

			if (stripeSubscriptionId == null) return;
			// if (user.value.stripeSubscriptionId == null) return;

			await store.dispatch(storeActionGetSubscriptionInfo, { stripeSubscriptionId }).catch(() => { });
			await store.dispatch(storeActionGetSubscriptionEvents, { userId, stripeSubscriptionId, billWerkSubscriptionHandle }).catch((err) => console.log(err));
		}

		async function updateSubscriptionPlan() {
			loading.value = true;
			const currentPlan = user.value.subscriptionPlan;

			if (currentPlan == null || currentPlan == 'None') {
				toast.add({
					severity: 'error',
					summary: 'Could not update subscription plan',
					detail: 'The current plan must be active',
					life: 3000,
				});
				loading.value = false;
				return;
			}

			var newPlan = currentPlan == 'Monthly' ? 'HalfYearly' : 'Monthly';

			await store
				.dispatch(storeActionUpdateSubscriptionPlan, { userId: user.value.id, subscriptionPlan: newPlan })
				.then(() => {
					toast.add({
						severity: 'success',
						summary: 'Success',
						detail: `Subscription plan was updated to ${newPlan}`,
						life: 3000,
					});
				})
				.catch((err) => {
					toast.add({
						severity: 'error',
						summary: 'Could not update subscription plan',
						detail: err,
						life: 3000,
					});
				});
			showChangeSubscriptionPlan.value = false;
			await reloadUser();
			loading.value = false;
		}

		function goToStripe() {
			window.open('https://dashboard.stripe.com/customers/' + user.value.stripeCustomerId, '_blank');
		}

		async function loadRequiredData() {
			loading.value = true;

			// Load User
			await store.dispatch('users/ensureUser', { userId: userId });
			const loadedUser = store.getters['users/user'](userId);

			user.value = JSON.parse(JSON.stringify(loadedUser));
			updatedMembership.value = user.value.subscriptionTier;

			// Load stripe subscription
			await getStripeSubscriptionInfo();

			await store.dispatch('memberships/ensureMembershipListItems');

			const loadedMemberships = store.getters['memberships/membershipListItems'];
			memberships.value = loadedMemberships;

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			pageTitle,
			pageDescription,
			loading,
			updating,

			entityUpdated,
			updateEntity,
			cancelMembership,
			addFreeDays,
			resumeMembership,
			pauseMembership,
			updateSubscriptionPlan,

			user,
			hasOngoingSubscription,
			subscription,
			invoices,
			nextInvoiceDate,
			continualPayments,
			subscriptionStatus,
			subscriptionEvents,
			getSubscriptionEventTitle,
			getColorOfMembershipFeeTag,
			getTextOfMembershipFeeTag,

			possibleUpdatePlan,
			freeDays,
			entityName,
			showUpdatingDialog,
			showCancelMembershipDialog,
			showPauseMembershipDialog,
			showResumeMembershipDialog,
			showProlongPauseDialog,
			showAddFreeDaysDialog,
			showChangeSubscriptionPlan,
			showChangeMembershipDialog,

			membershipOptions,
			memberships,
			updatedMembership,

			numberOfPausedMonths,
			subscriptionIsPaused,
			subscriptionWillBePaused,
			subscriptionPauseAllowedToProlong,
			subscriptionWillBeCancelled,

			membershipIsNotStandard,

			getColorForSubscriptionTier,
			colorOfSubscriptionStatus,
			hasNoMembership,
			formatDateTime,
			formatDate,
			goToStripe,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
