export default function getCountries() {
    const getCountryCode = (accountId) => ({
        "deb1e1f0-5754-4dcd-9e44-0934ab88001d": "dk",
        "9bdbdbc4-e5f5-4cc6-b233-630fc1eeda51": "no",
        "e8b4c05e-22a8-41bc-98bb-37fc7692e402": "fi",
        "67ec67ed-bd20-477c-9f02-9b37d789a8bf": "uk",
    }[accountId])

    const getCountryName = (accountId) => ({
        "deb1e1f0-5754-4dcd-9e44-0934ab88001d": "Denmark",
        "9bdbdbc4-e5f5-4cc6-b233-630fc1eeda51": "Norway",
        "e8b4c05e-22a8-41bc-98bb-37fc7692e402": "Finland",
        "67ec67ed-bd20-477c-9f02-9b37d789a8bf": "United Kingdom",
    }[accountId])

    return {
        getCountryCode: getCountryCode,
        getCountryName: getCountryName
    }
}