<template>
	<Sidebar v-if="canCreateNew" v-model:visible="createNewSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="creating" :title="'Creating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Create {{ entityName }}</h3>
			<Form :fields="fields" :context="newEntity" @onAction="createEntity" :actionLabel="'Create'" :actionIcon="'pi pi-plus-circle'" />
		</div>
	</Sidebar>

	<Sidebar v-if="canUpdate" v-model:visible="updateSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit {{ entityName }}</h3>
			<Form :fields="fields" :context="selectedEntity" @onAction="updateEntity" :actionLabel="'Update'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<Sidebar v-if="canUpdate" v-model:visible="assignPerksBarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit Perks</h3>
			<Form :fields="perkFields" :context="selectedEntity" @onAction="updatePerkConfigs" :actionLabel="'Update'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<Sidebar v-if="canUpdate" v-model:visible="assignSignUpPerksBarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit one-time expiration perks</h3>
			<Form :fields="signUpPerkFields" :context="selectedEntity" @onAction="updateSignUpPerkConfigs" :actionLabel="'Update'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>
	
	<Sidebar v-if="canUpdate" v-model:visible="updatePerkConfigBarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating' + '...'" :full="false" />
		<div v-else>
			<h3>Edit Perk configurations</h3>
			<SubscriptionPerkConfigs :context="subscriptionPerkConfigs" @onAction="updateSubscriptionPerks"  />
		</div>
	</Sidebar>

	<Dialog v-model:visible="updateFreemiumConfigVisible" :draggable="false" modal header="Header" :style="{ width: '40rem' }">
        <template #header>
            <div class="inline-flex align-items-center justify-content-center gap-2">
                <h3 class="font-bold white-space-nowrap m-0 p-0">{{isFreemiumOnly ? 'Enable' : 'Disable'}} Memberships</h3>
            </div>
        </template>
        <div class="text-center">
            <p>You are about to set memberships to <b>{{isFreemiumOnly ? 'active' : 'freemium only'}}. </b> </p>
			<p>This will affect all subscription related contents in the App, including NP+ signup and functions</p>
            <p>Pleas confirm your action</p>
            <Button @click="updateFreemiumConfig(!isFreemiumOnly)" class="px-8 mt-4">{{isFreemiumOnly ? 'Set to active' : 'Set to freemium only'}}</Button>
        </div>
    </Dialog>


	<div class="surface-section p-6 pt-0">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">
					{{ pageTitle }}
					<Chip v-tooltip="`Memberships for this country is ${isFreemiumOnly ? 'Freemium only' :'active'} in the App`" :class="isFreemiumOnly ? 'bg-blue-400' : 'bg-green-400'" class="text-white px-3 " style="height: 1.5rem" :label="isFreemiumOnly ? 'Freemium only': 'Active'" />
				</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center gap-2">
				<Button :label="'Edit perk configurations'" icon="pi pi-plus-circle" @click="updatePerkConfigBarVisible = true" />
				<Button @click="updateFreemiumConfigVisible = true" :class="isFreemiumOnly ? 'bg-green-400 border-green-400' : 'bg-orange-400 border-orange-400'"  
					:label="isFreemiumOnly ? 'Enable': 'Disable'" 
					:icon="`pi ${isFreemiumOnly ? 'pi-play' : 'pi-stop'}`"
					v-tooltip="'Enable/Disable all subscription related contents in the App, including NP+ signup and functions'"
					/>
			</div>
		</div>

		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
		<div v-else class="card mb-2">
			<DataTable :value="entities" dataKey="id" @row-click="onRowClicked" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll">
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
				<Column field="title" header="Name"></Column>
				<Column header="Fee">
					<template #body="{ data }"> {{ data.membershipFee }} {{ data.currency }} </template>
				</Column>
				<Column header="Full Discount">
					<template #body="{ data }"> {{ data.fullDiscount }}% </template>
				</Column>
				<Column header="Reduced Discount">
					<template #body="{ data }"> {{ data.reducedDiscount }}% </template>
				</Column>
				<Column header="Cutover">
					<template #body="{ data }">
						{{ data.discountCutOverHour < 10 ? '0' : '' }}{{ data.discountCutOverHour }}:{{ data.discountCutOverMinute < 10 ? '0' : '' }}{{ data.discountCutOverMinute }}
					</template>
				</Column>
				<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<Button @click="assignSignUpPerks(data)" type="button" icon="fa fa-user-plus"></Button>
					</template>
				</Column>
				<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<Button @click="assignPerks(data)" type="button" icon="fa fa-ticket"></Button>
					</template>
				</Column>
			</DataTable>
		</div>
		
		<UnsubscribeOptions />
	</div>
</template>

<script>
import { ref, onMounted, inject, computed } from 'vue';
import { useStore } from 'vuex';

import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import UnsubscribeOptions from '@/components/UnsubscribeOptions/UnsubscribeOptions'
import SubscriptionPerkConfigs from './membership/SubscriptionPerkConfigs.vue';
export default {
	components: {
		SubscriptionPerkConfigs,
		UnsubscribeOptions
	},
	setup() {
		const storeActionGetEntities = 'memberships/getMembershipListItems';
		const storeGetterGetEntities = 'memberships/membershipListItems';
		// const storeActionCreateEntity = 'memberships/create'
		const storeActionUpdateEntity = 'memberships/update';
		const storeActionDeleteEntity = 'memberships/delete';
		const storeActionUpdatePerkConfigs = 'membershipPerks/update';
		const storeActionUpdateSignUpPerkConfigs = 'membershipPerks/updateSignUpPerks';
		const storeActionUpdateFreemiumConfig = 'updateFreemiumConfig';

		const entityName = 'Membership';
		const pageTitle = 'Memberships';
		const pageDescription = 'Here you can manage your Memberships';
		const canCreateNew = true;
		const canUpdate = true;

		const fields = ref([]);
		const perkFields = ref([]);
		const signUpPerkFields = ref([]);

		const loading = ref(true);
		// const creating = ref(false)
		const updating = ref(false);

		const userTerms = ref([]);
		const perks = ref([]);
		const isFreemiumOnly = computed(() => {
					var activeAccountCountry = store.getters["activeAccountCountry"]
			var countryAccounts = store.getters["countryAccounts"]
			return countryAccounts?.find(x => x.id == activeAccountCountry)?.freemiumOnly
		});

		const useFormatDate = inject('useFormatDate');

		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const entities = ref([]);
		const updateFreemiumConfigVisible = ref(false)

		const newEntity = ref({
			logoUrl: '',
			backgroundUrl: '',
			title: '',
			onlinePosGroup: '',
			onlinePosCampaignGroup: '',
			tagLine: '',
			backgroundSubtitle: '',
			userTermId: {
				id: null,
			},
			sortKey: 0,
			discountPercentage: 0,
			showBenefits: false,
			visible: false,
			enabled: false,
		});
		const selectedEntity = ref({});

		const subscriptionPerkConfigs = ref([
				{
				title: "Signup Perks",
				help: "Perks given when a user successfully signs up for a membership subscription.",
				configs: [
					{
					perkId: {id: ""},
					venueId: {id: ""},
					isFromNightPay: false,
					quantity: 1,
					expires: false,
					expire: 1,
					expireType: "month",
					expireDate: null

					}
				],
				enabled: false,
				},
				{
				title: "Referral Perk",
				help: "Perk given when a user redeems a referral code on sign up.",
				perkId: {id: ""},
				venueId: {id: ""},
				isFromNightPay: false,
				quantity: 1,
				expires: false,
				expire: 1,
				expireType: 1,
				enabled: false,
				expireDate: null
				
				}
			
		])
		

		const createNewSidebarVisible = ref(false);
		const updateSidebarVisible = ref(false);
		const assignPerksBarVisible = ref(false);
		const assignSignUpPerksBarVisible = ref(false);
		const updatePerkConfigBarVisible = ref(false);



		function assignPerks(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload));

			selectedEntity.value.perkConfigs = selectedEntity.value.perkConfigs?.map(x => {
			return {...x, venueId: {id: x?.venueId?.id}}
			})  ?? []
			assignPerksBarVisible.value = true;
		}

		function assignSignUpPerks(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload));

			selectedEntity.value.signUpPerkConfigs = selectedEntity.value.signUpPerkConfigs?.map(x => {
			return {...x, venueId: {id: x?.venueId?.id}}
			})  ?? []
			assignSignUpPerksBarVisible.value = true;
		}


		function onRowClicked(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload.data));
			updateSidebarVisible.value = true;
		}

		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({
							severity: 'success',
							summary: 'Success',
							detail: entityName + ' deleted',
							life: 3000,
						});
					} catch (err) {
						toast.add({
							severity: 'error',
							summary: 'Error',
							detail: 'Could not delete ' + entityName + ': ' + err,
							life: 3000,
						});
					}

					loading.value = false;
				},
				reject: () => {},
			});
		}


		

		async function getSubscriptionPerkConfigs(){
			await store.dispatch("perks/getSubscriptionPerkItems");
			var loadedEntities = store.getters["perks/subscriptionPerkListItems"]

			if(loadedEntities.signupPerkConfigs?.length > 0){
				subscriptionPerkConfigs.value[0] = {
					configs: loadedEntities.signupPerkConfigs,
					title: "Signup Perks",
					help: "Perks given when a user successfully signs up for a membership subscription.",
					enabled: true,
				}
			}

			if(loadedEntities.referralPerkConfig){
				subscriptionPerkConfigs.value[1] = {
					...loadedEntities.referralPerkConfig,
					title: "Referral Perk",
					help: "Perk given when a user redeems a referral code on sign up.",
					enabled: true
				}

			}
		}

		async function updateFreemiumConfig(config){
			updateFreemiumConfigVisible.value = false
            
			await store.dispatch(storeActionUpdateFreemiumConfig, config)
            .then(()=>{
                var detail = `Memberships is now ${config ? 'freemium only' : 'active'}`
                toast.add({ severity: 'success', summary: 'Success', detail: detail, life: 3000 });
            })
            .catch((err)=>{
                toast.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
            })

             await store.dispatch("getCountryAccounts")
            .catch((err) => {
                toast.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
            });
            
		}

		async function updateSubscriptionPerks(context){
			console.log("context", context)

			var signupPerkConfig = {...context[0]}
			var referralPerkConfig = {...context[1]}

			var signupPerkConfigs = []
			
			signupPerkConfig.configs.forEach(config => {

				if(!config.venueId?.id)
					config.venueId = null

				if(!config.perkId?.id || !signupPerkConfig.enabled)
					config = null

				if(config != null)
					signupPerkConfigs.push(config)
				
			})
			
			if(!referralPerkConfig.venueId?.id)
					referralPerkConfig.venueId = null
				
			if(!referralPerkConfig.perkId?.id || !referralPerkConfig.enabled)
				referralPerkConfig = null

			var perkConfigs = {
				signupPerkConfigs,
				referralPerkConfig
			}			

			await store.dispatch("perks/updateSubscriptionPerks", perkConfigs)
			.then(()=>{
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Subscription perk configurations updated',
					life: 3000,
				});
			})
			.catch((err)=>{
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update subscription perk configurations ' + ': ' + err,
					life: 3000,
				});
			})
			getSubscriptionPerkConfigs();
			updatePerkConfigBarVisible.value = false
		}

		async function updatePerkConfigs(formContext) {
			updating.value = true;
			var payload = formContext
			payload.perkConfigs = formContext.perkConfigs?.map(x => {
				return {...x, venueId:  x?.venueId?.id ? {id: x?.venueId?.id} : null}
			})
			try {
				await store.dispatch(storeActionUpdatePerkConfigs, payload);

				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: entityName + ' updated',
					life: 3000,
				});
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update ' + entityName + ': ' + err,
					life: 3000,
				});
			}

			loading.value = false;
			updating.value = false;
		}

		async function updateSignUpPerkConfigs(formContext) {
			updating.value = true;
			var payload = formContext
			payload.signUpPerkConfigs = formContext.signUpPerkConfigs?.map(x => {
				return {...x, venueId:  x?.venueId?.id ? {id: x?.venueId?.id} : null}
			})
			try {
				await store.dispatch(storeActionUpdateSignUpPerkConfigs, payload);

				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: entityName + ' updated',
					life: 3000,
				});
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update ' + entityName + ': ' + err,
					life: 3000,
				});
			}

			loading.value = false;
			updating.value = false;
		}

		async function updateEntity(formContext) {
			updating.value = true;

			try {
				await store.dispatch(storeActionUpdateEntity, formContext);

				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: entityName + ' updated',
					life: 3000,
				});
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update ' + entityName + ': ' + err,
					life: 3000,
				});
			}

			loading.value = false;
			updating.value = false;
		}

	
		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities);
				const loadedEntities = store.getters[storeGetterGetEntities];
				entities.value = loadedEntities;

				// reload perks
				if (assignPerksBarVisible.value) {
					const element = entities.value.find((entity) => entity.id === selectedEntity.value.id);
					if (element != null) {
						assignPerks(element);
					}
				}
				if (assignSignUpPerksBarVisible.value) {
					const element = entities.value.find((entity) => entity.id === selectedEntity.value.id);
					if (element != null) {
						assignSignUpPerks(element);
					}
				}
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not load ' + pageTitle + ': ' + err,
					life: 3000,
				});
			}
		}

		async function loadRequiredData() {
			loading.value = true;

			try {
				await store.dispatch('userTerms/ensureUserTermsListItems');
				const loadedUserTerms = store.getters['userTerms/userTermsListItems'];
				userTerms.value = loadedUserTerms;
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not load User Terms: ' + err,
					life: 3000,
				});
			}

			reloadEntities();

			// Load Perks
			try {
				await store.dispatch('perks/ensurePerkListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Perks: ' + err, life: 3000 });
			}
			const loadedPerks = store.getters['perks/perkListItems'];
			perks.value = loadedPerks;


			fields.value = [
				{
					id: 'title',
					type: 'text-input',
					title: 'Title',
					help: 'Name of the member card type',
				},
				{
					id: 'membershipFee',
					type: 'number-input',
					title: 'Membership Fee',
				},
				{
					id: 'currency',
					path: 'currency',
					type: 'dropdown',
					title: 'Currency',
					config: {
						options: [
							{ value: 'DKK', label: 'DKK' },
							{ value: 'NOR', label: 'NOR' },
							{ value: 'EUR', label: 'EUR' },
						],
						optionLabel: 'label',
						optionValue: 'value',
						placeholder: 'Select Currency',
					},
				},
				{
					id: 'fullDiscount',
					type: 'number-input',
					title: 'Full Discount',
				},
				{
					id: 'reducedDiscount',
					type: 'number-input',
					title: 'Reduced Discount',
				},
				{
					id: 'discountCutOverHour',
					type: 'number-input',
					title: 'Discount Cut Over hour',
				},
				{
					id: 'discountCutOverMinute',
					type: 'number-input',
					title: 'Discount Cut Over minute',
				},
				{
					id: 'orderFromApp',
					type: 'switch',
					title: 'Order from App',
				},
				// {
				// 	id: 'freeTicketPerMonth',
				// 	type: 'number-input',
				// 	title: 'Free perks every month',
				// },
				{
					id: 'freeEntranceCutOverHour',
					type: 'number-input',
					title: 'Free Entrace Cut Over hour',
				},
				{
					id: 'freeEntranceCutOverMinute',
					type: 'number-input',
					title: 'Free Entrace Cut Over minute',
				},
				{
					id: 'freeEntranceAdditionalGuests',
					type: 'number-input',
					title: 'Additional guests',
				},
				{
					id: 'freeEntranceAllNight',
					type: 'switch',
					title: 'All night',
				},
				{
					id: 'nightWheelSpins',
					type: 'number-input',
					title: 'NightWheel spins',
				},
				/*{
          id: "nightWheelPeriodType",
          type: "number-input",
          title: "NightWheel period type",
        },*/
				{
					id: 'sendADrinkCount',
					type: 'number-input',
					title: 'Send a Drink',
				},
				/*{
          id: "sendADrinkPeriodType",
          type: "number-input",
          title: "Send a Drink period type",
        },*/
				{
					id: 'specialEvents',
					type: 'switch',
					title: 'Special events',
				},
				{
					id: 'referralDrinksPerFriend',
					type: 'number-input',
					title: 'Referral drinks',
				},
				{
					id: 'referralInvitations',
					type: 'number-input',
					title: 'Referral invitations',
				},
				/*{
          id: "referralInvitationType",
          type: "number-input",
          title: "Referral invitation type",
        },*/
				{
					id: 'privateConcierge',
					type: 'switch',
					title: 'Private Concierge',
				},
				{
					id: 'frontOfLineFreeWardrobe',
					type: 'switch',
					title: 'Front of Line + Free Wardrobe',
				},
				{
					id: 'frontOfLineFreeWardrobeAdditional',
					type: 'number-input',
					title: 'Additional guests',
				},
				{
					id: 'birthDayDrinks',
					type: 'number-input',
					title: 'Birthday drinks',
				},
				{
					id: 'birthDayFreeParty',
					type: 'switch',
					title: 'Free party',
				},
				{
					id: 'cardBackgroundImageUrl',
					type: 'text-input',
					title: 'Card Background Image Url',
					help: 'Url to the card background image',
				},
				{
					id: 'cardBackgroundColorCode',
					type: 'text-input',
					title: 'Card Background Color Code',
					help: 'Color Code to the card background image',
				},
				{
					id: 'reducedCardBackgroundImageUrl',
					type: 'text-input',
					title: 'Reduced Card Background Image Url',
					help: 'Url to the reduced card background image',
				},
				{
					id: 'reducedCardBackgroundColorCode',
					type: 'text-input',
					title: 'Reduced Card Background Color Code',
					help: 'Color Code to the reduced card background image',
				},
				{
					id: 'inactiveCardBackgroundImageUrl',
					type: 'text-input',
					title: 'Inactive Card Background Image Url',
					help: 'Url to the inactive card background image',
				},
				{
					id: 'inactiveCardBackgroundColorCode',
					type: 'text-input',
					title: 'Inactive Card Background Color Code',
					help: 'Color Code to the inactive card background image',
				}
			];

			perkFields.value = [
				{
					id: 'perkConfigs',
					type: 'RolePerkConfigs',
					title: 'Perk Configs',
				},
			];

			signUpPerkFields.value = [
				{
					id: 'signUpPerkConfigs',
					type: 'RolePerkConfigsMembership',
					title: 'Configurations',
				},
			];

			loading.value = false;
		}

		

		onMounted(async () => {
			await loadRequiredData();
			getSubscriptionPerkConfigs();
		});

		return {
			entityName,
			pageTitle,
			pageDescription,
			canCreateNew,
			canUpdate,
			loading,
			// creating,
			updating,

			fields,
			perkFields,
			signUpPerkFields,
			newEntity,
			selectedEntity,
			// createEntity,
			updatePerkConfigs,
			updateSignUpPerkConfigs,
			updateEntity,
			confirmDeleteEntity,
			updateFreemiumConfig,


			isFreemiumOnly,
			userTerms,
			entities,
			perks,

			createNewSidebarVisible,
			updateSidebarVisible,
			assignPerksBarVisible,
			assignSignUpPerksBarVisible,
			updatePerkConfigBarVisible,
			updateFreemiumConfigVisible,

			onRowClicked,
			assignPerks,
			assignSignUpPerks,

			subscriptionPerkConfigs,
			updateSubscriptionPerks,	

			formatDate: useFormatDate().formatDate,
		};
	},

}
</script>