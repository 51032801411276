<template>
    <div v-if="!loading" class="flex flex-wrap justify-content-center gap-6 mt-2">  
        <div>
           <Chart type="bar" :data="chartDataDaily" :options="chartOptions" class="w-20rem" />
            <p class=" m-0 mx-auto pl-5 text-xs text-gray-400 w-14rem">(resets daily at 10.00 AM)</p>
        </div> 
        <div>
            <Chart type="bar" :data="chartDataWeekly" :options="chartOptions" class="w-20rem" />
            <p class=" m-0 mx-auto pl-5 text-xs text-gray-400 w-14rem">(resets weekly on mondays at 10.00 AM)</p>
        </div>
        <div>
            <Chart type="bar" :data="chartDataMonthly" :options="chartOptions" class="w-20rem" />
            <p class=" m-0 mx-auto pl-5 text-xs text-gray-400 w-14rem">(resets monthly on the first day of the month at 10.00 AM)</p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
export default {
components: {
},
setup() {


const store = useStore();
const toast = useToast();

const loading = ref(true)

const chartDataDaily = ref({
        labels: ["Daily"],
        datasets: [
                {
                    label: "New subscribers",
                    data: [],
                },
                 {
                    label: "Unsubscribed",
                    data: [],
                }
            ]
    });

const chartDataWeekly = ref({
    labels: ["Weekly"],
    datasets: [
            {
                label: "New subscribers",
                data: [],
            },
                {
                 label: "Unsubscribed",
                data: [],
            }
        ]
});


const chartDataMonthly = ref({
    labels: ["Monthly"],
    datasets: [
            {
                label: "New subscribers",
                data: [],
            },
            {
                 label: "Unsubscribed",
                data: [],
            }
        ]
});

const chartOptions = ref({
    plugins: {
        legend: {
            display: false,
            labels: {
                usePointStyle: true
            }
        }
    }
});


async function loadRequiredData() {
        loading.value = true;

        try {
            await store.dispatch('statistics/ensureSubscriptionStatistics');
            const loadedEntities = store.getters['statistics/subscriptionStatistics'];

            var account = store.getters['activeAccountCountry']
            var temporaryFix = account == "deb1e1f0-5754-4dcd-9e44-0934ab88001d" ? 5945 : 0

          
            chartDataDaily.value.datasets[0].data.push(loadedEntities.daily.newSubscriptions);
            chartDataDaily.value.datasets[1].data.push(loadedEntities.daily.newUnsubscribed);
            
            chartDataWeekly.value.datasets[0].data.push(loadedEntities.weekly.newSubscriptions);
            chartDataWeekly.value.datasets[1].data.push(loadedEntities.weekly.newUnsubscribed);

            chartDataMonthly.value.datasets[0].data.push(loadedEntities.monthly.newSubscriptions);
            chartDataMonthly.value.datasets[1].data.push(loadedEntities.monthly.newUnsubscribed - temporaryFix);

            
       } catch (err) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Could not load subscription statistics: ' + err,
                life: 3000,
            });
        }

        loading.value = false
    }

    onMounted(async ()=>{
      await loadRequiredData();
    })

    return {
        chartDataDaily,
        chartDataMonthly,
        chartDataWeekly,
        chartOptions,

        loading

    }
}
}
</script>
