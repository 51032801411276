import apiClient from '@/services/axios'

export async function put(userId, payload) {
  var response = await apiClient.put('/users/' + userId + '/membership', payload)
  
  if (response) {
    return response.data
  }

  throw new Error('Unable to update User Membership.')
}
