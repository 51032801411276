import apiClient from '@/services/axios';

export async function del(venueId, url) {
	console.log('del', venueId, url);
	var response = await apiClient.delete('/venues/' + venueId + '/images/', { data: { url: url } });
	if (response) {
		return response.data;
	}

	throw new Error('Unable to delete Venue Image.');
}
