<template>
  <div class="flex h-full align-items-center text-center w-full justify-content-center">
    <NavBar></NavBar>

    <!-- Page Content  -->
    <div class="page h-20rem w-full bg-black" >
      <div style="text-align: center;">
        <div>
          <img src="https://rekomfltstoragedev.blob.core.windows.net/static/rekom-logo.png" style="border-radius:50%;max-height:100px;max-width:100px;"/>
          
          <div v-if="loading">
            <div class="loading-container">
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div>
              <div>
                <h2>Insufficient permissions</h2>
                <p class="mb-4">
                  Please contact your administrator in order to continue.
                </p>
                <!--<button class="btn btn-warning mt-5">Contact Support <i class="fas fa-arrow-right"></i></button>-->
              </div>
            </div>
          </div>

          <hr class="mt-5" style="border-top-color: white;margin-right: 100px;margin-left: 100px;"/>

          <span>Need help?</span> <a href="mailto:mikkel@hempel.vc">Click here</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  components: {
  },
  setup() {
    const loading = ref(false)
    const store = useStore();
        const router = useRouter();

    onMounted(async () => {
      console.log('Mounted No Account page')
        const hasAccess = store.getters["hasSidebarItems"];
        if(hasAccess){
          router.replace({name: "home"})
        }
    })

    return {
      loading
    }
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
