<template>
  <div class="flex flex-column mb-2">
    <p v-if="configs == null || configs.length == 0">
      Please add a Perk Config
    </p>
    <div style="padding-left: 12px">
      <div
        v-for="(perkConfig, index) in configs"
        :key="index"
        class="field border-1 border-gray-300 p-2 border-round shadow-1"
      >
        <label
          :for="'config-' + index"
          class="font-bold text-900"
          style="font-size: 90%; margin-bottom: 2px"
          >Perk {{ index + 1 }}</label
        >
        <div class="p-inputgroup">
          <Dropdown
            v-model="perkConfig.perkId.id"
            :options="perks"
            optionLabel="title"
            optionValue="id"
            placeholder="Select Perk"
          />
          <Button
            @click="deleteConfig(index)"
            icon="pi pi-times"
            class="p-button-warning"
          />
        </div>

        <label
          v-if="!perkConfig.isFromNightPay"
          style="font-size: 90%; margin-bottom: 2px"
          class="mt-2"
          >Venue</label
        >
        <div v-if="!perkConfig.isFromNightPay" class="p-inputgroup">
          <Dropdown
            v-model="perkConfig.venueId.id"
            :options="venues"
            optionLabel="name"
            optionValue="id"
            placeholder="All"
          />
        </div>

        <label style="font-size: 90%; margin-bottom: 2px" class="mt-2"
          >Is from NightPay</label
        >
        <div class="p-inputgroup">
          <InputSwitch v-model="perkConfig.isFromNightPay" />
        </div>

        <div>
          <div class="mt-1">
            <label style="font-size: 90%; margin-bottom: 2px" class="mt-2"
              >Perk expires at date</label
            >
            <div>
              <Calendar
                :showIcon="true"
                :modelValue="new Date(perkConfig.expireDate)"
                @update:modelValue="(value) => updateExpireDate(index, value)"
                placeholder="Select date"
                showTime
                hourFormat="24"
                dateFormat="yy-mm-dd"
              />
            </div>
          </div>
        </div>
        <hr />
        <Button
          @click="assignToExistingMembersNow(perkConfig)"
          icon="pi pi-play"
          label="Assign perk to existing members now"
          class="p-button-warning"
        />
      </div>
      <Button
        @click="addConfig"
        label="Add Perk"
        class="mt-2 p-button-secondary p-button-sm"
      />
    </div>
  </div>
</template>

<script>
import { useConfirm } from "primevue/useconfirm";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";

export default {
  props: ["context", "field"],
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const confirm = useConfirm();

    const generateUUID = () => {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    };

    const loading = ref(true);
    const perks = ref([]);
    const venues = ref([]);
    console.log("props.context", props.context);
    const configs =
      props.context.signUpPerkConfigs != null
        ? ref(props.context.signUpPerkConfigs)
        : ref(props.context);

    const membershipId = ref(props.context.id).value;

    const updateExpireDate = (index, value) => {
      configs.value[index].expireDate = value.toISOString();
    };

    async function loadRequiredData() {
      loading.value = true;

      // Load Perks
      try {
        await store.dispatch("perks/ensurePerkListItems");
      } catch (err) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Could not load Perks: " + err,
          life: 3000,
        });
      }
      const loadedPerks = store.getters["perks/perkListItems"];
      perks.value = loadedPerks;

      loadVenues();
      loading.value = false;
    }

    async function loadVenues() {
      await store.dispatch("venues/ensureVenueListItems");
      const loadedVenues = store.getters["venues/venueListItems"];
      venues.value = [{ id: null, name: "All" }, ...loadedVenues];
    }

    function addConfig() {
      configs.value.push({
        perkId: {
          id: null,
        },
        venueId: {
          id: null,
        },
        perkConfigurationId: generateUUID(),
        quantity: 1,
        occurence: 0,
        occurenceType: 0,
        expires: true,
        expire: 0,
        expireType: 0,
        reassigns: false,
        expireDate: new Date().toISOString(),
        reassignsUntilTime: "20:00",
      });
    }

    function deleteConfig(index) {
      configs.value.splice(index, 1);
    }

    function assignToExistingMembersNow(perkConfig) {
      if (perkConfig.perkId.id == null) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select a perk",
          life: 3000,
        });
        return;
      }
      if (
        perkConfig.expireDate == null ||
        perkConfig.expireDate == "" ||
        perkConfig.expireDate == undefined ||
        new Date(perkConfig.expireDate) < new Date()
      ) {
        // Check if expire date is before now

        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select an valid expire date",
          life: 3000,
        });
        return;
      }

      confirm.require({
        message:
          "Are you sure you want to assign this perk to existing members? this cannot be undone!",
        header: "Please confirm",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          loading.value = true;

          try {
            var res = await store.dispatch(
              "membershipPerks/assignSignUpPerksToMembersNow",
              { membershipId: membershipId, perkConfig }
            );
            toast.add({
              severity: "success",
              summary: "Process started",
              detail: "Assigning perk to " + res + " members",
              life: 8000,
            });
          } catch (err) {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: "Something went wrong " + err,
              life: 3000,
            });
          }

          loading.value = false;
        },
        reject: () => {},
      });
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      perks,
      venues,
      configs,
      assignToExistingMembersNow,
      addConfig,
      deleteConfig,
      updateExpireDate,
    };
  },
};
</script>
