import apiClient from '@/services/axios'

export async function put(membershipId, payload) {
  var response = await apiClient.put('/membership?membershipId=' + membershipId, payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update Membership.')
}
