<template>
	<Sidebar v-if="hasPermission('np.backoffice.campaigns.import')" v-model:visible="importSidebarVisible" position="right" :showCloseIcon="false">
		<h3>Import Campaigns</h3>
		<div class="flex flex-column">
			<div v-if="importing">
				<div class="spinner-grow" style="width: 3rem; height: 3rem" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>

			<div v-if="importing == false && importComplete == true">
				<Panel header="Import complete">
					<div class="font-semibold">No changes: {{ importStats.noChanges }}</div>
					<div class="mt-2 font-semibold">Updated: {{ importStats.updated }}</div>
					<div class="mt-2 font-semibold">Added: {{ importStats.added }}</div>
				</Panel>
			</div>

			<Button v-if="importComplete" class="flex-1 mt-3 p-button-success" @click="closeSidebar" label="Done" icon="pi pi-check-circle" />
			<Button v-else class="flex-1 mt-3" @click="importCampaigns" label="Import" icon="pi pi-refresh" />
		</div>
	</Sidebar>
	<div class="surface-section p-6 pt-0" v-if="hasPermission('np.backoffice.campaigns.show')">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.campaigns.import')" @click="importSidebarVisible = true" label="Import OnlinePOS Campaigns" icon="pi pi-refresh" />
			</div>
		</div>

		<div v-if="!loading" class="card">
			<DataTable :value="entities" dataKey="id" @row-click="onRowClicked" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll">
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
				<Column field="campaignName" header="Name"></Column>
				<Column field="posCampaignId" header="PosCampaignId"></Column>
				<Column field="type" header="Type"></Column>
				<Column header="Active">
					<template #body="{ data }">
						<Chip v-if="data.active" label="Active" />
						<Chip v-else label="Inactive" />
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
//import { useRouter } from 'vue-router'
import { useStore } from 'vuex';

import { useToast } from 'primevue/usetoast';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const pageTitle = 'Campaigns';
		const pageDescription = 'Here you can manage your Campaigns';
		const loading = ref(true);

		const importing = ref(false);
		const importComplete = ref(false);
		const importStats = ref({});

		const useFormatDate = inject('useFormatDate');

		//const router = useRouter()
		const store = useStore();
		const toast = useToast();

		const entities = ref([]);

		const importSidebarVisible = ref(false);

		function onRowClicked(/*payload*/) {
			//router.push({ name: 'edit-workflow', params: { workflowId: payload.data.id } });
		}

		function closeSidebar() {
			importing.value = false;
			importComplete.value = false;
			importSidebarVisible.value = false;
		}

		async function importCampaigns() {
			importing.value = true;

			try {
				await store.dispatch('campaigns/importOnlinePosCampaigns');
				importStats.value = store.getters['campaigns/lastImportStats'];

				importComplete.value = true;

				toast.add({ severity: 'success', summary: 'Success', detail: 'Campaigns imported', life: 3000 });
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not import Campaigns: ' + err, life: 3000 });
			}

			const loadedEntities = store.getters['campaigns/campaignListItems'];
			entities.value = loadedEntities;

			importing.value = false;

			await loadRequiredData();
		}

		async function loadRequiredData() {
			loading.value = true;

			try {
				await store.dispatch('campaigns/ensureCampaignListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Campaigns: ' + err, life: 3000 });
			}

			const loadedEntities = store.getters['campaigns/campaignListItems'];
			entities.value = loadedEntities;

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			loading,
			pageTitle,
			pageDescription,

			importing,
			importComplete,
			importStats,
			importCampaigns,

			entities,

			importSidebarVisible,

			onRowClicked,
			closeSidebar,

			formatDate: useFormatDate().formatDate,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
