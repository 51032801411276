<template>
    <div class="lf-navbar surface-overlay px-6 shadow-2 flex fixed top-0 left-0 justify-content-between  w-screen "
        style="z-index: 100; height: 84px">
        <h1 class="mr-4 ">
            <router-link :to="{ name: 'home' }" class="cursor-pointer logo align-self-center"><img
                    src="../assets/nightpay_logo_RGB.jpg" style="height: 48px; width: auto;" /></router-link>
            <!-- <router-link :to="{ name: 'home' }" class="cursor-pointer logo mr-0 lg:mr-6 align-self-center">NightPay<span>.</span></router-link> -->
        </h1>
        <!--<img src="images/blocks/logos/bastion-700.svg" alt="Image" height="40" class="mr-0 lg:mr-6 align-self-center">-->
        <a @click="test" class="cursor-pointer block lg:hidden align-self-center text-700">
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div
            class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay ml-2 left-0 top-100 z-1 shadow-2 lg:shadow-none">
            <ul class="list-none p-0 m-0  flex select-none 0 align-items-center">
                <li class="mt-2 px-2  flex align-items-center">
                    <Breadcrumbs :items="breadcrumbs" />
                </li>
            </ul>
            <ul
                class="list-none p-0 m-0 flex select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                <!-- <li>
                    <a class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150">
                        <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Inbox</span>
                    </a>
                </li> -->
                <!-- <li>
                    <a class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150">
                        <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Notifications</span>
                    </a>
                </li> -->
                <li class="border-top-1 surface-border lg:border-top-none flex align-items-center">
                    <h4 class="mr-3 font-semibold text-center">{{ user.name }}</h4>
                    <Avatar @click="toggle" :image="user.picture" class="cursor-pointer shadow-2" size="large"
                        shape="circle" />

                    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 300px"
                        :breakpoints="{ '960px': '75vw' }">
                        <div class="flex mb-3 ">
                            <Avatar @click="toggle" :image="user.picture" class="mr-2 " size="xlarge" shape="circle" />
                            <h3>{{ user.name }}</h3>
                        </div>
                        <Button label="View Profile" style="width:100%;"
                            class="mb-2 p-button-rounded p-button-outlined p-button-info" />
                        <hr style="opacity: 0.4;" />
                        <h4 class="mb-1">Account</h4>
                        <Button label="Switch Account" class="p-button-secondary p-button-text" />
                        <hr style="opacity: 0.4;" />
                        <Button @click="logout" label="Sign Out" class="p-button-danger p-button-text" />
                    </OverlayPanel>
                </li>
                <li class="border-top-1 surface-border lg:border-top-none flex align-items-center ml-3">
                    <Avatar @click="toggleCountries" :image="activeAccountCountryImage"
                        class="w-4rem  cursor-pointer shadow-2  border-round overflow-hidden" style="height: 2.5rem" />
                    <OverlayPanel ref="countrySelector" appendTo="body" :showCloseIcon="false" id="overlay_panel_country"
                        style="width: 200px" :breakpoints="{ '960px': '75vw' }">
                        <div class="flex flex-column gap-2">

                            <div v-for="country in countryAccounts" :key="country" @click="setActiveCountry(country)"
                                class="flex gap-2 align-items-center cursor-pointer h-3rem p-2 border-round hover:bg-gray-300 font-medium"
                                :class="{ 'bg-blue-200': country.id == activeAccountCountry }">
                                <img :src="getCountryInfo(country.countryCode)?.image" class="w-3 h-2 border-round-xs">
                                {{ getCountryInfo(country.countryCode)?.title }}
                            </div>
                        </div>
                    </OverlayPanel>
                </li>

            </ul>
        </div>
    </div>
    <slot />
</template>

<script>
import { ref, computed } from 'vue'
import DKImage from "@/assets/countries/DK.png"
import NOImage from "@/assets/countries/NO.png"
import FIImage from "@/assets/countries/FI.png"
import UKImage from "@/assets/countries/UK.png"

import { useMsal } from '@/plugins/useMsal'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

export default {
    name: "NavBar",
    components: {
    },
    props: ['breadcrumbs'],
    setup() {
        const { instance, accounts } = useMsal();
        const store = useStore()
        const router = useRouter()

        const user = computed(() => {
            if (accounts.value.length > 0) {
                const decoded = accounts.value[0].idTokenClaims

                return {
                    name: decoded.name,
                    firstName: decoded.given_name,
                    lastName: decoded.family_name,
                    role: decoded.extension_UserRole,
                    type: decoded.extension_UserType,
                    picture: 'https://rekomlifestorageprod.blob.core.windows.net/user-uploads/avatars/' + decoded.oid + '.jpg'
                }
            }

            return {}
        })

        async function logout() {
            const returnUrl = window.location.origin;

            await instance.logoutRedirect({
                returnTo: returnUrl,
            });
        }
        const countrySelector = ref()
        const toggleCountries = (event) => {
            countrySelector.value.toggle(event)
        }

        const countryInfo = [{ title: "Denmark", image: DKImage, countryCode: "DK" },
        { title: "Norway", image: NOImage, countryCode: "NO" },
        { title: "Finland", image: FIImage, countryCode: "FI" },
        { title: "United Kingdom", image: UKImage, countryCode: "GB" }
        ];

        const getCountryInfo = (countryCode) => {
            return countryInfo?.find(x => x.countryCode == countryCode)
        }

        const activeAccountCountry = computed(() => store.getters["activeAccountCountry"]);
        const userAccounts = computed(() => store.getters["userAccounts"])
        const countryAccounts = computed(() => store.getters["countryAccounts"])

        const setActiveCountry = (countryAccount) => {
            store.dispatch("setAccountCountry", { accountId: countryAccount.id })
                .then(() => {
                    var selectedAccount = userAccounts.value.find(x => x.accountId == countryAccount.id)
                    store.dispatch("setActiveUserAccount", { accountId: selectedAccount.accountId })
                    if (router.currentRoute.value.name == "no-permissions") {
                        router.push({ name: "home" })
                    } else {
                        router.go()
                    }
                })
        }

        const activeAccountCountryImage = computed(() => getCountryInfo(countryAccounts.value?.find(x => x.id == activeAccountCountry.value)?.countryCode)?.image);

        const op = ref()
        const toggle = (event) => {
            op.value.toggle(event)
        };

        function test() {
            var el = document.getElementById("app-sidebar-2")
            if (el.classList.contains("hidden")) {
                el.classList.remove(["hidden"])
            } else {
                el.classList.add(["hidden"])
            }
        }


        return {
            op,
            countrySelector,
            toggle,
            toggleCountries,
            user,
            logout,

            getCountryInfo,
            activeAccountCountry,
            countryAccounts,

            setActiveCountry,
            activeAccountCountryImage,
            test
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    margin-bottom: 20px;
    font-weight: 700;
}

h1 .logo {
    color: #2A2371;
    text-decoration: none;
}

h1 .logo>span {
    color: #F8B449;
}
</style>
