<template>
	<Dialog v-model:visible="showUpdatingDialog" :showHeader="false" :closeOnEscape="false" :closable="false" :draggable="false" :style="{ width: '50vw' }" :modal="true">
		<Loading title="Deleting User" :full="false" class="mt-5" />
	</Dialog>
	<div class="surface-section">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
		</div>

		<Loading v-if="loading" :title="'Loading User'" :full="false" />
		<div v-else class="card">
			<Button v-if="hasPermission('np.backoffice.users.editdelete')" @click="deleteUser" label="Delete User" icon="pi pi-trash" class="p-button-danger" />
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue';

import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useRoute, useRouter } from 'vue-router';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const pageTitle = 'Danger Zone';
		const pageDescription = 'Critical operations, e.g. Delete user etc.';

		const loading = ref(true);
		const updating = ref(false);

		const store = useStore();
		const toast = useToast();
		const route = useRoute();
		const router = useRouter();

		const user = ref();
		const userId = route.params.userId;

		const showUpdatingDialog = ref(false);

		const fields = ref([
			{
				id: 'showRegisterBeacon',
				type: 'switch',
				title: 'Register Beacon',
				horizontal: true,
			},
			{
				id: 'isStaff',
				type: 'switch',
				title: 'User is staff',
				horizontal: true,
			},
			// {
			//   id: 'showSharePageStaffVersion',
			//   type: 'switch',
			//   title: 'Share Page (staff version)',
			//   horizontal: true
			// },
			// {
			//   id: 'showSendADrinkStaffPage',
			//   type: 'switch',
			//   title: 'Send a Drink (staff version)',
			//   horizontal: true
			// },
			// {
			//   id: 'showRekomBenefits',
			//   type: 'switch',
			//   title: 'Show REKOM Benefits',
			//   horizontal: true
			// },
			{
				id: 'showAssignPerksPage',
				type: 'switch',
				title: 'Assign Perks',
				horizontal: true,
			},
		]);


		async function deleteUser() {
			showUpdatingDialog.value = true;

			try {
				await store.dispatch('users/deleteUser', { userId: userId });
				toast.add({ severity: 'success', summary: 'Success', detail: 'User deleted', life: 3000 });

				router.push({ name: 'users' });
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Success', detail: 'Could not delete User', life: 3000 });
			}

			showUpdatingDialog.value = false;
		}

		async function loadRequiredData() {
			loading.value = true;

			await store.dispatch('users/ensureUser', { userId: userId });

			const loadedUser = store.getters['users/user'](userId);

			user.value = JSON.parse(JSON.stringify(loadedUser));

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			pageTitle,
			pageDescription,
			loading,
			updating,

			deleteUser,

			user,

			fields,

			showUpdatingDialog,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
