import apiClient from '@/services/axios'

export async function put(promoCodeId, payload) {
  var response = await apiClient.put('/promoCodes/' + promoCodeId, payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update Promo Code.')
}
