<template>
	<Sidebar  v-model:visible="createNewSidebarVisible" class="p-sidebar-md" position="right" :showCloseIcon="false">
		<Loading v-if="creating" :title="'Creating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Create {{ entityName }}</h3>
			<Form :fields="fields" :context="newEntity" @onAction="createEntity" :actionLabel="'Create'" :actionIcon="'pi pi-plus-circle'" />
		</div>
	</Sidebar>
    <div>
        <div class="flex flex-wrap">
            <div style="flex: 1" class="min-w-max">
                <div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
                <div class="text-700 mb-2 line-height-3">{{ pageDescription }}.</div>
            </div>
            <div class="flex justify-content-end align-items-center mt-6">
                <Button  @click="createNewSidebarVisible = true" :label="'New unsubscribe cause'" icon="pi pi-plus-circle" />
            </div>
        </div>
        <Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
        <div v-else class="card " >

            <TabView class="tabview-custom " style="margin-top: 0px">
                <TabPanel>
                    <template #header>
                        <i class="pi pi-chart-bar mr-2"></i>
                        <span>Statistics</span>
                    </template>
                    <div class="p-2 flex justify-content-evenly flex-wrap gap-4">
                        <div class="text-center">
                            <label for="pie-chart">Most common causes</label>
                            <Chart v-if="chartData.labels?.length != 0" type="pie" name="pie-chart" :data="chartData" :options="chartOptions" class="w-full md:w-20rem mt-4" />
                        </div>


                        <div class="text-center">
                            <label >Amount of unsubscribe causes</label>
                            <DataTable :value="loadedStatisticsPaged[currentPage]" dataKey="unsubscribeOption" class="p-datatable-lg mt-4" style="min-width: 25rem; max-width: 25rem" 
                            :rowHover="true" responsiveLayout="scroll"
                             lazy paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                            :rows="10" :totalRecords="totalRecords" 
                             @page="onPage($event)">
                                <template #empty> No {{ pageTitle  }} found. </template>
                                <template #loading> Loading {{ pageTitle }}. Please wait. </template>
                                <Column field="unsubscribeOption" header="Cause"></Column>
                                <Column field="amount" header="Amount"></Column>
                              
                            </DataTable>  
                        </div>

                    </div>


                        <div class="text-center mt-4">
                             <label >Subscriber Metrics</label>
                             <subscription-statistics />
                        </div>
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-list mr-2"></i>
                        <span>Options</span>
                    </template>

                    <DataTable :value="loadedUnsubscribeOptions" dataKey="id" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll">
                        <template #empty> No {{ pageTitle }} found. </template>
                        <template #loading> Loading {{ pageTitle }}. Please wait. </template>
                        <Column field="title" header="Name"></Column>
                        <Column header="Status">
                             <template #body="{ data }">
                                <Chip v-if="!data.deleted" label="Active" class="chip-enabled" />
                                <Chip v-else label="Deleted" class="chip-grey" />
                            </template>
                        </Column>
            
                        <Column header="Created">
                            <template #body="{ data }">
                                {{ dateFormatter.formatDate(data.created) }}
                            </template>
                        </Column>
                        <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                            <template #body="{ data }">
                                <Button @click="deleteEntity(data.id)" type="button" icon="fa fa-trash"></Button>
                            </template>
                        </Column>
                    </DataTable>
            </TabPanel>
            </TabView>
			
		</div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import useFormatDate from '../../utils/useFormatDate';
import SubscriptionStatistics from '../Statistics/SubscriptionStatistics.vue';
export default {
components: {
    SubscriptionStatistics
},
setup() {

    const storeActionCreateEntity = "unsubscribeOptions/add"
    const storeActionDeleteEntity = "unsubscribeOptions/delete"

    const pageTitle = 'Statistics and unsubscribe causes';
    const pageDescription = 'Here you can view subscriber metrics and manage your unsubscribe causes';
    const entityName = 'Unsubscribe cause';

    const store = useStore();
    const toast = useToast()

    const loading = ref(false)
    const creating = ref(false)
    const loadedUnsubscribeOptions = ref([])
    const loadedStatistics = ref([])
    const loadedStatisticsPaged = ref({1:[]})
    const totalRecords = ref(0)
    const currentPage = ref(1)
    
    const createNewSidebarVisible = ref(false)

    const fields = ref([
				{
					id: 'title',
					type: 'text-input',
					title: 'Cause',
					help: 'Description of the unsubscribe cause',
				}])
	const newEntity = ref({
                    title: ""
                })
    const dateFormatter = useFormatDate()

    const chartData = ref({
        labels: [],
        datasets: [
                {
                    data: [],

                }
            ]
    });
    const chartOptions = ref({
        plugins: {
            legend: {
                display: false,
                labels: {
                    usePointStyle: true
                }
            }
        }
    });


    const onPage = (event) => {
        var val = event;
        currentPage.value = val.page + 1
    };



    async function createEntity(formContext) {
            creating.value = true

            try {
                console.log(formContext)
                await store.dispatch(storeActionCreateEntity, formContext)

                toast.add({severity: 'success', summary: 'Success', detail: entityName + ' created', life: 3000});
                createNewSidebarVisible.value = false

                await reloadRequiredData();
            
                
            } catch (err) {
                toast.add({severity:'error', summary: 'Error', detail: 'Could not create ' + entityName + ': ' + err, life: 3000});
            }

            creating.value = false
	}


    async function deleteEntity(id) {
            try {
                await store.dispatch(storeActionDeleteEntity, id)

                toast.add({severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000});

                await reloadRequiredData();
            
                
            } catch (err) {
                toast.add({severity:'error', summary: 'Error', detail: 'Could not create ' + entityName + ': ' + err, life: 3000});
            }
	}


    async function reloadRequiredData() {
        try {
            await store.dispatch('unsubscribeOptions/getUnsubscribeOptionListItems');
            const loadedEntities = store.getters['unsubscribeOptions/unsubscribeOptionListItems'];
            loadedUnsubscribeOptions.value = loadedEntities;
            
        } catch (err) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Could not load User Terms: ' + err,
                life: 3000,
            });
        }
    }
    

    async function loadRequiredData() {
        loading.value = true;

        try {
            await store.dispatch('unsubscribeOptions/getUnsubscribeOptionListItems');
            const loadedEntities = store.getters['unsubscribeOptions/unsubscribeOptionListItems'];
            loadedUnsubscribeOptions.value = loadedEntities;

            await loadUnsubscribeOptions()
            
       } catch (err) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Could not load User Terms: ' + err,
                life: 3000,
            });
        }

        loading.value = false
    }

    async function loadUnsubscribeOptions(){
        await store.dispatch('unsubscribeOptions/getUnsubscribeOptionStatisticListItems');
        const loadedStats = store.getters['unsubscribeOptions/statisticListItems']; 
        loadedStatistics.value = loadedStats

        var page = 1
        if(loadedStats.length >= 10){
            for(var x = 1; x <= loadedStats.length; x++){
                console.log(x)
                if (x % 10 == 0){
                    loadedStatisticsPaged.value[page] = loadedStats.slice(x - 10, x)
                    page += 1
                }

                if(x == loadedStats.length){
                    loadedStatisticsPaged.value[page] = loadedStats.slice(x - (x - 10), x)
                }
                
            }
        } else {
             loadedStatisticsPaged.value[1] = loadedStats
        }
        

        chartData.value.labels = loadedStats.slice(0,10).map(x => x.unsubscribeOption)
        chartData.value.datasets[0].data = loadedStats.slice(0,10).map(x => x.amount)

        totalRecords.value = loadedStats.length;

    }


    onMounted(()=>{
         loadRequiredData();
    })

    return {
        pageTitle,
        pageDescription,
        entityName,

        fields,
        newEntity,

        loading,
        loadedUnsubscribeOptions,
        loadedStatistics,

        dateFormatter,
        createNewSidebarVisible,

        createEntity,
        deleteEntity,
        
        totalRecords,
        chartData,
        chartOptions,

        loadedStatisticsPaged,
        currentPage,

        onPage

    }
}

}
</script>
