<template>
	<h3>Edit Perks</h3>

	<div class="field">
		<h4 class="mb-2">Select Country</h4>

		<Dropdown v-model="selectedPerkCountry" :options="countries" optionLabel="label" placeholder="Select country" @change="perkCountryChanged($event)"></Dropdown>
	</div>
	<div v-if="perkCountrySelected">
		<h4>Edit perks for {{ selectedPerkCountry.label }}</h4>
		<Form
			:key="componentKey"
			:fields="perkFields"
			:context="selectedSettings"
			@onAction="updatePerkConfigs"
			:actionLabel="'Update'"
			:actionIcon="'pi pi-cloud-upload'"
		/>
		<!-- @onChange="selectedSettingsChanged" -->
	</div>
</template>

<script>
import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';

export default {
	components: {},
	props: ['context', 'countries'],
	setup(props) {
		const storeActionUpdatePerkConfigs = 'roles/update';

		// const selectedEntity = ref({});
		const selectedSettings = ref({});
		const selectedPerkCountry = ref({});

		const perkCountrySelected = ref(false);

		const perkFields = ref([]);
		const perks = ref([]);

		const entityName = 'Role';

		const store = useStore();
		const toast = useToast();

		const loading = ref(true);
		const updating = ref(false);
		const componentKey = ref(false);

		function perkCountryChanged(event) {
			componentKey.value = !componentKey.value;
			perkCountrySelected.value = true;
			
console.log('props.context', props.context);			
console.log('event.value.value', event.value.value);			
console.log('props.context.PerkConfigs', props.context.perkConfigs);			
console.log('props.context.perkConfigs[event.value.value]', props.context.perkConfigs[event.value.value]);			

			selectedSettings.value = props.context.perkConfigs[event.value.value];
			console.log('selectedSettings', selectedSettings.value);
			console.log(selectedSettings.value);

			if (selectedSettings.value == null) {
				console.log('selectedSettings is null');
				selectedSettings.value = [];
			}
		}

		async function updatePerkConfigs(formContext) {
			// props.context.perkConfigs[selectedPerkCountry.value.value] = formContext;
			console.log('formContext', formContext);
			var values = {
				id: props.context.id,
				countryCode: selectedPerkCountry.value.value,
				perkConfigs: formContext,
			};
			updating.value = true;
			try {
				await store.dispatch(storeActionUpdatePerkConfigs, values);

				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: entityName + ' updated',
					life: 3000,
				});

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not update ' + entityName + ': ' + err,
					life: 3000,
				});
			}

			loading.value = false;
			updating.value = false;
			return;
		}

		async function loadRequiredData() {
			loading.value = true;
			
			// Load Perks
			try {
				await store.dispatch('perks/ensurePerkListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Perks: ' + err, life: 3000 });
			}
			const loadedPerks = store.getters['perks/perkListItems'];
			perks.value = loadedPerks;

			reloadEntities();

			perkFields.value = [
				{
					id: 'perkConfigs',
					type: 'RolePerkConfigs',
					title: 'Perk Configs',
				},
			];

			loading.value = false;
		}

		async function reloadEntities() {
			try {
				// var element = entities.value.find((entity) => entity.id === selectedEntity.value.id);
				// if (element != null) {
				// 	assignPerks(element);
				// }
			} catch (err) {
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Could not load perks: ' + err,
					life: 3000,
				});
			}
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			selectedSettings,
			selectedPerkCountry,
			perkFields,
			perkCountrySelected,
			componentKey,

			updatePerkConfigs,
			perkCountryChanged,
		};
	},
};
</script>
