import apiClient from '@/services/axios'

export async function put(userId, months, prolong) {
  var response = await apiClient.put(`/users/${userId}/pauseMembership` + "?numberOfMonthsPaused=" + months + "&prolong=" + prolong)
  if (response) {
    return response.data
  }

  throw new Error('Unable to pause User membership.')
}
