<template>
    <div class="flex flex-column mb-2">
        <p v-if="configs.length == 0">Please add a Perk Config</p>

        <div style="padding-left: 12px;">
            <div v-for="(perkConfig, index) in configs" :key="index" class="field mb-5 border-1 border-gray-300 p-2 border-round shadow-1">
                <label :for="'config-' + index" class="font-bold text-900" style="font-size:90%;margin-bottom: 2px;">Perk {{index + 1}}</label>

                <div class="p-inputgroup">
                    <Dropdown  
                        v-model="perkConfig.id" 
                        :options="perks" 
                        optionLabel="title" 
                        optionValue="id" 
                        placeholder="Select Perk" />
                    <Button @click="deleteConfig(index)" icon="pi pi-times" class="p-button-warning"/>
                </div>

                <label v-show="!perkConfig.isFromNightPay" :for="'venue-' + index" class="mt-2" style="font-size:90%;margin-bottom: 2px;">Venue</label>
                <div  v-show="!perkConfig.isFromNightPay"  class="p-inputgroup">
                    <Dropdown  
                        v-model="perkConfig.venueId" 
                        :options="venues" 
                        optionLabel="name" 
                        optionValue="id" 
                        filter
                        placeholder="All" />
                </div>
                <label class="mt-2" style="font-size:90%;margin-bottom: 2px;">Is From NightPay</label>
                <div class="p-inputgroup">
                    <InputSwitch  
                        v-model="perkConfig.isFromNightPay" 
                        />
                </div>

            </div>
            <Button @click="addConfig" label="Add Perk" class="mt-2 p-button-secondary p-button-sm" />
        </div>
    </div>
</template>

<script>
import { ref, onMounted} from 'vue'

import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'

export default {
    props: [
        'context',
        'field'
    ],
    setup(props) {
        const store = useStore()
        const toast = useToast()

        const loading = ref(true)
        const perks = ref([])
        const venues = ref([])

        const configs = ref(props.context.perkConfigs)

        function addConfig() {
            configs.value.push({ id: null, venueId: null, isFromNightPay: false })
        }

        function deleteConfig(index) {
            configs.value.splice(index, 1)
        }

        async function loadRequiredData() {
            loading.value = true

            // Load Perks
            try {
                await store.dispatch('perks/ensurePerkListItems');
            } catch (err) {
                toast.add({severity:'error', summary: 'Error', detail: 'Could not load Perks: ' + err, life: 3000})
            }
            const loadedPerks = store.getters['perks/perkListItems']
            perks.value = loadedPerks

            loadVenues();
            loading.value = false
        }


		async function loadVenues(){
			await store.dispatch('venues/ensureVenueListItems');
			const loadedVenues = store.getters['venues/venueListItems'];
			venues.value = [{id: null, name: "All"}, ...loadedVenues];
            console.log(configs.value)

		}

        onMounted(async () => {
            await loadRequiredData()
        })

        return {
            perks,
            venues,

            configs,

            addConfig,
            deleteConfig
        }
    },
}
</script>