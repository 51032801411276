<template>
	<Sidebar v-if="hasPermission('np.backoffice.users.addperkmembercard')" v-model:visible="createNewSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="creating" :title="'Creating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Create {{ entityName }}</h3>
			<Form :fields="memberCardFields" :context="memberCardContext" @onChange="handleExpirationChange" @onAction="createEntity" :actionLabel="'Create'" :actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<div class="surface-section">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.users.addperkmembercard')" @click="createNewSidebarVisible = true" :label="'New ' + entityName" icon="pi pi-plus-circle" />
			</div>
		</div>

		<Loading v-if="loading" :full="false" :title="'Loading ' + pageTitle + '...'" />
		<div v-else class="card">
			<DataTable :value="entities" dataKey="id" class="p-datatable-lg" :rowHover="true" responsiveLayout="scroll">
				<template #empty> No {{ pageTitle }} found. </template>
				<template #loading> Loading {{ pageTitle }}. Please wait. </template>
				<Column field="cachedMemberCard.title" header="Card Type"></Column>
				<Column header="Status">
					<template #body="{ data }">
						<!-- <Chip v-if="data" label="Active" class="chip-enabled" /> -->
						
				<Chip v-if="getMemberCardStatus(data) == 'Deleted'" label="Deleted" class="chip-expired" />
				<Chip v-else-if="getMemberCardStatus(data) == 'Active'" label="Active" class="chip-enabled" />
        <Chip v-else-if="getMemberCardStatus(data) == 'NoConsent'" label="Awaiting consent" class="chip-waiting w-9rem nowrap" />
				<Chip v-else-if="getMemberCardStatus(data) == 'Expired'" label="Expired" class="chip-expired" />

        <Chip v-else label="Inactive" class="chip-disabled" />
                
					</template>
				</Column>
				<Column field="id" header="Card Number"></Column>
				<Column field="cachedMemberCard.onlinePosGroup" header="Online POS Group"></Column>
        <Column field="expiresAt" header="Expires">
              <template #body="{ data }">
                  {{formatDate(data.expiresAt)}}
              </template>
        </Column>

				<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
					<template #body="{ data }">
						<Button v-if="data.deleted == false && hasPermission('np.backoffice.users.addperkmembercard')" @click="confirmDeleteEntity(data)" type="button" icon="pi pi-trash"></Button>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';

import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'userMemberCards/getUserMemberCardListItems';
		const storeGetterGetEntities = 'userMemberCards/userMemberCardListItems';
		const storeActionCreateEntity = 'userMemberCards/add';
		const storeActionDeleteEntity = 'userMemberCards/delete';
		const entityName = 'Member Card';
		const pageTitle = 'Member Cards';
		const pageDescription = "Here you can manage the User's Member Cards";

		const memberCardFields = ref([]);

		const loading = ref(true);
		const creating = ref(false);

		const useFormatDate = inject('useFormatDate');

		const route = useRoute();
		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const entities = ref([]);

		const userId = route.params.userId;
		const user = ref();
		const userMemberCards = ref([]);

		const memberCardContext = ref({
			memberCardId: { id: null },
      defaultExpiresAt: false,
      expiresAt: ""
		});

		const memberCards = ref([]);

		const createNewSidebarVisible = ref(false);

		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000 });
					} catch (err) {
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete ' + entityName + ': ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => {},
			});
		}

    function handleExpirationChange(context){
      if(context.defaultExpiresAt == memberCardContext.value.defaultExpiresAt)
        return;
      if(context.defaultExpiresAt){
          var date = new Date()
          date.setMonth(date.getMonth() + 6);
          context.expiresAt = date
      } else {
        context.expiresAt = null
      }
    
    }

		async function createEntity(context) {
			creating.value = true;

			console.log('CREATE MEMBER CARD:', context);
			try {
				await store.dispatch(storeActionCreateEntity, { userId: userId, memberCardId: context.memberCardId, expiresAt: context.expiresAt });

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' created', life: 3000 });
				createNewSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			creating.value = false;
		}

		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities, { userId: userId });
				const loadedEntities = store.getters[storeGetterGetEntities](userId);
				entities.value = JSON.parse(JSON.stringify(loadedEntities));

				console.log('MEMBER CARDS:', entities.value);
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}


		async function loadRequiredData() {
			loading.value = true;

			// Load User
			await store.dispatch('users/ensureUser', { userId: userId });
			const loadedUser = store.getters['users/user'](userId);
			user.value = JSON.parse(JSON.stringify(loadedUser));

			// Load User's Member Cards
			await store.dispatch('userMemberCards/ensureUserMemberCardListItems', { userId: userId });
			const loadedUserMemberCards = store.getters['userMemberCards/userMemberCardListItems'](userId);
			userMemberCards.value = JSON.parse(JSON.stringify(loadedUserMemberCards));

			// Load Member Cards
			try {
				await store.dispatch('memberCards/ensureMemberCardListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Member Cards: ' + err, life: 3000 });
			}
			const loadedMemberCards = store.getters['memberCards/memberCardListItems'];
			memberCards.value = loadedMemberCards;

			reloadEntities();

			memberCardFields.value = [
				{
					id: 'memberCardId',
					path: 'memberCardId.id',
					type: 'dropdown',
					title: 'Member Card',
					filter: 'true',
					config: {
						options: memberCards,
						optionLabel: 'title',
						optionValue: 'id',
						placeholder: 'Please select Member Card',
					},

				},

        {
					id: 'defaultExpiresAt',
					type: 'switch',
					title: 'Default expiration (6 months)',
					
				},
         {
					id: 'expiresAt',
					type: 'calendar',
					title: 'Custom expiration',
					
				},
			];

			loading.value = false;
		}

    function getMemberCardStatus(memberCard){
        if(memberCard.expired)
          return "Expired";

        if(memberCard.deleted)
          return "Deleted";

        if(!memberCard.enabled)
          return "Inactive";

        if(memberCard.needsConsent && !memberCard.consentGiven)
          return "NoConsent"

        if(memberCard.needsMarketingConsent && !memberCard.marketingConsentGiven)
          return "NoConsent"

        return "Active"
    }



		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			entityName,
			pageTitle,
			pageDescription,
			loading,
			creating,

			memberCardFields,
			memberCardContext,
			createEntity,
			confirmDeleteEntity,
      getMemberCardStatus,
      handleExpirationChange,
			
      memberCards,

			entities,

			createNewSidebarVisible,

			formatDate: useFormatDate().formatDate,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<!--
<template>
  <div class="page">
    <overlay
      animate="zoom-in"
      :opened="addMemberCardOpened"
      :visible="addMemberCardVisible"
      :closeable="true"
      @closed="addMemberCardOpened = addMemberCardVisible = false"
    >
      <div>
        <h5>Add Member Card</h5>
        <hr/>
        
        <div class="form-group">
          <label for="memberCard">Member Card</label>
          <select v-model="selectedMemberCardId" class="form-control">
            <option disabled value="">Please select Member Card</option>
            <option v-for="(option, index) in memberCards" :key="index" :value="option.id">{{ option.title }}</option>
          </select>
        </div>

        <button @click="confirmAddMemberCard" type="button" class="mt-4 btn btn-secondary">
          <i class="fa fa-plus-circle"></i> Add Member Card
        </button>
      </div>
    </overlay>

    <div class="mb-4" style="display: flex;justify-content: space-between;align-items: end;">
      <div>
        <div>
          <p v-if="user" style="margin-bottom:0.2rem;text-transform: uppercase;">Member Cards - {{user.firstName}} {{user.lastName}}</p>
          <h2>Member Cards</h2>
        </div>
        <p>
          User's Member Cards.
        </p>
      </div>
      <div>
        <button @click="addMemberCard" type="button" class="btn btn-primary">
          <i class="fa fa-plus-circle"></i> Add Member Card
        </button>
      </div>
    </div>

    <div class="page-container">
      <Loading v-if="loading" />

      <div v-else>
        <div v-if="userMemberCards.length === 0" style="text-align: center;">
          <img src="@/assets/icons/empty-box.png" alt="" style="opacity: 0.3; width: 178px; height: 178px;">
          <h3 style="color: #b3cbe6;">There are no results.</h3>
        </div>

        <div v-else class="vs-table">
          <table>
            <thead class="vs-table__thead">
              <tr class="vs-table__tr">
                <th class="vs-table__th">
                  <div class="vs-table__th__content">
                    Card Type
                  </div>
                </th>
                <th class="vs-table__th">
                  <div class="vs-table__th__content">
                    Active
                  </div>
                </th>
                <th class="vs-table__th">
                  <div class="vs-table__th__content">
                    Card Number
                  </div>
                </th>
                <th class="vs-table__th">
                  <div class="vs-table__th__content">
                    Online POS Group
                  </div>
                </th>
                <th class="vs-table__th">
                  <div class="vs-table__th__content">
                    Actions
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="vs-table__tbody">
              <tr @click="onRowClicked(userMemberCard)" class="vs-table__tr" v-for="userMemberCard in userMemberCards" :key="userMemberCard.id">
                <td class="vs-table__td">{{userMemberCard.cachedMemberCard.title}}</td>
                <td class="vs-table__td">
                  <span class="badge bg-success" style="padding: 5px 8px;">Active</span>
                </td>
                <td class="vs-table__td">{{userMemberCard.id}}</td>
                <td class="vs-table__td">{{userMemberCard.cachedMemberCard.onlinePosGroup}}</td>
                <td class="vs-table__td">
                  <button @click.stop.prevent="deleteUserMemberCard(userMemberCard)" class="ml-2 btn btn-warning round">
                    <i class="fas fa-trash" style="font-size:0.9rem;"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

import Overlay from "@/components/Overlay";

export default {
  components: {
    Overlay
  },
  props: {},
  setup() {
    const loading = ref(true)
    const user = ref(undefined)
    const memberCards = ref([])
    const userMemberCards = ref([])

    const addMemberCardOpened = ref(false)
    const addMemberCardVisible = ref(false)
    const selectedMemberCardId = ref()

    const store = useStore()
    const route = useRoute()
    const toast = useToast()

    const userId = route.params.userId

    function addMemberCard() {
      addMemberCardOpened.value = true
      addMemberCardVisible.value = true
    }

    async function confirmAddMemberCard() {
      loading.value = true

      addMemberCardOpened.value = false
      addMemberCardVisible.value = false

      try {
        await store.dispatch('userMemberCards/add', { 
          userId: userId, 
          memberCardId: selectedMemberCardId.value 
        })
        toast.success('Member Card added')

        // Load User's Member Cards
        await store.dispatch('userMemberCards/ensureUserMemberCardListItems', { userId: userId });
        const loadedUserMemberCards = store.getters['userMemberCards/userMemberCardListItems'](userId)
        userMemberCards.value = JSON.parse(JSON.stringify(loadedUserMemberCards))
      } catch (err) {
        toast.error('Could not add MemberCard: ' + err)
      }

      loading.value = false
    }

    async function loadRequiredData() {
      loading.value = true

      // Load User
      await store.dispatch('users/ensureUser', { userId: userId });
      const loadedUser = store.getters['users/user'](userId)
      user.value = JSON.parse(JSON.stringify(loadedUser))

      // Load Member Cards
      await store.dispatch('memberCards/ensureMemberCardListItems');
      const loadedMemberCards = store.getters['memberCards/memberCardListItems']
      memberCards.value = loadedMemberCards

      // Load User's Member Cards
      await store.dispatch('userMemberCards/getUserMemberCardListItems', { userId: userId });
      const loadedUserMemberCards = store.getters['userMemberCards/userMemberCardListItems'](userId)
      userMemberCards.value = JSON.parse(JSON.stringify(loadedUserMemberCards))

      loading.value = false
    }

    loadRequiredData();

    return {
      loading,

      user,
      userMemberCards,
      memberCards,

      addMemberCardOpened,
      addMemberCardVisible,
      selectedMemberCardId,

      addMemberCard,
      confirmAddMemberCard
    }
  }
};
</script>
-->
