import apiClient from '@/services/axios';

export async function put(membershipId, payload) {
	var perkConfigs = { perkConfigs: payload.perkConfigs };
	var response = await apiClient.put('MembershipPerks?membershipId=' + membershipId, perkConfigs);
	if (response) {
		return response.data;
	}

	throw new Error('Unable to update Membership perk configs.');
}
