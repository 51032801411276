<template>
	<Dialog v-model:visible="showUpdatingDialog" :showHeader="false" :closeOnEscape="false" :closable="false" :draggable="false" :style="{ width: '50vw' }" :modal="true">
		<Loading :title="'Updating ' + entityName" :full="false" class="mt-5" />
	</Dialog>
	<div class="surface-section pt-0">
		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex justify-content-end align-items-center">
				<Button v-if="hasPermission('np.backoffice.venues.editdelete')" @click="updateEntity" label="Save Venue" icon="pi pi-cloud-upload" />
			</div>
		</div>

		<Loading v-if="loading" :title="'Loading Venue'" :full="false" />
		<div v-else class="card">
			<Form :fields="fields" :context="generalInfo" @onChange="generalInfoUpdated" :hasAction="false" />
			<br />
			<p v-if="venue.isOnline" class="font-semibold">Venue is flagged as an Online venue. To set the address, please remove this flag under the NightPay tab.</p>
			<GoogleMapsAddress v-else :context="address" @onChange="addressUpdated"></GoogleMapsAddress>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';

import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import GoogleMapsAddress from '../../../components/GoogleMapsAddress.vue';
import { uploadFile} from '@/services/fileUploadService'

export default {
	inject: ['hasPermission'],
	components: {
		GoogleMapsAddress,
	},
	setup() {
		const storeActionUpdateEntity = 'venues/updateVenueGeneralInfo';
		const entityName = 'General Info';
		const pageTitle = 'General';
		const pageDescription = 'Venue information is synced from IDM.';

		const loading = ref(true);
		const updating = ref(false);
	
		const store = useStore();
		const toast = useToast();
		const route = useRoute();

		const venueId = route.params.venueId;
		const venue = ref();
		const generalInfo = ref();
		const address = ref();

		const activeCountryCode = computed(() => {
			var activeAccountCountry = store.getters["activeAccountCountry"]
			var countryAccounts = store.getters["countryAccounts"]
			return countryAccounts?.find(x => x.id == activeAccountCountry)?.countryCode
		});


		const showUpdatingDialog = ref(false);

		const fields = ref([
			{
				id: 'name',
				type: 'text-input',
				title: 'Name',
				help: 'Name of the venue as shown in the app.',
			},
			{
				id: 'description',
				type: 'text-area',
				title: 'Description',
			},
			{
				id: 'venueLogoUrl',
				type: 'text-input',
				title: 'Venue Logo Url',
				config: {
					readonly: 'true',
				},
			},
			{
				id: 'nightPayLogoUrl',
				type: 'imageUpload',
				title: 'NightPay Logo',
			},
		]);


		function generalInfoUpdated(formContext) {
			venue.value = { ...formContext, ...address.value };

		}

		function addressUpdated(formContext) {
			venue.value = { ...generalInfo.value, ...formContext };
		}

		async function updateEntity() {
			showUpdatingDialog.value = true;

			try {
				await UploadImagesIfNeeded()
				await store.dispatch(storeActionUpdateEntity, { venueId: venueId, generalInfo: venue.value });

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' updated', life: 3000 });

				loading.value = true;
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			showUpdatingDialog.value = false;
		}

		async function UploadImagesIfNeeded(){

				var imageContainer = generalInfo.value.name + "-" + activeCountryCode.value + "-images"
				if(venue.value.venueLogoUrl && venue.value.venueLogoUrl?.name ){
					var pictureFile = await uploadFile(venue.value.venueLogoUrl, "venues", imageContainer, store)
					venue.value.venueLogoUrl = pictureFile.url
				}
				if(venue.value.nightPayLogoUrl && venue.value.nightPayLogoUrl?.name ){
					var iconFile = await uploadFile(venue.value.nightPayLogoUrl, "venues", imageContainer, store)
					venue.value.nightPayLogoUrl = iconFile.url
				}
		}

		async function loadRequiredData() {
			loading.value = true;

			await store.dispatch('venues/getVenue', { venueId: venueId })
			.catch((err)=>{
				toast.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 })
				loading.value = false
			});
			const loadedVenue = store.getters['venues/venue'](venueId);

			generalInfoUpdated(loadedVenue);
			addressUpdated(loadedVenue);

			generalInfo.value = {
				name: loadedVenue.name,
				description: loadedVenue.description,
				isOnline: loadedVenue.isOnline,
				venueLogoUrl: loadedVenue.venueLogoUrl,
				nightPayLogoUrl: loadedVenue.nightPayLogoUrl,
			};

			address.value = {
				street: loadedVenue.street,
				postalCode: loadedVenue.postalCode,
				city: loadedVenue.city,
				country: loadedVenue.country,
				latitude: loadedVenue.latitude,
				longitude: loadedVenue.longitude,
			};

			loading.value = false;
		}

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			pageTitle,
			pageDescription,
			loading,
			updating,

			generalInfoUpdated,
			addressUpdated,
			updateEntity,

			venue,
			generalInfo,
			address,

			fields,

			entityName,

			showUpdatingDialog,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vue-map-container {
	height: 400px;
}
</style>
