<template>
    <ul class="list-none p-3 pl-0 m-0 mb-2 surface-card flex align-items-center font-medium overflow-x-auto">
      <li class="pr-3">
        <router-link :to="{ name: 'home' }" class="cursor-pointer">
          <i class="pi pi-home text-blue-500"></i>
        </router-link>
      </li>
      <li v-if="items?.length != 0">
        <i class="pi pi-angle-right text-500"></i>
      </li>
      <div v-for="(item, index) in items" :key="index" class="breadcrumb-item">
        <li class="px-2">
          
          <a @click="item?.route ? $router.push({name: item.route}) : ''" class="cursor-pointer text-900 white-space-nowrap" :class="{'text-blue-500': index < items.length - 1}">{{item.title}}</a>
        </li>
        <li v-if="index < items.length - 1">
          <i class="pi pi-angle-right text-500"></i>
        </li>
      </div>
      <!--
      <li class="px-2">
        <a class="cursor-pointer text-900 white-space-nowrap">Dokumenter</a>
      </li>
      -->
    </ul>
</template>

<script>
export default {

  components: {

  },
  


  props: ['items'],
  setup(props) {
    console.log(props.items)
    return {
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.breadcrumb-item {
  display: flex;
}
</style>