import * as apiCreatePerk from '@/api/perks/createPerk'
import * as apiGetPerk from '@/api/perks/getPerk'
import * as apiGetPerks from '@/api/perks/getPerks'
import * as apiUpdatePerk from '@/api/perks/updatePerk'
import * as apiDeletePerk from '@/api/perks/deletePerk'
import * as apiGetSubscriptionPerkConfigurations from '@/api/subscriptionConfigurations/getSubscriptionConfigurations'
import * as apiUpdateSubscriptionPerkConfigurations from '@/api/subscriptionConfigurations/updateSubscriptionConfigurations'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        perkListItems: [],
        detailedPerks: {},
        subscriptionPerks: [],
    }),
    mutations: {
        gotPerkListItems(state, perks) {
            state.perkListItems = perks
        },

        gotPerk(state, perk) {
            state.detailedPerks[perk.id] = perk
        },

        gotSubscriptionPerks(state, perks) {
            state.subscriptionPerks = perks
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getPerkListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Perk List Items');
        
            const result = await apiGetPerks.get();

            if (result) {
                commit('gotPerkListItems', result)
            }
        
            commit('isLoading', false)
        },

        async getSubscriptionPerkItems({ commit }) {
            commit('isLoading', true)
        
            const result = await apiGetSubscriptionPerkConfigurations.get();

            if (result) {
                commit('gotSubscriptionPerks', result)
            }
        
            commit('isLoading', false)
        },

        async updateSubscriptionPerks({ commit }, payload) {
            commit('isLoading', true)
        
            const result = await apiUpdateSubscriptionPerkConfigurations.post(payload);
        
            commit('isLoading', false)

            return result
        },

        async create({ commit }, payload) {
            commit('isLoading', true)

            console.log('Posting:', payload)

            const result = await apiCreatePerk.post(payload)

            commit('isLoading', false)

            return result
        },

        async update({ commit }, perk) {
            commit('isLoading', true)

            await apiUpdatePerk.put(perk.id, perk)

            commit('isLoading', false)
        },

        async delete({ commit }, entityId) {
            commit('isLoading', true)

            const result = await apiDeletePerk.del(entityId)

            commit('isLoading', false)

            return result
        },

        async getPerk({ commit }, { perkId }) {
            commit('isLoading', true)

            console.log('Getting Perk:', perkId);
        
            const result = await apiGetPerk.get(perkId)
            
            if (result) {
                commit('gotPerk', result)
            }
        
            commit('isLoading', false)
        },

        async ensurePerkListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.perkListItems.length === 0) {
                await dispatch('getPerkListItems')
            }

            commit('isLoading', false)
        },

        async ensurePerk({ commit, dispatch, getters }, { perkId }) {
            commit('isLoading', true)

            if (!getters.perk(perkId)) {
                console.log('Perk not cached. Getting from API.')
                await dispatch('getPerkListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        subscriptionPerkListItems: (state) => state.subscriptionPerks,

        perkListItems: (state) => state.perkListItems,
        perk: (state) => (perkId) => {
            return  _.find(state.perkListItems, function(perk) { return perk.id === perkId; });
        }
    }
};