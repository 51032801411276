import apiClient from './index'
import TokenService from './tokenService'

const setupInterceptors = (acquireToken) => {
    console.log('Setting up Interceptors...')

    apiClient.interceptors.request.use(request => {
        return new Promise(function (resolve) {
            const token = TokenService.getLocalAccessToken()
            if (token) {
                request.headers.Authorization = `Bearer ${token}`
                //request.headers.AccessToken = token

                const activeUserAccount = localStorage.activeUserAccount
                const activeCountryAccount = localStorage.activeCountryAccount

                if (activeUserAccount) {
                    request.headers.AccountId = activeUserAccount
                    request.headers.CountryAccountId = activeCountryAccount
                }
            }

            request.headers.ClientTimezone = new Date().toString().match(/([A-Z]+[+-][0-9]+.*)/)[1]

            resolve(request)
        })
    })

    apiClient.interceptors.response.use( 
        undefined, // Response
        
        async (err) => {
            const originalConfig = err.config;

            if (/*originalConfig.url !== "/auth/signin" && */err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        console.log('Access Token expired. Acquiring new...')

                        const result = await acquireToken()
                        if (result.value) {
                            TokenService.updateLocalAccessToken(result.value.accessToken)
                            return apiClient(originalConfig);
                        }
                    } catch (_error) {
                        console.log('interceptors error')
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err?.response?.data?.Message ?? err);
        }
    )
}

export default setupInterceptors