<template>

    <Sidebar v-model:visible="createNewSidebarVisible" class="p-sidebar-lg" position="right" :showCloseIcon="false">
        <div >

            <div class="flex flex-column mb-3">
                <h3 class="mb-1">Create new label for {{ title }}</h3>
            </div>           
            <Form :fields="formFields" :context="newEntity" :actionLabel="'Add to ' + title" @onChange="onFormChange" @onAction="addLabel" :actionIcon="'pi pi-plus-circle'" />
        </div>
    </Sidebar>
  <Sidebar v-model:visible="editEntitySidebarVisible"  class="p-sidebar-lg" position="right" :showCloseIcon="false">
        <div >

            <div class="flex flex-column mb-3">
                <h3 class="mb-1">Edit label for {{ title }}</h3>
            </div>           
            <Form :fields="formFields" :context="editEntity" :actionLabel="'Update label'" @onChange="onFormChange"  @onAction="updateLabel" :actionIcon="'pi pi-plus-circle'" />
        </div>
    </Sidebar>

    <div>
        <div class="flex">
        <h2>{{title}}</h2>
        <Button  :disabled="maxLabels <= labels?.length"  class="ml-auto text-xs my-auto" :class="maxLabels <= labels?.length ? 'p-button-danger':''" icon="pi pi-plus-circle" :label="maxLabels <= labels?.length ? 'Max 3 labels' : 'New'" @click="showAddNew"></Button>
        </div>
        <div class="p-2 flex gap-2 mx-auto flex-column overflow-hidden relative" :class="type" style="width: 420px">

       
            <div v-if="type != 'bottomSection'" class="h-4rem flex justify-content-center ">
                <img :src="nplogo" width="150"/>
            </div>
            

            <div v-for="label, i in cardLabels" :key="label?.orderNumber" class="w-full pb-2 px-3 border-round-3xl relative text-white label">
                <div class="right-0 label-edit ">
                    <Button   class=" my-auto" icon="pi pi-pencil" label="" @click="edit(label, i)"></Button>
                    <Button class="text-xs mx-2 p-button-danger  my-auto " icon="pi pi-trash" label="" @click="removeLabel(i)"></Button>
                </div>
                <div class="flex-column h-full gap-2 top-0 justify-center label-edit">
                    <Button  class="text-xs p-button  text-gray-300  mt-auto " icon="pi pi-chevron-up " label="" @click="changeLabelOrder(i, i - 1)"></Button>
                    <Button class="text-xs p-button text-gray-300 mb-auto " icon="pi pi-chevron-down " label="" @click="changeLabelOrder(i, i + 1)"></Button>
                </div>
            
                <div v-if="!label.recurringDrinkAsTitle" class="text-center py-3 flex flex-column ">
                    <img v-if="label?.iconUrl" class="mx-auto" style="width:4rem; height: 4rem; object-fit: contain" :src="label?.iconUrl" />
                    <h4  v-if="label?.subtitleTop" class="subtitle">{{label.subtitleTop}}</h4>
                    <h1 v-if="label?.title" class="m-0" :style="getFontSize(label.titleSize)"  >{{label.title}}</h1>
                    <h4  v-if="label?.subtitleBottom" class="subtitle" >{{label.subtitleBottom}}</h4>
  
                </div>
                <div v-else class="text-center py-3 flex justify-content-center flex-column ">
                    <div class="flex justify-content-center align-items-center gap-2">
                        <img v-if="label?.iconUrl" class="-mb-2" style="width:5rem; height: 5rem; object-fit: contain" :src="label?.iconUrl" />
                        <div class="flex ">
                            <h1 v-if="label?.title" class="m-0  p-0" :style="'font-size: 4rem; font-weight: 900'"  >{{label.title}} x</h1>
                            <h2  v-if="label?.subtitleTop" style="font-weight: 900" class="m-0 w-8rem align-self-end mb-2">{{label.subtitleTop}}</h2>
                        </div>
                    </div>
                    <h4  v-if="label?.subtitleBottom" class="subtitle" >{{label.subtitleBottom}}</h4>
                </div>
            
            </div>
             
            <div v-if="bottomImage" class="w-full justify-content-center flex mt-auto" >
                    <img id="freeusercard-img"  class="absolute bottom-0" style="width:423px; object-fit: contain" :src="bottomImage"   />
            </div>
        </div>
    </div>
</template>



<script>
import { computed, ref } from 'vue';
import nplogo from "./1231nplogo.svg"


export default {
    components: {
    },
    props: ['title', 'labels', 'formFields', 'language', 'type', 'maxLabels', 'bottomImage'],
    emits: ["onAddNew", "onRemove", "onChangeOrder", "onUpdate", "onFormChange"],

   
	setup(props, {emit}) {

        // const storeActionUpdateEntity = 'appContents/update'

        
        const cardLabels = computed(()=>{
            return props.labels?.map(x => 
                {
               
                    return{...x}
                }
            )
        })

        const createNewSidebarVisible = ref(false)
        const editEntitySidebarVisible = ref(false)

        const isEditing = ref(false)
        const context = ref({
            title: "",
            subtitleTop: "",
        })

        const newEntity = ref({
            title: "",
            subtitleTop: "",
            subtitleBottom: "",
            iconUrl: null,
            recurringDrinkAsTitle: false,
            titleSize: "Small"
        })

        const editEntity = ref({
            title: "",
            subtitleTop: "",
            subtitleBottom: "",
            iconUrl: null,
            recurringDrinkAsTitle: false,
            titleSize: "Small"

        })

        const editEntityIndex = ref(0)

        function updateLabel(formContext){
            editEntitySidebarVisible.value = false
            emit("onUpdate", formContext, editEntityIndex.value)

        }
        

        function addLabel(formContext){
            createNewSidebarVisible.value = false

            emit("onAddNew", formContext)
        }

        function removeLabel(index){
            emit("onRemove", index)
        }

        function changeLabelOrder(oldIndex, newIndex){
            if(newIndex < 0 || newIndex >= props.labels?.length) return
            emit("onChangeOrder", {oldIndex, newIndex})
        }

        function hasAnyValueForLanguage(label){
           var keys = Object.keys(label)
           return !keys.every(x => !label[x][props.language])

        }

        function onFormChange(context){
            emit("onFormChange", context)
            if(context?.recurringDrinkAsTitle){
                context.title = Number(context.title)
            }
        }


        function getFontSize(size){
            if(size == "Small") return ""
            if(size == "Medium") return " font-size: 4rem; font-weight: 900"
            if(size == "Large") return " font-size: 6rem; font-weight: 900"

           return ''
        }

     


        function edit(label, index){
            editEntity.value = label
            editEntityIndex.value = index
            if(label?.recurringDrinkAsTitle){
                editEntity.value.title = Number(label.title)
            }
             emit("onFormChange", label)
            editEntitySidebarVisible.value = true
        }

        function showAddNew(){
             emit("onFormChange", newEntity)
            createNewSidebarVisible.value = true
        }

       
        
        
        

    
        
        return {
            edit,
            showAddNew,
            addLabel,
            removeLabel,
            updateLabel,
            hasAnyValueForLanguage,
            changeLabelOrder,
            isEditing,
            context,
            createNewSidebarVisible,
            editEntitySidebarVisible,
            newEntity,
            editEntity,
            cardLabels,
            getFontSize,
            onFormChange,
        nplogo
    }

    
}
}
</script>

<style scoped>
.label-edit{
    display: none;
}

.label:hover  .label-edit{
  display:  flex;
  position: absolute;
}

.label:hover {
      background-color: rgba(255, 255, 255, 0.45);

}

.fullSection{
min-height: 38rem;
border-color: #F4BA01;
border-width: 3px;
border-style: solid;
border-radius: 1.5rem;
background-image: linear-gradient(to right top, #081654, #081654, #122886, #122886, #122886);
}

.topSection{
min-height: 22rem;
border-color: #F4BA01;
border-width: 3px;
border-style: solid;
border-bottom: none;
border-radius: 1.5rem;

border-bottom-right-radius: 0px;
border-bottom-left-radius: 0px;

background-image: linear-gradient(to right top, #081654, #081654, #122886, #122886, #122886);
}
.bottomSection{
min-height: 22rem;
border-color: #F4BA01;
border-width: 3px;
border-style: solid;
border-top: none;
border-radius: 1.5rem;
border-top-right-radius: 0;
border-top-left-radius: 0;
background-image: linear-gradient(to left top, #081654, #122886, #122886, #081654, #081654);
}

.subtitle{
    color: #c19916;
    font-size: 0.8rem;
    margin-top: 0;
    margin-bottom: 0rem;
}

.fullSection:first-child h1{
    font-size: 14rem;
    background-color: red;
}


.image-placeholder{
    background-color: #9c9c9c8a;
    height: 8rem;
    position: absolute;

    
}
</style>