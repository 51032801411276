import * as apiGetSpecialOffers from '@/api/paymentPlanOffers/getSpecialOffer';
import * as apiUpsertSpecialOffer from '@/api/paymentPlanOffers/upsertSpecialOffer';
import * as apiEnableSpecialOffer from '@/api/paymentPlanOffers/enableSpecialOffer';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
        specialOffer: undefined,
	}),
	mutations: {
		isLoading(state, loading) {
			state.loading = loading;
		},
        gotSpecialOffer(state, offer){
			state.specialOffer = offer

        },
	},
	actions: {
        async getSpecialOffer({ commit }) {
			commit('isLoading', true);

            const result = await apiGetSpecialOffers.get();

            if (result) {
				commit('gotSpecialOffer', result)
			}
			commit('isLoading', false);
		},



        async upsertSpecialOffer({ commit }, payload) {
            commit('isLoading', true)

            await apiUpsertSpecialOffer.post(payload);

            commit('isLoading', false)
        },

        async enableSpecialOffer({ commit }, payload) {
            commit('isLoading', true)

            await apiEnableSpecialOffer.put(payload);

            commit('isLoading', false)
        }

    },
    getters: {
        specialOffer: (state) => state.specialOffer
    }
}