import apiClient from '@/services/axios'

export async function del(userId) {
  var response = await apiClient.delete('/users/' + userId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to delete User.')
}
