import apiClient from '@/services/axios'

export async function put(enable) {
  var response = await apiClient.put('/Memberships?enable=' + enable)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update freemium configuration.')
}
