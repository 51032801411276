

import * as apiGetAppContent from '@/api/appContent/getAppContent';
import * as apiUpdateAppContent from '@/api/appContent/updateAppContent';
import * as apiUpdateSignupCardInfo from '@/api/appContent/updateSignupCardInfo';
import * as apiUpdateFreeUserCardInfo from '@/api/appContent/updateFreeUserCardInfo';
import * as apiUpdateActiveCardInfo from '@/api/appContent/updateActiveUserCardInfo';

import * as apiCreateAppContent from '@/api/appContent/createAppContent';

export default {
	namespaced: true,
	state: () => ({
		loading: false,
        appContent: null
	}),
	mutations: {
		isLoading(state, loading) {
			state.loading = loading;
		},
        gotAppContent(state, appContent) {
			state.appContent = appContent
		},
	},
	actions: {
        async getAppContent({ commit }) {
			commit('isLoading', true);

            const result = await apiGetAppContent.get();

			if (result) {
				commit('gotAppContent', result)
			}
			commit('isLoading', false);
		},




        async create({ commit }, appContent) {
			commit('isLoading', true);

			await apiCreateAppContent.post(appContent);

			commit('isLoading', false);
		},
		
        async update({ commit }, appContent) {
			commit('isLoading', true);

			await apiUpdateAppContent.put(appContent.id, appContent);

			commit('isLoading', false);
		},

		async updateSignupCardInfo({ commit,}, payload) {
			commit('isLoading', true);

			await apiUpdateSignupCardInfo.put(payload.appContentId, {signupCardInfo: payload.signupCardInfo});

			commit('isLoading', false);
		},

		async updateFreeUserCardInfo({ commit,}, payload) {
			commit('isLoading', true);

			await apiUpdateFreeUserCardInfo.put(payload.appContentId, {freeUserCardInfo: payload.freeUserCardInfo});

			commit('isLoading', false);
		},
		async updateActiveUserCardInfo({ commit,}, payload) {
			commit('isLoading', true);

			await apiUpdateActiveCardInfo.put(payload.appContentId, {activeUserCardInfo: payload.activeUserCardInfo});

			commit('isLoading', false);
		},
	},
	getters: {
        appContent: (state) => state.appContent,
		appContentId: (state) => state.appContent?.id,
		signupCardInfo: (state) => state.appContent?.signupCardInfo,
		freeUserCardInfo: (state) => state.appContent?.freeUserCardInfo,
		activeUserCardInfo: (state) => state.appContent?.activeUserCardInfo

    },
};