import apiClient from '@/services/axios'

export async function put(memberCardId, payload) {
  var response = await apiClient.put('/memberCards/' + memberCardId, payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update Member Card.')
}
