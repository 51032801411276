import apiClient from '@/services/axios'

export async function del(userId, cancelAtPeriodEnd = true) {
  var response = await apiClient.delete('/users/' + userId + "/membership" + "?cancelAtPeriodEnd=" + cancelAtPeriodEnd)
  if (response) {
    return response.data
  }

  throw new Error('Unable to cancel User membership.')
}
