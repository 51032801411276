<template>
  <div class="surface-section">
    <div class="flex">
      <div style="flex: 1">
        <div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
        <div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
      </div>
      <div class="flex gap-2 justify-content-end align-items-center">
        <Button @click="loadRequiredData" label="Refresh" :loading="loading" icon="pi pi-refresh" />
      </div>
    </div>

    <Loading v-if="loading" :title="'Loading event log'" :full="false" />
    <div v-else class="relative">
      <!-- Timeline -->
      <Timeline
        :value="filteredEventLog"
        v-auto-animate
        dataKey="eventId"
        class="z-0"
        :pt="{
          opposite: 'font-bold text-xl'
        }"
      >
        <template #marker>
          <span class="flex w-2rem h-2rem align-items-center justify-content-center border-circle z-1 shadow-1 text-grey-200">
            <i class="pi pi-clock"></i>
          </span>
        </template>
        <template #opposite="slotProps">
          <div :key="slotProps.item.eventId">
            <div class="mt-2">
              <small class="text-color-secondary text-sm">{{ formatDateTimeFullTimezone(slotProps.item.timestamp) }}</small>
            </div>
            <div class="mt-2">
              <small class="font-bold text-sm" :class="'aggregate-color-' + slotProps.item.eventAggregateSource">
                {{ slotProps.item.eventAggregateSource }}
                <i :class="aggregateSourceToIcon(slotProps.item.eventAggregateSource)"></i>
              </small>
            </div>
            <div>
              <small class="font-bold font-italic text-sm">{{ aggregateIdentityLookup[slotProps.item.mainAggregateId] }}</small>
            </div>
          </div>
        </template>
        <template #content="slotProps">
          <div class="relative" :key="slotProps.item.eventId">
            <Card class="mb-4 shadow-3 border-round-lg">
              <template #title>
                <div class="flex flex-row justify-content-between align-items-end">
                  <div>
                    {{ slotProps.item.eventName }}
                  </div>
                  <div class="ml-2 mb-1">
                    <Tag :value="slotProps.item.eventSource" severity="info" class="text-xs" />
                  </div>
                </div>
              </template>
              <template #subtitle>
                {{ slotProps.item.eventDescription }}
              </template>
              <template #content>
                <div style="min-width: 38rem; width: 100%">
                  <Panel toggleable header="Properties" :collapsed="true">
                    <DataTable :value="slotProps.item.changes">
                      <Column field="propertyName" header="Property">
                        <template #body="slotProps">
                          <div>
                            <!-- <Tag :value="slotProps.data.propertyName" severity="primary" /> -->
                            <b>{{ slotProps.data.propertyName }}</b>
                          </div>
                          <div>
                            <i>{{ slotProps.data.propertyComment }}</i>
                          </div>
                        </template>
                      </Column>
                      <!-- <Column field="propertyComment" header="Description"></Column> -->
                      <Column
                        field="value"
                        header="Value"
                        style="white-space: pre-line; word-break: break-word; font-family: Consolas, monospace"
                      ></Column>
                    </DataTable>
                  </Panel>
                </div>
              </template>
            </Card>
            <div
              class="absolute left-0 top-0 bottom-0 border-round-left-lg"
              style="width: 6px"
              :class="'aggregate-bgcolor-' + slotProps.item.eventAggregateSource"
            ></div>
          </div>
        </template>
      </Timeline>

      <!-- Filters -->
      <div class="fixed z-0 w-24rem filters-card" :class="{ collapsed: filtersCollapsed }">
        <Card class="shadow-4 border-round-right-lg">
          <template #header>
            <div class="flex justify-content-between align-items-end">
              <div class="font-bold text-900 text-2xl pt-4 pl-3">
                <span class="fa fa-cog mr-2"></span>
                <span>Filters</span>
              </div>

              <div class="mr-2 relative">
                <Button
                  icon="fa fa-chevron-left"
                  severity="secondary"
                  text
                  class="absolute right-0 bottom-0"
                  :class="{ hidden: filtersCollapsed }"
                  @click="filtersCollapsed = !filtersCollapsed"
                />
                <Button
                  icon="fa fa-cog"
                  severity="secondary"
                  text
                  :class="{ hidden: !filtersCollapsed }"
                  class="absolute right-0 bottom-0"
                  @click="filtersCollapsed = !filtersCollapsed"
                />

                <!-- <div class="absolute right-0 top-0 mt-1 -mr-1 font-bold text-sm" :class="{ hidden: !filtersCollapsed }">Filters</div> -->
              </div>
            </div>
          </template>
          <template #content>
            <div class="flex flex-column align-items-start justify-content-start filters-content">
              <div class="flex gap-2">
                <div>
                  <label for="startDate" class="text-sm pb-1">From date</label>
                  <Calendar id="startDate" v-model="fromDate" dateFormat="dd-mm-yy" :minDate="minDate" :maxDate="maxDate" showTime />
                </div>

                <div>
                  <label for="startDate" class="text-sm pb-1">To date</label>
                  <Calendar id="startDate" v-model="toDate" dateFormat="dd-mm-yy" :minDate="minDate" :maxDate="maxDate" showTime />
                </div>
              </div>

              <label for="startDate" class="text-sm mt-3 pb-1">Event aggregate source</label>
              <TreeSelect
                v-model="selectedAggregateSources"
                :options="aggregateSourcesTreeNode"
                placeholder="Select aggregate sources"
                :maxSelectedLabels="3"
                class="w-full md:w-22rem"
                selectionMode="checkbox"
              />

              <label for="startDate" class="text-sm mt-3 pb-1">Event origin</label>
              <MultiSelect
                v-model="selectedAggregateOrigins"
                :options="aggregateOrigins"
                placeholder="Select aggregate origins"
                :maxSelectedLabels="3"
                class="w-full md:w-22rem"
                selectionMode="checkbox"
              />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from "vue";

import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  inject: ["hasPermission"],
  components: {},
  setup() {
    const entityName = "EventLog";
    const pageTitle = "Event log";
    const pageDescription = "The event sourcing log for this user";

    const loading = ref(true);
    const updating = ref(false);

    const store = useStore();
    const route = useRoute();

    const useFormatDate = inject("useFormatDate");
    const fromDate = ref(null);
    const toDate = ref(null);
    const minDate = ref(null);
    const maxDate = ref(null);

    const userId = route.params.userId;

    const showUpdatingDialog = ref(false);

    const eventLog = ref();
    const aggregateIdentityLookup = ref();
    const aggregateSources = ref();
    const selectedAggregateSources = ref({});

    const aggregateOrigins = ref(["BackOffice", "MobileApp", "General"]);
    const selectedAggregateOrigins = ref([]);

    const filtersCollapsed = ref(false);

    const filteredEventLog = computed(() => {
      if (eventLog.value == null) return [];

      return (
        eventLog.value
          // Filter by date range
          .filter(event => {
            return new Date(event.timestamp) >= fromDate.value && new Date(event.timestamp) <= toDate.value;
          })
          // Filter by aggregate source
          .filter(event => {
            if (Object.keys(selectedAggregateSources.value).length === 0) return true; // If no aggregate sources are selected, return true

            // If any key in the selectedAggregateSources object has checked = true, then return true
            const value = selectedAggregateSources.value[event.mainAggregateId];
            return value && value.checked;
          })
          // Filter by aggregate origin
          .filter(event => {
            if (selectedAggregateOrigins.value.length === 0) return true; // If no aggregate origins are selected, return true

            return selectedAggregateOrigins.value.includes(event.eventSource);
          })
      );
    });

    const aggregateSourcesTreeNode = computed(() => {
      return aggregateSources.value.map(aggregateSource => {
        return {
          key: aggregateSource.name,
          label: aggregateSource.name + " events",
          icon: aggregateSourceToIcon(aggregateSource.name),
          children: aggregateSource.aggregateIdentities.map(identity => {
            return {
              key: identity.id,
              label: identity.name
              // icon: aggregateSourceToIcon(aggregateSource.name)
            };
          })
        };
      });
    });

    function aggregateSourceToIcon(aggregateSource) {
      switch (aggregateSource) {
        case "User":
          return "fa fa-user";
        case "UserMemberCard":
          return "fa fa-credit-card";
        case "UserPerk":
          return "fa fa-gift";
        case "UserLocation":
          return "fa fa-map-marker";
        case "UserPromoCode":
          return "fa fa-ticket";
        default:
          return "pi pi-question";
      }
    }

    async function loadRequiredData() {
      loading.value = true;

      await store.dispatch("users/getUserEventLog", { userId: userId });
      const eventLogWrapper = store.getters["users/eventLogWrapper"];
      eventLog.value = eventLogWrapper.eventLog;
      aggregateIdentityLookup.value = eventLogWrapper.aggregateIdentityLookup;
      aggregateSources.value = eventLogWrapper.aggregateSources;
      // selectedAggregateSources.value = aggregateSources.value;
      console.log("Event log: ", JSON.parse(JSON.stringify(eventLog.value)), aggregateIdentityLookup, aggregateSources);

      // Get lowest timestamp in event log
      const minTimestamp = eventLog.value.reduce((min, p) => (p.timestamp < min ? p.timestamp : min), eventLog.value[0].timestamp);
      const maxTimestamp = eventLog.value.reduce((max, p) => (p.timestamp > max ? p.timestamp : max), eventLog.value[0].timestamp);

      minDate.value = new Date(minTimestamp);
      maxDate.value = new Date(maxTimestamp);
      // max date set to end of day
      maxDate.value.setHours(23, 59, 59, 999);

      fromDate.value = minDate.value;
      toDate.value = maxDate.value;

      console.log("from date: ", fromDate.value);
      console.log("to date: ", toDate.value);

      loading.value = false;
    }

    onMounted(async () => {
      await loadRequiredData();
    });

    return {
      pageTitle,
      pageDescription,
      loading,
      updating,

      entityName,

      showUpdatingDialog,

      eventLog,
      aggregateIdentityLookup,
      filteredEventLog,

      aggregateSources,
      aggregateSourceToIcon,
      aggregateSourcesTreeNode,
      selectedAggregateSources,

      aggregateOrigins,
      selectedAggregateOrigins,

      filtersCollapsed,

      minDate,
      maxDate,
      fromDate,
      toDate,
      formatDateTimeFullTimezone: useFormatDate().formatDateTimeFullTimezone,

      loadRequiredData
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.aggregate-color-User {
  color: #2196f3;
}
.aggregate-bgcolor-User {
  background-color: #2196f3;
}
.aggregate-color-UserMemberCard {
  color: #4caf50;
}
.aggregate-bgcolor-UserMemberCard {
  background-color: #4caf50;
}
.aggregate-color-UserPerk {
  color: #ff9800;
}
.aggregate-bgcolor-UserPerk {
  background-color: #ff9800;
}
.aggregate-color-UserLocation {
  color: #9c27b0;
}
.aggregate-bgcolor-UserLocation {
  background-color: #9c27b0;
}
.aggregate-color-UserPromoCode {
  color: #f44336;
}
.aggregate-bgcolor-UserPromoCode {
  background-color: #f44336;
}

.filters-card {
  top: 214px;
  left: 280px;

  transition: left 0.3s ease-in-out;

  .filters-content {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }

  &.collapsed {
    left: -48px;
    // max-height: 64px;
    // overflow: hidden;

    .filters-content {
      pointer-events: none;
      opacity: 0;
    }
  }
}
</style>
