<template>
  <div id="app-sidebar-2" class="surface-section hidden lg:block flex-shrink-0 z-1 border-right-1 surface-border h-full select-none" style="width:280px; background-color: #f4f7f8;">
      <div class="flex flex-column h-full">
          <div class="flex flex-column align-items-center px-5 mt-4">
            <Button @click="toVenues" label="Back to Venues" icon="pi pi-chevron-left" class="w-full p-button-secondary p-button-text p-button-sm" />
            <p class="text-lg font-semibold">Venue Settings</p>
          </div>
          <div class="overflow-y-auto">
              <ul class="list-none p-3 m-0">
                  <li v-for="option in config.options" :key="option.title">
                    <router-link v-if="!option?.role || hasRole(option?.role)" :to="'/venue/' + venueId + option.slug" style="text-decoration: none;" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                        <i class="fa-fw mr-2" :class="option.icon"></i>
                        <span class="font-medium">{{option.title}}</span>
                        <span v-if="option.label" class="inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle" style="min-width: 1.5rem; height: 1.5rem">
                          {{option.label}}
                        </span>
                    </router-link>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'

import { useRouter } from 'vue-router'

export default {
  inject: ['hasRole'],

  props: ['venueId'],
  setup() {
    const loading = ref(false)

    const router = useRouter()

    function toVenues() {
      router.push({ name: 'venues' })
    }

    async function loadRequiredData() {
      loading.value = true
      loading.value = false
    }

    onMounted(async () => {
      await loadRequiredData()
    })
    
    return {
      loading,

      toVenues,

      config: computed(() => {
        const options = [];
        options.push({
            title: 'General',
            icon: 'pi pi-info-circle',
            slug: '/general'
        })

        options.push({
            title: 'NightPay',
            icon: 'pi pi-mobile',
            slug: '/nightpay'
        })

        options.push({
            title: 'Photos',
            icon: 'pi pi-images',
            slug: '/photos'
        })
      
         options.push({
            title: 'OnlinePos',
            icon: 'pi pi-cog',
            slug: '/onlinepos',
            role: "np-dev"
        })


        return {
          options,
        }
      })
    }
  }
};
</script>

<style scoped>
.router-link-exact-active .font-medium, .router-link-exact-active i.pi {
  font-weight: 700 !important;
}
</style>
