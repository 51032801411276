import dayjs from 'dayjs';

export default function useFormatDate() {
    const formatDate = function (dateString) {
        const date = dayjs(dateString);

        if (date.year() === 1 || date.year() === 9999)
            return '-'

        return date.format('dddd MMMM D, YYYY');
    }


    const formatDateSimple = function (dateString) {
        const date = dayjs(dateString);

        if (date.year() === 1 || date.year() === 9999)
            return '-'

        return date.format('DD-MM-YYYY');
    }

    const formatDateTime = function (dateString) {
        const date = dayjs(dateString);

        if (date.year() === 1 || date.year() === 9999)
            return '-'

        return date.format('DD-MM-YYYY HH:mm');
    }

    const formatDateTimeFull = function (dateString) {
        const date = dayjs(dateString);

        if (date.year() === 1 || date.year() === 9999)
            return '-'

        return date.format('DD-MM-YYYY HH:mm:ss');
    }

    const formatDateTimeFullTimezone = function (dateString) {
        const date = dayjs(dateString);

        if (date.year() === 1 || date.year() === 9999)
            return '-'

        return date.format('DD-MM-YYYY HH:mm:ss (Z)');
    }

    return {
        formatDate: formatDate,
        formatDateSimple: formatDateSimple,
        formatDateTime: formatDateTime,
        formatDateTimeFull: formatDateTimeFull,
        formatDateTimeFullTimezone: formatDateTimeFullTimezone
    }
}