import apiClient from '@/services/axios'

export async function get(roleId) {
  var response = await apiClient.get('/roles/' + roleId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Role.')
}
