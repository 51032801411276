import apiClient from '@/services/axios';

export async function put(memberCardId, payload) {
	var benefits = { benefits: payload.benefits };
	var response = await apiClient.put('MemberCardBenefits?memberCardId=' + memberCardId, benefits);
	if (response) {
		return response.data;
	}

	throw new Error('Unable to update MemberCard benefits.');
}