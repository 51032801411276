
import * as apiGetUnsubscribeOptions from '@/api/unsubscribeOptions/getUnsubscribeOptions'
import * as apiGetUnsubscribeOptionStatistics from '@/api/unsubscribeOptions/getUnsubscribeOptionStatistics'
import * as apiAddUnsubscribeOption from '@/api/unsubscribeOptions/addUnsubscribeOption'
import * as apiDeleteUnsubscribeOption from '@/api/unsubscribeOptions/deleteUnsubscribeOption'

export default {
	namespaced: true,
	state: () => ({
		loading: false,
		unsubscribeOptionListItems: [],
        statisticsListItems: []
	}),
	mutations: {
		gotUnsubscribeOptionListItems(state, { unsubscribeOptions }) {
			state.unsubscribeOptionListItems = unsubscribeOptions;
		},

        gotStatisticListItems(state, { statistics }) {
			state.statisticsListItems = statistics;
		},

		isLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async add({ commit }, payload ) {
			commit('isLoading', true);
            console.log(payload)
			const result = await apiAddUnsubscribeOption.post(payload);

			commit('isLoading', false);

			return result;
		},

		async delete({ commit }, entityId) {
			commit('isLoading', true);

			const result = await apiDeleteUnsubscribeOption.del(entityId);

			commit('isLoading', false);

			return result;
		},

		async getUnsubscribeOptionListItems({ commit }) {
			commit('isLoading', true);

			const result = await apiGetUnsubscribeOptions.get();

			if (result) {
				commit('gotUnsubscribeOptionListItems', {
					unsubscribeOptions: result,
				});
			}

			commit('isLoading', false);
		},
        async getUnsubscribeOptionStatisticListItems({ commit }) {
			commit('isLoading', true);

			const result = await apiGetUnsubscribeOptionStatistics.get();
            console.log("RES", result)
			if (result) {
				commit('gotStatisticListItems', {
					statistics: result,
				});
			}

			commit('isLoading', false);
		},

		
	},
	getters: {
		unsubscribeOptionListItems: (state) => {
			return state.unsubscribeOptionListItems;
		},
        statisticListItems: (state) => {
			return state.statisticsListItems;
		}
	},
};
