import apiClient from '@/services/axios';

export async function post(payload) {
	var response = await apiClient.post('/UserPerks/BulkAssignPerks', payload);
	if (response) {
		return response.data;
	}

	throw new Error('Unable to add User Perks.');
}
