import apiClient from '@/services/axios'

export async function put(appContentId, payload) {
  var response = await apiClient.put('/appContentConfigurations/' + appContentId + '/freeuser-card', payload)
  if (response) {
    return response.data
  }

  throw new Error('Unable to update free user Card.')
}
