import apiClient from '@/services/axios'

export async function get() {
  var response = await apiClient.get('/roles/')
  if (response) {
    console.log(response.data)
    return response.data
  }

  throw new Error('Unable to get Roles.')
}
