import apiClient from '@/services/axios'

export async function get(userId, from, to) {
  var query = "?from=" + from 
  to ? query += "&to=" + to : ''

  var response = await apiClient.get('/userPerks/' + userId + query)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get User Perks.')
}
