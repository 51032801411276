import apiClient from '@/services/axios'

export async function get(partnershipId) {
  var response = await apiClient.get('/partnerships/' + partnershipId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Partnership.')
}
