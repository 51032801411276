<template>
	<Sidebar v-if="hasPermission('np.backoffice.promocodes.add')" v-model:visible="createNewSidebarVisible"
		class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="creating" :title="'Creating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Create {{ entityName }}</h3>
			<Form :fields="fields" :context="newEntity" @onAction="createEntity" @onChange="handleFormChange" :actionLabel="'Create'"
				:actionIcon="'pi pi-plus-circle'" />
		</div>
	</Sidebar>

	<Sidebar v-if="hasPermission('np.backoffice.promocodes.editdelete')" v-model:visible="updateSidebarVisible"
		class="p-sidebar-lg" position="right" :showCloseIcon="false">
		<Loading v-if="updating" :title="'Updating ' + entityName + '...'" :full="false" />
		<div v-else>
			<h3>Edit {{ entityName }}</h3>
			<Form :fields="fields" :context="selectedEntity" @onChange="handleFormChange" @onAction="updateEntity" :actionLabel="'Update'"
				:actionIcon="'pi pi-cloud-upload'" />
		</div>
	</Sidebar>

	<div class="surface-section p-6 pt-0" v-if="hasPermission('np.backoffice.promocodes.show')">
		<div class="xl:flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
			<div class="flex xl:justify-content-end xl:pb-0 pb-4 align-items-center">
				<Button v-if="hasPermission('np.backoffice.promocodes.add')" @click="createNewSidebarVisible = true"
					:label="'New ' + entityName" icon="pi pi-plus-circle" />
			</div>
		</div>
		<Toolbar class="mb-2">
				<template #start>
					<div class="flex gap-4">
					<div>
					<h4 class="w-full mt-1 mb-2 text-gray-700">Type filter</h4>
						<Dropdown v-model="filters.type" display="chip"  class="w-28rem"
						:options="typeFilterItems" :filter="false" optionLabel="title"  @change="applyFilter" optionValue="id"  placeholder="Select Promo code types" >
						</Dropdown> 
					</div>
					<div>
						<h4 class="w-full mt-1 mb-2 text-gray-700">Show expired</h4>
						<InputSwitch  v-model="filters.showExpired" @change="applyFilter" />
					</div>
					</div>
					
				</template>
			</Toolbar>
		<Loading v-if="loading" class="mt-6" :full="false" :title="'Loading ' + pageTitle + '...'" />
		<div v-else >
			<div class="card overflow-x-auto">
				<DataTable :value="filteredEntities" dataKey="id" @row-click="onRowClicked" class="p-datatable-md " :rowHover="true"
					responsiveLayout="scroll">
					<template #empty> No {{ pageTitle }} found. </template>
					<template #loading> Loading {{ pageTitle }}. Please wait. </template>
					<Column header="Type">
						<template #body="{ data }">
							<div class="w-6rem">
								<Chip :label="data.promoCodeType" class="chip-enabled" />
							</div>
						</template>
					</Column>
					<Column header="Code" field="code"></Column>
					<Column header="Valid from">
						<template #body="{ data }">
							<div class="text-sm">{{ formatDate(data.validFromUtc) }}</div>
						</template>
					</Column>
					<Column header="Valid to">
						<template #body="{ data }">
							<div class="text-sm">{{ formatDate(data.validToUtc) }}</div>
						</template>
					</Column>
					<Column header="Perk">
						<template #body="{ data }">
							{{ formattedPerks(data.perkConfigs) }}
						</template>
					</Column>
					<Column header="Perk active days" field="perkActiveDays">
						<template #body="{ data }">
							<div class="text-sm">{{ data.perkActiveDays == null ? 7 : data.perkActiveDays }}</div>
						</template>
					</Column>
					<Column header="Trial days" field="freeDays"></Column>
					<Column header="Created by" >
					<template #body="{ data }">
						{{data?.createdByDisplayName}}
					</template>
					</Column>

					<Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
						<template #body="{ data }">
							<Button v-if="hasPermission('np.backoffice.promocodes.editdelete')"
								@click="confirmDeleteEntity(data)" type="button" icon="pi pi-trash"></Button>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { ref, onMounted, inject, computed } from 'vue';

import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';

export default {
	inject: ['hasPermission'],
	components: {},
	setup() {
		const storeActionGetEntities = 'promoCodes/getPromoCodeListItems';
		const storeGetterGetEntities = 'promoCodes/promoCodeListItems';
		const storeActionCreateEntity = 'promoCodes/create';
		const storeActionUpdateEntity = 'promoCodes/update';
		const storeActionDeleteEntity = 'promoCodes/delete';
		const entityName = 'Promo Code';
		const pageTitle = 'Promo Codes';
		const pageDescription = 'Here you can manage your Promo Codes';

		const fields = ref([]);

		const loading = ref(true);

		const creating = ref(false);
		const updating = ref(false);

		const memberCards = ref([]);
		const perks = ref([]);
		const staffUsers = ref([])

		const useFormatDate = inject('useFormatDate');

		const store = useStore();
		const toast = useToast();
		const confirm = useConfirm();

		const entities = ref([]);
		const filteredEntities = computed(()=> {

			var filtered = [...entities.value];
			return filtered
		})

		const newEntity = ref({
			promoCodeType: 'General',
			mustBeTrialPeriodEligible: false,
			campaignName: null,
			redeemLimit: 1,
			code: null,
			userId: { id: null },
			validFromUtc: null,
			validToUtc: null,
			perkActiveDays: 7,
			freeDays: 0,
			perkConfigs: [{ id: null, venueId: null, isFromNightPay: false}],
			partnershipId: { id: null },
			memberCardConfigs: [{ id: null }],
		});

		const selectedEntity = ref({});

		const createNewSidebarVisible = ref(false);
		const updateSidebarVisible = ref(false);

		const filters = ref({
			showExpired: false,
			type: "All"
		})
		

		const typeFilterItems = [
							{ id: 'All', title: 'All' },
							{ id: 'General', title: 'General' },
							{ id: 'Signup', title: 'Signup' },
							{ id: 'OnlySubscribers', title: 'OnlySubscribers' }
						];



		function onRowClicked(payload) {
			selectedEntity.value = JSON.parse(JSON.stringify(payload.data));

			selectedEntity.value.validFromUtc = new Date(selectedEntity.value.validFromUtc);
			selectedEntity.value.validToUtc = new Date(selectedEntity.value.validToUtc);
			selectedEntity.value.perkActiveDays = selectedEntity.value.perkActiveDays == null ? 7 : selectedEntity.value.perkActiveDays;

			handleFormChange(selectedEntity.value)
			updateSidebarVisible.value = true;
		}

		

		

		function getCreatedBy(userId){
			var user = staffUsers.value?.find(x => x.id == userId)
			return user ? user?.firstName + " " + user?.lastName : "Unknown"
		}

		function formattedPerks(perkConfigs) {
			if (perkConfigs.id == null || perkConfigs.length == 0) return '';

			const perkList = [];
			perkConfigs.forEach((perkConfig) => {
				console.log(perkConfig)
				const perk = store.getters['perks/perk'](perkConfig.id);

				if (perk != null) perkList.push(perk.title);
			});

			return perkList.join(', ');
		}

		function confirmDeleteEntity(data) {
			confirm.require({
				message: 'Are you sure you want to delete the ' + entityName + '?',
				header: 'Please confirm',
				icon: 'pi pi-exclamation-triangle',
				accept: async () => {
					loading.value = true;

					try {
						await store.dispatch(storeActionDeleteEntity, data.id);
						await reloadEntities();

						toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' deleted', life: 3000 });
					} catch (err) {
						toast.add({ severity: 'error', summary: 'Error', detail: 'Could not delete ' + entityName + ': ' + err, life: 3000 });
					}

					loading.value = false;
				},
				reject: () => { },
			});
		}

		function handleFormChange(context){
			if(context.promoCodeType == "Signup" && fields.value.findIndex(x => x.id == "freeDays") < 0) 
				fields.value.splice(7,0,	{
					id: 'freeDays',
					type: 'number-input',
					title: 'Free days',
				})
			else if (context.promoCodeType != "Signup") {
				if(fields.value[7].id == "freeDays"){
				fields.value.splice(7,1)

				}
			
			
			}
		}

		async function createEntity(formContext) {
			creating.value = true;

			try {
				formContext.userId.id = 
				await store.dispatch(storeActionCreateEntity, formContext);
				console.log(storeActionCreateEntity);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' created', life: 3000 });
				createNewSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not create ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			creating.value = false;
		}

		async function updateEntity(formContext) {
			updating.value = true;

			try {
				await store.dispatch(storeActionUpdateEntity, formContext);

				toast.add({ severity: 'success', summary: 'Success', detail: entityName + ' updated', life: 3000 });
				updateSidebarVisible.value = false;

				loading.value = true;
				await reloadEntities();
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not update ' + entityName + ': ' + err, life: 3000 });
			}

			loading.value = false;
			updating.value = false;
		}

		async function reloadEntities() {
			try {
				await store.dispatch(storeActionGetEntities, filters.value);
				const loadedEntities = store.getters[storeGetterGetEntities];
				entities.value = loadedEntities;
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load ' + pageTitle + ': ' + err, life: 3000 });
			}
		}


		async function loadMemberCards(){
			try {
				await store.dispatch('memberCards/ensureMemberCardListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Member Cards: ' + err, life: 3000 });
			}
			const loadedMemberCards = store.getters['memberCards/memberCardListItems'];
			memberCards.value = loadedMemberCards;
		}

		async function loadPerks(){
			try {
				await store.dispatch('perks/ensurePerkListItems');
			} catch (err) {
				toast.add({ severity: 'error', summary: 'Error', detail: 'Could not load Perks: ' + err, life: 3000 });
			}
			const loadedPerks = store.getters['perks/perkListItems'];
			perks.value = loadedPerks;
		}

		async function applyFilter(){
				loading.value = true;
				await reloadEntities()
				loading.value = false
		}

		async function loadRequiredData() {
			loading.value = true;

			
			await Promise.all([loadMemberCards(),loadPerks(), reloadEntities()])



			fields.value = [
				{
					id: 'promoCodeType',
					type: 'dropdown',
					title: 'Promo Code type',
					config: {
						options: [
							{ id: 'General', title: 'General' },
							{ id: 'Signup', title: 'Signup' },
							{ id: 'OnlySubscribers', title: 'OnlySubscribers' }
						],
						optionLabel: 'title',
						optionValue: 'id',
						placeholder: 'Select Promo Code',
					},
				},
				
				{
					id: 'campaignName',
					type: 'text-input',
					title: 'Campaign name',
				},
				{
					id: 'redeemLimit',
					type: 'number-input',
					title: 'Redeem limit',
				},
				{
					id: 'code',
					type: 'text-input',
					title: 'Code',
				},
				{
					id: 'validFromUtc',
					type: 'calendar',
					title: 'Valid from',
				},
				{
					id: 'validToUtc',
					type: 'calendar',
					title: 'Valid to',
				},
				{
					id: 'perkActiveDays',
					type: 'number-input',
					title: 'Perk active days',
				},
			
				{
					id: 'perkConfigs',
					type: 'PerkConfigs',
					title: 'Perk Configs',
				},
				{
					id: 'memberCardConfigs',
					type: 'MemberCardConfigs',
					title: 'Member Card Configs',
				}
			];

			loading.value = false;
		}
	

		onMounted(async () => {
			await loadRequiredData();
		});

		return {
			entityName,
			pageTitle,
			pageDescription,
			loading,
			creating,
			updating,

			formattedPerks,
			getCreatedBy,

			fields,
			newEntity,
			selectedEntity,
			createEntity,
			updateEntity,
			confirmDeleteEntity,
			handleFormChange,
			applyFilter,

			memberCards,
			perks,

			entities,
			filteredEntities,

			createNewSidebarVisible,
			updateSidebarVisible,

			typeFilterItems,
			filters,

			onRowClicked,

			formatDate: useFormatDate().formatDate,
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
