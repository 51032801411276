import apiClient from '@/services/axios'

export async function get(memberCardId) {
  var response = await apiClient.get('/memberCards/' + memberCardId)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Member Card.')
}
