import * as apiGetPerkCategories from '@/api/perkCategories/getPerkCategories'

import _ from "lodash";

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        perkCategoryListItems: []
    }),
    mutations: {
        gotPerkCategoryListItems(state, perkCategories) {
            state.perkCategoryListItems = perkCategories
        },

        isLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getPerkCategoryListItems({ commit }) {
            commit('isLoading', true)

            console.log('Getting Perk Category List Items');
        
            const result = await apiGetPerkCategories.get();

            if (result) {
                commit('gotPerkCategoryListItems', result)
            }
        
            commit('isLoading', false)
        },

        async ensurePerkCategoryListItems({ commit, dispatch, getters }) {
            commit('isLoading', true)

            if (getters.perkCategoryListItems.length === 0) {
                await dispatch('getPerkCategoryListItems')
            }

            commit('isLoading', false)
        }
    },
    getters: {
        perkCategoryListItems: (state) => state.perkCategoryListItems,
        perkCategory: (state) => (perkCategoryId) => {
            return  _.find(state.perkCategoryListItems, function(perkCategory) { return perkCategory.id === perkCategoryId; });
        }
    }
};