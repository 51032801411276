import apiClient from '@/services/axios'

export async function get(showExpired, type) {

  console.log(type)
  var query = "?showExpired=" + showExpired;

  if(type != "All" && type != null){
      query += `&type=${type}`
  }

    var response = await apiClient.get('/promoCodes' + query)
  if (response) {
    return response.data
  }

  throw new Error('Unable to get Promo Codes.')
}
