<template>
    <div class="surface-section pb-4">

		<div class="flex">
			<div style="flex: 1">
				<div class="font-bold text-900 text-2xl mt-5 mb-2">{{ pageTitle }}</div>
				<div class="text-700 mb-5 line-height-3 mb-5">{{ pageDescription }}.</div>
			</div>
		</div>
		<Loading v-if="loading"  :title="'Loading receipts'  + '...'" :full="false" />
		<div v-else>
			<!-- <Toolbar class="w-full mb-4">
				<template #start>
					<div class="flex-wrap flex  ">
							<h4 class="w-full text-gray-700 m-0 mb-2">Receipt Type</h4>
						<MultiSelect v-model="selectedStatusFilters" display="chip"  class="w-28rem"
						:options="StatusFilterOptions" :filter="false" :showToggleAll="true" optionLabel="name" placeholder="Select receipt types" >
							<template #header>
								<div class="checkbox-all-text">All</div>
							</template>
						</MultiSelect> 
					</div>
				</template>
			</Toolbar> -->

			<div class="w-full flex flex-wrap gap-4">
				<template v-for="receipt in receipts" :key="receipt?.id" >
					<Receipt :receipt="receipt" />
				</template>
			</div>
		</div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Receipt from "@/components/Receipts/Receipt.vue"
export default {
	inject: ['hasPermission'],
	components:{
		Receipt
	},
	setup() {

        const pageTitle = "Receipts"
		const pageDescription = "Here you can manage the Users Receipts";
		const storeGetterGetEntities = 'userPayments/userPayments';
		const storeActionGetEntities = 'userPayments/getUserPayments';

		const route = useRoute()
		const store = useStore()

		const userId = route.params.userId;
		const receipts = ref([])

		const loading = ref(false)



		async function loadRequiredData() {
			loading.value = true;

			// Load User
			await store.dispatch(storeActionGetEntities, { userId: userId });
			const loadedReceipts = store.getters[storeGetterGetEntities](userId);
			receipts.value = loadedReceipts;

			loading.value = false
		}

		onMounted(async ()=>{
			await loadRequiredData();
		})

      return {
        pageTitle,
        pageDescription,

		receipts,
		loading

        }
    }
}
</script>
