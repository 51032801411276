import apiClient from '@/services/axios'

export async function put(userId) {
  var response = await apiClient.put(`/users/${userId}/resumeMembership`)
  if (response) {
    return response.data
  }

  throw new Error('Unable to resume user membership.')
}
