<template>
<div class="surface-section p-6 pt-0 pb-2">
    <div class="flex">
      <div style="flex: 1">
        <div class="font-bold text-900 text-2xl mt-5 mb-2">
          {{ title }}
          <Chip
          v-if="showEnabledChip"
            v-tooltip="
              `${entityName} is ${
                chipEnabled ? 'enabled' : 'disabled'
              } in the App`
            "
            :class="chipEnabled ? 'bg-green-400' : 'bg-orange-400'"
            class="text-white px-3"
            style="height: 1.5rem"
            :label="chipEnabled ? 'Enabled' : 'Disabled'"
          />
        </div>
        <div class="text-700 mb-5 line-height-3 mb-5">
          {{ description }}.
        </div>
      </div>
      <div class="flex justify-content-end gap-2 align-items-center">
        <Button
        v-if="showCreateButton"
          @click="showCreate"
          :label="'New ' + entityName"
          icon="pi pi-plus-circle"
        />
        <Button
        v-if="showEnabledChip"
          :class="
            !chipEnabled
              ? 'bg-green-400 border-green-400'
              : 'bg-orange-400 border-orange-400'
          "
          :label="chipEnabled ? 'Disable' : 'Enable'"
          :icon="`pi ${chipEnabled ? 'pi-stop' : 'pi-play'}`"
          v-tooltip="
            `${
              !chipEnabled ? 'Enable' : 'Disable'
            } ${entityName} in the App`
          "
          @click="onEnable"
        />
      </div>
    </div>
</div>
</template>

<script >


export default {
  components: {},
  props: ['title', 'description', 'entityName' ,'showEnabledChip', 'chipEnabled', 'showCreateButton'],
  emits: ['onEnable'],
  setup(props, {emit}) {

    function onEnable(){
        emit('onEnable')
    }

    return{
        onEnable
    }
  }
}


</script>
